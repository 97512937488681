export default () => ({
    selectedProcessingProject: null,
    searchQueryData: {
        query: '',
        sort: {icon: 'sort-alphabetical-ascending', text: 'Alphabetically ascending', value: 'ASCENDING'},
        projectType: {icon: '', text: 'All', value: 'ALL'},
        active: {icon: 'filter-off', text: 'All', value: 'ALL'}
    },
    selectedProcessingDevice: null,
    selectedVideoCalibrations: null,
    selectedCalibration: null,
    dpLegendSelection: {
        CREATED: {name: 'Created', value: true, color: '#8C84FF'},
        RUNNING: {name: 'Running', value: true, color: '#D8BA13'},
        COMPLETED: {name: 'Completed', value: true, color: '#1CAB80'},
        NO_TASKS: {name: 'No Tasks', value: true, color: 'black'}
    }
});
