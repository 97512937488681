<template>
  <div>
    <div class="columns ml-0 mr-0">
      <div class="is-size-6 has-text-grey-light has-text-weight-normal mb-2">COMMON INSTALLATION DATA</div>

    </div>

    <div class="p-0 pl-0 m-0">
      <div class="columns m-0 p-0">
        <div class="column p-0 mt-2 ml-0">
          <b-field grouped label="Installation date *" label-position="on-border">
            <b-datepicker :mobile-native="false" expanded v-model="installationInputs.installationDate" size="is-small"
                          position="is-top-right">
              <template v-slot:trigger>
                <b-input expanded readonly rounded placeholder="Installation date *" :value="installationDateString"
                         type="text"
                         required/>
              </template>
            </b-datepicker>
          </b-field>
        </div>
        <div class="column p-0 mt-2 ml-2">
          <b-field grouped label="Decommission date *" label-position="on-border">
            <b-datepicker :mobile-native="false" expanded v-model="installationInputs.decommissionDate" size="is-small"
                          position="is-top-right">
              <template v-slot:trigger>
                <b-input expanded readonly rounded placeholder="Decommission date *" :value="decommissionDateString"
                         type="text"
                         required/>
              </template>
            </b-datepicker>
          </b-field>
        </div>
        <div class="column p-0 ml-2 mt-1">
          <b-field expanded label="Installation crew" label-position="on-border" class="mt-1">
            <b-dropdown
                expanded
                v-model="installationInputs.installationCrew"
                multiple
                aria-role="list">
              <template #trigger>
                <my-button
                    expanded
                    style="border-radius: 32px"
                    icon-right="menu-down">
                  <div class="is-flex" v-if="installationInputs.installationCrew.length > 0">
                    <user-avatar v-for="user in installationInputs.installationCrew" :user="user" :is-small="true"
                                 :key="user.id"></user-avatar>
                  </div>
                  <div v-else class="has-text-grey-light">
                    Installation crew
                  </div>

                </my-button>
              </template>
              <b-dropdown-item v-for="user in users" :value="user" :key="user.id" aria-role="listitem">
                <div class="is-flex m-0 p-0">
                  <div class="m-0 p-0">
                    <user-avatar :user="user" :is-small="true"></user-avatar>
                  </div>
                  <div class="m-0 ml-2 p-0">
                    <div class="is-size-7 has-text-weight-semibold" style="line-height: 1">{{ user.fullName }}</div>
                    <div class="is-size-7" style="line-height: 1"> {{user.partEmail}}</div>
                  </div>
                </div>

              </b-dropdown-item>
            </b-dropdown>
          </b-field>
        </div>
        <div class="column p-0 ml-2 mt-1">
          <b-field expanded label="Decommission crew" label-position="on-border" class="mt-1">
            <b-dropdown
                expanded
                v-model="installationInputs.decommissionCrew"
                multiple
                aria-role="list">
              <template #trigger>
                <my-button
                    expanded
                    style="border-radius: 32px"
                    icon-right="menu-down">
                  <div class="is-flex" v-if="installationInputs.decommissionCrew.length > 0">
                    <user-avatar v-for="user in installationInputs.decommissionCrew" :user="user" :is-small="true"
                                 :key="user.id"></user-avatar>
                  </div>
                  <div v-else class="has-text-grey-light">
                    Decommission crew
                  </div>

                </my-button>
              </template>
              <b-dropdown-item v-for="user in users" :value="user" :key="user.id" aria-role="listitem">
                <div class="is-flex m-0 p-0">
                  <div class="m-0 p-0">
                    <user-avatar :user="user" :is-small="true"></user-avatar>
                  </div>
                  <div class="m-0 ml-2 p-0">
                    <div class="is-size-7 has-text-weight-semibold" style="line-height: 1">{{ user.fullName }}</div>
                    <div class="is-size-7" style="line-height: 1"> {{user.partEmail}}</div>
                  </div>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </b-field>
        </div>
        <div class="ml-4 mr-0 pr-0 mt-3 is-flex">
          <b-tooltip position="is-left" label="Copy to selected" class="ml-auto mr-1" :delay="1000" type="is-dark">
            <b-button @click="onApplyToSelection" size="is-small" type="is-primary" outlined
                      icon-right="check-all"></b-button>
          </b-tooltip>
          <b-tooltip position="is-left" label="Copy to all" :delay="1000" type="is-dark">
            <b-button @click="onApplyToAll" size="is-small" type="is-primary" outlined
                      icon-right="content-copy"></b-button>
          </b-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/avatar/avatar.vue";
import MyButton from "@/components/my-button/my-button.vue";
import {getDate} from "@/utils/utils";

export default {
  name: "installation-inputs",
  components: {MyButton, UserAvatar},
  data() {
    return {
      installationInputs: {
        installationDate: null,
        decommissionDate: null,
        installationCrew: [],
        decommissionCrew: []
      }
    }
  },
  methods: {
    onApplyToSelection() {
      console.log('On Apply to selection', this.installationInputs)
      this.$emit('onCopyToSelection', this.installationInputs)
    },
    onApplyToAll() {
      console.log('On apply to all', this.installationInputs)
      this.$emit('onCopyToAll', this.installationInputs)
    }
  },
  computed: {
    users() {
      return this.$store.getters.getAllowedUsers
    },
    installationDateString() {
      return this.installationInputs.installationDate ? getDate(this.installationInputs.installationDate) : ''
    },
    decommissionDateString() {
      return this.installationInputs.decommissionDate ? getDate(this.installationInputs.decommissionDate) : ''
    },
  }
}
</script>

<style scoped>

</style>