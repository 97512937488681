<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>

    <div class="box m-0 p-0" style="background: #fafafa">
      <transition name="fade" mode="out-in">
        <search-box-assessment ref="searchBoxMonitoring" v-if="selectedProject === null"></search-box-assessment>
      </transition>
      <transition name="fade" mode="out-in" v-if="selectedProject !== null && monitoringData !== null">
        <div class="box pl-5 pr-5">
          <div class="is-flex">
            <div class="is-size-5 has-text-weight-semibold mt-2">{{ selectedProject.name }}</div>
            <b-icon icon="close" class="ml-auto" @click.native="closeProject"></b-icon>
          </div>

          <div class="columns mt-4">
            <div class="column">
              <div class="box">
                <b-icon icon="video" class="has-text-grey"></b-icon>
                <div class="mt-4 is-size-7 has-text-grey-light">NUMBER OF CAMERAS</div>
                <div class="is-size-4 has-text-weight-bold">{{ monitoringData.cameras.length }}</div>
              </div>
            </div>
            <div class="column">
              <div class="box">
                <b-icon icon="file" class="has-text-grey"></b-icon>
                <div class="mt-4 is-size-7 has-text-grey-light">NUMBER OF FILES</div>
                <div class="is-size-4 has-text-weight-bold">{{ monitoringData.numberOfFiles }}</div>
              </div>
            </div>
            <div class="column">
              <div class="box">
                <b-icon icon="filmstrip" class="has-text-grey"></b-icon>
                <div class="mt-4 is-size-7 has-text-grey-light">AMMOUNT OF FOOTAGE</div>
                <div class="is-size-4 has-text-weight-bold">{{ monitoringData.amountOfFootage }}</div>
              </div>
            </div>
          </div>
          <div class="modal-tab-content">
            <div class="mt-5 is-size-5 has-text-weight-semibold">All devices</div>
            <div class="is-size-6 has-text-grey-light">{{ monitoringData.allMissingFootageMsg }}</div>

            <g-map-assessment class="mb-4 mt-4"></g-map-assessment>

            <div class="mt-3" style="margin-bottom: 120px">
              <div v-for="key in Object.keys(cameraRows)"
                   :key="key">
                <div class="columns">
                  <div class="column">
                    <div class="box p-3"
                         :class="cameraRows[key].selected && cameraRows[key].selected.name && cameraRows[key].selected.name === cameraRows[key].data[0].name ? 'selected' : 'unselected'"
                         v-if="cameraRows[key].data[0]">
                      <div class="is-size-6 has-text-weight-semibold">{{ cameraRows[key].data[0].name }}</div>
                      <div class="is-size-7 mt-3 is-flex">
                        <div class="has-text-grey-light">Total footage so far:</div>
                        <div class="has-text-weight-semibold ml-1"> {{ cameraRows[key].data[0].totalFootage }}</div>
                      </div>
                      <div class="is-size-7 is-flex">
                        <div class="has-text-grey-light">Footage missing so far:</div>
                        <div class="has-text-weight-semibold ml-1"> {{
                            cameraRows[key].data[0].footageMissing
                          }}
                        </div>
                        <b-button class="ml-auto" size="is-small"
                                  @click="onClick(cameraRows[key].data[0], `${key}`)">
                          Details
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <div class="box p-3"
                         :class="cameraRows[key].selected && cameraRows[key].selected.name && cameraRows[key].selected.name === cameraRows[key].data[1].name ? 'selected' : 'unselected'"
                         v-if="cameraRows[key].data[1]">
                      <div class="is-size-6 has-text-weight-semibold">{{ cameraRows[key].data[1].name }}</div>
                      <div class="is-size-7 mt-3 is-flex">
                        <div class="has-text-grey-light">Total footage:</div>
                        <div class="has-text-weight-semibold ml-1"> {{ cameraRows[key].data[1].totalFootage }}</div>
                      </div>
                      <div class="is-size-7 is-flex">
                        <div class="has-text-grey-light">Footage missing:</div>
                        <div class="has-text-weight-semibold ml-1"> {{
                            cameraRows[key].data[1].footageMissing
                          }}
                        </div>
                        <b-button class="ml-auto" size="is-small"
                                  @click="onClick(cameraRows[key].data[1], `${key}`)">
                          Details
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <div class="box p-3"
                         :class="cameraRows[key].selected && cameraRows[key].selected.name && cameraRows[key].selected.name === cameraRows[key].data[2].name ? 'selected' : 'unselected'"
                         v-if="cameraRows[key].data[2]">
                      <div class="is-size-6 has-text-weight-semibold">{{ cameraRows[key].data[2].name }}</div>
                      <div class="is-size-7 mt-3 is-flex">
                        <div class="has-text-grey-light">Total footage:</div>
                        <div class="has-text-weight-semibold ml-1"> {{ cameraRows[key].data[2].totalFootage }}</div>
                      </div>
                      <div class="is-size-7 is-flex">
                        <div class="has-text-grey-light">Footage missing:</div>
                        <div class="has-text-weight-semibold ml-1"> {{
                            cameraRows[key].data[2].footageMissing
                          }}
                        </div>
                        <b-button class="ml-auto" size="is-small"
                                  @click="onClick(cameraRows[key].data[2], `${key}`)">
                          Details
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <div class="box p-3"
                         :class="cameraRows[key].selected && cameraRows[key].selected.name && cameraRows[key].selected.name === cameraRows[key].data[3].name ? 'selected' : 'unselected'"
                         v-if="cameraRows[key].data[3]">
                      <div class="is-size-6 has-text-weight-semibold">{{ cameraRows[key].data[3].name }}</div>
                      <div class="is-size-7 mt-3 is-flex">
                        <div class="has-text-grey-light">Total footage:</div>
                        <div class="has-text-weight-semibold ml-1"> {{ cameraRows[key].data[3].totalFootage }}</div>
                      </div>
                      <div class="is-size-7 is-flex">
                        <div class="has-text-grey-light">Footage missing:</div>
                        <div class="has-text-weight-semibold ml-1"> {{
                            cameraRows[key].data[3].footageMissing
                          }}
                        </div>
                        <b-button class="ml-auto" size="is-small"
                                  @click="onClick(cameraRows[key].data[3], `${key}`)">
                          Details
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
                <b-collapse
                    class="mt-1 mb-4"
                    :ref="key"
                    animation="slide"
                    :open="false"
                    :aria-id="'contentId' + key">
                  <div class="card-content p-1">
                    <div class="content" :key="refresh">
                      <div v-if="cameraRows[key].isSelected">
                        <div class="is-flex">
                          <div class="is-size-5 has-text-weight-semibold">Device details</div>
                          <b-icon icon="close" class="ml-auto" @click.native="onClose(`${key}`)"></b-icon>
                        </div>

                        <b-field class="is-flex-grow-1 mr-1 mt-4">
                          <b-select placeholder="Select date" size="is-small" @input="onDateSelected" v-model="selectedDateFilter">
                            <option
                                v-for="option in dates(cameraRows[key].selected)"
                                :value="option"
                                :key="option">
                              {{ option }}
                            </option>
                          </b-select>
                        </b-field>

                        <apex-recording-chart :planned-times="cameraRows[key].selected.plannedCollectionTimes"
                                              :existing-times="cameraRows[key].selected.existsCollectionTimes"
                                              :missing-times="cameraRows[key].selected.missingCollectionTimes"
                                              :incorrect-times="cameraRows[key].selected.irregularConditionedFiles"
                                              :unfitted-times="cameraRows[key].selected.unfittedConditionalFiles"
                                              :selected-date="selectedDateFilter">

                        </apex-recording-chart>
                        <files-data-view :camera="cameraRows[key].selected" :date-filter="selectedDateFilter"></files-data-view>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <div v-else style="height: 100vh" class="is-flex is-flex-direction-column">
        <div class="is-flex is-align-items-center dashed-border mt-auto mb-auto ml-4 mr-4">
          <h4 class="is-size-4 ml-auto mr-auto has-text-grey-light has-text-weight-light">Please select the project</h4>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ApexRecordingChart from "@/modules/assessment-module/recording-chart/apex-recording-chart.vue";
import {mapActions, mapGetters} from "vuex";

import {
  getDate,
  getTime,
  getDatesBetween,
  getDateTime,
  getDateTimeInTimeZone,
  formatTimeInTimeZone
} from "@/utils/utils";
import FilesDataView from "@/modules/assessment-module/components/files-data-view/files-data-view.vue";
import SearchBoxAssessment
  from "@/modules/assessment-module/components/search-box-assessment/search-box-assessment.vue";
import GMapAssessment from "@/modules/assessment-module/components/g-map-assessment/g-map-assessment.vue";

export default {
  name: "assessment-page",
  components: {GMapAssessment, SearchBoxAssessment, ApexRecordingChart, FilesDataView},
  data() {
    return {
      isLoading: false,
      selectedTab: 0,
      refresh: false,
      selectedDateFilter: getDate(new Date(), 'YYYY-MM-DD')
    }
  },
  async created() {
    if (this.allProjects.length === 0) {
      this.isLoading = true
      await this.getAllProjects()
      this.isLoading = false
    }
  },
  methods: {
    formatTimeInTimeZone,
    getDateTimeInTimeZone,
    onDateSelected(date) {
      console.log('On Date Selected', date)
      this.selectedDateFilter = date
    },
    getDateTime(date) {
      return getDateTime(date)
    },
    ...mapActions({
      setSelectedMonitoringProject: 'assessmentModule/getMonitoringProject',
      getAllProjects: 'projectsModule/getAllProjects'
    }),
    closeProject() {
      this.setSelectedMonitoringProject(null)
    },
    onClose(ref) {
      this.selectedDateFilter = getDate(new Date(), 'YYYY-MM-DD')
      this.$refs[ref][0].toggle()
      this.cameraRows[ref].selected = null
      this.cameraRows[ref].isSelected = false
      this.refresh = !this.refresh
    },
    onClick(item, ref) {
      let datesAvailable = this.dates(item)
      if(datesAvailable && datesAvailable.length > 0){
        this.selectedDateFilter = datesAvailable[0]
      }

      this.$refs[ref][0].toggle()

      setTimeout(() => {
        console.log('On click: ', item, this.$refs[ref][0].isOpen)
        if (this.$refs[ref][0].isOpen) {
          this.cameraRows[ref].selected = item
          this.cameraRows[ref].isSelected = true

          console.log('Project site plans', this.selectedProject.projectSitePlans)
          if (this.selectedProject.projectSitePlans && this.selectedProject.projectSitePlans.length > 0) {
            //find plan for device by device name

            let devicePlan

            this.selectedProject.projectSitePlans.forEach(plan => {
              if (plan.listOfPlanDevices && plan.listOfPlanDevices.length > 0) {
                let devIndex = plan.listOfPlanDevices.findIndex(planDevice => planDevice.name === this.cameraRows[ref].selected.name)
                if (devIndex !== -1) {
                  devicePlan = plan.listOfPlanDevices[devIndex]
                }
              }
            })

            console.log('Device plan: ', devicePlan)

            let range = devicePlan.collectionPeriod.dateRange
            let timeRanges = devicePlan.collectionPeriod.timeRanges
            console.log('Range: ', range)
            console.log('Time Ranges: ', timeRanges)

            let startDate = new Date(range[0])
            let endDate = new Date(range[1])

            let dates = getDatesBetween(startDate, endDate, timeRanges)
            this.cameraRows[ref].selected.graphData = dates

            dates.forEach(date => {
              console.log('Date: ', date)
            })
          }
        } else {
          this.cameraRows[ref].selected = null
          this.cameraRows[ref].isSelected = false
        }

        this.refresh = !this.refresh

        console.log(this.cameraRows[ref])
      }, 50)

    },
    dates(camera) {
      let hours = {}
      if (camera) {
          camera.plannedCollectionTimes.forEach(time => {
            hours[getDate(time.start, 'YYYY-MM-DD')] = 'test'
            hours[getDate(time.end,'YYYY-MM-DD')] = 'test'
          })

        camera.unfittedConditionalFiles.forEach(time => {
          hours[getDate(time.start, 'YYYY-MM-DD')] = 'test'
          hours[getDate(time.end,'YYYY-MM-DD')] = 'test'
        })
      }

      let hoursArray = Object.keys(hours)
      hoursArray = hoursArray.sort(function(a, b){ return new Date(b) - new Date(a);})

      return hoursArray

    },

  },
  computed: {
    ...mapGetters({
      selectedProject: 'assessmentModule/getSelectedMonitoringProject',
      allProjects: 'projectsModule/getAllProjects',
      monitoringData: 'assessmentModule/getMonitoringData'
    }),
    cameraRows() {
      let cameras = []
      if (this.monitoringData && this.monitoringData.cameras) {
        cameras = this.monitoringData.cameras
      }

      let chunks = Array.from({length: Math.ceil(cameras.length / 4)}, (v, i) =>
          cameras.slice(i * 4, i * 4 + 4)
      );

      console.log('Chunks: ', chunks)

      let rows = {}

      chunks.forEach((chunk, index) => {
        let id = 'Row' + index
        rows[id] = {}
        rows[id].data = chunk
        rows[id].isSelected = false
        rows[id].selected = ''
      })

      return rows
    },
    cameras() {
      let cameras = []
      if (this.monitoringData && this.monitoringData.cameras) {
        cameras = this.monitoringData.cameras.map(camera => camera.name)
      }

      return cameras
    }
  }
}
</script>

<style scoped lang="scss">
.unselected {
  background-color: hsl(0, 0%, 98%);
  box-shadow: none;
  border-radius: 8px;
  border-style: solid;
  border-color: #ececec;
  border-width: 1px;
}

.selected {
  background-color: hsl(0deg, 0%, 100%);
  box-shadow: 0 0.2em 1em -0.075em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
  border-radius: 8px;
  border-style: solid;
  border-color: #f5f5f5;
  border-width: 1px;
}

</style>