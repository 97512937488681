<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <b-modal v-model="modalActive" width="90%" scroll="clip" style="z-index: 101" @close="clearFields()">
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head" style="padding: 12px">
          <h6 class="is-size-5 ml-auto mr-auto pl-4 has-text-weight-semibold">
            {{ devices.length > 1 ? 'Edit Site Plan Devices' : 'Edit Site Plan Device' }}</h6>
          <button
              type="button"
              class="delete"
              @click="show(false, '', deselectOnClose)"/>
        </header>
        <section class="modal-card-body p-0" style="overflow: unset">
          <map-input ref="mapBoxInput" v-if="mapType === 'mapbox'"
                     @onMapInputClicked="onMapInputClicked"
                     @onCoordinatesUpdated="onCoordinatesUpdated"
                     @onRotationChanged="onRotationChanged"
                     @onMarkerClicked="onSelectDevice"
                     :devices="editedDevices"
                     :selected-device="selectedDevice"
                     :selected-project="selectedProject"
                     draw-controls-mode="edit"
                     @onMapClicked="onMapClicked">

          </map-input>
          <g-map-input ref="gMapInput" v-if="mapType === 'gmap'" style="max-height: 35vh;"
                       @onMapInputClicked="onMapInputClicked"
                       @onCoordinatesUpdated="onCoordinatesUpdated"
                       @onRotationChanged="onRotationChanged"
                       @onMarkerClicked="onSelectDevice"
                       :is-markers-selectable="true"
                       :devices="editedDevices"
                       :selected-device="selectedDevice"
                       :selected-project="selectedProject"
                       :show-edit-location="selectedTab === 0"
                       :site-location="editedDevices.length === 1 ? editedDevices[0].siteLocation : null"
                       :site-name="editedDevices.length === 1 ? editedDevices[0].siteName : null"
                       draw-controls-mode="edit"
                       @onMapClicked="onMapClicked"
                       @onMapEditAddressChanged="onMapEditAddressChanged"
          ></g-map-input>
          <div>
            <div class="ml-0 mr-0 mb-0 mt-3">
              <b-tabs type="is-toggle" v-model="selectedTab" class="ml-4 mt-5">
                <b-tab-item label="Locations" icon="map-marker">
                  <div class="modal-tab-content">
                    <hr class="m-0">
                    <div class="box simple-device-card p-2 pl-5 m-0 mt-2" style="cursor: pointer"
                         v-for="device in editedDevices"
                         :key="device._id" :class="{'selected':selectedDevice === device}">
                      <div class="columns m-0 p-0 is-align-items-center">
                        <div class="column p-0 is-2" @click="onSelectDevice(device)">
                          <div class="is-size-7 has-text-grey-light">Site name</div>
                          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ device.siteName }}</div>
                        </div>
                        <div class="column p-0 is-2" @click="onSelectDevice(device)">
                          <div class="is-size-7 has-text-grey-light">Device Type</div>
                          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ device.type }}</div>
                        </div>
                        <div class="column p-0 is-2" @click="onSelectDevice(device)">
                          <div class="is-size-7 has-text-grey-light">Device Name</div>
                          <div class="is-size-6 has-text-weight-semibold has-text-black">
                            {{ device.name ? device.name : 'Undefined' }}
                          </div>
                        </div>
                        <div class="column p-0 is-4" @click="onSelectDevice(device)">
                          <div class="is-size-7 has-text-grey-light">Location</div>
                          <div class="is-size-6 has-text-weight-semibold has-text-black">
                            {{ device.location.coordinates }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab-item>
                <b-tab-item label="Configuration" icon="cog">
                  <div class="modal-tab-content">
                    <hr class="m-0">
                    <div class="box simple-device-card p-2 pl-5 m-0 mt-2 pr-0" v-for="device in editedDevices"
                         :key="device._id" :class="{'selected':selectedDevice === device}">
                      <div class="columns m-0 p-0 is-align-items-center">
                        <div class="column p-0 is-3" @click="onSelectDevice(device)" style="cursor: pointer">
                          <div class="is-size-7 has-text-grey-light">Site Name</div>
                          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ device.siteName }}</div>
                        </div>
                        <div class="column p-0 is-2" @click="onSelectDevice(device)" style="cursor: pointer">
                          <div class="is-size-7 has-text-grey-light">Device type</div>
                          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ device.type }}</div>
                        </div>
                        <div class="column p-0" @click="onSelectDevice(device)" style="cursor: pointer">
                          <div class="is-size-7 has-text-grey-light">Device Name</div>
                          <div class="is-size-6 has-text-weight-semibold has-text-black">
                            {{ device.name ? device.name : 'Undefined' }}
                          </div>
                        </div>
                        <div class="column m-0 p-0 is-flex is-1">
                          <b-tooltip v-if="selectedDevice === device  && devices.length > 1" label="Copy to all"
                                     class="ml-auto mr-2"
                                     :delay="1000" type="is-dark">
                            <a @click="onCopyConfigurationClick(device)">
                              <b-icon icon="content-copy">
                              </b-icon>
                            </a>
                          </b-tooltip>
                        </div>
                      </div>
                      <configuration-input :device-type="device.type" class="mt-4 mr-3" :key="device._id + changes"
                                           title="CONFIGURATION" @onDataChanged="onConfigurationChanged"
                                           :device="device"></configuration-input>
                    </div>
                  </div>
                </b-tab-item>
                <b-tab-item label="Instructions" icon="information">
                  <div class="modal-tab-content">
                    <hr class="m-0">
                    <div class="box simple-device-card p-2 pl-5 m-0 mt-2"
                         v-for="device in editedDevices"
                         :key="device._id" :class="{'selected':selectedDevice === device}">
                      <div class="columns m-0 p-0 is-align-items-center">
                        <div class="column p-0 is-2" @click="onSelectDevice(device)" style="cursor: pointer">
                          <div class="is-size-7 has-text-grey-light">Site name</div>
                          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ device.siteName }}</div>
                        </div>
                        <div class="column p-0 is-2" @click="onSelectDevice(device)" style="cursor: pointer">
                          <div class="is-size-7 has-text-grey-light">Device Type</div>
                          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ device.type }}</div>
                        </div>
                        <div class="column p-0 is-2" @click="onSelectDevice(device)" style="cursor: pointer">
                          <div class="is-size-7 has-text-grey-light">Device Name</div>
                          <div class="is-size-6 has-text-weight-semibold has-text-black">
                            {{ device.name ? device.name : 'Undefined' }}
                          </div>
                        </div>
                        <div class="column p-0">
                          <b-field>
                            <b-input v-model="device.instruction" placeholder="Installation Instruction"
                                     rounded></b-input>
                          </b-field>
                          <b-field>
                            <b-input v-model="device.decommissionInstruction" placeholder="Decommission Instruction"
                                     rounded></b-input>
                          </b-field>
                        </div>
                        <div class="column p-0 is-flex is-1" style="max-width: 64px">
                          <div class="is-flex is-flex-direction-column ml-auto mr-2">
                            <b-tooltip v-if="selectedDevice === device  && devices.length > 1" label="Copy to all"
                                       class="mb-3" position="is-left"
                                       :delay="1000" type="is-dark">
                              <a @click="onCopyInstructionClick(device)">
                                <b-icon icon="content-copy">
                                </b-icon>
                              </a>
                            </b-tooltip>
                            <b-tooltip v-if="selectedDevice === device  && devices.length > 1" label="Copy to all"
                                       class="mt-3" position="is-left"
                                       :delay="1000" type="is-dark">
                              <a @click="onCopyDecommissionInstructionClick(device)">
                                <b-icon icon="content-copy">
                                </b-icon>
                              </a>
                            </b-tooltip>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab-item>
                <b-tab-item label="TODO Lists" icon="clipboard-list">
                  <div class="modal-tab-content">
                    <hr class="m-0">
                    <div class="box simple-device-card p-2 pl-5 m-0 mt-2 pr-0" v-for="device in editedDevices"
                         :key="device._id" :class="{'selected':selectedDevice === device}">
                      <div class="columns m-0 p-0 is-align-items-center">
                        <div class="column p-0 is-3" @click="onSelectDevice(device)" style="cursor: pointer">
                          <div class="is-size-7 has-text-grey-light">Site Name</div>
                          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ device.siteName }}</div>
                        </div>
                        <div class="column p-0 is-2" @click="onSelectDevice(device)" style="cursor: pointer">
                          <div class="is-size-7 has-text-grey-light">Device type</div>
                          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ device.type }}</div>
                        </div>
                        <div class="column p-0" @click="onSelectDevice(device)" style="cursor: pointer">
                          <div class="is-size-7 has-text-grey-light">Device Name</div>
                          <div class="is-size-6 has-text-weight-semibold has-text-black">
                            {{ device.name ? device.name : 'Undefined' }}
                          </div>
                        </div>
                        <div class="column m-0 p-0 is-flex is-1">
                          <b-tooltip v-if="selectedDevice === device  && devices.length > 1" label="Copy to all"
                                     class="ml-auto mr-2" position="is-left"
                                     :delay="1000" type="is-dark">
                            <a @click="onCopyToDoClick(device)">
                              <b-icon icon="content-copy">
                              </b-icon>
                            </a>
                          </b-tooltip>
                        </div>
                      </div>
                      <todo-list-input :device-type="device.type" class="mt-4 mr-3" :key="device._id + changes"
                                       title="TODO LIST" @onInstallDataChanged="onInstallTodoListChanged"
                                       @onDecommissionDataChanged="onDecommissionTodoListChanged"
                                       :device="device"></todo-list-input>
                    </div>
                  </div>
                </b-tab-item>
                <b-tab-item label="Collection/Proccessing Periods" icon="calendar-range">
                  <div class="modal-tab-content">
                    <hr class="m-0">
                    <div class="box simple-device-card p-2 pl-5 m-0 mt-2 pr-0" v-for="device in editedDevices"
                         :key="device._id" :class="{'selected':selectedDevice === device}">
                      <div class="columns m-0 p-0 is-align-items-center">
                        <div class="column p-0 is-3" @click="onSelectDevice(device)" style="cursor: pointer">
                          <div class="is-size-7 has-text-grey-light">Site Name</div>
                          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ device.siteName }}</div>
                        </div>
                        <div class="column p-0 is-2" @click="onSelectDevice(device)" style="cursor: pointer">
                          <div class="is-size-7 has-text-grey-light">Device type</div>
                          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ device.type }}</div>
                        </div>
                        <div class="column p-0" @click="onSelectDevice(device)" style="cursor: pointer">
                          <div class="is-size-7 has-text-grey-light">Device Name</div>
                          <div class="is-size-6 has-text-weight-semibold has-text-black">
                            {{ device.name ? device.name : 'Undefined' }}
                          </div>
                        </div>
                        <div class="column m-0 p-0 is-flex is-1">
                          <b-tooltip v-if="selectedDevice === device  && devices.length > 1" label="Copy periods to all"
                                     class="ml-auto mr-2" position="is-left"
                                     :delay="1000" type="is-dark">
                            <a @click="onCopyPeriodsClick(device)">
                              <b-icon icon="content-copy">
                              </b-icon>
                            </a>
                          </b-tooltip>
                        </div>
                      </div>

                      <collection-processing-input
                          class="mt-4 ml-0"
                          :show-copy-actions="false"
                          :initial-collection-period="device.collectionPeriod"
                          :initial-processing-period="device.processingPeriod"
                          :initial-processing-periods="device.processingPeriods"
                          @collectionPeriodChanged="onCollectionPeriodChanged(device, $event)"
                          @processingPeriodChanged="onProcessingPeriodChanged(device, $event)"
                          @processingPeriodsChanged="onProcessingPeriodsChanged(device, $event)">
                      </collection-processing-input>
                    </div>
                  </div>
                </b-tab-item>
              </b-tabs>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot" style="padding: 0">
          <b-button class="is-primary is-fullwidth m-2" @click="onUpdateDevices" rounded>
            {{ devices.length > 1 ? 'UPDATE DEVICES' : 'UPDATE DEVICE' }}
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MapInput from "@/components/map-input/map-input.vue";
import ConfigurationInput from "@/modules/projects-module/components/configuraton-input/configuration-input.vue";
import {mapActions, mapGetters} from "vuex";
import { DeviceType, TaskType} from "@/utils/utils";
import GMapInput from "@/components/g-map-input/g-map-input.vue";
import {getUpdateSitePlanDevicesPayload} from "@/utils/helper";
import TodoListInput from "@/modules/projects-module/components/todo-list-input/todo-list-input.vue";
import CollectionProcessingInput
  from "@/modules/projects-module/components/collection-processing-input/collection-processing-input.vue";

export default {
  name: "edit-devices",
  components: {CollectionProcessingInput, TodoListInput, ConfigurationInput, MapInput, GMapInput},
  props: {
    devices: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      isLoading: false,
      modalActive: false,
      deviceType: 'CAMERA',
      editedDevices: [],
      selectedDevice: null,
      selectedTab: 0,
      deselectOnClose: false,
      clickedMarker: false,
      changes: 0,
    }
  },
  mounted() {
    console.log('Devices: ', this.devices)
    this.setDevices()
  },
  methods: {
    ...mapActions({
      getLocation: 'getLocationData',
      deselectAllDevices: 'projectsModule/deselectAllDevices',
      updateSitePlanDevices: 'projectsModule/updateSitePlanDevices'
    }),

    onCollectionPeriodChanged(dev, event){
        console.log('On Collection Period Changed: ', dev, event)
        let index = this.editedDevices.findIndex(device => device._id === dev._id)
        if (index !== -1) {
          this.editedDevices[index].collectionPeriod = event
        }
    },
    onProcessingPeriodChanged(dev, event){
        console.log('On Processing Period Changed: ', dev, event)
        let index = this.editedDevices.findIndex(device => device._id === dev._id)
        if (index !== -1) {
          this.editedDevices[index].processingPeriod = event
        }
    },
    onProcessingPeriodsChanged(dev, event){
      console.log('On Processing PeriodS Changed: ', dev, event)
      let index = this.editedDevices.findIndex(device => device._id === dev._id)
      if (index !== -1) {
        this.editedDevices[index].processingPeriods = event
      }
    },
    show(show, action, deselectOnClose) {
      this.modalActive = show
      this.deselectOnClose = deselectOnClose
      if (!this.modalActive) {
        this.clearFields()
      }else{
        this.isLoading = true
        if (action === 'location') {
          this.selectedTab = 0
        } else if (action === 'configuration') {
          this.selectedTab = 1
        } else if (action === 'instruction') {
          this.selectedTab = 2
        } else if (action === 'todo') {
          this.selectedTab = 3
        }else if (action === 'periods') {
          this.selectedTab = 4
        }
        setTimeout(()=>{
          this.setDevices()
          console.log('Show edit device:', this.deselectOnClose, this.editedDevices)
          this.isLoading = false
        }, 500)

      }
    },
    setDevices() {
      this.editedDevices = JSON.parse(JSON.stringify(this.devices))
      console.log('Edited devices: ', this.editedDevices)
      this.editedDevices.forEach(device => {
        let installTask = device.tasks.find(task => task.taskType === TaskType.INSTALLATION)
        let decommissionTask = device.tasks.find(task => task.taskType === TaskType.DECOMMISSION)

        if (installTask) {
          device.instruction = installTask.instruction
          device.todos = installTask.todos
        }

        if (decommissionTask) {
          device.decommissionInstruction = decommissionTask.instruction
          device.decommissionTodos = decommissionTask.todos
        }

        if(device.collectionPeriod === null || device.collectionPeriod === undefined){
          device.collectionPeriod =  {dateRange: [], timeRanges: [{startTime: null, endTime: null}]}
        }
        if(device.processingPeriod === null || device.processingPeriod === undefined){
          device.processingPeriod =  {dateRange: [], timeRanges: [{startTime: null, endTime: null}]}
        }
        if(device.processingPeriods === null || device.processingPeriods === undefined){
          device.processingPeriods =  [{dateRange: [], timeRanges: [{startTime: null, endTime: null}]}]
        }

      })
    },
    onConfigurationChanged(data) {
        console.log('On configuration changed: ', data)
        let index = this.editedDevices.findIndex(device => device._id === data.device._id)
        if (index !== -1) {
          if (this.editedDevices[index].settingsProfile == null) {
            this.editedDevices[index].settingsProfile = {}
          }
          data.configuration.forEach(conf => {
            this.editedDevices[index].settingsProfile[conf.field] = conf.value
          })
        }
    },
    onInstallTodoListChanged(data) {
        console.log('On install todo list changed: ', data)
        let index = this.editedDevices.findIndex(device => device._id === data.device._id)
        if (index !== -1) {
          this.editedDevices[index].todos = data.configuration
        }
    },
    onDecommissionTodoListChanged(data) {
        console.log('On decommission todo list changed: ', data)
        let index = this.editedDevices.findIndex(device => device._id === data.device._id)
        if (index !== -1) {
          this.editedDevices[index].decommissionTodos = data.configuration
        }
    },
    async onUpdateDevices() {
      console.log('On update devices: ', this.editedDevices)
      let payload = getUpdateSitePlanDevicesPayload(this.editedDevices)
      this.isLoading = true
      let response = await this.updateSitePlanDevices(payload)
      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
      } else {
        await this.clearFields()
        this.showToast('Devices successfully updated', 'is-success')
        this.show(false)
        await this.deselectAllDevices()
      }
      this.isLoading = false
      console.log('On update devices payload: ', payload)
    },
    async onMapClicked(position) {
      console.log('On map clicked edit devices:', position)
      if (!this.clickedMarker || this.mapType === 'gmap') {
        this.selectedDevice = null
      }
      this.clickedMarker = false
      this.zoomToObject()
    },
    onMapInputClicked(position) {
      console.log('On map input clicked:', position)
    },
    getAddressString(location) {
      if (location.address) {
        if (location.place.includes(location.address)) {
          return `${location.place}`
        } else {
          return `${location.address}, ${location.place}`
        }

      } else {
        this.$buefy.toast.open({
          message: 'Can\'t get address for selected location',
          duration: 4000,
          type: 'is-danger'
        })
        return 'unknown'
      }
    },
    async onCoordinatesUpdated(data) {
        console.log('On update coordinates: ', data)
        let index = this.editedDevices.findIndex(device => device._id === data.item._id)
        if (index !== -1) {
          console.log('Find item: ', this.editedDevices[index])
          this.isLoading = true
          let location = await this.getLocation(data.coordinates)
          this.isLoading = false
          this.editedDevices[index].location.coordinates = [data.coordinates.lng, data.coordinates.lat]
          this.editedDevices[index].address = this.getAddressString(location)

          if (this.selectedDevice && this.selectedDevice._id === this.editedDevices[index]._id) {
            console.log('Device is selected')
            this.selectedDevice = this.editedDevices[index]
          }
        }
    },
    onMapEditAddressChanged(data) {
        let index = this.editedDevices.findIndex(device => device._id === data._id)
        if (index !== -1) {
          console.log('Find item: ', this.editedDevices[index])
          this.editedDevices[index].location.coordinates = data.location.coordinates
          this.editedDevices[index].address = data.address
          if (this.selectedDevice && this.selectedDevice._id === this.editedDevices[index]._id) {
            console.log('Device is selected')
            this.selectedDevice = this.editedDevices[index]
          }
        }
    },
    onRotationChanged(data) {
        console.log('On rotation changed', data)
        let index = this.editedDevices.findIndex(device => device._id === data.item._id)
        if (index !== -1) {
          console.log('Find item rotation: ', this.editedDevices[index])
          this.editedDevices[index].rotation = data.rotation
        }
    },
    async clearFields() {
      this.editedDevices = []
      console.log('Clear fields edit device', this.deselectOnClose)
      if (this.deselectOnClose) {
        console.log('Clear fields edit device', this.devices[0])
        this.selectedDevice = null
        await this.deselectAllDevices()
      }
    },
    onSelectDevice(device) {
      console.log('Device selected:', device)
      if (this.selectedDevice && this.selectedDevice._id === device._id) {
        this.selectedDevice = null
      } else {
        this.selectedDevice = device
        this.clickedMarker = true
      }
      this.zoomToObject()
    },
    onCopyCrewClick(device) {
      console.log('On copy crew click', device)
      this.editedDevices.forEach(dev => {
        dev.installationCrew = device.installationCrew
        dev.decommissionCrew = device.decommissionCrew
      })
    },
    onCopyConfigurationClick(device) {
      console.log('On copy configuration click', device)
      this.isLoading = true
      setTimeout(()=>{
        this.editedDevices.forEach(dev => {
          if (dev.type === device.type) {
            dev.settingsProfile = device.settingsProfile
          }
        })
        this.changes++
        this.isLoading = false
      },200)
    },
    onCopyToDoClick(device) {
      console.log('On copy To lists click', device)
      this.isLoading = true
      setTimeout(()=>{
        this.editedDevices.forEach(dev => {
          if (dev.type === device.type) {
            dev.todos = device.todos
            dev.decommissionTodos = device.decommissionTodos
          }
        })
        this.changes++
        this.isLoading = false
      },200)
    },
    onCopyPeriodsClick(device){
      this.isLoading = true
      setTimeout(()=>{
        this.editedDevices.forEach(dev => {
          if (dev.type === device.type) {
            dev.processingPeriod = device.processingPeriod
            dev.collectionPeriod = device.collectionPeriod
            dev.processingPeriods = device.processingPeriods
          }
        })
        this.changes++
        this.isLoading = false
      },200)

    },
    onCopyInstructionClick(device) {
      console.log('On copy instruction click', device)
      this.isLoading = true
      setTimeout(()=>{
        this.editedDevices.forEach(dev => {
          if (dev.type === device.type) {
            dev.instruction = device.instruction
          }
        })
        this.changes++
        this.isLoading = false
      },200)

    },
    onCopyDecommissionInstructionClick(device) {
      console.log('On copy decommission instruction click', device)
      this.isLoading = true
      setTimeout(()=>{
        this.editedDevices.forEach(dev => {
          if (dev.type === device.type) {
            dev.decommissionInstruction = device.decommissionInstruction
          }
        })
        this.changes++
        this.isLoading = false
      },200)
    },
    click() {
      console.log('Clcked')
    },
    zoomToObject() {
      if (this.$refs.mapBoxInput) {
        this.$refs.mapBoxInput.zoomMapToSelectedSiteOrProject(true)
      } else if (this.$refs.gMapInput) {
        this.$refs.gMapInput.zoomMapToSelectedSiteOrProject(true)
      }
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
  computed: {
    ...mapGetters({
      mapType: 'getMapType',
      selectedProject: 'projectsModule/getSelectedProject'
    }),
    DeviceType() {
      return DeviceType
    }
  }
}
</script>

<style lang="scss" scoped>
.simple-device-card {
  border-radius: 32px;
  background-color: #fdfdfd;
  box-shadow: 0 0.125em 0.125em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
}

.selected {
  background-color: #e3e3e3;
}

.modal-tab-content {
  height: 30vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 24px;
  padding-right: 16px;
}

</style>