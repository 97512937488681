<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <b-modal v-model="modalActive" width="70%" scroll="keep" @close="clearFields()">
      <div class="card">
        <header class="card-header ">
          <div class="card-header-title is-flex">
            <h6 class="is-size-6 ml-auto mr-auto pl-4 has-text-weight-semibold">{{ getTitle() }}</h6>
          </div>
          <b-button class="card-header-icon mt-2 mr-2" aria-label="more options" @click="show(false, false)">
            <b-icon icon="close"></b-icon>
          </b-button>
        </header>
        <div class="card-content">
          <div class="content">
            <gmap-map
                ref="googleMap"
                :center="mapCenter"
                @click="onMapClicked"
                :zoom="9"
                :heading="heading"
                :options="options"
                map-type-id="terrain"
                style="width: 100%"
                class="mapbox-container">

              <gmap-marker v-if="markerLocation"
                           ref="marker"
                           :position="markerLocation"
                           :clickable="true"
                           :draggable="true"
                           @dragend="updateCoordinates">
              </gmap-marker>
            </gmap-map>
            <div class="columns mt-4">
              <div class="column pt-0 pb-4 pr-2">
                <b-field label="Project Status" label-position="on-border">
                  <b-dropdown v-model="project.status" aria-role="list">
                    <template v-if="project.status === 'Active'" #trigger>
                      <b-button
                          label="Active"
                          icon-left="calendar-check"
                          icon-right="menu-down" />
                    </template>
                    <template v-else-if="project.status === 'Inactive'" #trigger>
                      <b-button
                          label="Inactive"
                          icon-left="calendar-lock"
                          icon-right="menu-down" />
                    </template>
                    <template v-else-if="project.status === 'Archived'" #trigger>
                      <b-button
                          label="Archived"
                          icon-left="archive-lock-outline"
                          icon-right="menu-down" />
                    </template>

                    <b-dropdown-item :value="'Active'" aria-role="listitem">
                      <div class="is-flex">
                        <b-icon icon="calendar-check"></b-icon>
                        <div>Active</div>
                      </div>
                    </b-dropdown-item>
                    <b-dropdown-item :value="'Inactive'" aria-role="listitem">
                      <div class="is-flex">
                        <b-icon icon="calendar-lock"></b-icon>
                        <div>Inactive</div>
                      </div>
                    </b-dropdown-item>
                    <b-dropdown-item :value="'Archived'" aria-role="listitem" :disabled="true">
                      <div class="is-flex">
                        <b-icon icon="archive-lock-outline"></b-icon>
                        <div>Archived</div>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-field>
              </div>
              <div class="column pt-0 pb-4 pl-2">
              </div>
            </div>
            <div class="columns">
              <div class="column pt-4 pb-4 pr-2">
                <b-field label="Project Name *" label-position="on-border">
                  <b-input @blur="isValidForm" @input="isValidForm" ref="name" placeholder="Project Name *" rounded
                           v-model="project.name" type="text" required></b-input>
                </b-field>
              </div>
              <div class="column pt-4 pb-4 pl-2">
                <b-field label="Project Code *" label-position="on-border">
                  <b-input @blur="isValidForm" @input="isValidForm" ref="code" placeholder="Project Code *" rounded
                           v-model="project.code" type="text" required></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column pt-0 pb-4">
                <b-field label="Project Description" label-position="on-border">
                  <b-input @blur="isValidForm" @input="isValidForm" ref="description" placeholder="Project Description"
                           rounded v-model="project.description" type="text"></b-input>
                </b-field>
              </div>
              <div class="column pt-0 pb-4">
                <b-field label="Project Location - lat,lng format *" label-position="on-border">
                  <b-input @blur="isValidForm" @input="setLocation" ref="location"
                           placeholder="Project Location - lat,lng format *" rounded v-model="project.location"
                           type="text" required></b-input>
                </b-field>
              </div>
            </div>

            <div class="columns">
              <div class="column pt-4 pb-4 pl-2">
                <b-field grouped label="Start date *" label-position="on-border">
                  <b-datepicker :mobile-native="false" expanded v-model="project.startDate" size="is-small"
                                position="is-top-right" @input="isValidForm">
                    <template v-slot:trigger>
                      <b-input expanded readonly rounded placeholder="Start date *" :value="startDateString" type="text" ref="startDate"
                               required/>
                    </template>
                  </b-datepicker>
                </b-field>
              </div>
              <div class="column pt-4 pb-4 pl-2">
                <b-field grouped label="End date" label-position="on-border">
                  <b-datepicker :mobile-native="false" expanded v-model="project.endDate" size="is-small"
                                position="is-top-right">
                    <template v-slot:trigger>
                      <b-input expanded readonly rounded placeholder="End date" :value="endDateString" type="text"
                               required/>
                    </template>
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column pt-4 pb-4 pr-2">
                <b-field label="Client name" label-position="on-border">
                  <b-input placeholder="Client name" rounded v-model="project.clientName" type="text"></b-input>
                </b-field>
              </div>
              <div class="column pt-4 pb-4 pl-2">
                <b-field label="Client address" label-position="on-border">
                  <b-input placeholder="Client address" rounded v-model="project.clientAddress" type="text"></b-input>
                </b-field>
              </div>
            </div>

            <div class="columns" style="margin-bottom: 24px">
              <div class="column pt-0 pb-4 pr-2">
                <b-field label="Client contact phone" label-position="on-border">
                  <b-input placeholder="Client contact phone" rounded v-model="project.clientPhone"
                           type="text"></b-input>
                </b-field>
              </div>
              <div class="column pt-0 pb-4 pl-2">
                <b-field label="Client contact e-mail" label-position="on-border">
                  <b-input type="email" placeholder="Client contact e-mail" rounded
                           v-model="project.clientEmail"></b-input>
                </b-field>
              </div>
            </div>

          </div>
        </div>
        <footer class="card-footer">
          <b-button class="is-primary is-fullwidth m-2" @click="onSaveClicked" rounded :disabled="!formValid">Save
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {
  dateToISOString,
  extractGMapGpsCoordinates,
  getDate,
  getDateObjectFromMelbourneUtcToLocal,
  gMapOptions, gMapStyles
} from "@/utils/utils";

export default {
  name: "add-project",
  components: {},
  data() {
    return {
      isLoading: false,
      modalActive: false,
      isEditMode: true,
      options: {styles: gMapStyles},
      project: {
        startDate: null,
        endDate: null,
        name: null,
        code: null,
        description: null,
        location: null,
        clientName: null,
        clientAddress: null,
        clientPhone: null,
        clientEmail: null,
        status: 'Active'
      },
      startDateMsg: null,
      endDateMsg: null,
      formValid: false,
      mapCenter: {lat: -37.80193, lng: 144.94444},
      markerLocation: null,
      mapZoom: 10,
      heading: 0
    }
  },
  mounted() {

  },
  methods: {
    ...mapActions({
      createNewProject: 'projectsModule/createNewProject',
      updateProject: 'projectsModule/updateProject',
    }),
    onProjectStatusChanged(status){

    },
    show(show, isEdit) {
      this.modalActive = show
      this.isEditMode = isEdit

      if (isEdit) {
        this.populateFields()
      }

      if (!this.modalActive) {
        this.clearFields()
      }
    },
    getTitle() {
      return this.isEditMode ? 'Edit overview' : 'Create New Project'
    },
    onMapClicked(event) {
      console.log('On map clicked')
      let position = event.latLng.toJSON()
      let ev = {
        item: this.item,
        coordinates: position
      }

      this.markerLocation = position
      this.project.location = [this.markerLocation.lat, this.markerLocation.lng].toString()

      console.log('On map clicked end', position)
      this.isValidForm()
      //this.$emit('onMapClicked')
    },
    setLocation(event) {
      console.log('Set location', event)
      // -37.801319637488284, 144.94214436435936

      let coordinates = extractGMapGpsCoordinates(event)
      if (coordinates) {
        this.markerLocation = coordinates
        this.mapCenter = coordinates
        this.project.location = [this.markerLocation.lat, this.markerLocation.lng].toString()
      } else {
        this.markerLocation = null
        this.project.location = null
        this.showToast('Invalid input. PLease provide coordinates in format lat,lng', 'is-danger')
      }

    },
    onSaveClicked() {

      let payload = {
        name: this.project.name,
        code: this.project.code,
        description: this.project.description,
        longitude: this.markerLocation.lng,
        latitude: this.markerLocation.lat,
        startDate: dateToISOString(this.project.startDate),
        endDate: dateToISOString(this.project.endDate),
        clientName: this.project.clientName,
        clientAddress: this.project.clientAddress,
        clientPhone: this.project.clientPhone,
        clientEmail: this.project.clientEmail,
        status: this.project.status
      }

      if (this.isEditMode) {
        payload.id = this.selectedProject._id
      }

      this.addOrUpdate(payload)
    },
    async addOrUpdate(payload) {

      console.log('Payload: ', payload)
      this.isLoading = true
      if (this.isEditMode) {
        let response = await this.updateProject(payload)
        if (response instanceof Error) {
          this.showToast(response.message, 'is-danger')
        } else {
          this.clearFields()
          this.showToast('Project successfully updated', 'is-success')
          this.show(false, false)
        }
      } else {
        let response = await this.createNewProject(payload)
        if (response instanceof Error) {
          this.showToast(response.message, 'is-danger')
        } else {
          this.clearFields()
          this.showToast('Project successfully created', 'is-success')
          this.show(false, false)
        }
      }

      this.isLoading = false
    },
    clearFields() {
      this.project.startDate = null
      this.project.endDate = null
      this.project.name = null
      this.project.code = null
      this.project.description = null
      this.project.location = null
      this.project.clientName = null
      this.project.clientAddress = null
      this.project.clientPhone = null
      this.project.clientEmail = null
      this.project.status = 'Active'
      this.markerLocation = null
    },
    populateFields() {
      if (this.selectedProject) {

        console.log('Selected project:', this.selectedProject)

        this.markerLocation = {
          lat: this.selectedProject.location.coordinates[1],
          lng: this.selectedProject.location.coordinates[0]
        }
        this.mapCenter = this.markerLocation
        this.project.location = [this.markerLocation.lat, this.markerLocation.lng].toString()

        this.project.name = this.selectedProject.name
        this.project.code = this.selectedProject.code
        this.project.description = this.selectedProject.description
        this.project.startDate = getDateObjectFromMelbourneUtcToLocal(new Date(this.selectedProject.startDate))
        this.project.endDate = this.selectedProject.endDate ? getDateObjectFromMelbourneUtcToLocal(new Date(this.selectedProject.endDate)) : null
        this.project.clientName = this.selectedProject.clientName
        this.project.clientAddress = this.selectedProject.clientAddress
        this.project.clientPhone = this.selectedProject.clientPhone
        this.project.clientEmail = this.selectedProject.clientEmail
        this.project.status = this.selectedProject.status

        this.formValid = true
      }
    },
    isValidForm() {
      console.log('Valid: ', this.$refs.name)
      if (this.$refs.name && this.$refs.code) {
        this.formValid = this.$refs.name.isValid && this.$refs.code.isValid && this.$refs.startDate.isValid
        this.formValid = this.formValid && this.project.name && this.project.code && this.project.startDate
        this.formValid = this.formValid && this.project.location
      } else {
        this.formValid = false
      }
    },
    updateCoordinates(event) {
      let position = event.latLng.toJSON()

      this.markerLocation = position
      this.project.location = [this.markerLocation.lat, this.markerLocation.lng].toString()
      console.log('On drag end', position)
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
  computed: {
    ...mapGetters({
      selectedProject: 'projectsModule/getSelectedProject',
    }),
    startDateString() {
      return this.project.startDate ? getDate(this.project.startDate) : ''
    },
    endDateString() {
      return this.project.endDate ? getDate(this.project.endDate) : ''
    }
  }
}
</script>

<style scoped lang="scss">
.mapbox-container {
  width: 100%;
  height: 350px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  background-color: #D9D9D9;
}
</style>