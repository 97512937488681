<template>
  <div style="margin-top: 12px">
    <b-tooltip style="margin-top: 24px; margin-right: 16px" v-if="!client.connected"
               label="Live updates inactive. Click to reconnect." position="is-left"
               type="is-dark">
      <b-button icon-right="wifi-strength-alert-outline" @click="createConnection"
                type="is-danger" outlined size="is-small"></b-button>
    </b-tooltip>

    <b-tooltip v-else style="margin-top: 24px; margin-right: 16px" label="Live updates active" position="is-left"
               type="is-dark">

      <b-button icon-right="wifi" size="is-small"
                type="is-primary"></b-button>
    </b-tooltip>
    <div>{{ mqttMessage }}</div>
  </div>
</template>

<script>
import mqtt from 'mqtt/dist/mqtt';
import {mapActions, mapGetters} from "vuex";

export default {
  name: "mqtt-service",
  data() {
    return {
      client: {
        connected: false,
      }
    }
  },
  mounted() {
    this.topics = [
      {name: 'resource', topicName: 'topic/resource/', topic: 'topic/resource/', qos: 0}
    ]
    this.createConnection()
/*    this.$root.$on('onResourceChanged', (event) => {
      console.log('New event', event)
    })*/
  },
  methods: {
    ...mapActions({
      setSelectedProject: 'projectsModule/setSelectedProject',
      fetchAllProjects: 'projectsModule/getAllProjects'
    }),
    createConnection() {
      console.log('Create connection called')
      if (this.$store.state.mqttStatus !== 'connected') {
        this.$store.dispatch('setMqttStatus', 'connecting')
        try {
          this.client = mqtt.connect(this.$env.VUE_APP_MQTT_URL, {
            clientId: "stlz_web_" + Math.random().toString(16).substring(2, 8),
          })
        } catch (error) {
          this.$store.dispatch('setMqttStatus', 'disconnected')
          console.log('mqtt.connect error', error)
        }
        this.client.on('connect', () => {
          console.log('Connection succeeded!')
          this.$store.dispatch('setMqttStatus', 'connected')
          if (this.topics.length > 0) {
            this.topics.forEach(topic => {
              this.doSubscribe(topic)
            })
          }
        })
        this.client.on('error', error => {
          this.$store.dispatch('setMqttStatus', 'disconnected')
          console.log('Connection failed', error)
        })
        this.client.on('message', async (topic, message) => {
          let receivedTopic = this.topics.find(t => topic.includes(t.topicName))
          console.log('Received topic', topic)
          if (receivedTopic !== undefined) {
            let objectUpdate = JSON.parse(message)
            console.log('Update object', objectUpdate)
            console.log('User:', this.$store.state.user)
            if (objectUpdate != null && (objectUpdate.changedBy !== this.$store.state.user.username || objectUpdate.fromWhere !== 'web')) {
              let msg = ''
              if (receivedTopic.name === 'resource') {
                console.log('Received Resource Change', objectUpdate.resId)
                if(this.allProjects){
                  let projectIndex = this.allProjects.findIndex(project => project._id === objectUpdate.resId)
                  if(projectIndex === -1){
                    console.log('New project added')
                    await this.fetchAllProjects()
                    msg = 'System Syncing...'
                  }
                }
                if (this.selectedProject && this.selectedProject._id === objectUpdate.resId) {
                  await this.setSelectedProject({_id: objectUpdate.resId})
                  msg = 'System Syncing...'
                }
              }
              if(msg !== ''){
                this.$buefy.toast.open({
                  message: msg,
                  duration: 2000,
                  type: 'is-primary'
                })
              }

            }
          }
        })
      }
    },
    destroyConnection() {
      if (this.client.connected) {
        try {
          this.client.end()
          this.client = {
            connected: false,
          }
          this.$store.dispatch('setMqttStatus', 'disconnected')
          console.log('Successfully disconnected!')
        } catch (error) {
          console.log('Disconnect failed', error.toString())
        }
      } else {
        this.$store.dispatch('setMqttStatus', 'disconnected')
      }
    },
    doUnSubscribe(tpc) {
      if (this.client.connected) {
        const {topic} = tpc
        this.client.unsubscribe(topic, error => {
          if (error) {
            console.log('Unsubscribe error', error)
          }
        })
      }
    },
    doSubscribe(top) {
      if (this.client.connected) {
        const {topic, qos} = top
        this.client.subscribe(topic, {qos}, (error, res) => {
          if (error) {
            console.log('Subscribe to topics error', error)
            return
          }
          console.log('Subscribe to topics res', res)
        })
      }
    },
    sendEvent(resId) {
      let payload = {
        resId: resId,
        changedBy: this.$store.state.user.username,
        fromWhere: 'web'
      }
      this.$store.commit('setMqttEventToSend', null)

      if (this.client && this.client.connected) {
        this.client.publish('topic/resource/', JSON.stringify(payload))
      }
    }
  },
  beforeDestroy() {
    /*this.$root.$off('onResourceChanged')*/
    this.topics.forEach(topic => {
      this.doUnSubscribe(topic)
    })
    this.destroyConnection()
  },
  computed: {
    ...mapGetters({
      selectedProject: 'projectsModule/getSelectedProject',
      mqttMessage: 'getMqttEventToSend',
      allProjects: 'projectsModule/getAllProjects'
    }),
  },
  watch: {
    mqttMessage: {
      handler: function (after, before) {
        console.log('Event to send: ', after, before)
        if (after !== null) {
          this.sendEvent(after)
        }
      },
      deep: true,
    }
  }
}
</script>

<style scoped>

</style>