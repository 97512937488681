<template>
  <div :key="selectedQaDevice._id">
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <div class="box p-4 mb-4">
      <div class="is-flex is-flex-direction-column">
        <b-taglist attached class="m-0 p-0 ml-auto">
          <b-tag type="is-dark">Version</b-tag>
          <b-tag type="is-info">{{ version }}</b-tag>
        </b-taglist>
        <div class="is-flex mb-3">
          <div class="is-size-4 has-text-weight-semibold ml-2 mb-2">Vehicle Counts Report</div>
          <b-field class="ml-auto mr-1 mt-1">
            <b-select placeholder="Select date" size="is-small" @input="onDateSelected" v-model="selectedDateFilter"
                      style="min-width: 220px; width: 220px" expanded>
              <option
                  v-for="option in dates"
                  :value="option"
                  :key="option">
                {{ option }}
              </option>
            </b-select>
          </b-field>
        </div>
        <transition name="fade">
          <div v-if="checkedRows.length > 0" class="box columns ml-0 mr-0 mt-3 mb-3 p-1">
            <div class="mt-3 ml-2 has-text-weight-semibold">Selected rows: {{ checkedRows.length }}</div>
            <div class="column ml-4 mr-2">
              <b-field label="Avg Speed" label-position="on-border">
                <b-input placeholder="Set Avg Speed" size="is-small" v-model="avgSpeed"
                         icon-right="close-circle"
                         icon-right-clickable
                         type="number"
                         step=".01"
                         @icon-right-click="avgSpeed = null">

                </b-input>
              </b-field>
            </div>
            <div class="column mr-2">
              <b-field label="User Class" label-position="on-border">
                <b-select placeholder="Set User Class" v-model="userClass" icon-right="close-circle" size="is-small" expanded>
                  <optgroup label="Vehicles">
                    <option value="light vehicle">Light vehicle</option>
                    <option value="small heavy vehicle">Small heavy vehicle</option>
                    <option value="medium heavy vehicle">Medium heavy vehicle</option>
                    <option value="heavy vehicle">Heavy vehicle</option>
                    <option value="bus">Bus</option>
                  </optgroup>
                  <option :value="null"></option>
                </b-select>
              </b-field>
            </div>
            <div class="column mr-4">
              <b-field grouped label="Event Date" label-position="on-border">
                <b-datepicker :mobile-native="false" expanded v-model="eventDate"
                              size="is-small" position="is-bottom-right">
                  <template v-slot:trigger>
                    <b-input expanded readonly placeholder="Event Date" size="is-small"
                             :value="eventDateString" type="text"
                             icon-right="close-circle"
                             icon-right-clickable
                             @icon-right-click="eventDate = null"
                             required/>
                  </template>
                </b-datepicker>
              </b-field>
            </div>
            <div class="column ml-0 pl-0 pr-1" style="max-width: 80px">
              <b-button size="is-small" type="is-primary" @click="onChangeSet">CHANGE</b-button>
            </div>
          </div>
        </transition>
      </div>
      <b-table
          key="qa_table"
          ref="table"
          :data="qaTable"
          :checked-rows.sync="checkedRows"
          @check-all="onCheckAll"
          :checkable="checkPermission"
          checkbox-position="left"
          checkbox-type="is-primary"
          hoverable
          paginated
          :per-page="itemsPerPage"
          current-page.sync="1"
          :pagination-simple="false"
          pagination-position="bottom"
          default-sort-direction="asc"
          :pagination-rounded="false"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          @sort="sortClicked"
          default-sort="name"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          :page-input="false">
        <b-table-column field="_id" label="ID" width="250px">
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="none" tooltip="" element-id="1345233"
                          :table-header-data="tableHeaderData" @onSort="onSort">
            </table-header>
          </template>
          <template v-slot="props">
            {{ props.row._id }}
          </template>
        </b-table-column>
        <b-table-column field="userType" label="Type" sortable>
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="search" tooltip="Filter by user type" element-id="46629333"
                          :table-header-data="tableHeaderData" @onSort="onSort">
            </table-header>
          </template>
          <template v-slot="props">
            {{ props.row.userType }}
          </template>
        </b-table-column>
        <b-table-column field="direction" label="Direction" sortable>
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="search" tooltip="Filter by direction" element-id="46683875"
                          :table-header-data="tableHeaderData" @onSort="onSort">
            </table-header>
          </template>
          <template v-slot="props">
            {{ props.row.direction }}
          </template>
        </b-table-column>
        <b-table-column field="avgSpeed" label="AVG Speed" sortable>
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="search" tooltip="Filter by speed" element-id="467443454"
                          :table-header-data="tableHeaderData" @onSort="onSort">
            </table-header>
          </template>
          <template v-slot="props">
            {{ props.row.avgSpeed }}
          </template>
        </b-table-column>
        <b-table-column field="userClass" label="Class" sortable>
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="dropdown" tooltip="Filter by user class" element-id="467476937" :options="options"
                          :table-header-data="tableHeaderData" @onSort="onSort">
            </table-header>
          </template>
          <template v-slot="props">
            {{ props.row.userClass }}
          </template>
        </b-table-column>
        <b-table-column field="eventAt" label="Event Date">
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="none" tooltip="" element-id="254788453754656"
                          :table-header-data="tableHeaderData">
            </table-header>
          </template>
          <template v-slot="props">
            {{ getDateInTimeZone(props.row.eventAt, selectedQaDevice ? selectedQaDevice.timezone : null) }}
          </template>
        </b-table-column>
        <b-table-column field="eventTime" label="Event Time">
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="none" tooltip="" element-id="254788453743444"
                          :table-header-data="tableHeaderData">
            </table-header>
          </template>
          <template v-slot="props">
            {{ getTime(props.row.eventAt, selectedQaDevice ? selectedQaDevice.timezone : null) }}
          </template>
        </b-table-column>
      </b-table>
    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import TableHeader from "@/components/table-header/table-header.vue";
import {
  getDate, getDateInTimeZone,
  getDateTime,
  getTime, isAllowed, startEndFromTimeZoneToIso
} from "@/utils/utils";

export default {
  name: "qa-table",
  components: {TableHeader},
  data() {
    return {
      checkedRows: [],
      isLoading: false,
      itemsPerPage: 10,
      selectedDateFilter: null,
      avgSpeed: null,
      userClass: null,
      eventDate: null
    }
  },
  async mounted() {
  },
  methods: {
    getDateInTimeZone,
    getTime,
    getDate,
    getDateTime,
    ...mapActions({
      setTableHeaderData: 'qaModule/setQaTableHeaderData',
      getQaTableData: 'qaModule/getQaTableData',
      changeDataSet: 'qaModule/changeDataSet'
    }),
    async onChangeSet() {
      this.isLoading = true

      let payload = {}
      if (this.avgSpeed && this.avgSpeed.length > 0) {
        payload.avgSpeed = Number(this.avgSpeed)
      }

      if (this.userClass && this.userClass.length > 0) {
        payload.userClass = this.userClass
      }

      if (this.eventDate) {

        let selectedDate
        if (this.selectedDateFilter) {
          selectedDate = new Date(this.selectedDateFilter + 'T00:00:00.000Z')
          selectedDate.setHours(0, 0, 0, 0)
        }

        console.log('Event date: ', this.eventDate)
        console.log('Selected date: ', selectedDate)

        if (selectedDate && this.eventDate) {
          const diffDays = Math.floor((this.eventDate - selectedDate) / (1000 * 60 * 60 * 24));
          console.log('Diff days: ', diffDays)
          payload.addDay = diffDays
        }
      }

      if (this.checkedRows && this.checkedRows.length > 0) {
        payload.ids = this.checkedRows.map(row => row._id)
      }

      if (this.selectedQaDevice && this.selectedQaDevice._id) {
        payload.dataDeviceId = this.selectedQaDevice._id
        payload.timeZone = this.selectedQaDevice.timezone
        //payload.chartsVersion = this.selectedQaDevice && this.selectedQaDevice.chartsData ? this.selectedQaDevice.chartsData.version : 1
        payload.vehicleVersion = this.selectedQaDevice && this.selectedQaDevice.chartsData && this.selectedQaDevice.chartsData.querySet ? this.selectedQaDevice.chartsData.querySet.veh.version : 1
        payload.pedestrianVersion = this.selectedQaDevice && this.selectedQaDevice.chartsData && this.selectedQaDevice.chartsData.querySet ? this.selectedQaDevice.chartsData.querySet.ped.version : 1
      }

      console.log('Payload: ', payload)
/*      console.log('Rows: ', this.checkedRows)
      console.log('Rows: ', this.selectedQaDevice)*/

      await this.changeDataSet(payload)
      await this.callGetQaTable()

      this.checkedRows = []
      this.avgSpeed = null
      this.userClass = null
      this.eventDate = null

      this.isLoading = false
    },
    async onDateSelected(date) {
      console.log('On Date Selected', date)
      this.selectedDateFilter = date
      await this.callGetQaTable()
    },
    async callGetQaTable() {
      let selectedDate = this.selectedDateFilter + 'T00:00:00.000Z'
      let timeZone = this.selectedQaDevice.timezone ? this.selectedQaDevice.timezone : 'Australia/Melbourne'
      let startEndDate = startEndFromTimeZoneToIso(selectedDate, timeZone)
      console.log('Start end date: ', startEndDate)

      let data = {
        deviceId: this.selectedQaDevice._id,
        startDate: startEndDate.start,
        endDate: startEndDate.end
      }

      this.isLoading = true
      await this.getQaTableData(data)
      this.checkedRows = []
      this.isLoading = false
    },
    onCheckAll(checkedList) {
      if (checkedList.length > this.checkedRows.length) {
        this.$nextTick(() => {
          this.checkedRows = this.qaTable
        })
        console.log(this.checkedRows)
      } else {
        this.$nextTick(() => {
          this.checkedRows = []
        })
      }
    },
    sortClicked(field, order) {
      console.log('Sorted: ', field, order)
      let tableHeaderData = this.tableHeaderData
      tableHeaderData.activeSorted = {field: field, order: order}
      this.setTableHeaderData(tableHeaderData)
    },
    onSort(tableHeaderData) {
      this.setTableHeaderData(tableHeaderData)
    },
  },
  computed: {
    ...mapGetters({
      tableHeaderData: 'qaModule/getQaTableHeaderData',
      qaTable: 'qaModule/getQaTable',
      selectedQaDevice: 'qaModule/getSelectedQaDevice',
      qaData: 'qaModule/getQaData',
    }),
    version() {
      if (this.qaTable && this.qaTable.length > 0) {
        return this.qaTable[0].version
      }

      return '-'
    },
    dates() {
      if (this.selectedQaDevice && this.selectedQaDevice.availableDatesInTimeZone) {
        let dts = this.selectedQaDevice.availableDatesInTimeZone
        return dts
      }

      return []
    },
    eventDateString() {
      return this.eventDate ? getDate(this.eventDate) : ''
    },
    checkPermission() {
      return isAllowed(this.$store.getters.getMe, 'user-counts:update')
    },
    options(){
      if(this.qaData && this.qaData.length > 0){
        //return unique
        let classes = this.qaData.map(item => item.userClass)
        let unique = [...new Set(classes)];
        return unique
      }

      return []
    }
  },
  watch: {
    selectedQaDevice: {
      handler: function (newVal, oldVal) {
        if (newVal === null || (oldVal && newVal && oldVal._id !== newVal._id)) {
          this.selectedDateFilter = null
        }
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>