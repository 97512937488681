<template>
  <div class="pb-6">
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>

    <div class="m-5 mt-6">
      <div class="is-flex is-align-items-center mb-3">
        <div class="is-size-5 has-text-grey">Device Settings Templates</div>
        <div class="ml-auto">
          <view-guard :permission="'template:create'">
            <b-button @click="onAddTemplate" type="is-primary" icon-left="plus" rounded>Add Template
            </b-button>
          </view-guard>
        </div>
      </div>
      <div v-if="settingsTemplates && settingsTemplates.length > 0">
        <device-settings-template class="mb-1" v-for="settingsTemplate in settingsTemplates" :key="settingsTemplate._id"
                                  :settings-template="settingsTemplate" @onEditSettingsTemplate="onEditSettingsTemplate"
                                  @onDeleteSettingsTemplate="onDeleteSettingsTemplate"></device-settings-template>
      </div>
      <div v-else class="is-flex is-align-items-center dashed-border">
        <h4 class="is-size-4 ml-auto mr-auto has-text-grey-light has-text-weight-light">Click on "+" button to add first
          Device Settings template</h4>
      </div>
      <add-edit-settings-template ref="settingsTemplate"></add-edit-settings-template>
    </div>
  </div>
</template>

<script>
import {TemplateType} from "@/utils/utils";
import AddEditSettingsTemplate from "@/components/device-settings-templates/add-edit-settings-template.vue";
import DeviceSettingsTemplate from "@/components/device-settings-templates/device-settings-template.vue";
import ViewGuard from "@/components/view-guard/view-guard.vue";

export default {
  name: "device-settings-templates",
  components: {ViewGuard, DeviceSettingsTemplate, AddEditSettingsTemplate},
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    onAddTemplate() {
      this.$refs.settingsTemplate.show(true, null)
    },
    onEditSettingsTemplate(id) {
      console.log('Template id: ', id)
      this.$refs.settingsTemplate.show(true, id)
    },
    onDeleteSettingsTemplate(id) {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: 'Are you sure you want to delete this template?',
        onConfirm: () => this.deleteItem(id)
      })
    },
    async deleteItem(id) {
      console.log('Call delete item: ', id)
      this.isLoading = true
      let response = await this.$store.dispatch('deleteTemplate', id)
      if (response.errorMessage) {
        this.showToast(response.errorMessage, 'is-danger')
      } else {
        this.showToast('Device Settings template successfully deleted', 'is-success')
      }
      this.isLoading = false
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
  computed: {
    settingsTemplates() {
      if (this.$store.getters.getSettings && this.$store.getters.getSettings.todoTemplates) {
        return this.$store.getters.getSettings.todoTemplates.filter(template => template.templateType === TemplateType.SETTINGS_PROFILE)
      }
      return []
    }
  }
}
</script>

<style scoped>

</style>