<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <b-modal v-model="modalActive" width="90%" scroll="clip" style="z-index: 101" @close="clearFields()">
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head" style="padding: 12px">
          <h6 class="is-size-5 ml-auto mr-auto pl-4 has-text-weight-semibold">
            {{ devices.length > 1 ? 'Edit Devices Locations' : 'Edit Device Location' }}</h6>
          <button
              type="button"
              class="delete"
              @click="show(false, '', deselectOnClose)"/>
        </header>
        <section class="modal-card-body p-0" style="overflow: unset">
          <g-map-input ref="gMapInput" v-if="mapType === 'gmap'" style="max-height: 45vh;"
                       @onMapInputClicked="onMapInputClicked"
                       @onCoordinatesUpdated="onCoordinatesUpdated"
                       @onMarkerClicked="onSelectDevice"
                       :selected-device="selectedDevice"
                       :is-markers-selectable="true"
                       :devices="editedDevices"
                       :selected-project="selectedProject"
                       :show-edit-location="true"
                       draw-controls-mode="edit"
                       @onMapClicked="onMapClicked"
                       @onMapEditAddressChanged="onMapEditAddressChanged"
                       @onMapEditCoordinatesChanged="onMapEditCoordinatesChanged"
          ></g-map-input>
          <div>
            <div class="ml-0 mr-0 mb-0 mt-3">
              <div class="ml-3 mr-3 mt-4" style="min-height: 240px">
                <div class="box simple-device-card p-2 pl-5 m-0 mt-2" style="cursor: pointer"
                     v-for="device in editedDevices"
                     :key="device._id" :class="{'selected':selectedDevice === device}">
                  <div class="columns m-0 p-0 is-align-items-center">
                    <div class="column p-0 is-2" @click="onSelectDevice(device)">
                      <div class="is-size-7 has-text-grey-light">Site name</div>
                      <div class="is-size-6 has-text-weight-semibold has-text-black">{{ device.siteName }}</div>
                    </div>
                    <div class="column p-0 is-2" @click="onSelectDevice(device)">
                      <div class="is-size-7 has-text-grey-light">Device Type</div>
                      <div class="is-size-6 has-text-weight-semibold has-text-black">{{ device.type }}</div>
                    </div>
                    <div class="column p-0 is-2" @click="onSelectDevice(device)">
                      <div class="is-size-7 has-text-grey-light">Device Name</div>
                      <div class="is-size-6 has-text-weight-semibold has-text-black">
                        {{ device.name ? device.name : 'Undefined' }}
                      </div>
                    </div>
                    <div class="column p-0 is-4" @click="onSelectDevice(device)">
                      <div class="is-size-7 has-text-grey-light">Location</div>
                      <div class="is-size-6 has-text-weight-semibold has-text-black">
                        {{ device.location.coordinates }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot" style="padding: 0">
          <b-button class="is-primary is-fullwidth m-2" @click="onUpdateDevices" rounded>
            {{ devices.length > 1 ? 'UPDATE DEVICES' : 'UPDATE DEVICE' }}
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {DeviceType} from "@/utils/utils";
import GMapInput from "@/components/g-map-input/g-map-input.vue";

export default {
  name: "edit-data-device-location",
  components: {GMapInput},
  props: {
    devices: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      isLoading: false,
      modalActive: false,
      deviceType: 'CAMERA',
      editedDevices: [],
      selectedDevice: null,
      deselectOnClose: false,
      clickedMarker: false,
      changes: 0,
    }
  },
  mounted() {
    console.log('Devices: ', this.devices)
    //this.setDevices()
  },
  methods: {
    ...mapActions({
      getLocation: 'getLocationData',
      getTimeZone: 'getTimeZone',
      deselectAllDevices: 'projectsModule/deselectAllDevices',
      updateDataDeviceLocation: 'projectsModule/updateDataDeviceLocation'
    }),
    show(show, deselectOnClose) {
      this.modalActive = show
      this.deselectOnClose = deselectOnClose
      if (!this.modalActive) {
        this.clearFields()
      } else {
        this.isLoading = true
        setTimeout(() => {
          this.setDevices()
          console.log('Show edit device:', this.deselectOnClose, this.editedDevices)
          this.isLoading = false
        }, 500)

      }
    },
    setDevices() {
      this.editedDevices = JSON.parse(JSON.stringify(this.devices))
      this.editedDevices.forEach(device => {
        device.status = device.currentStatus
      })
      console.log('Edited devices: ', this.editedDevices)
    },
    async onUpdateDevices() {
      console.log('On update devices: ', this.editedDevices)
      if (this.editedDevices && this.editedDevices.length > 0) {
        delete this.editedDevices[0].address
        let payload = {
          id: this.editedDevices[0]._id,
          longitude: this.editedDevices[0].location.coordinates[0],
          latitude: this.editedDevices[0].location.coordinates[1],
          timeZone: ''
        }

        this.isLoading = true
        let timeZone = await this.getTimeZone({lat: payload.latitude, lng: payload.longitude})
        console.log('Time zone: ', timeZone)
        if (timeZone && timeZone.timeZoneId) {
          payload.timeZone = timeZone.timeZoneId
          let response = await this.updateDataDeviceLocation(payload)
          if (response instanceof Error) {
            this.showToast(response.message, 'is-danger')
          } else {
            await this.clearFields()
            this.showToast('Data Device successfully updated', 'is-success')
            this.show(false)
            await this.deselectAllDevices()
          }

        } else {
          this.showToast('Can not get time zone for selected location', 'is-danger')
        }
        this.isLoading = false
        console.log('On update devices payload: ', payload)
      }


    },
    async onMapClicked(position) {
      console.log('On map clicked edit devices:', position)
      if (!this.clickedMarker || this.mapType === 'gmap') {
        this.selectedDevice = null
      }
      this.clickedMarker = false
      this.zoomToObject()
    },
    onMapInputClicked(position) {
      console.log('On map input clicked:', position)
    },
    onSelectDevice(device) {
      console.log('Device selected:', device)
      if (this.selectedDevice && this.selectedDevice._id === device._id) {
        this.selectedDevice = null
      } else {
        this.selectedDevice = device
        this.clickedMarker = true
      }
      this.zoomToObject()
    },
    getAddressString(location) {
      if (location.address) {
        if (location.place.includes(location.address)) {
          return `${location.place}`
        } else {
          return `${location.address}, ${location.place}`
        }

      } else {
        this.$buefy.toast.open({
          message: 'Can\'t get address for selected location',
          duration: 4000,
          type: 'is-danger'
        })
        return 'unknown'
      }
    },
    async onCoordinatesUpdated(data) {
      console.log('On update coordinates: ', data)
      let index = this.editedDevices.findIndex(device => device._id === data.item._id)
      if (index !== -1) {
        console.log('Find item: ', this.editedDevices[index])
        this.isLoading = true
        let location = await this.getLocation(data.coordinates)
        this.isLoading = false
        this.editedDevices[index].location.coordinates = [data.coordinates.lng, data.coordinates.lat]
        this.editedDevices[index].address = this.getAddressString(location)

        if (this.selectedDevice && this.selectedDevice._id === this.editedDevices[index]._id) {
          console.log('Device is selected')
          this.selectedDevice = null
        }
      }
    },
    onMapEditAddressChanged(data) {
      let index = this.editedDevices.findIndex(device => device._id === data._id)
      if (index !== -1) {
        console.log('Find item: ', this.editedDevices[index])
        this.editedDevices[index].location.coordinates = data.location.coordinates
        this.editedDevices[index].address = data.address
        if (this.selectedDevice && this.selectedDevice._id === this.editedDevices[index]._id) {
          console.log('Device is selected')
          this.selectedDevice = this.editedDevices[index]
        }
      }
    },
    async onMapEditCoordinatesChanged(data) {
      console.log('On map edit coordinates changed: ', data)
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        let index = this.editedDevices.findIndex(device => device._id === data._id)
        if (index !== -1) {
          console.log('Find item: ', this.editedDevices[index])
          this.isLoading = true
          let coordinates = {lat: data.location.coordinates[1], lng: data.location.coordinates[0]}
          let location = await this.getLocation(coordinates)
          this.isLoading = false
          this.editedDevices[index].location.coordinates = [coordinates.lng, coordinates.lat]
          this.editedDevices[index].address = this.getAddressString(location)

          if (this.selectedDevice && this.selectedDevice._id === this.editedDevices[index]._id) {
            console.log('Device is selected')
            this.selectedDevice = null
          }
        }
      }, 1000)

    },
    async clearFields() {
      this.editedDevices = []
      console.log('Clear fields edit device', this.deselectOnClose)
      if (this.deselectOnClose) {
        console.log('Clear fields edit device', this.devices[0])
        this.selectedDevice = null
        await this.deselectAllDevices()
      }
    },
    click() {
      console.log('Clcked')
    },
    zoomToObject() {
      if (this.$refs.mapBoxInput) {
        this.$refs.mapBoxInput.zoomMapToSelectedSiteOrProject(true)
      } else if (this.$refs.gMapInput) {
        this.$refs.gMapInput.zoomMapToSelectedSiteOrProject(true)
      }
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
  computed: {
    ...mapGetters({
      mapType: 'getMapType',
      selectedProject: 'projectsModule/getSelectedProject'
    }),
    DeviceType() {
      return DeviceType
    }
  }
}
</script>

<style lang="scss" scoped>
.simple-device-card {
  border-radius: 32px;
  background-color: #fdfdfd;
  box-shadow: 0 0.125em 0.125em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
}

.selected {
  background-color: #e3e3e3;
}

.modal-tab-content {
  height: 30vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 24px;
  padding-right: 16px;
}

</style>