<template>
  <div class="box m-2 box mb-4 mt-4 is-flex is-flex-direction-column">
<!--    <b-taglist attached class="m-0 p-0 ml-auto">
      <b-tag type="is-dark">Version</b-tag>
      <b-tag type="is-info">{{selectedQaDevice.chartsData ? selectedQaDevice.chartsData.dataVersion : 'unknown'}}</b-tag>
    </b-taglist>-->
    <b-tabs type="is-toggle" class="m-2 mb-4 mt-1" :key="selectedQaDevice._id">
      <b-tab-item label="Data Completeness Check" icon="database-eye" class="mt-4">
        <vehicle-chart></vehicle-chart>
      </b-tab-item>
      <b-tab-item label="Vehicles Data Check" icon="car" class="mt-4">
        <speed-distribution-chart></speed-distribution-chart>
      </b-tab-item>
      <b-tab-item label="Pedestrians Data Check" icon="walk" class="mt-4">
        <pedestrian-chart></pedestrian-chart>
      </b-tab-item>
    </b-tabs>
  </div>

</template>

<script>

import SpeedDistributionChart
  from "@/modules/qa-module/components/speed-distribution-chart/speed-distribution-chart.vue";
import VehicleChart from "@/modules/qa-module/components/vehicle-chart/vehicle-chart.vue";
import {mapGetters} from "vuex";
import PedestrianChart from "@/modules/qa-module/components/pedestrian-chart/pedestrian-chart.vue";

export default {
  name: "device-qa",
  components: {PedestrianChart, SpeedDistributionChart, VehicleChart},
  computed: {
    ...mapGetters({
      selectedQaDevice: 'qaModule/getSelectedQaDevice'
    })
  }
}
</script>

<style scoped>

</style>