<template>
  <div>
    <b-modal v-model="modalActive" width="80%" scroll="keep" style="z-index: 101" @close="onClose">
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head">
          <div class="is-size-5 ml-auto mr-auto has-text-weight-semibold">Notes</div>
          <button
              type="button"
              class="delete"
              @click="show(false)"/>
        </header>
        <section class="modal-card-body p-0 pt-4 pl-2 pr-2">
          <div class="ml-4 mr-4 mb-6 mt-6">
            <div class="is-flex m-0 p-0" v-for="(entry, index) in notes" :key="index">
              <div class="m-0 p-0 ml-6" style="width: 128px; min-width: 128px">
                <div class="is-size-7 date-background">{{ formatDateTime(entry) }}</div>
              </div>
              <div class="m-0 p-0 is-1 ml-2 mr-2" style="max-width: 16px">
                <div class="timeline-line"></div>
              </div>
              <div class="ml-2" style="margin-top: 8px; width: 100%">
                <div class="timeline-item is-flex mt-auto mb-auto pl-3 pr-5">
                  <div class="media mr-4" v-if="entry.createdBy != null">
                    <user-avatar :user="entry.createdBy"></user-avatar>
                    <div class="media-content" style="margin-left: 8px; padding-top: 0">
                      <div class="has-text-weight-semibold"
                           style="margin-bottom: 0; margin-top: 12px; line-height: 0.5;">{{ entry.createdBy.fullName }}
                      </div>
                      <small class="is-size-7 has-text-grey-light" style="white-space: nowrap">{{
                          entry.createdBy.role
                        }}</small>
                    </div>
                  </div>
                  <hr>
                  <div>
                    <div class="media" v-if="entry.createdBy != null">
                      <div class="media-content" style="padding-top: 0">
                        <small class="is-size-7 has-text-grey-light" style="white-space: nowrap">NOTE</small>
                        <div class="has-text-weight-semibold"
                             style="margin-bottom: 0; margin-top: 4px; line-height: 0.8;">{{ entry.text }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {getDate, getDateTime} from "@/utils/utils";
import UserAvatar from "@/components/avatar/avatar.vue";
import {mapActions} from "vuex";

export default {
  name: "notes-modal",
  components: {UserAvatar},
  data() {
    return {
      isLoading: false,
      modalActive: false,
      notes: []
    }
  },
  methods: {
    async show(show, notes) {
      console.log('Notes:', notes)

      this.modalActive = show
      if (show) {
        notes.forEach(note => {
          let noteObj = {
            date: note.createdAt,
            createdBy: this.$store.getters.getUserById(note.createdBy),
            text: note.text,
            createdAt: note.createdAt
          }

          this.notes.push(noteObj)
        })

        console.log('Parsed notes: ', this.notes)
      }

      if (!this.modalActive) {
        this.notes = []
      }
    },
    onClose() {
      this.notes = []
    },
    formatDateTime(entry) {
      return getDateTime(entry.createdAt)
    },
  },
  computed: {
    users() {
      return this.$store.getters.getAllowedUsers
    }
  }
}
</script>

<style scoped>

.date-background {
  color: white;
  width: fit-content;
  border-radius: 8px;
  background: #454545;
  padding: 4px 8px;
  margin-top: 26px;
}

.timeline-line {
  background-image: url('~@/assets/timeline_line.png');
  width: 12px;
  height: 112px;
}

.timeline-line-small {
  background-image: url('~@/assets/timeline_line.png');
  width: 12px;
  height: 64px;
}

.arrow {
  background-image: url('~@/assets/arrow_right.png');
  width: 16px;
  height: 8px;
}

.timeline-item {
  position: relative;
  height: 64px;
  background: #F4F4F4;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  padding: 8px;
}

.timeline-item:before {
  content: "";
  position: absolute;
  top: 22px;
  left: -20px;
  z-index: 1;
  border: solid 10px transparent;
  border-right-color: #F4F4F4;
}

hr {
  border: none;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  height: 80%;
  width: 1px;
  margin: 8px 24px 0 24px;
}

.status-horizontal {
  color: white;
  height: 24px;
  width: fit-content;
  border-radius: 4px;
  background: #B1B1B1;
  padding: 0 8px;
}

.no-color {
  border-color: rgba(0, 0, 0, 0.5);
  border-width: 1px;
  border-style: solid;
  background: transparent;
}

</style>