<template>
  <div>
    <b-modal v-model="modalActive" width="85%" scroll="keep">
      <div class="card">
        <header class="card-header ">
          <div class="card-header-title is-flex">
            <h6 class="is-size-6 ml-auto mr-auto pl-4 has-text-weight-semibold">Video Materials</h6>
          </div>
          <b-button class="card-header-icon mt-2 mr-2" aria-label="more options" @click="show(false, null)">
            <b-icon icon="close"></b-icon>
          </b-button>
        </header>
        <div class="card-content" style="min-height: 250px; overflow-y: scroll">
          <div class="content" v-if="data">
            <div style="max-height: 600px">
              <div class="mt-4 mb-4" v-for="chartData in data.chartsData" :key="chartData.chartName">
                <video-data-chart :title="chartData.chartName" :data="chartData.y"
                                  :categories="chartData.x"></video-data-chart>
              </div>
            </div>
          </div>
        </div>
        <footer class="modal-card-foot">
          <b-button
              class="ml-auto"
              @click="closeModal"
              label="Close" />
          <b-button
              label="Proceed"
              @click="onProceed"
              type="is-primary" />
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VideoDataChart from "@/modules/data-processing-module/components/video-data-chart/video-data-chart.vue";

export default {
  name: "check-materials-modal",
  components: {VideoDataChart},
  data() {
    return {
      isLoading: false,
      modalActive: false,
      data: null
    }
  },
  mounted() {

  },
  methods: {
    show(show, data) {
      this.data = data
      this.modalActive = show
    },
    onProceed(){
      this.$emit('onProceed', this.data)
    },
    closeModal(){
      this.data = null
      this.modalActive = false
    }
  }
}
</script>

<style scoped lang="scss">
</style>