<template src="./template.html"></template>
<script>
export default {
  name: 'Forgot-Page',
  data() {
    return {
      email: null,
      code: null,
      password: null,
      confirmPass: null
    }
  },
  mounted() {
  },
  methods: {
    toLogin() {
      this.$router.push('/')
    },
    forgotPassword: async function () {
      this.$emit('showLoading', true)

      await this.$auth.forgotPassword(this.email)
          .then((data) => {
            this.$buefy.notification.open({
              message: 'Code is sent to your email address',
              type: 'is-success'
            })
            setTimeout(() => {
              this.$router.push({path: 'reset-password', query: {email: this.email}})
            }, 1000)
            //this.codeBlock = true
          })
          .catch((error) => {
            this.$buefy.notification.open({
              duration: 5000,
              message: error.message,
              position: 'is-bottom-right',
              type: 'is-danger',
              hasIcon: false
            })
          });

      this.$emit('showLoading', false)
    },
    showError(message) {
      this.$emit('showLoading', false)
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger'
      })
    }
  }
}
</script>
<style scoped lang="scss" src="./style.scss"></style>
