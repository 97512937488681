<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <add-change-role ref="addModal"></add-change-role>
    <div class="box p-4 mb-6 is-flex is-flex-direction-column">
      <div class="is-flex">
        <div class="is-size-4 has-text-weight-semibold ml-2">Roles</div>
        <view-guard :permission="'role:create'" class="ml-auto">
          <b-button type="is-success" @click="onAddRole" icon-left="plus">Create new role</b-button>
        </view-guard>
      </div>
      <hr class="m-0 mt-2 p-0 mb-5">
      <b-table v-if="allRoles && allRoles.length > 0"
               key="incident_table"
               ref="table"
               :data="roles"
               hoverable
               paginated
               per-page="10"
               current-page.sync="1"
               :pagination-simple="false"
               pagination-position="bottom"
               default-sort-direction="asc"
               :pagination-rounded="false"
               sort-icon="arrow-up"
               sort-icon-size="is-small"
               @sort="sortClicked"
               default-sort="name"
               aria-next-label="Next page"
               aria-previous-label="Previous page"
               aria-page-label="Page"
               aria-current-label="Current page"
               detailed
               detail-key="name"
               detail-transition="fade"
               @details-open="(row) => $buefy.toast.open(`Expanded ${row.name}`)"
               :page-input="false">
        <b-table-column field="name" label="Role name" sortable>
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="search" tooltip="Filter by name" element-id="13225233"
                          :table-header-data="tableHeaderData" @onSort="onSort">
            </table-header>
          </template>
          <template v-slot="props">
            {{ props.row.name }}
          </template>
        </b-table-column>
        <b-table-column field="description" label="Description" sortable>
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="search" tooltip="Filter by description" element-id="467224434"
                          :table-header-data="tableHeaderData" @onSort="onSort">
            </table-header>
          </template>
          <template v-slot="props">
            {{ props.row.description }}
          </template>
        </b-table-column>
        <b-table-column field="appName" label="Application" sortable>
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="dropdown"
                          element-id="223dd422"
                          :options="getAppList()"
                          tooltip="Filter by application"
                          :table-header-data="tableHeaderData"
                          @onSort="onSort"
            ></table-header>
          </template>
          <template v-slot="props">
            {{ props.row.appName }}
          </template>
        </b-table-column>
        <b-table-column field="permissionNames" label="Permissions">
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="dropdown"
                          element-id="223dd42276"
                          :options="getPermList()"
                          tooltip="Filter by permissions"
                          :table-header-data="tableHeaderData"
                          @onSort="onSort"
            ></table-header>
          </template>
          <template v-slot="props">
            Permissions: {{ props.row.permissionNames.length }}
          </template>
        </b-table-column>
        <b-table-column field="actions" label="Actions" sortable width="100">
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="none" tooltip="" element-id="467sd224434"
                          :table-header-data="tableHeaderData" @onSort="onSort">
            </table-header>
          </template>
          <template v-slot="props">
            <div class="is-flex">
              <view-guard :permission="'role:update'" class="mr-1 ml-auto">
                <b-tooltip position="is-top" label="Update Role" :delay="1000" type="is-dark">
                  <b-button size="is-small" icon-left="pencil" @click="onEditRole(props.row)"></b-button>
                </b-tooltip>
              </view-guard>
              <view-guard :permission="'role:delete'">
                <b-tooltip position="is-top" label="Delete Role" :delay="1000" type="is-dark">
                  <b-button size="is-small" icon-left="delete" type="is-danger" outlined
                            @click="confirmDelete(props.row)"></b-button>
                </b-tooltip>
              </view-guard>
            </div>
          </template>
        </b-table-column>
        <template #detail="props">
          <div class="has-text-weight-semibold is-size-5">Permissions</div>
          <hr class="m-0 mb-3">
          <div class="is is-flex is-flex-wrap-wrap ml-2 mr-2">
            <div v-for="groupName in Object.keys(props.row.groupedPermissions)" :key="groupName" style="width: 20%"
                 class="mb-2">
              <div class="m-0 mr-3 mb-2 pl-2 mt-1" style="border-radius: 4px; background: #ececec">{{ groupName }}</div>
              <div v-for="item in Object.values(props.row.groupedPermissions[groupName])" :key="item.name" class="pl-2">
                <b-tooltip :label="item.description" :delay="1000" type="is-dark" style="cursor: default" class="mb-1">
                  <b-tag style="background: #f3f3f3">{{ item.name }}</b-tag>
                </b-tooltip>
              </div>
            </div>
          </div>
        </template>
      </b-table>
      <div v-else class="is-flex is-align-items-center dashed-border m-2">
        <h4 class="is-size-4 ml-auto mr-auto has-text-grey-light has-text-weight-light">Click on "+" button to add first
          role</h4>
      </div>
    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import TableHeader from "@/components/table-header/table-header.vue";
import AddChangeRole from "@/modules/uac-module/components/add-change-role/add-change-role.vue";
import ViewGuard from "@/components/view-guard/view-guard.vue";

export default {
  name: "roles-list",
  components: {ViewGuard, AddChangeRole, TableHeader},
  data() {
    return {
      checkedRows: [],
      isLoading: false
    }
  },
  methods: {
    ...mapActions({
      setTableHeaderData: 'uacModule/setRolesTableHeaderData',
      deleteRole: 'uacModule/deleteRole',
      getUsers: 'uacModule/getUsers',
    }),
    toggle(row) {
      this.$refs.table.toggleDetails(row)
    },
    sortClicked(field, order) {
      console.log('Sorted: ', field, order)
      let tableHeaderData = this.tableHeaderData
      tableHeaderData.activeSorted = {field: field, order: order}
      this.setTableHeaderData(tableHeaderData)
    },
    onSort(tableHeaderData) {
      this.setTableHeaderData(tableHeaderData)
    },
    onEditRole(role) {
      console.log('Clicked: ', role)
      this.$refs.addModal.show(true, role.id)
    },
    confirmDelete(role) {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: 'This action will remove role from all UAC records also. Are you sure you want to delete ' + role.name + ' ?',
        onConfirm: () => this.delete(role)
      })
    },
    async delete(role) {
      console.log('Delete role: ', role)
      this.isLoading = true
      let response = await this.deleteRole(role.id)
      await this.getUsers()
      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
      } else {
        this.showToast('Role successfully deleted', 'is-success')
      }
      this.isLoading = false
    },
    onAddRole() {
      this.$refs.addModal.show(true)
    },
    getPermList() {
      return this.permissionsList.map((perm) => perm.name)
    },
    getAppList() {
      return this.apps.map((app) => app.name)
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
  computed: {
    ...mapGetters({
      roles: 'uacModule/getRolesTable',
      allRoles: 'uacModule/getRoles',
      apps: 'uacModule/getApps',
      tableHeaderData: 'uacModule/getRolesTableHeaderData',
      permissionsList: 'uacModule/getPermissions'
    })
  }
}
</script>

<style scoped>

</style>