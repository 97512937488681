<template>
  <div class="floating-view-background" :style="{'height': height*3 + 'px'}">
    <div class="floating-view" :style="{'margin-left': -width/2 + 'px', 'top': height/2 + 'px'}">
      <div :style="{'width': width - 16 + 'px', 'height':height - 16 + 'px'}" class="box content-view p-0 m-0">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "floating-view",
  props: {
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 64
    }
  }
}
</script>

<style lang="scss" scoped>

.floating-view-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 20%;
  z-index: 99;
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
}

.floating-view {
  position: fixed;
  top: 64px;
  left: 50%;
  z-index: 100;
}

.content-view {
  border-radius: 64px;
}
</style>