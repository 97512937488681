import Vue from "vue";

export default {
    getLiveMonitoring: async ({commit}, days) => {
        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/live-monitoring-v3',
            headers: {
                'Content-Type': 'application/json'
            },
            params: {lastDays: days}
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Get live monitoring response: ', response)
                if (response.status === 200) {
                    commit('setLiveMonitoring', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    setSelectedProjects: async ({commit}, projects) => {
        commit('setSelectedProjects', projects)
    },
    setLegendSelection: async ({commit}, selection) => {
        commit('setLegendSelection', selection)
    }
}

