<template>
  <div class="box simple-site-card p-2 pl-2 m-0">
    <div class="columns m-0 p-0">
      <div v-if="selectionMode" class="column pl-3 mt-1" style="max-width: 48px">
        <b-checkbox @input="onSelectionChanged" v-model="plan.isSelected"></b-checkbox>
      </div>
      <div class="column p-0 ml-3 mt-2 mr-2">
        <!--        <div class="is-size-6 has-text-weight-semibold has-text-black">{{ site.name }}</div>-->
        <b-field grouped label="Site name *" label-position="on-border">
          <b-input expanded rounded placeholder="Site name *" v-model="plan.editedSiteName" @input="onSiteNameChanged"
                   type="text"
                   required/>
        </b-field>
      </div>
      <div class="column p-0 is-1">
        <div class="is-size-7 has-text-grey-light">Device Name</div>
        <div class="is-size-6 has-text-weight-semibold has-text-black">{{ site.device.name }}</div>
      </div>
      <div class="column p-0 mt-2">
        <b-field grouped label="Installation date *" label-position="on-border">
          <b-datepicker :mobile-native="false" expanded v-model="plan.installationDate" size="is-small"
                        position="is-top-right" @input="onInstallationDateChanged">
            <template v-slot:trigger>
              <b-input expanded readonly rounded placeholder="Installation date *" :value="installationDateString"
                       type="text"
                       required/>
            </template>
          </b-datepicker>
        </b-field>
      </div>
      <div class="column p-0 mt-2 ml-1">
        <b-field grouped label="Decommission date *" label-position="on-border">
          <b-datepicker :mobile-native="false" expanded v-model="plan.decommissionDate" size="is-small"
                        position="is-top-right" @input="onDecommissionDateChanged">
            <template v-slot:trigger>
              <b-input expanded readonly rounded placeholder="Decommission date *" :value="decommissionDateString"
                       type="text"
                       required/>
            </template>
          </b-datepicker>
        </b-field>
      </div>
      <div class="column p-0 ml-1 mt-1">
        <b-field expanded label="Installation crew" label-position="on-border" class="mt-1">
          <b-dropdown
              expanded
              v-model="plan.installationCrew"
              multiple
              @change="onInstallationCrewChanged"
              aria-role="list">
            <template #trigger>
              <my-button
                  expanded
                  style="border-radius: 32px"
                  icon-right="menu-down">
                <div class="is-flex" v-if="plan.installationCrew.length > 0">
                  <user-avatar v-for="user in plan.installationCrew" :user="user" :is-small="true"
                               :key="user.id"></user-avatar>
                </div>
                <div v-else class="has-text-grey-light">
                  Installation crew
                </div>

              </my-button>
            </template>
            <b-dropdown-item v-for="user in users" :value="user" :key="user.id" aria-role="listitem">
              <div class="is-flex m-0 p-0">
                <div class="m-0 p-0">
                  <user-avatar :user="user" :is-small="true"></user-avatar>
                </div>
                <div class="m-0 ml-2 p-0">
                  <div class="is-size-7 has-text-weight-semibold" style="line-height: 1">{{ user.fullName }}</div>
                  <div class="is-size-7" style="line-height: 1"> {{user.partEmail}}</div>
                </div>
              </div>

            </b-dropdown-item>
          </b-dropdown>
        </b-field>
      </div>
      <div class="column p-0 ml-1 mt-1">
        <b-field expanded label="Decommission crew" label-position="on-border" class="mt-1">
          <b-dropdown
              expanded
              v-model="plan.decommissionCrew"
              multiple
              @change="onDecommissionCrewChanged"
              aria-role="list">
            <template #trigger>
              <my-button
                  expanded
                  style="border-radius: 32px"
                  icon-right="menu-down">
                <div class="is-flex" v-if="plan.decommissionCrew.length > 0">
                  <user-avatar v-for="user in plan.decommissionCrew" :user="user" :is-small="true"
                               :key="user.id"></user-avatar>
                </div>
                <div v-else class="has-text-grey-light">
                  Decommission crew
                </div>

              </my-button>
            </template>
            <b-dropdown-item v-for="user in users" :value="user" :key="user.id" aria-role="listitem">
              <div class="is-flex m-0 p-0">
                <div class="m-0 p-0">
                  <user-avatar :user="user" :is-small="true"></user-avatar>
                </div>
                <div class="m-0 ml-2 p-0">
                  <div class="is-size-7 has-text-weight-semibold" style="line-height: 1">{{ user.fullName }}</div>
                  <div class="is-size-7" style="line-height: 1"> {{user.partEmail}}</div>
                </div>
              </div>

            </b-dropdown-item>
          </b-dropdown>
        </b-field>
      </div>

      <div class="column p-0 mt-1 is-flex" style="max-width: 64px">
        <b-tooltip label="Remove Site" class="ml-auto mr-2 mt-2" :delay="1000" type="is-dark">
          <a @click="onDeleteClick">
            <b-icon icon="delete">
            </b-icon>
          </a>
        </b-tooltip>
      </div>
    </div>

    <collection-processing-input
        class="mt-4"
        :show-copy-actions="false"
        :initial-collection-period="plan.collectionPeriod"
        :initial-processing-period="plan.processingPeriod"
        :initial-processing-periods="plan.processingPeriods"
      @collectionPeriodChanged="onCollectionPeriodChanged"
      @processingPeriodChanged="onProcessingPeriodChanged"
      @processingPeriodsChanged="onProcessingPeriodsChanged"
    >
    </collection-processing-input>
  </div>
</template>

<script>
import UserAvatar from "@/components/avatar/avatar.vue";
import MyButton from "@/components/my-button/my-button.vue";
import {getDate, getDateRange} from "@/utils/utils";
import CollectionProcessingInput
  from "@/modules/projects-module/components/collection-processing-input/collection-processing-input.vue";

export default {
  name: "simple-project-site-card",
  components: {CollectionProcessingInput, MyButton, UserAvatar},
  props: {
    site: {
      type: Object,
      required: true
    },
    selectionMode: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      plan: {
        installationCrew: [],
        decommissionCrew: [],
        installationDate: null,
        decommissionDate: null,
        isSelected: false,
        siteName: null,
        editedSiteName: null,
        collectionPeriod: {dateRange: [], timeRanges: [{startTime: null, endTime: null}]},
        processingPeriod: {dateRange: [], timeRanges: [{startTime: null, endTime: null}]}
      },
    }
  },
  mounted() {
    this.plan.siteName = this.site.name
    this.plan.editedSiteName = this.site.name
  },
  methods: {
    onCollectionPeriodChanged(value){
      this.plan.collectionPeriod = value
      this.emitChanges()
    },
    onProcessingPeriodChanged(value){
      this.plan.processingPeriod = value
      this.emitChanges()
    },
    onProcessingPeriodsChanged(value){
      this.plan.processingPeriods = value
      this.emitChanges()
    },
    onSelectionChanged(event) {
      console.log('On selection changed:', event)
      this.plan.siteName = this.site.name
      this.plan.isSelected = event
      this.$emit('onPlanSelectionChanged', this.plan)
    },
    onDeleteClick() {
      console.log('On delete site click')
      this.$emit('onSiteDeleteClicked')
    },
    onInstallationDateChanged(event) {
      console.log('On installation date changed', event)
      this.plan.installationDate = new Date(event)
      this.emitChanges()
    },
    onDecommissionDateChanged(event) {
      console.log('On decommission date changed', event)
      this.plan.decommissionDate = new Date(event)
      this.emitChanges()
    },
    onInstallationCrewChanged(event) {
      console.log('On installation crew changed', event)
      this.plan.installationCrew = event
      this.emitChanges()
    },
    onDecommissionCrewChanged(event) {
      console.log('On decommission crew changed', event)
      this.plan.decommissionCrew = event
      this.emitChanges()
    },
    onSiteNameChanged(siteName) {
      this.plan.editedSiteName = siteName
      this.emitChanges()
    },
    emitChanges() {
      console.log('Installation plan: ', this.plan)
      this.plan.siteName = this.site.name
      this.$emit('onSitePlanChanged', this.plan)
    }

  },
  computed: {
    installationDateString() {
      return this.plan.installationDate ? getDate(this.plan.installationDate) : ''
    },
    decommissionDateString() {
      return this.plan.decommissionDate ? getDate(this.plan.decommissionDate) : ''
    },
    users() {
      return this.$store.getters.getAllowedUsers
    },
  },
  watch: {
    site: {
      handler: function (newVal, oldVal) {
        console.log('SITE CHANGED', this.site)
        this.plan.installationCrew = this.site.installationCrew
        this.plan.decommissionCrew = this.site.decommissionCrew
        this.plan.installationDate = this.site.installationDate
        this.plan.decommissionDate = this.site.decommissionDate
        this.plan.isSelected = this.site.isSelected

        this.plan.processingPeriod = this.site.processingPeriod
        this.plan.collectionPeriod = this.site.collectionPeriod
        this.plan.processingPeriods = this.site.processingPeriods
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.simple-site-card {
  border-radius: 16px;
  background-color: #f7f7f7;
  box-shadow: 0 0.125em 0.125em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
}
</style>