import { render, staticRenderFns } from "./project-overview.vue?vue&type=template&id=bea2e768&scoped=true"
import script from "./project-overview.vue?vue&type=script&lang=js"
export * from "./project-overview.vue?vue&type=script&lang=js"
import style0 from "./project-overview.vue?vue&type=style&index=0&id=bea2e768&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bea2e768",
  null
  
)

export default component.exports