<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <add-change-user-uac ref="addChangeUac" @onBulkActionSuccess="checkedRows = []"></add-change-user-uac>
    <div class="box p-4 mb-6">
      <div class="is-flex">
        <div class="is-size-4 has-text-weight-semibold ml-2 mb-2">Users List</div>
        <view-guard :permission="'uac:update-multiple'" class="mr-1 ml-auto">
          <b-tooltip position="is-top" label="Assign UAC" :delay="1000" type="is-dark">
            <b-button v-if="checkedRows.length > 0" icon-left="security" @click="assignBulkRole()">
              Selected: {{ checkedRows.length }}
            </b-button>
          </b-tooltip>
        </view-guard>
      </div>
      <b-table
          key="incident_table"
          ref="table"
          :data="users"
          :checked-rows.sync="checkedRows"
          checkable
          checkbox-position="left"
          checkbox-type="is-primary"
          hoverable
          paginated
          per-page="10"
          current-page.sync="1"
          :pagination-simple="false"
          pagination-position="bottom"
          default-sort-direction="asc"
          :pagination-rounded="false"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          @sort="sortClicked"
          default-sort="name"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          :page-input="false">
        <b-table-column field="name" label="Full name" sortable>
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="search" tooltip="Filter by name" element-id="1345233"
                          :table-header-data="tableHeaderData" @onSort="onSort">
            </table-header>
          </template>
          <template v-slot="props">
            {{ props.row.name ? props.row.name : 'No Name' }}
          </template>
        </b-table-column>
        <b-table-column field="email" label="E-mail" sortable>
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="search" tooltip="Filter by e-mail" element-id="4674434"
                          :table-header-data="tableHeaderData" @onSort="onSort">
            </table-header>
          </template>
          <template v-slot="props">
            {{ props.row.email }}
          </template>
        </b-table-column>
        <b-table-column field="roles" label="Roles" sortable>
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="dropdown"
                          element-id="223422"
                          :options="roleNames"
                          tooltip="Filter by roles"
                          :table-header-data="tableHeaderData"
                          @onSort="onSort"
            ></table-header>
          </template>
          <template v-slot="props">

            <span class="tag mr-1" v-for="role in props.row.roles" :key="role">
                    {{ role.toString() }}
                </span>
          </template>
        </b-table-column>
        <b-table-column field="resources" label="Projects" sortable>
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="search" tooltip="Filter by project" element-id="4674dfd434"
                          :table-header-data="tableHeaderData" @onSort="onSort">
            </table-header>
          </template>
          <template v-slot="props">
            {{ props.row.resources && props.row.resources ? props.row.resources.toString() : ' ' }}
          </template>
        </b-table-column>
        <b-table-column field="internalUser" label="Internal" sortable width="100">
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="none" tooltip="" element-id="4674sdsd434"
                          :table-header-data="tableHeaderData" @onSort="onSort">
            </table-header>
          </template>
          <template v-slot="props">
            <b-tooltip :label="props.row.internalUser ? 'Internal user' : 'User'" class="ml-auto mr-1" :delay="1000"
                       type="is-dark">
              <b-icon
                  :icon="props.row.internalUser !== undefined ? props.row.internalUser === true ? 'account-check' : 'account-off' : '' "
                  :class="props.row.internalUser ? 'has-text-primary' : 'has-text-grey-light'"></b-icon>
            </b-tooltip>
          </template>
        </b-table-column>
        <b-table-column field="status" label="Status" sortable width="100">
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="none" tooltip="" element-id="4674sddfdddsd434"
                          :table-header-data="tableHeaderData" @onSort="onSort">
            </table-header>
          </template>
          <template v-slot="props">
            <b-tooltip :label="props.row.status" class="ml-auto mr-1" :delay="1000"
                       type="is-dark">
              <b-icon :icon="props.row.status === 'Enabled' ? 'check-circle' : 'minus-circle'"
                      :class="props.row.status === 'Enabled' ? 'has-text-primary' : 'has-text-danger'"></b-icon>
            </b-tooltip>
          </template>
        </b-table-column>
        <b-table-column field="actions" label="Actions" width="100px">
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="none" tooltip="" element-id="467443454"
                          :table-header-data="tableHeaderData">
            </table-header>
          </template>
          <template v-slot="props">
            <div class="is-flex">
              <view-guard :permission="'user:disable'" class="mr-1">
                <b-tooltip position="is-top" label="Disable user" :delay="1000" type="is-dark">
                  <b-button size="is-small" icon-left="account-minus" @click="confirmDisable(props.row)"></b-button>
                </b-tooltip>
              </view-guard>
              <view-guard :permission="'uac:create'" class="mr-1">
                <b-tooltip position="is-top" label="Assign UAC" :delay="1000" type="is-dark">
                  <b-button size="is-small" icon-left="security" @click="assignRole(props.row)"></b-button>
                </b-tooltip>
              </view-guard>
              <view-guard :permission="'uac:delete'">
                <b-tooltip position="is-top" label="Delete UAC for user" :delay="1000" type="is-dark">
                  <b-button
                      :disabled="props.row.uac === undefined || props.row.uac === null || props.row.uac._id === undefined"
                      size="is-small"
                      type="is-danger" outlined icon-left="delete"
                      @click="confirmDelete(props.row)"></b-button>
                </b-tooltip>
              </view-guard>
            </div>
          </template>
        </b-table-column>
      </b-table>
    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import TableHeader from "@/components/table-header/table-header.vue";
import AddChangeUserUac from "@/modules/uac-module/components/add-change-user-uac/add-change-user-uac.vue";
import ViewGuard from "@/components/view-guard/view-guard.vue";

export default {
  name: "users-list",
  components: {ViewGuard, AddChangeUserUac, TableHeader},
  data() {
    return {
      checkedRows: [],
      isLoading: false
    }
  },
  async mounted() {
    await this.getAllProjects()
  },
  methods: {
    ...mapActions({
      setTableHeaderData: 'uacModule/setUsersTableHeaderData',
      getAllProjects: 'uacModule/getAllProjects',
      deleteUserUAC: 'uacModule/deleteUserUac',
      disableUser: 'uacModule/disableUser',
    }),
    sortClicked(field, order) {
      console.log('Sorted: ', field, order)
      let tableHeaderData = this.tableHeaderData
      tableHeaderData.activeSorted = {field: field, order: order}
      this.setTableHeaderData(tableHeaderData)
    },
    onSort(tableHeaderData) {
      this.setTableHeaderData(tableHeaderData)
    },
    confirmDisable(item) {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: 'Are you sure you want to disable this user?',
        onConfirm: () => this.disable(item)
      })
    },
    async disable(item) {
      console.log('Clicked: ', item)
      if (item.id) {
        this.isLoading = true
        let response = await this.disableUser(item.id)
        if (response instanceof Error) {
          this.showToast(response.message, 'is-danger')
        } else {
          this.showToast('User successfully disabled', 'is-success')
        }
        this.isLoading = false
      }
    },
    assignRole(user) {
      console.log('Clicked: ', user)
      this.$refs.addChangeUac.show(true, user.uac, user.id)
    },
    assignBulkRole() {
      console.log('Checked rows: ', this.checkedRows)
      this.$refs.addChangeUac.show(true, this.checkedRows, null)
    },
    confirmDelete(item) {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: 'This action will delete User Access Control data. Are you sure you want to delete UAC object for this user?',
        onConfirm: () => this.deleteUac(item)
      })
    },
    async deleteUac(item) {
      console.log('Clicked: ', item)

      if (item.uac && item.uac._id) {
        this.isLoading = true
        let response = await this.deleteUserUAC(item.uac._id)
        if (response instanceof Error) {
          this.showToast(response.message, 'is-danger')
        } else {
          this.showToast('User UAC successfully deleted', 'is-success')
        }
        this.isLoading = false
      }

    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
  computed: {
    ...mapGetters({
      tableHeaderData: 'uacModule/getUsersTableHeaderData',
      users: 'uacModule/getUsersTable',
      roles: 'uacModule/getRoles'
    }),
    roleNames() {
      return this.roles.map(role => role.name)
    }
  }
}
</script>

<style scoped>

</style>