export default {
    setLiveMonitoring(state, data) {
        state.liveMonitoring = data
    },
    setSelectedProjects(state, projects) {
        state.selectedProjects = projects
    },
    setLegendSelection(state, selection) {
        state.legendSelection = selection
    }
}
