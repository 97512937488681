<script>
import {defineComponent, h} from "vue";
import {VideoCalibrationCategory} from "@/utils/utils";

export default defineComponent({
  name: "draw-canvas",
  props: {
    strokeType: {
      type: String,
      validator: (value) => {
        return ['dash', 'line', 'square', 'circle', 'triangle', 'half_triangle', 'dot', 'polygon', 'none'].indexOf(value) !== -1
      },
      default: 'dash'
    },
    selectedCategory:{
      type: String,
      required: true
    },
    fillShape: {
      type: Boolean,
      default: false
    },
    width: {
      type: [String, Number],
      default: 600
    },
    height: {
      type: [String, Number],
      default: 400
    },
    image: {
      type: String,
      default: ''
    },
    eraser: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#000000'
    },
    guidesColor: {
      type: String,
      default: '#a2a5f8'
    },
    lineWidth: {
      type: Number,
      default: 5
    },
    lineCap: {
      type: String,
      validator: (value) => {
        return ['round', 'square', 'butt'].indexOf(value) !== -1
      },
      default: 'round'
    },
    lineJoin: {
      type: String,
      validator: (value) => {
        return ['miter', 'round', 'bevel'].indexOf(value) !== -1
      },
      default: 'miter'
    },
    lock: {
      type: Boolean,
      default: false
    },
    styles: {
      type: [Array, String, Object],
    },
    classes: {
      type: [Array, String, Object],
    },
    backgroundColor: {
      type: String,
      default: '#FFFFFF'
    },
    backgroundImage: {
      type: String,
      default: null
    },
    watermark: {
      type: Object,
      default: null
    },
    saveAs: {
      type: String,
      validator: (value) => {
        return ['jpeg', 'png'].indexOf(value) !== -1
      },
      default: 'png'
    },
    canvasId: {
      type: String,
      default: 'draw-canvas'
    },
    initialImage: {
      type: Array,
      default: () => []
    },
    additionalImages: {
      type: Array,
      default: () => []
    },
    outputWidth: {
      type: Number
    },
    outputHeight: {
      type: Number
    },
    deleteColor: {
      type:String,
      default: '#ff0000'
    }
  },
  data() {
    return {
      loadedImage: null,
      drawing: false,
      context: null,
      images: [],
      strokes: {
        id: '',
        type: '',
        from: {x: 0, y: 0},
        coordinates: [],
        color: '',
        width: '',
        fill: false,
        lineCap: '',
        lineJoin: '',
        text: '',
        textPosition: {x: 0, y: 0},
        renderedDimensions: null,
        newDimensions: null,
        category: ''
      },
      guides: [],
      trash: [],
      polygonStarted: false
    };
  },
  mounted() {
    this.images = []
    this.setContext();
    this.$nextTick(() => {
      this.drawInitialImage()
    })
  },
  watch: {
    backgroundImage() {
      this.loadedImage = null
    },
    selectedCategory(){
      this.redraw(false)
    }
  },
  methods: {
    onHoverOutside(imageId){
      let index = this.images.findIndex(image => image.id === imageId)
      if(index !== -1){
        this.images[index].color = this.deleteColor
        this.redraw(false)
      }
    },
    onLeaveOutside(imageId){
      let index = this.images.findIndex(image => image.id === imageId)
      if(index !== -1){
        this.images[index].color = this.color
        this.redraw(false)
      }
    },
    onDeleteOutside(imageId){
      let index = this.images.findIndex(img => img.id === imageId)
      if (index !== -1) {
        this.$emit('onDeleteImage', imageId)
        this.images.splice(index, 1)
        this.redraw(true)
      }
    },
    setInputData(images){
      this.images = JSON.parse(JSON.stringify(images))
      console.log('Set input data: ',this.images)
      this.redraw(true)
    },
    fireOnDrawFinished(){
      let images = this.images.filter(image => image.type === 'dot' || image.type === 'polygon' || image.type === 'line')
      this.$emit('onDrawDone', images)
    },
    async setContext() {
      let canvas = document.querySelector('#' + this.canvasId);
      console.log('Canvas: ', canvas)
      this.context = this.context ? this.context : canvas.getContext('2d');
      await this.setBackground();
    },
    drawInitialImage() {
      if (this.initialImage && this.initialImage.length > 0) {
        // @ts-ignore
        this.images = [].concat(this.images, this.initialImage)
        this.redraw(true)
      }
    },
    async setBackground() {
      this.clear();
      this.context.fillStyle = this.backgroundColor;
      this.context.fillRect(0, 0, Number(this.width), Number(this.height))

      this.$nextTick(() => {
        this.drawBackgroundImage()
      })
      this.save();
    },
    async drawBackgroundImage() {
      if (!this.loadedImage) {
        return new Promise((resolve, reject) => {
          if (!this.backgroundImage) {
            resolve()
            return;
          }
          const image = new Image();
          image.src = this.backgroundImage;
          image.onload = () => {
            this.context.drawImage(image, 0, 0, Number(this.width), Number(this.height));
            this.loadedImage = image
            resolve();
          }
        })
      } else {
        this.context.drawImage(this.loadedImage, 0, 0, Number(this.width), Number(this.height));
      }
    },
    clear() {
      this.context.clearRect(0, 0, Number(this.width), Number(this.height));
    },
    getCoordinates(event) {
      let x, y;
      if (event.touches && event.touches.length > 0) {
        let canvas = document.querySelector('#' + this.canvasId);
        let rect = canvas.getBoundingClientRect();
        x = (event.touches[0].clientX - rect.left);
        y = (event.touches[0].clientY - rect.top);
      } else {
        x = event.offsetX;
        y = event.offsetY;
      }
      return {
        x: x,
        y: y
      }
    },
    startDraw(event) {
      if (!this.lock && !this.eraser && this.strokeType !== 'none') {
        this.drawing = true;
        if(this.strokeType !== 'polygon' || this.strokes === null || (this.strokes && this.strokes.type !== 'polygon')){
          let coordinate = this.getCoordinates(event);

          //TODO create id type and number
          let imgs = this.images.filter(image => image.category === this.selectedCategory)
          console.log('IMAGES: ', imgs)
          let id = this.selectedCategory + '_0'
          if(imgs.length > 0){
            id = this.selectedCategory + '_' + imgs.length
          }

          this.strokes = {
            id: id,
            type: this.eraser ? 'eraser' : this.strokeType,
            from: coordinate,
            coordinates: [],
            color: this.eraser ? this.backgroundColor : this.color,
            width: this.lineWidth,
            fill: this.eraser || this.strokeType === 'dash' || this.strokeType === 'line' ? false : this.fillShape,
            lineCap: this.lineCap,
            lineJoin: this.lineJoin,
            text: this.strokeType === 'dot' ? 'DOT' : null,
            textPosition: this.strokeType === 'dot' ? coordinate : null,
            renderedDimensions: {width: this.width, height:this.height},
            category: this.selectedCategory
          };
          this.guides = [];
        }
      }
    },
    stopDraw(finishDraw, from) {
      console.log('From: ',from)
      if(finishDraw && this.drawing){
        this.drawing = false;
        this.trash = [];
        this.guides = []
        this.redraw(true);
        if(this.strokeType === 'polygon'){
          this.strokes = null
        }
        this.fireOnDrawFinished()
      }
      if (this.drawing) {
        console.log('STROKE STOP:', this.strokes)
        if (this.strokeType === 'polygon') {
          if (this.strokes.coordinates.length === 0) {
            this.strokes.coordinates = this.guides
          } else {
            let index = this.strokes.coordinates.findIndex(coordinate => coordinate === this.guides[this.guides.length - 1])
            if(index === -1){
              this.strokes.coordinates.push(this.guides[this.guides.length - 1])
            }
          }
          let index = this.images.findIndex(image => image.id === this.strokes.id)
          this.strokes.color = this.guidesColor
          if(index === -1){
            this.images.push(this.strokes);
          }

          this.redraw(true);
        } else {
          this.strokes.coordinates = this.guides.length > 0 ? this.guides : this.strokes.coordinates;
          this.images.push(this.strokes);

          this.redraw(true);
          this.drawing = false;
          this.trash = [];
          this.fireOnDrawFinished()
        }
      }else if(this.eraser){
        this.deleteImage()
        this.redraw(true);
        this.drawing = false;
        this.trash = [];
        this.fireOnDrawFinished()
      }
    },
    draw(event) {
      if (this.drawing) {
        if (!this.context) {
          this.setContext();
        }
        let coordinate = this.getCoordinates(event);
        if (this.eraser || this.strokeType === 'dash') {
          this.strokes.coordinates.push(coordinate);
          this.drawShape(this.context, this.strokes, false);
        } else {
          if (this.strokeType === 'line') {
            this.guides = [{x: coordinate.x, y: coordinate.y}]
          } else if (this.strokeType === 'polygon') {
            this.guides = [{x: coordinate.x, y: coordinate.y}]
          } else if (this.strokeType === 'square') {
            this.guides = [
              {x: coordinate.x, y: this.strokes.from.y},
              {x: coordinate.x, y: coordinate.y},
              {x: this.strokes.from.x, y: coordinate.y},
              {x: this.strokes.from.x, y: this.strokes.from.y}
            ]
          } else if (this.strokeType === 'triangle') {
            const center = Math.floor((coordinate.x - this.strokes.from.x) / 2) < 0 ? Math.floor((coordinate.x - this.strokes.from.x) / 2) * -1 : Math.floor((coordinate.x - this.strokes.from.x) / 2)
            const width = this.strokes.from.x < coordinate.x ? this.strokes.from.x + center : this.strokes.from.x - center
            this.guides = [
              {x: coordinate.x, y: this.strokes.from.y},
              {x: width, y: coordinate.y},
              {x: this.strokes.from.x, y: this.strokes.from.y}
            ]
          } else if (this.strokeType === 'half_triangle') {
            this.guides = [
              {x: coordinate.x, y: this.strokes.from.y},
              {x: this.strokes.from.x, y: coordinate.y},
              {x: this.strokes.from.x, y: this.strokes.from.y}
            ]
          } else if (this.strokeType === 'circle') {
            let radiusX = this.strokes.from.x - coordinate.x < 0 ? (this.strokes.from.x - coordinate.x) * -1 : this.strokes.from.x - coordinate.x
            this.guides = [
              {
                x: this.strokes.from.x > coordinate.x ? this.strokes.from.x - radiusX : this.strokes.from.x + radiusX,
                y: this.strokes.from.y
              },
              {x: radiusX, y: radiusX}
            ]
          }
          this.drawGuide(true);
        }
      }
    },
    drawGuide(closingPath) {
      this.redraw(true);
      this.$nextTick(() => {
        this.context.strokeStyle = this.guidesColor;
        this.context.lineWidth = 1;
        this.context.lineJoin = this.lineJoin;
        this.context.lineCap = this.lineCap;

        this.context.beginPath();
        this.context.setLineDash([15, 15]);
        if (this.strokes.type === 'circle') {
          this.context.ellipse(this.guides[0].x, this.guides[0].y, this.guides[1].x, this.guides[1].y, 0, 0, Math.PI * 2);
        } else if (this.strokeType === 'polygon') {
          console.log('Draw guide poly')
          if (this.strokes.coordinates && this.strokes.coordinates.length > 0) {
            this.context.moveTo(this.strokes.coordinates[this.strokes.coordinates.length-1].x, this.strokes.coordinates[this.strokes.coordinates.length - 1].y);
          } else if(this.strokes.from){
            this.context.moveTo(this.strokes.from.x, this.strokes.from.y);
          }

          this.guides.forEach((coordinate) => {
            this.context.lineTo(coordinate.x, coordinate.y);
          });
          if (closingPath) {
            this.context.closePath();
          }
        } else {
          this.context.moveTo(this.strokes.from.x, this.strokes.from.y);
          this.guides.forEach((coordinate) => {
            this.context.lineTo(coordinate.x, coordinate.y);
          });
          if (closingPath) {
            this.context.closePath();
          }
        }
        this.context.stroke();
      })
    },
    drawShape(context, strokes, closingPath) {
      context.strokeStyle = strokes.color;
      context.fillStyle = strokes.color;
      context.lineWidth = strokes.width;
      context.lineJoin = strokes.lineJoin === undefined ? this.lineJoin : strokes.lineJoin;
      context.lineCap = strokes.lineCap === undefined ? this.lineCap : strokes.lineCap;
      context.beginPath();
      context.setLineDash([]);
      if (strokes.type === 'circle') {
        context.ellipse(strokes.coordinates[0].x, strokes.coordinates[0].y, strokes.coordinates[1].x, strokes.coordinates[1].y, 0, 0, Math.PI * 2);
      } else {
        if(strokes.type === 'dot'){
          context.lineWidth = strokes.width + 2;
        }else{
          context.lineWidth = strokes.width;
        }
        context.moveTo(strokes.from.x, strokes.from.y);
        strokes.coordinates.forEach((stroke) => {
          context.lineTo(stroke.x, stroke.y);
        });
        if (closingPath) {
          context.closePath();
        }
      }
      if (strokes.fill) {
        context.fill();
      } else {
        context.stroke();
      }
    },
    deleteImage() {
      let index = this.images.findIndex(img => img.color === this.deleteColor)
      if (index !== -1) {
        let imageId = this.images[index].id
        this.$emit('onDeleteImage', imageId)
        this.images.splice(index, 1)
      }

      console.log('Delete images: ', this.images)
    },
    reset() {
      if (!this.lock) {
        this.images = [];
        this.strokes = {
          type: '',
          coordinates: [],
          color: '',
          width: '',
          fill: false,
          lineCap: '',
          lineJoin: ''
        };
        this.guides = [];
        this.trash = [];
        this.redraw(true);
        this.fireOnDrawFinished()
      }
    },
    undo() {
      if (!this.lock) {
        let strokes = this.images.pop();
        if (strokes) {
          this.trash.push(strokes);
          this.redraw(true);
        }
      }
    },
    redo() {
      if (!this.lock) {
        let strokes = this.trash.pop();
        if (strokes) {
          this.images.push(strokes);
          this.redraw(true);
        }
      }
    },
    async redraw(output) {
      output = typeof output !== 'undefined' ? output : true;
      await this.setBackground()
          .then(() => {
            let baseCanvas = document.createElement('canvas')
            let baseCanvasContext = baseCanvas.getContext('2d')
            baseCanvas.width = Number(this.width)
            baseCanvas.height = Number(this.height)

            if (baseCanvasContext) {
              this.images.forEach((stroke) => {
                if(stroke.category === this.selectedCategory || (this.selectedCategory === VideoCalibrationCategory.SPEED_LINE && stroke.category === 'measurement_line')){
                  if (baseCanvasContext) {
                    baseCanvasContext.globalCompositeOperation = stroke.type === 'eraser' ? 'destination-out' : 'source-over'

                    if (stroke.type !== 'circle' || (stroke.type === 'circle' && stroke.coordinates.length > 0)) {
                      this.drawShape(baseCanvasContext, stroke, (!(stroke.type === 'eraser' || stroke.type === 'dash' || stroke.type === 'line')))
                    }
                    if (stroke.text && stroke.textPosition) {
                      baseCanvasContext.font = "10px Arial";

                      let metrics = baseCanvasContext.measureText(stroke.text)
                      let leftStart = stroke.textPosition.x - metrics.width - 8
                      let xStart = stroke.textPosition.x - metrics.width/2 - 8
                      let xStop = stroke.textPosition.x + metrics.width/2 + 8
                      if(stroke.category === 'measurement_line'){

                        let bottomLeft = {x: xStart, y:stroke.textPosition.y + 10}
                        let bottomRight = {x: xStop, y:stroke.textPosition.y + 10}
                        let topRight = {x: xStop, y:stroke.textPosition.y - 8}
                        let topLeft = {x: xStart, y:stroke.textPosition.y - 8}

                        let rectText = {
                          type: 'rect',
                          from: bottomLeft,
                          coordinates: [bottomRight,topRight,topLeft],
                          color: stroke.color,
                          width: this.lineWidth,
                          fill: true,
                          lineCap: this.lineCap,
                          lineJoin: this.lineJoin,
                          text: null,
                          textPosition: null,
                          renderedDimensions: {width: this.width, height:this.height},
                          category: 'line'
                        }

                        this.drawShape(baseCanvasContext, rectText, true)
                      }

                      if(stroke.category === 'measurement_line'){
                        baseCanvasContext.fillStyle = '#000000'
                        baseCanvasContext.fillText(stroke.text, xStart+8, stroke.textPosition.y + 4);
                      }else{
                        baseCanvasContext.fillStyle = stroke.color
                        baseCanvasContext.fillText(stroke.text, leftStart, stroke.textPosition.y + 4);
                      }

                    }

                  }
                }
              })
              this.context.drawImage(baseCanvas, 0, 0, Number(this.width), Number(this.height))
            }
          })
          .then(() => {
            if (output) {
              this.save();
            }
          });
    },
    async setNewDimension(width,height){

      console.log('Images Set New: ', this.images)

      this.images.forEach(image => {
        if(image.type === 'dot' || image.type === 'line' || image.type === 'polygon'){

          image.newDimensions = {width: width,height:height}

          let percentageX = (image.newDimensions.width / image.renderedDimensions.width)
          let percentageY = (image.newDimensions.height / image.renderedDimensions.height)
          image.from.x = image.from.x * percentageX
          image.from.y = image.from.y * percentageY
          if(image.coordinates && image.coordinates.length > 0){
            image.coordinates.forEach(coordinate => {
              coordinate.x = coordinate.x * percentageX
              coordinate.y = coordinate.y * percentageY
            })
          }
          image.renderedDimensions = {width: width,height:height}
          if(image.textPosition){
            image.textPosition = {x: image.textPosition.x * percentageX, y:image.textPosition.y * percentageY}
          }
        }
      })

      await this.redraw(true)
    },
    save() {
      let canvas = document.querySelector('#' + this.canvasId);
      if (this.watermark) {
        let temp = document.createElement('canvas');
        let ctx = temp.getContext('2d')

        if (ctx) {
          temp.width = Number(this.width);
          temp.height = Number(this.height);
          ctx.drawImage(canvas, 0, 0, Number(this.width), Number(this.height));
        }
      } else {
        let temp = document.createElement('canvas');
        let tempCtx = temp.getContext('2d')
        let tempWidth = this.outputWidth === undefined ? this.width : this.outputWidth
        let tempHeight = this.outputHeight === undefined ? this.height : this.outputHeight
        temp.width = Number(tempWidth)
        temp.height = Number(tempHeight)

        if (tempCtx) {
          tempCtx.drawImage(canvas, 0, 0, Number(tempWidth), Number(tempHeight));
          this.$emit('update:image', temp.toDataURL('image/' + this.saveAs, 1));
          return temp.toDataURL('image/' + this.saveAs, 1);
        }
      }
    },
    isEmpty() {
      return this.images.length <= 0;
    },
    getAllStrokes() {
      return this.images;
    },
    handleMousemove(event) {
      if (this.images && this.images.length > 0 && this.eraser) {

        let coordinate = this.getCoordinates(event);
        let mouseX = coordinate.x;
        let mouseY = coordinate.y;

        let images = JSON.parse(JSON.stringify(this.images))

        images.forEach(image => {
          if(image.category === this.selectedCategory){
            if (image.type === 'line' || image.type === 'dot' || image.type === 'polygon') {
              if (image.type === 'dot') {
                if (image.from.x >= mouseX - 2 && image.from.x <= mouseX + 2 && image.from.y >= mouseY - 2 && image.from.y <= mouseY + 2) {
                  image.color = this.deleteColor
                } else {
                  image.color = this.color
                }
              } else if(image.type === 'line') {
                if (mouseX < image.from.x || mouseX > image.coordinates[0].x) {
                  image.color = this.color
                  return;
                }
                let line = {x0: image.from.x, x1: image.coordinates[0].x, y0: image.from.y, y1: image.coordinates[0].y}
                let linePoint = this.linePointNearestMouse(line, mouseX, mouseY);
                let dx = mouseX - linePoint.x;
                let dy = mouseY - linePoint.y;
                let distance = Math.abs(Math.sqrt(dx * dx + dy * dy));
                if (distance < 3) {
                  console.log('INSIDE')
                  image.color = this.deleteColor
                } else {
                  image.color = this.color
                  console.log('OUTSIDE')
                }
              }else if(image.type === 'polygon') {
                let inside = this.inside(mouseX, mouseY, [image.from, ...image.coordinates])
                if (inside) {
                  console.log('INSIDE')
                  image.color = this.deleteColor
                } else {
                  image.color = this.color
                  console.log('OUTSIDE')
                }
              }
            }
          }
        })

        let redraw = false
        for (let i = 0; i < this.images.length; i++) {
          if (this.images[i].color !== images[i].color) {
            redraw = true
            break;
          }
        }
        if (redraw) {
          this.images = images
          this.redraw(true)
        }
      }

    },
    linePointNearestMouse(line, x, y) {
      let dx = line.x1 - line.x0;
      let dy = line.y1 - line.y0;
      let t = ((x - line.x0) * dx + (y - line.y0) * dy) / (dx * dx + dy * dy);
      let lineX = this.lerp(line.x0, line.x1, t);
      let lineY = this.lerp(line.y0, line.y1, t);
      return ({
        x: lineX,
        y: lineY
      });
    },
    inside(x,y, poly) {
      let inside = false;
      for (let i = 0, j = poly.length - 1; i < poly.length; j = i++) {
        let xi = poly[i].x, yi = poly[i].y;
        let xj = poly[j].x, yj = poly[j].y;

        let intersect = ((yi > y) !== (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
      }

      return inside;
    },
    lerp(a, b, x) {
      return (a + x * (b - a));
    }
  },
  render() {
    return h('canvas', {
      attrs: {
        id: this.canvasId,
        width: Number(this.width),
        height: Number(this.height)
      },
      style: {
        'touchAction': 'none',
        // @ts-ignore
        ...this.styles
      },
      class: this.classes,
      on: {
        mousedown: (event) => this.startDraw(event),
        mousemove: (event) => {
          this.draw(event);
          this.handleMousemove(event);
        },
        mouseup: () => this.stopDraw(false, 'mouseup'),
        //mouseleave: () => this.stopDraw(true, 'mouseleave'),
        touchstart: (event) => this.startDraw(event),
        touchmove: (event) => this.draw(event),
        touchend: () => this.stopDraw(false, 'touchend'),
        touchleave: () => this.stopDraw(true, 'touchlive'),
        touchcancel: () => this.stopDraw(false, 'touchcancel'),
        pointerdown: (event) => this.startDraw(event),
        pointermove: (event) => this.draw(event),
        pointerup: () => this.stopDraw(false, 'pointerup'),
        //pointerleave: () => this.stopDraw(true, 'pointerleave'),
        pointercancel: () => this.stopDraw(true, 'pointercancel'),
      },
      ...this.$props
    })
  }
});
</script>

<style scoped>

</style>