<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <b-modal v-model="modalActive" width="50%" scroll="keep" style="z-index: 101" @close="clearFields()">
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head">
          <h6 class="is-size-5 ml-auto mr-auto pl-4 has-text-weight-semibold">
            {{ isUpdateMode ? 'Update Permission' : 'Create New Permission' }}</h6>
          <button
              type="button"
              class="delete"
              @click="show(false, null)"/>
        </header>
        <section class="modal-card-body p-0 pt-5 pl-3 pr-3 pb-6">
          <b-field label="Name *" label-position="on-border" expanded class="mt-5">
            <b-input v-model="permission.name" rounded required ref="name"
                     validation-message="Required filed"></b-input>
          </b-field>
          <b-field label="Function name *" label-position="on-border" expanded class="mt-5">
            <b-input v-model="permission.functionName" rounded required ref="functionName"
                     validation-message="Required filed"></b-input>
          </b-field>
          <b-field label="Description *" label-position="on-border" expanded class="mt-5">
            <b-input v-model="permission.description" rounded required ref="description"
                     validation-message="Required filed"></b-input>
          </b-field>

        </section>
        <footer class="modal-card-foot">
          <b-button class="is-primary is-fullwidth ml-2 mr-2" @click="onActionClicked" rounded
                    :disabled="!isFormValid()" :key="refresh">
            {{ isUpdateMode ? 'UPDATE PERMISSION' : 'CREATE PERMISSION' }}
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "add-change-permission",
  data() {
    return {
      isLoading: false,
      modalActive: false,
      refresh: false,
      isUpdateMode: false,
      permission: {
        id: null,
        name: null,
        description: null,
        functionName: null
      }
    }
  },
  methods: {
    ...mapActions({
      addPermission: 'uacModule/addPermission',
      updatePermission: 'uacModule/updatePermission'
    }),
    async onActionClicked() {
      this.isLoading = true
      let payload = {
        name: this.permission.name,
        description: this.permission.description,
        functionName: this.permission.functionName
      }
      console.log('Add permission payload: ', payload)
      if (this.isUpdateMode) {
        payload.id = this.permission.id
        let response = await this.updatePermission(payload)
        if (response instanceof Error) {
          this.showToast(response.message, 'is-danger')
        } else {
          this.clearFields()
          this.showToast('Permission successfully updated', 'is-success')
          this.show(false, null)
        }
      } else {
        let response = await this.addPermission(payload)
        if (response instanceof Error) {
          this.showToast(response.message, 'is-danger')
        } else {
          this.clearFields()
          this.showToast('Permission successfully created', 'is-success')
          this.show(false, null)
        }
      }

      this.isLoading = false
    },
    show(show, permissionId) {
      if (permissionId) {
        let updatePermission = this.permissions.find((rl) => rl._id === permissionId)
        if (updatePermission) {
          this.permission.id = permissionId
          this.permission.name = updatePermission.name
          this.permission.description = updatePermission.description
          this.permission.functionName = updatePermission.functionName

          this.isUpdateMode = true

          setTimeout(() => {
            this.refresh = true
          }, 50)

        }
      }
      this.modalActive = show
      if (!this.modalActive) {
        this.clearFields()
      }
    },
    clearFields() {
      this.permission.id = null
      this.permission.name = null
      this.permission.functionName = null
      this.permission.description = null
      this.refresh = false
      this.isUpdateMode = false
    },
    isFormValid() {
      if (this.$refs.name && this.$refs.name.value && this.$refs.description && this.$refs.description.value && this.$refs.functionName && this.$refs.functionName.value) {
        return this.$refs.name.isValid && this.$refs.functionName.isValid && this.$refs.description.isValid
      }
      return false
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'uacModule/getPermissions',
    }),
  }
}
</script>

<style scoped>

</style>