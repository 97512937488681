<template>
  <div class="mapbox-container">
    <MglMap
        ref="mapboxMapHome"
        container="map-home"
        :accessToken="$env.VUE_APP_ACCESS_TOKEN"
        :center="mapCenter"
        :zoom="mapZoom"
        :mapStyle="mapStyle"
        @click="onMapClicked"
        @load="onMapLoaded">
      <geocoder-control
          :accessToken="$env.VUE_APP_ACCESS_TOKEN"
          :mapbox="mapboxGl()"
          :marker="false"/>
      <div v-for="(item, index) in devices" :key="drawMode + index">
        <my-marker :item="item" :map-rotation="mapRotation" @onMarkerClicked="markerClicked(item)"
                   :is-selected="item === selectedDevice"
                   @onUpdateCoordinates="onUpdateCoordinates"
                   @onRotateFinished="onRotationChanged"
                   :draw-mode="drawMode">
        </my-marker>
      </div>
      <map-style-menu @onStyleChanged="changeStyle"></map-style-menu>
      <draw-controls @onDrawModeSelected="onDrawModeSelected" :mode="drawControlsMode"></draw-controls>
    </MglMap>
  </div>
</template>

<script>
import Mapbox from "mapbox-gl";
import {mapGetters} from "vuex";
import {MglMap} from "vue-mapbox";
import MapStyleMenu from "@/components/map-style-menu/map-style-menu.vue";
import geocoderControl from "@/components/geocoder-control/geocoder-control";
import MyMarker from "@/modules/projects-module/components/my-marker/my-marker.vue";
import DrawControls from "@/modules/projects-module/components/draw-controls/draw-controls.vue";
import {bbox, lineString} from "@turf/turf";

export default {
  name: 'map-input',
  components: {DrawControls, MyMarker, MapStyleMenu, MglMap, geocoderControl},
  props: {
    devices: {
      type: Array,
      default: () => {
      },
      immediate: true,
    },
    selectedDevice: {
      type: Object
    },
    selectedProject: {
      type: Object
    },
    drawControlsMode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      mapStyle: '',
      mapCenter: [144.94444, -37.80193],
      mapZoom: 10,
      drawMode: null,
      mapRotation: 0,
    }
  },
  mounted() {
    this.mapStyle = this.getMapStyle
  },
  methods: {
    onDrawModeSelected(mode) {
      this.drawMode = mode
      if (this.drawMode === 'add') {
        this.map._canvas.style.cursor = "crosshair";
      } else if (this.drawMode === 'move') {
        this.map._canvas.style.cursor = "move";
      } else if (this.drawMode === 'rotate') {
        this.map._canvas.style.cursor = "default";
      } else {
        this.map._canvas.style.cursor = "grab";
      }
      console.log('Draw mode', this.drawMode)
    },
    onMapClicked(event) {
      console.log('On map clicked', event)
      if (this.drawMode === 'add') {
        this.$emit('onMapInputClicked', event.mapboxEvent.lngLat)
      } else {
        this.$emit('onMapClicked', event.mapboxEvent.lngLat)
      }
    },
    onUpdateCoordinates(event) {
      this.$emit('onCoordinatesUpdated', event)
    },
    onRotationChanged(data) {
      this.$emit('onRotationChanged', data)
    },
    onMapLoaded(map) {
      console.log('On map loaded')
      let obj = this
      this.map = map.map;
      obj.mapRotation = this.map.getBearing()
      this.map.on('zoom', () => {
        this.zoom = this.map.getZoom();
      });

      this.map.on('rotate', () => {
        console.log('Map rotation', this.map.getBearing())
        obj.mapRotation = this.map.getBearing()
      })

      this.zoomMapToSelectedSiteOrProject(true)
    },
    changeStyle(style) {
      this.mapStyle = style
    },
    mapboxGl() {
      return Mapbox
    },
    markerClicked(event) {
      console.log('Marker clicked map input:', event)
      this.$emit('onMarkerClicked', event)
    },
    zoomMapToSelectedSiteOrProject(animate) {

      if (this.selectedProject) {
        //Zoom to selected site
        let coordinates = []
        let zoomPoint = {
          center: this.mapCenter,
          zoom: 18,
          pitch: 0,
          bearing: 0
        }
        if (this.selectedDevice) {
          zoomPoint = {
            center: this.selectedDevice.location.coordinates,
            zoom: 18,
            pitch: 0,
            bearing: 0
          }
        } else {
          this.selectedProject.sites.forEach(site => {
            coordinates.push(...site.devices.map(device => {
              return device.location.coordinates
            }))
          })
        }

        if (coordinates.length > 0) {
          let line = lineString(coordinates);
          let bb = bbox(line);
          this.map.fitBounds(bb, {padding: 200})
        } else if (animate) {
          this.map.flyTo(zoomPoint)
        }
      } else {
        const startPoint = {
          center: this.mapCenter,
          zoom: 13,
          pitch: 0,
          bearing: 0
        };
        this.map.flyTo(startPoint)
      }
    },
  },
  computed: {
    ...mapGetters({
      getMapStyle: 'getMapStyle'
    }),
    /*    devices(){
          if(this.sites && this.sites.length > 0){
            return this.sites.map(site => site.device)
          }
          return []
        }*/
  },
  watch: {
    location: function (val) {
      this.$forceUpdate();
    }
  }
}
</script>

<style scoped>
.mapbox-container {
  width: 100%;
  height: 500px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  background-color: #D9D9D9;
}
</style>
