import {Role} from "@/utils/utils";
import home from "@/modules/projects-module/views/home/home.vue";

const Module = () => import("./Module.vue");

const moduleRoute = {
    path: "/projects",
    component: Module,
    children: [
        {
            path: '/',
            name: 'projects',
            meta: {
                product: 'Realite Admin',
                title: 'Home',
                isSubCategory: false,
                roles: [Role.ADMIN, Role.TRAFFIC_ENG],
                permission: 'project:list',
                topBarTitle: '',
                menuPriority: 0,
                hasMenu: true,
                hasInternalTransition: false
            },
            component: home
        }
    ]
};

export default router => {
    router.addRoutes([moduleRoute]);
};
