<template>
  <div class="pt-6 pl-5 pr-5" style="padding-bottom: 120px">
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <view-guard :permission="'app:list'">
      <apps-list></apps-list>
    </view-guard>
    <view-guard :permission="'user:list'">
      <users-list></users-list>
    </view-guard>
    <view-guard :permission="'role:list'">
      <roles-list></roles-list>
    </view-guard>
    <view-guard :permission="'permission:list'">
      <permissions-list></permissions-list>
    </view-guard>
  </div>
</template>
<script>
import UsersList from "@/modules/uac-module/components/users-list/users-list.vue";
import RolesList from "@/modules/uac-module/components/roles-list/roles-list.vue";
import AppsList from "@/modules/uac-module/components/apps-list/apps-list.vue";
import {mapActions, mapGetters} from "vuex";
import PermissionsList from "@/modules/uac-module/components/permissions-list/permissions-list.vue";
import ViewGuard from "@/components/view-guard/view-guard.vue";

export default {
  name: "uac-screen",
  components: {ViewGuard, PermissionsList, AppsList, RolesList, UsersList},
  data() {
    return {
      isLoading: false
    }
  },

  async mounted() {
    this.isLoading = true
    if (this.apps === null || this.apps.length === 0) {
      await this.getApps()
    }
    if (this.permissions === null || this.permissions.length === 0) {
      await this.getPermissions()
    }
    if (this.roles === null || this.roles.length === 0) {
      await this.getRoles()
    }
    if (this.users === null || this.users.length === 0) {
      await this.getUsers()
    }
    this.isLoading = false
  },
  methods: {
    ...mapActions({
      getUsers: 'uacModule/getUsers',
      getApps: 'uacModule/getApps',
      getRoles: 'uacModule/getRoles',
      getPermissions: 'uacModule/getPermissions'
    }),
  },
  computed: {
    ...mapGetters({
      users: 'uacModule/getUsers',
      apps: 'uacModule/getApps',
      roles: 'uacModule/getRoles',
      permissions: 'uacModule/getPermissions'
    })

  }
}
</script>

<style scoped>

</style>