<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <LayoutDefault>
      <router-view
          @showLoading="showLoading"
          @showMessage="showMessage">
      </router-view>
    </LayoutDefault>
  </div>
</template>

<script>
import LayoutDefault from './layouts/default/default';
import store from "@/store";

export default {
  name: 'Home-Page',
  components: {
    LayoutDefault
  },
  data() {
    return {
      transitionName: 'slide-right',
      isLoading: false
    }
  },
  async created() {
    await this.checkAuth()
/*    if (this.$store.getters.getMe === null) {
      await this.$store.dispatch('getMe')
    }*/
    //await this.$store.dispatch('getMe')
    await this.$store.dispatch('getSettings')
    await this.$store.dispatch('getUsers')
  },
  methods: {
    async checkAuth() {
      await this.$auth.currentAuthenticatedUser().then((user) => {
        this.$store.commit('saveUserData', user)
      }).catch((error) => {
        console.log(error)
      });
    },
    showLoading(show) {
      this.isLoading = show
    },
    showMessage(message, success) {
      this.$buefy.toast.open({
        message: message,
        type: success ? 'is-success' : 'is-danger'
      })
    },
  },
  watch: {
    $route(to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    },
  },
};
</script>
