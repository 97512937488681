<template>
  <div>
    <b-modal v-model="modalActive" width="50%" scroll="keep">
      <div class="card">
        <header class="card-header ">
          <div class="card-header-title is-flex">
            <h6 class="is-size-6 ml-auto mr-auto pl-4 has-text-weight-semibold">{{ title }}</h6>
          </div>
          <b-button class="card-header-icon mt-2 mr-2" aria-label="more options" @click="show(false, false, false)">
            <b-icon icon="close"></b-icon>
          </b-button>
        </header>
        <div class="card-content" style="min-height: 250px">
          <div class="content" v-if="data">

            <div class="m-0 p-0 pr-2 pl-0">
              <div class="is-size-7 has-text-grey-light mt-1">Site Name</div>
              <div class="mt-0 has-text-weight-semibold">{{ data.siteName }}</div>
            </div>

            <div class="m-0 p-0 pr-2 pl-0">
              <div class="is-size-7 has-text-grey-light mt-1">Device Name</div>
              <div class="mt-0 has-text-weight-semibold">{{ data.name }}</div>
            </div>

            <div v-if="infoType && infoType === 'periods'" class="mt-3 mb-6">
              <div class="m-0 p-0 pr-2 pl-0" v-if="data.processingPeriods">
                <div class="is-size-7 has-text-grey-light mt-1">Processing periods</div>
                <div class="is-flex" v-for="(period, index) in this.data.processingPeriods" :key="index">
                  <div v-if="processingPeriodByIndex(index)" class="is-flex">
                    <b-icon icon="calendar" size="is-small" class="mt-1 mr-1"></b-icon>
                    <div>{{ processingPeriodByIndex(index) }}</div>
                    <div class="is-flex is-flex-direction-column ml-4">
                      <div v-for="timeRange in processingTimesByIndex(index)" :key="timeRange">
                        <div class="is-flex">
                          <b-icon icon="clock-outline" size="is-small" class="ml-2 mt-1 mr-1"></b-icon>
                          <div>{{ timeRange }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="is-size-6 has-text-weight-normal has-text-warning">Undefined</div>
                </div>
              </div>
            </div>
            <div v-if="infoType && infoType === 'ai'" class="mt-3 mb-6">
              <div class="m-0 p-0 pr-2 pl-0">
                <div class="is-size-7 has-text-grey-light mt-1">AI Config</div>
                <div class="mt-0 has-text-weight-semibold">{{ data.trackingConfig }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {
  dateToISOString,
  extractGMapGpsCoordinates,
  getDate,
  getDateObjectFromMelbourneUtcToLocal, getDateRange, getDateTime, getPickerTimeToTimeZone,
  gMapOptions, gMapStyles, parseTimeRanges
} from "@/utils/utils";
import moment from "moment-timezone";

export default {
  name: "info-modal",
  components: {},
  data() {
    return {
      isLoading: false,
      modalActive: false,
      data: null,
      title: null,
      infoType: null
    }
  },
  mounted() {

  },
  methods: {
    show(show, data, title, infoType) {
      this.data = data
      this.title = title
      this.infoType = infoType
      this.modalActive = show
    },
    processingPeriodByIndex(index) {
      if (this.data && this.data.processingPeriods && this.data.processingPeriods.length > 0) {
        if (this.data.processingPeriods[index].dateRange && this.data.processingPeriods[index].dateRange.length > 1) {
          return getDateRange(this.data.processingPeriods[index].dateRange)
        }
      }
      return null
    },
    processingTimesByIndex(index) {
      if (this.data && this.data.processingPeriods && this.data.processingPeriods.length > 0) {
        if (this.data.processingPeriods[index].dateRange && this.data.processingPeriods[index].dateRange.length > 1) {
          return parseTimeRanges(this.data.processingPeriods[index].timeRanges)
        }
      }
      return null
    },
  }
}
</script>

<style scoped lang="scss">
</style>