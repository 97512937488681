<template>
  <div id="map-container" class="columns is-gapless monitoring-map" style="margin-bottom: 0">
    <gmap-map
        ref="googleMap"
        :center="mapCenter"
        @click="onMapClicked"
        :zoom="9"
        :heading="heading"
        :options="options"
        map-type-id="terrain"
        style="width: 100%"
        class="mapbox-container">

      <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
                        @closeclick="onCloseInfoWindow">
      </gmap-info-window>

      <my-google-marker v-for="(item, index) in markers" :key="JSON.stringify(item)"
                        :item="item"
                        @onMarkerClicked="onMarkerClicked(item, index)"
                        :is-selected="selectedDevice === item"
                        :draw-mode="null">
      </my-google-marker>
      <div slot="visible">
        <health-legend @onHealthFilterChanged="healthFilterChanged"></health-legend>
      </div>
    </gmap-map>

    <div class="project-select">
      <b-field>
        <b-dropdown
            multiple
            expanded
            @change="onProjectSelected"
            aria-role="list">
          <template #trigger>
            <b-button
                expanded
                rounded
                icon-right="menu-down">
              Selected projects ({{ selectedProjects.length > 0 ? selectedProjects.length : 'All' }})
            </b-button>
          </template>
          <b-dropdown-item v-for="project in projects" :value="project" aria-role="listitem" :key="project._id">
            <span>{{ project.name }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </b-field>
    </div>

  </div>
</template>

<script>
import MyGoogleMarker from "@/modules/projects-module/components/my-google-marker/my-google-marker.vue";
import {mapActions, mapGetters} from "vuex";
import {bbox, lineString} from "@turf/turf";
import {
  getGmapPopupContent,
  getGmapPopupDeviceContent,
  getGMapPopupSitePlanContent,
  getGMapPopupTrafficSiteContent, gMapStyles
} from "@/utils/utils";
import MapDeviceLegend from "@/modules/projects-module/components/map-device-legend/map-device-legend.vue";
import MapMarkersLegend from "@/modules/projects-module/components/map-markers-legend/map-markers-legend.vue";
import HealthLegend from "@/modules/live-monitoring-module/components/healt-legend/health-legend.vue";

export default {
  name: "g-map-monitoring",
  components: {HealthLegend, MyGoogleMarker},
  props: {
    selectedDeviceId: {
      type: String
    }
  },
  data() {
    return {
      mapCenter: {lat: -37.80193, lng: 144.94444},
      mapData: [],
      mapZoom: 10,
      drawMode: null,
      heading: 0,
      options: {styles: gMapStyles},
      selectedDevice: null,
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        content: '',
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -16
        }
      },
      filter: {site: true, device: true, sitePlan: true, planDevice: true}
    }
  },
  async mounted() {
    console.log('Gmap input sites: ', this.sitePlans)
    await this.$gmapApiPromiseLazy();

    console.log('Map: ', this.$refs.googleMap)

    this.$refs.googleMap.$mapObject.addListener('projection_changed', () => {
      console.log('Map heading', this.$refs.googleMap.$mapObject.heading)
      this.heading = this.$refs.googleMap.$mapObject.heading
    });

    this.zoomMapToSelectedSiteOrProject(true)

  },
  methods: {
    ...mapActions({
      setSelectedProjects: 'liveMonitoringModule/setSelectedProjects',
      setLegendSelection: 'liveMonitoringModule/setLegendSelection',
    }),
    healthFilterChanged(selection) {
      this.setLegendSelection(selection)
    },
    onProjectSelected(projects) {
      console.log('On projects selected: ', projects)
      this.setSelectedProjects(projects)
    },
    onCloseInfoWindow() {
      this.infoWinOpen = false
      this.selectedDevice = null
      //this.zoomMapToSelectedSiteOrProject(true)
    },
    onMapClicked() {
      console.log('On map clicked')
      this.$emit('onMapClicked')
      this.selectedDevice = null
      if (!this.infoWinOpen) {
        this.zoomMapToSelectedSiteOrProject(true)
      }
      this.infoWinOpen = false
    },
    onMarkerClicked(device, index) {
      console.log('On marker clicked', device)
      if (this.selectedDevice === device) {
        this.selectedDevice = null
      } else {
        this.selectedDevice = device
      }

      this.zoomMapToSelectedSiteOrProject(true)
      this.toggleInfoWindow(device, index)
    },
    toggleInfoWindow(device, idx) {
      this.infoWindowPos = {lat: device.location.coordinates[1], lng: device.location.coordinates[0]};
      if (device.objectType === 'device') {
        this.infoOptions.content = getGmapPopupDeviceContent(device, device.statusColor, device.projectName)
      } else if (device.objectType === 'site') {
        this.infoOptions.content = getGMapPopupTrafficSiteContent(device)
      }


      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }

      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    deviceStatusColor(name) {
      if (this.$store.getters.getSettings) {
        let status = this.$store.getters.getSettings.deviceStatuses.find(status => status.name === name)
        if (status)
          return status.color
      }

      return '#ffffff'
    },
    zoomMapToSelectedSiteOrProject(animate) {
      console.log('GMap, zoom to projects or sites')
      let coordinates = []
      if (this.selectedDevice !== null) {
        let center = {
          lat: this.selectedDevice.location.coordinates[1],
          lng: this.selectedDevice.location.coordinates[0]
        }
        this.$refs.googleMap.$mapObject.panTo(center);
        return
      } else if (this.markers && this.markers.length > 0) {
        this.markers.forEach(marker => {
          if (marker.location) {
            coordinates.push(marker.location.coordinates)
          }
        })
      }

      if (coordinates.length > 0) {
        console.log('COORDINATES', coordinates)

        if (coordinates.length > 1) {
          let line = lineString(coordinates);
          let bb = bbox(line);
          console.log('Bounding box:', bb)
          let boundsGMap = new window.google.maps.LatLngBounds()
          boundsGMap.extend({lat: bb[1], lng: bb[0]})
          boundsGMap.extend({lat: bb[3], lng: bb[2]})
          console.log('LatLngBounds: ', boundsGMap)
          this.$refs.googleMap.$mapObject.panTo(boundsGMap.getCenter());
          this.$refs.googleMap.$mapObject.fitBounds(boundsGMap, 60)
        } else {
          this.$refs.googleMap.$mapObject.panTo({lat: coordinates[0][1], lng: coordinates[0][0]});
        }
      } else if (animate) {
        this.$refs.googleMap.$mapObject.panTo(this.mapCenter);
      }
    }
  },
  computed: {
    ...mapGetters({
      liveMonitoring: 'liveMonitoringModule/liveMonitoring',
      selectedProjects: 'liveMonitoringModule/selectedProjects',
      legendSelection: 'liveMonitoringModule/legendSelection',
    }),
    markers() {
      let devices = []
      if (this.liveMonitoring && this.liveMonitoring.length > 0) {
        this.liveMonitoring.forEach(data => {
          if (this.selectedProjects.length === 0 || (data.project && this.selectedProjects.map(project => project._id).includes(data.project._id))) {
            console.log('Legend selection: ', this.legendSelection)
            if (data.device && data.device.location) {
              data.device.objectType = 'device'
              data.device.statusColor = this.deviceStatusColor(data.device.currentStatus)
              data.device.projectName = data.project && data.project.name ? data.project.name : 'No project'
              if (data.device && data.device.health && data.device.health.connectionStatus && (data.device.health.connectionStatus === 'connected' || data.device.health.connectionStatus === 'rebooted')) {
                if (this.legendSelection[data.device.health.status]) {
                  data.device.color = data.device.health.status === 'yellow' ? '#ffea00' : data.device.health.status
                  devices.push(data.device)
                }
              } else if (this.legendSelection['noHealth']) {
                data.device.color = 'black'
                devices.push(data.device)
              }
            }

          }
        })
      }
      console.log('Markers: ', devices)
      return devices
    },
    projects() {
      let projects = []
      if (this.liveMonitoring && this.liveMonitoring.length > 0) {
        let prjs = this.liveMonitoring.map(data => data.project)
        console.log('Projects: ', prjs)
        prjs.forEach(prj => {
          if(prj){
            let index = projects.findIndex(pr => pr._id === prj._id)
            console.log('Index: ', index)
            if (index === -1) {
              projects.push(prj)
            }
          }
        })
      }

      return projects
    },
  },
  watch: {
    markers: {
      handler: function (newVal, oldVal) {
        this.zoomMapToSelectedSiteOrProject(true)
      },
      deep: true
    },
    selectedDeviceId: {
      handler: function (newVal, oldVal) {
        console.log('Selected device id changed', this.selectedDeviceId)
        console.log('Selected device id changed', newVal)
        console.log('Markers: ', this.markers)
        if (this.selectedDeviceId) {
          let index = this.markers.findIndex(marker => marker._id === this.selectedDeviceId)
          if (index !== -1) {
            this.onMarkerClicked(this.markers[index], index)
          } else {
            this.onMapClicked()
          }
        } else {
          this.onMapClicked()
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
.monitoring-map {
  height: 500px;
  transition: all .6s linear;
}

.project-select {
  position: absolute;
  top: 150px;
  left: 10px;
  min-width: 400px;
}

</style>