<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>

    <div class="m-5 mt-6">
      <div class="is-flex is-align-items-center mb-3">
        <div class="is-size-5 has-text-grey">TODO Templates</div>
        <div class="ml-auto">
          <view-guard :permission="'template:create'">
            <b-button @click="onAddTemplate" type="is-primary" icon-left="plus" rounded>Add Template
            </b-button>
          </view-guard>
        </div>
      </div>
      <div v-if="todoTemplates && todoTemplates.length > 0">
        <to-do-template class="mb-1" v-for="todoTemplate in todoTemplates" :key="todoTemplate._id"
                        :todo-template="todoTemplate" @onEditTodoTemplate="onEditTodoTemplate"
                        @onDeleteTodoTemplate="onDeleteTodoTemplate"></to-do-template>
      </div>
      <div v-else class="is-flex is-align-items-center dashed-border">
        <h4 class="is-size-4 ml-auto mr-auto has-text-grey-light has-text-weight-light">Click on "+" button to add first
          ToDo template</h4>
      </div>
      <add-edit-template ref="todoTemplate"></add-edit-template>
    </div>
  </div>
</template>

<script>
import ToDoTemplate from "@/components/to-do-templates/to-do-template.vue";
import AddEditTemplate from "@/components/to-do-templates/add-edit-template.vue";
import {TemplateType} from "@/utils/utils";
import ViewGuard from "@/components/view-guard/view-guard.vue";

export default {
  name: "to-do-templates",
  components: {ViewGuard, AddEditTemplate, ToDoTemplate},
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    onAddTemplate() {
      this.$refs.todoTemplate.show(true, null)
    },
    onEditTodoTemplate(id) {
      console.log('Template id: ', id)
      this.$refs.todoTemplate.show(true, id)
    },
    onDeleteTodoTemplate(id) {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: 'Are you sure you want to delete this template?',
        onConfirm: () => this.deleteItem(id)
      })
    },
    async deleteItem(id) {
      console.log('Call delete item: ', id)
      this.isLoading = true
      let response = await this.$store.dispatch('deleteTemplate', id)
      if (response.errorMessage) {
        this.showToast(response.errorMessage, 'is-danger')
      } else {
        this.showToast('ToDo template successfully deleted', 'is-success')
      }
      this.isLoading = false
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
  computed: {
    todoTemplates() {
      if (this.$store.getters.getSettings && this.$store.getters.getSettings.todoTemplates) {
        return this.$store.getters.getSettings.todoTemplates.filter(template => template.templateType === TemplateType.TODO)
      }
      return []
    }
  }
}
</script>

<style scoped>

</style>