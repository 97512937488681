<template>
<div>
  <apex-chart type="bar" height="200" :options="chartOptions" :series="series" :key="title"></apex-chart>
</div>
</template>

<script>
import {formatTimeInTimeZone, formatUtcTime, getDateTime, getVideoTimeFromLocal} from "@/utils/utils";

export default {
  name: "video-data-chart",
  props:{
    data: {
      type:Array
    },
    categories:{
      type: Array
    },
    title: {
      type: String
    }
  },
  data(){
    return {
      series: [{
        name: 'Video Material',
        data: this.data
      }],
      chartOptions: {
        chart: {
          height: 200,
          type: 'bar',
          fontFamily: 'Barlow',
/*          zoom: {
            enabled:false
          }*/
        },
        tooltip: {
          x: {
            formatter: function (val) {
              return formatUtcTime(val);
            }
          },
          y:{
            formatter: function (val) {
              return `${val} min.`;
            }
          }
        },
        plotOptions: {
          bar: {
            columnWidth: '40%',
            horizontal: false
          },
        },
        dataLabels: {
          enabled: false,
        },

        xaxis: {
          type: 'datetime',
          categories: this.categories
        },
        yaxis: {
          title: {
            text: 'Minutes'
          }
        },
        title: {
          text: ''
        }
      },
    }
  },
  mounted() {
    this.series.data = this.data

    let optimalColumnWidthPercent = 10 + (60 / (1 + 30*Math.exp(-this.series.data.length /3)));
    let chartOptions = JSON.parse(JSON.stringify(this.chartOptions))

    chartOptions.xaxis.categories = this.categories
    chartOptions.title = {text: this.title}
    chartOptions.plotOptions = {
      bar: {
        columnWidth: optimalColumnWidthPercent + '%',
            horizontal: false
      },
    }

    this.chartOptions = chartOptions
  }
}
</script>

<style scoped>

</style>