export default {
    token: (state) => {
        return 'Bearer ' + state.user.signInUserSession.idToken
    },
    getMapType: (state) => {
        return state.mapType
    },
    getUserFullName: (state) => {
        console.log('Session:', state.user)
        return `${state.user.signInUserSession.idToken.payload['name']}`
    },
    getUserEmail: (state) => {
        console.log('Session:', state.user)
        return `${state.user.signInUserSession.idToken.payload['email']}`
    },
    getUserPhone: (state) => {
        console.log('Session:', state.user)
        return `${state.user.signInUserSession.idToken.payload['phone_number']}`
    },
    getUserRole: (state) => {
        return state.user.signInUserSession.idToken.payload['custom:role']
    },
    getUserId: (state) => {
        return state.user.signInUserSession.idToken.payload['sub']
    },
    isAuth: (state) => {
        return !!state.user
    },
    getMe: (state) => {
        return state.me
    },
    getMapStyle: (state) => {
        return state.mapStyle
    },
    getSettings: (state) => {
        return state.settings
    },
    getAllowedUsers: (state) => {
        if (state.users.length > 0) {
            //let roles = [Role.TRAFFIC_ENG]
            //let users = state.users.filter(user => roles.includes(user.role))
            return state.users
        }

        return []
    },
    getUserById: (state) => (id) => {
        if (state.users.length > 0) {
            let index = state.users.findIndex(user => user.id === id)
            if (index !== -1) {
                return state.users[index]
            }
        }

        return {fullName: 'Unknown', id: 'Unknown', role: 'Undefined'}
    },
    getMqttEventToSend:(state) => {
       return state.mqttEventToSend
    },
}
