<template>
  <div>
    <b-collapse
        class="card shadow"
        animation="slide"
        v-model="isOpen"
        style="border-radius: 16px; background-color: #f9f9f9;"
        aria-id="contentIdForA11y3">
      <template #trigger>
        <div
            class="card-header shadow"
            role="button"
            aria-controls="contentIdForA11y3"
            style="border-radius: 16px; background-color: white; height: 72px;">
          <div class="card-header-title">
            <div class="columns ml-1" style="width: 100%">
              <div class="column">
                <div class="is-size-7 has-text-grey-light has-text-weight-normal">Template name</div>
                <div class="has-text-black">{{ settingsTemplate.name }}</div>
              </div>
              <div class="column">
                <div class="is-size-7 has-text-grey-light has-text-weight-normal">Device Type</div>
                <div class="has-text-black">{{ settingsTemplate.deviceType }}</div>
              </div>
              <div class="column mr-0 pr-0">
                <div class="is-flex is-flex-direction-row mt-3 is-align-items-end">
                  <view-guard :permission="'template:update'" class="ml-auto mr-1">
                    <b-tooltip label="Edit Template" :delay="1000" type="is-dark">
                      <a v-on:click.stop @click="onEditTemplate">
                        <b-icon icon="pencil-circle-outline">
                        </b-icon>
                      </a>
                    </b-tooltip>
                  </view-guard>
                  <view-guard :permission="'template:delete'" class="mr-1">
                    <b-tooltip label="Delete Template" :delay="1000" type="is-dark">
                      <a v-on:click.stop @click="deleteItem">
                        <b-icon icon="delete-circle-outline">
                        </b-icon>
                      </a>
                    </b-tooltip>
                  </view-guard>
                </div>
              </div>
            </div>
          </div>
          <a class="card-header-icon">
            <b-icon
                :icon="isOpen ? 'chevron-down' : 'chevron-up'">
            </b-icon>
          </a>
        </div>
      </template>

      <div class="card-content m-0 p-0">
        <div class="content m-0 p-0">
          <div class="pb-3" v-for="(settings) in settingsTemplate.template" :key="settings._id">
            <div class="columns m-0 p-3 pl-5">
              <div class="column m-0 p-0 is-3 ">
                <div class="is-size-7 has-text-grey-light">Name</div>
                <div class="is-size-6 has-text-weight-semibold has-text-black">{{ settings.name }}</div>
              </div>
              <div class="column m-0 p-0 is-8">
                <div class="is-size-7 has-text-grey-light">Description</div>
                <div class="is-size-6 has-text-weight-semibold has-text-black">{{ settings.description }}</div>
              </div>
            </div>
            <hr class="m-0 p-0">
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import ViewGuard from "@/components/view-guard/view-guard.vue";

export default {
  name: "device-settings-template",
  components: {ViewGuard},
  props: {
    settingsTemplate: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    onEditTemplate() {
      this.$emit('onEditSettingsTemplate', this.settingsTemplate._id)
    },
    deleteItem() {
      this.$emit('onDeleteSettingsTemplate', this.settingsTemplate._id)
    }
  }
}
</script>

<style scoped>

</style>