import { render, staticRenderFns } from "./device-settings-template.vue?vue&type=template&id=3a42f6fe&scoped=true"
import script from "./device-settings-template.vue?vue&type=script&lang=js"
export * from "./device-settings-template.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a42f6fe",
  null
  
)

export default component.exports