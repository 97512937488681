export default () => ({
    user: null,
    mapType: 'gmap', //mapbox or gmap
    mapStyle: 'mapbox://styles/mapbox/streets-v11?optimize=true',
    settings: null,
    users: [],
    todoTemplates: [],
    me: null,
    mqttStatus: 'unknown',
    mqttEventToSend: null
});
