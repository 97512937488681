<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <div class="columns ml-3 mr-3 mt-2">
      <div class="column">
        <div class="is-flex">
          <div class="box p-0" style="width: 100%">
            <div class="is-flex">
              <div class="is-size-5 has-text-grey-light p-4">Device Statuses</div>
              <view-guard :permission="'status-template:create'" class="ml-auto mr-3 mt-auto mb-auto">
                <b-tooltip label="Add Status" :delay="1000" type="is-dark">
                  <a v-on:click.stop @click="onAddDeviceStatus">
                    <b-icon icon="plus-circle-outline">
                    </b-icon>
                  </a>
                </b-tooltip>
              </view-guard>
            </div>
            <hr class="p-0 m-0">
            <div class="p-5 is-flex is-flex-direction-row" v-if="!editStatusMode">
              <div v-for="devStatus in deviceStatuses" :key="devStatus._id">
                <b-tag @click="onDeviceStatusClicked(devStatus)" class="mr-1" type="is-primary" style="cursor: pointer"
                       :style="{'background': devStatus.color}" size="is-medium">{{ devStatus.name }}
                </b-tag>
              </div>
            </div>
            <div v-if="addStatusMode || editStatusMode" class="mt-1 is-flex">
              <div class="columns m-1 p-0" style="background: #f4f4f4; border-radius: 8px; width: 100%">
                <div class="column is-4">
                  <b-field>
                    <b-select placeholder="Status name *" v-model="newStatus.name" rounded required>
                      <option v-for="option in deviceStatusList" :value="option" :key="option">{{ option }}</option>
                    </b-select>
                  </b-field>
                </div>
                <div class="column is-4">
                  <b-field>
                    <b-colorpicker value="#000000" v-model="newStatus.color"/>
                  </b-field>
                </div>
                <div class="column is-4 is-flex is-align-items-center">
                  <b-button @click="onCancelAddStatus" size="is-small" class="ml-auto mr-1" type="is-danger" outlined>
                    CANCEL
                  </b-button>
                  <b-button @click="onSaveDeviceStatus" size="is-small" type="is-primary">SAVE</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="is-flex">
          <div class="box p-0" style="width: 100%">
            <div class="is-flex">
              <div class="is-size-5 has-text-grey-light p-4">Site Plan Statuses</div>
              <view-guard :permission="'status-template:create'" class="ml-auto mr-3 mt-auto mb-auto">
                <b-tooltip label="Add Status" :delay="1000" type="is-dark">
                  <a v-on:click.stop @click="onAddPlanStatus">
                    <b-icon icon="plus-circle-outline">
                    </b-icon>
                  </a>
                </b-tooltip>
              </view-guard>
            </div>
            <hr class="p-0 m-0">
            <div class="p-5 is-flex is-flex-direction-row" v-if="!editPlanStatusMode">
              <div v-for="planStatus in planStatuses" :key="planStatus._id">
                <b-tag @click="onPlanStatusClicked(planStatus)" class="mr-1" type="is-primary" style="cursor: pointer"
                       :style="{'background': planStatus.color}" size="is-medium">{{ planStatus.name }}
                </b-tag>
              </div>
            </div>
            <div v-if="addPlanStatusMode || editPlanStatusMode" class="mt-1 is-flex">
              <div class="columns m-1 p-0" style="background: #f4f4f4; border-radius: 8px; width: 100%">
                <div class="column is-4">
                  <b-field>
                    <b-select placeholder="Status name *" v-model="newStatus.name" rounded required>
                      <option v-for="option in planStatusList" :value="option" :key="option">{{ option }}</option>
                    </b-select>
                  </b-field>
                </div>
                <div class="column is-4">
                  <b-field>
                    <b-colorpicker value="#000000" v-model="newStatus.color"/>
                  </b-field>
                </div>
                <div class="column is-4 is-flex is-align-items-center">
                  <b-button @click="onCancelAddPlanStatus" size="is-small" class="ml-auto mr-1" type="is-danger"
                            outlined>CANCEL
                  </b-button>
                  <b-button @click="onSavePlanStatus" size="is-small" type="is-primary">SAVE</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="is-flex">
          <div class="box p-0" style="width: 100%">
            <div class="is-flex">
              <div class="is-size-5 has-text-grey-light p-4">Task Statuses</div>
              <view-guard :permission="'status-template:create'" class="ml-auto mr-3 mt-auto mb-auto">
                <b-tooltip label="Add Status" :delay="1000" type="is-dark">
                  <a v-on:click.stop @click="onAddTaskStatus">
                    <b-icon icon="plus-circle-outline">
                    </b-icon>
                  </a>
                </b-tooltip>
              </view-guard>
            </div>
            <hr class="p-0 m-0">
            <div class="p-5 is-flex is-flex-direction-row" v-if="!editTaskStatusMode">
              <div v-for="taskStatus in taskStatuses" :key="taskStatus._id">
                <b-tag @click="onTaskStatusClicked(taskStatus)" class="mr-1" type="is-primary" style="cursor: pointer"
                       :style="{'background': taskStatus.color}" size="is-medium">{{ taskStatus.name }}
                </b-tag>
              </div>
            </div>
            <div v-if="addTaskStatusMode || editTaskStatusMode" class="mt-1 is-flex">
              <div class="columns m-1 p-0" style="background: #f4f4f4; border-radius: 8px; width: 100%">
                <div class="column is-4">
                  <b-field>
                    <b-select placeholder="Status name *" v-model="newStatus.name" rounded required>
                      <option v-for="option in taskStatusList" :value="option" :key="option">{{ option }}</option>
                    </b-select>
                  </b-field>
                </div>
                <div class="column is-4">
                  <b-field>
                    <b-colorpicker value="#000000" v-model="newStatus.color"/>
                  </b-field>
                </div>
                <div class="column is-4 is-flex is-align-items-center">
                  <b-button @click="onCancelAddTaskStatus" size="is-small" class="ml-auto mr-1" type="is-danger"
                            outlined>CANCEL
                  </b-button>
                  <b-button @click="onSaveTaskStatus" size="is-small" type="is-primary">SAVE</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewGuard from "@/components/view-guard/view-guard.vue";
import {isAllowed} from "@/utils/utils";

export default {
  name: "system-statuses",
  components: {ViewGuard},
  data() {
    return {
      isLoading: false,
      addStatusMode: false,
      editStatusMode: false,
      addPlanStatusMode: false,
      editPlanStatusMode: false,
      addTaskStatusMode: false,
      editTaskStatusMode: false,
      newStatus: {
        name: null,
        color: null
      }
    }
  },
  methods: {
    onAddDeviceStatus() {
      this.newStatus = {
        name: null,
        color: null
      }
      this.editStatusMode = false
      this.addStatusMode = true
    },
    onAddPlanStatus() {
      this.newStatus = {
        name: null,
        color: null
      }
      this.editPlanStatusMode = false
      this.addPlanStatusMode = true
    },
    onAddTaskStatus() {
      this.newStatus = {
        name: null,
        color: null
      }
      this.editTaskStatusMode = false
      this.addTaskStatusMode = true
    },
    async onSaveDeviceStatus() {
      console.log('On save device status:', this.newStatus)
      if (this.newStatus.name && this.newStatus.color) {
        let color = this.newStatus.color.toString('hex')
        let payload = {
          type: 'DEVICE',
          name: this.newStatus.name,
          color: color
        }
        this.isLoading = true
        if (this.addStatusMode) {
          let response = await this.$store.dispatch('addStatus', payload)
          if (response.errorMessage) {
            this.showToast(response.errorMessage, 'is-danger')
          } else {
            this.newStatus = {
              name: null,
              color: null
            }
            this.addStatusMode = false
            this.showToast('New device status successfully created', 'is-success')
          }
        } else if (this.editStatusMode) {
          payload.id = this.newStatus.id
          console.log('On edit status', payload)
          let response = await this.$store.dispatch('editStatus', payload)
          if (response.errorMessage) {
            this.showToast(response.errorMessage, 'is-danger')
          } else {
            this.newStatus = {
              name: null,
              color: null,
              id: null
            }
            this.editStatusMode = false
            this.showToast('Device status successfully created', 'is-success')
          }
        }
      } else {
        this.showToast('Please select status name and color', 'is-danger')
      }
      this.isLoading = false
    },
    async onSavePlanStatus() {
      if (this.newStatus.name && this.newStatus.color) {
        let color = this.newStatus.color.toString('hex')
        let payload = {
          type: 'PLAN',
          name: this.newStatus.name,
          color: color
        }
        this.isLoading = true
        if (this.addPlanStatusMode) {
          let response = await this.$store.dispatch('addStatus', payload)
          if (response.errorMessage) {
            this.showToast(response.errorMessage, 'is-danger')
          } else {
            this.newStatus = {
              name: null,
              color: null
            }
            this.addPlanStatusMode = false
            this.showToast('New Plan status successfully created', 'is-success')
          }
        } else if (this.editPlanStatusMode) {
          payload.id = this.newStatus.id
          console.log('On edit status', payload)
          let response = await this.$store.dispatch('editStatus', payload)
          if (response.errorMessage) {
            this.showToast(response.errorMessage, 'is-danger')
          } else {
            this.newStatus = {
              name: null,
              color: null,
              id: null
            }
            this.editPlanStatusMode = false
            this.showToast('Plan status successfully created', 'is-success')
          }
        }
      } else {
        this.showToast('Please select status name and color', 'is-danger')
      }
      this.isLoading = false
    },
    async onSaveTaskStatus() {
      if (this.newStatus.name && this.newStatus.color) {
        let color = this.newStatus.color.toString('hex')
        let payload = {
          type: 'TASK',
          name: this.newStatus.name,
          color: color
        }
        this.isLoading = true
        if (this.addTaskStatusMode) {
          let response = await this.$store.dispatch('addStatus', payload)
          if (response.errorMessage) {
            this.showToast(response.errorMessage, 'is-danger')
          } else {
            this.newStatus = {
              name: null,
              color: null
            }
            this.addTaskStatusMode = false
            this.showToast('New Task status successfully created', 'is-success')
          }
        } else if (this.editTaskStatusMode) {
          payload.id = this.newStatus.id
          console.log('On edit status', payload)
          let response = await this.$store.dispatch('editStatus', payload)
          if (response.errorMessage) {
            this.showToast(response.errorMessage, 'is-danger')
          } else {
            this.newStatus = {
              name: null,
              color: null,
              id: null
            }
            this.editTaskStatusMode = false
            this.showToast('Task status successfully created', 'is-success')
          }
        }
      } else {
        this.showToast('Please select status name and color', 'is-danger')
      }
      this.isLoading = false
    },
    onCancelAddStatus() {
      this.newStatus = {
        name: null,
        color: null
      }
      this.editStatusMode = false
      this.addStatusMode = false
    },
    onCancelAddPlanStatus() {
      this.newStatus = {
        name: null,
        color: null
      }
      this.addPlanStatusMode = false
      this.editPlanStatusMode = false
    },
    onCancelAddTaskStatus() {
      this.newStatus = {
        name: null,
        color: null
      }
      this.addTaskStatusMode = false
      this.editTaskStatusMode = false
    },
    checkPermission() {
      return isAllowed(this.$store.getters.getMe, 'status-template:update')
    },
    onDeviceStatusClicked(devStatus) {
      if (this.checkPermission()) {
        this.newStatus = {
          name: devStatus.name,
          color: devStatus.color,
          id: devStatus._id
        }
        this.editStatusMode = true
        this.addStatusMode = false

        console.log('On Edit Device status:', this.newStatus)
      }

    },
    onPlanStatusClicked(planStatus) {
      if (this.checkPermission()) {
        this.newStatus = {
          name: planStatus.name,
          color: planStatus.color,
          id: planStatus._id
        }
        this.editPlanStatusMode = true
        this.addPlanStatusMode = false

        console.log('On Edit plan status:', this.newStatus)
      }
    },
    onTaskStatusClicked(taskStatus) {
      if (this.checkPermission()) {
        this.newStatus = {
          name: taskStatus.name,
          color: taskStatus.color,
          id: taskStatus._id
        }
        this.editTaskStatusMode = true
        this.addTaskStatusMode = false

        console.log('On Edit task status:', this.newStatus)
      }
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
  computed: {
    settings() {
      return this.$store.getters.getSettings
    },
    deviceStatuses() {
      if (this.$store.getters.getSettings) {
        return this.$store.getters.getSettings.deviceStatuses
      }

      return []
    },
    planStatuses() {
      if (this.$store.getters.getSettings) {
        return this.$store.getters.getSettings.planStatuses
      }
      return []
    },
    taskStatuses() {
      if (this.$store.getters.getSettings) {
        return this.$store.getters.getSettings.taskStatuses
      }
      return []
    },
    deviceStatusList() {
      if (this.$store.getters.getSettings) {
        return this.$store.getters.getSettings.statusesList.DEVICE
      }
      return []
    },
    planStatusList() {
      if (this.$store.getters.getSettings) {
        return this.$store.getters.getSettings.statusesList.PLAN
      }
      return []
    },
    taskStatusList() {
      if (this.$store.getters.getSettings) {
        return this.$store.getters.getSettings.statusesList.TASK
      }
      return []
    }

  }
}
</script>

<style scoped>

</style>