import { render, staticRenderFns } from "./g-map.vue?vue&type=template&id=649cd22a&scoped=true"
import script from "./g-map.vue?vue&type=script&lang=js"
export * from "./g-map.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "649cd22a",
  null
  
)

export default component.exports