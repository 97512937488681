<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <b-modal v-model="modalActive" width="40%" scroll="clip" style="z-index: 101" @close="clearFields()">
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head" style="padding: 12px">
          <h6 class="is-size-5 ml-auto mr-auto pl-4 has-text-weight-semibold">Add Qa Validation</h6>
          <button
              type="button"
              class="delete"
              @click="show(false)"/>
        </header>
        <section class="modal-card-body p-0" style="overflow: unset">
          <div class="modal-tab-content" style="padding-bottom: 32px">
            <div class="ml-5 mr-5 mt-2">
              <div class="column is-flex mb-1 pb-0">
                <div>Device name:</div>
                <div class="ml-auto has-text-weight-semibold">{{ selectedQaDevice.name }}</div>
              </div>
              <div class="column is-flex mb-1 pb-0">
                <div>Site name:</div>
                <div class="ml-auto has-text-weight-semibold">{{ selectedQaDevice.siteName }}</div>
              </div>
              <div class="column is-flex mb-1 pb-0">
                <div>Vehicles Data Version:</div>
                <b-taglist attached class="m-0 p-0 ml-auto">
                  <b-tag type="is-dark">Version</b-tag>
                  <b-tag type="is-info">
                    {{ selectedQaDevice.chartsData && selectedQaDevice.chartsData.querySet ? selectedQaDevice.chartsData.querySet.veh.version : 'unknown' }}
                  </b-tag>
                </b-taglist>
              </div>
              <div class="column is-flex mb-1 pb-0">
                <div>Pedestrians Data Version:</div>
                <b-taglist attached class="m-0 p-0 ml-auto">
                  <b-tag type="is-dark">Version</b-tag>
                  <b-tag type="is-info">
                    {{ selectedQaDevice.chartsData && selectedQaDevice.chartsData.querySet ? selectedQaDevice.chartsData.querySet.ped.version : 'unknown' }}
                  </b-tag>
                </b-taglist>
              </div>
              <div class="column is-flex mb-1 pb-0">
                <div>QA Status:</div>
                <b-field class="ml-auto">
                  <b-select placeholder="Select QA Status" size="is-small" @input="onOptionSelected"
                            v-model="selectedOption">
                    <option
                        v-for="option in options"
                        :value="option"
                        :key="option.name">
                      {{ option.name }}
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>
            <div class="is-flex pb-0 ml-5 mr-5 mt-3 is-flex-direction-column">
              <div class="ml-3">Comment:</div>
              <b-field class="ml-3 mr-3">
                <b-input maxlength="200" type="textarea" v-model="comment"></b-input>
              </b-field>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot" style="padding: 0px">
          <b-button class="is-primary is-fullwidth m-2" @click="onAddValidation" rounded
                    :disabled="selectedOption === null">Add Validation
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "add-qa-validation",
  data() {
    return {
      isLoading: false,
      modalActive: false,
      options: [{name: 'Pass', value: 'PASS'}, {name: 'Pass With Changes', value: 'PASS_WITH_CHANGES'}, {
        name: 'Fail',
        value: 'FAIL'
      }],
      selectedOption: null,
      comment: null
    }
  },
  methods: {
    ...mapActions({
      addQaValidation: 'qaModule/addQaValidation'
    }),
    clearFields() {
      this.selectedOption = null
      this.comment = null
    },
    async onAddValidation() {
      this.isLoading = true
      let payload = {
        dataDeviceId: this.selectedQaDevice._id,
        qaStatus: this.selectedOption.value,
        //dataVersion: this.selectedQaDevice.chartsData.dataVersion,
        pedestrianDataVersion: this.selectedQaDevice.chartsData && this.selectedQaDevice.chartsData.querySet ? this.selectedQaDevice.chartsData.querySet.ped.version : 0,
        vehicleDataVersion: this.selectedQaDevice.chartsData && this.selectedQaDevice.chartsData.querySet ? this.selectedQaDevice.chartsData.querySet.veh.version : 0,
        chartId: this.selectedQaDevice.chartsData && this.selectedQaDevice.chartsData.id ? this.selectedQaDevice.chartsData.id : null,
        comment: this.comment
      }

      let response = await this.addQaValidation(payload)
      if (response.errorMessage) {
        this.showToast(response.errorMessage, 'is-danger', 3000)
      } else {

        this.showToast('New QA Validation successfully created.', 'is-success', 3000)
        setTimeout(()=>{
          if(response.approveDataSetResponse){
            if(response.approveDataSetResponse.message){
              this.showToast('Approve dataset: ' + response.approveDataSetResponse.message, 'is-success', 5000)
            }else if(response.approveDataSetResponse.errorMessage){
              this.showToast('Approve dataset: ' + response.approveDataSetResponse.errorMessage, 'is-danger', 5000)
            }
          }
        }, 2000)
      }
      this.isLoading = false
      this.show(false)
    },
    showToast(message, type, duration) {
      this.$buefy.toast.open({
        message: message,
        duration: duration,
        type: type
      })
    },
    onOptionSelected(option) {

    },
    show(show) {
      this.modalActive = show
      if (!this.modalActive) {
        this.clearFields()
      }
    },
  },
  computed: {
    ...mapGetters({
      selectedQaDevice: 'qaModule/getSelectedQaDevice'
    }),
  }
}
</script>

<style scoped>

</style>