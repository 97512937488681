<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <b-modal v-model="modalActive" width="70%" scroll="keep" style="z-index: 39" @close="clearFields()">
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head">
          <h6 class="is-size-5 ml-auto mr-auto pl-4 has-text-weight-semibold">
            {{
              isUpdateMode ? 'Update User Access Control' : isBulkMode ? 'Update users Access Control' : 'Create User Access Control'
            }}</h6>
          <button
              type="button"
              class="delete"
              @click="show(false, null)"/>
        </header>
        <section class="modal-card-body p-0 pt-5 pl-5 pr-5 pb-6">
          <b-field label="Roles" label-position="on-border" expanded class="mt-5">
            <b-dropdown
                v-model="roles"
                multiple
                expanded
                aria-role="list">
              <template #trigger>
                <b-button expanded rounded
                          icon-right="menu-down">
                  Selected ({{ roles.length }})
                </b-button>
              </template>
              <b-dropdown-item v-for="role in allRoles" :value="role" :key="role._id" aria-role="listitem">
                <span>{{ role.name }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-field>
          <b-field class="ml-2 mt-4">
            <b-checkbox v-model="isInternal" @input="onInternalChanged">Internal user</b-checkbox>
          </b-field>

          <div class="mt-5 ml-2" style="margin-bottom: 300px">
            <div class="is-size-5">Subscriptions</div>
            <hr class="m-0 p-0 mt-2 mb-2">
            <b-field v-if="isInternal">
              <b-radio v-model="isAllResources"
                       native-value="all">
                Allow All Resources
              </b-radio>
            </b-field>
            <div class="is-flex">
              <b-field>
                <b-radio v-model="isAllResources"
                         native-value="sub">
                  Project Subscriptions
                </b-radio>
              </b-field>
              <b-button v-if="isAllResources === 'sub'" class="ml-auto" size="is-small" icon-left="plus"
                        @click="addNewResource"></b-button>
            </div>
            <div v-if="isAllResources === 'sub'">
              <div v-for="resource in userResources" :key="resource.localId" class="columns mt-0 ml-0 pl-0 mb-0 pr-2">
                <div class="column ml-0 pl-0">
                  <b-field expanded style="min-width: 300px" label="Select project"
                           label-position="on-border">
                    <b-select expanded style="min-width: 300px" placeholder="Select project" rounded
                              v-model="resource.project">
                      <option v-for="project in projects" :value="project" :key="project.projectId">{{
                          project.name
                        }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <div class="column">
                  <b-field grouped label="Subscription Start date *" label-position="on-border">
                    <b-datepicker :mobile-native="false" expanded v-model="resource.startDate"
                                  size="is-small" position="is-bottom-right">
                      <template v-slot:trigger>
                        <b-input expanded readonly rounded placeholder="Installation date *"
                                 :value="installationDateString(resource.startDate)" type="text"
                                 required/>
                      </template>
                    </b-datepicker>
                  </b-field>
                </div>
                <div class="column">
                  <b-field grouped label="Subscription End date *" label-position="on-border">
                    <b-datepicker :mobile-native="false" expanded v-model="resource.endDate"
                                  size="is-small" position="is-bottom-right">
                      <template v-slot:trigger>
                        <b-input expanded readonly rounded placeholder="Installation date *"
                                 :value="installationDateString(resource.endDate)" type="text"
                                 required/>
                      </template>
                    </b-datepicker>
                  </b-field>
                </div>
                <div class="column" style="max-width: 48px">
                  <b-button class="mt-1" size="is-small" icon-left="delete"
                            @click="onDeleteResource(resource)"></b-button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button class="is-primary is-fullwidth ml-2 mr-2" @click="onActionClicked" rounded
                    :key="refresh">
            {{ isUpdateMode ? 'UPDATE USER UAC' : isBulkMode ? 'UPDATE USERS UAC' : 'CREATE USER UAC' }}
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {dateToISOString, dateToISOStringEndOfDay, getDate, getDateObjectFromMelbourneUtcToLocal} from "@/utils/utils";

export default {
  name: "add-change-user-uac",
  data() {
    return {
      isLoading: false,
      modalActive: false,
      refresh: false,
      isUpdateMode: false,
      isBulkMode: false,
      isAllResources: 'sub',
      isSubscriptions: false,
      userResources: [],
      id: null,
      userId: null,
      apps: [],
      roles: [],
      isInternal: false,
      bulkUserIds: []
    }
  },
  methods: {
    ...mapActions({
      addUac: 'uacModule/addUac',
      updateUac: 'uacModule/updateUac',
      updateMultipleUac: 'uacModule/updateMultipleUac'
    }),
    onInternalChanged(value) {
      if (!value) {
        this.isAllResources = 'sub'
      }
    },
    addNewResource() {
      this.userResources.push({
        localId: Date.now(),
        project: null,
        startDate: new Date(),
        endDate: new Date()
      })
    },
    onDeleteResource(resource) {
      let index = this.userResources.indexOf(resource)
      if (index !== -1) {
        this.userResources.splice(index, 1)
      }

    },
    async onActionClicked() {
      this.isLoading = true

      let resources = []
      this.userResources.forEach(res => {
        if (res.project) {
          resources.push({
            projectId: res.project.projectId,
            subStartDate: dateToISOString(res.startDate),
            subEndDate: dateToISOStringEndOfDay(res.endDate)
          })
        }
      })

      let payload = {
        appIds: this.roles.map(rl => rl.appId),
        roles: this.roles.map(role => role._id),
        resources: this.isAllResources === 'all' ? '*' : resources,
        isInternal: this.isInternal,
      }

      if (this.isBulkMode) {
        payload.userIds = this.bulkUserIds
        this.confirmBulkAction(payload)
      } else {
        payload.userId = this.userId
        if (this.isUpdateMode) {
          payload.id = this.id
          let response = await this.updateUac(payload)
          if (response instanceof Error) {
            this.showToast(response.message, 'is-danger')
          } else {
            this.clearFields()
            this.showToast('User UAC successfully updated', 'is-success')
            this.show(false, null)
          }
        } else {
          let response = await this.addUac(payload)
          if (response instanceof Error) {
            this.showToast(response.message, 'is-danger')
          } else {
            this.clearFields()
            this.showToast('User UAC successfully created', 'is-success')
            this.show(false, null)
          }
        }
      }
      console.log('Add uac payload: ', payload)
      this.isLoading = false
    },
    confirmBulkAction(payload) {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: 'This action will override previous user UAC settings. If UAC settings for user not exists, this action will create one. Do you want to proceed?',
        onConfirm: () => this.submitBulkAction(payload)
      })
    },
    async submitBulkAction(payload) {
      this.isLoading = true
      let response = await this.updateMultipleUac(payload)
      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
      } else {
        this.clearFields()
        this.showToast('User multiple UAC successfully updated', 'is-success')
        this.show(false, null)
        this.$emit('onBulkActionSuccess')
      }
      this.isLoading = false
    },
    show(show, item, userId) {
      if (item) {
        if (Array.isArray(item)) {
          this.isBulkMode = true
          this.bulkUserIds = item.map(user => user.id)
        } else {
          this.id = item._id
          this.userId = userId
          this.isInternal = item.isInternal ? item.isInternal : false
          this.apps = item.appIds && item.appIds ? this.apps.filter(app => item.appIds.includes(app._id)) : []
          this.roles = item.roles && item.roles.length > 0 ? this.allRoles.filter(role => item.roles.includes(role._id)) : []
          if (item.resources && item.resources !== '*' && item.resources.length > 0) {
            item.resources.forEach(resource => {
              let res = Object.assign({}, resource)
              let project = this.projects.find(pr => pr.projectId === res.projectId)
              if (project) {
                res.project = {}
                res.project.name = project.name
                res.project.projectId = project.projectId
                res.startDate = getDateObjectFromMelbourneUtcToLocal(new Date(res.subStartDate))
                res.endDate = getDateObjectFromMelbourneUtcToLocal(new Date(res.subEndDate))
                this.userResources.push(res)
              }
            })
          }

          this.isAllResources = item.resources === '*' ? 'all' : 'sub'
          this.isUpdateMode = !(this.id === null || this.id === undefined)

        }
        console.log('User resources:', this.userResources, this.isUpdateMode, this.isBulkMode)
        console.log('Add change uac: ', item)

        setTimeout(() => {
          this.refresh = true
        }, 50)
      }
      this.modalActive = show
      if (!this.modalActive) {
        this.clearFields()
      }
    },
    clearFields() {
      this.id = null
      this.userId = null
      this.apps = []
      this.roles = []
      this.isInternal = false
      this.isAllResources = 'sub'
      this.userResources = []
      this.bulkUserIds = []

      this.refresh = false
      if (this.isBulkMode) {
        this.$emit('onBulkActionSuccess')
      }
      this.isBulkMode = false
      this.isUpdateMode = false
    },
    installationDateString(date) {
      return getDate(date)
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
  computed: {
    ...mapGetters({
      allApps: 'uacModule/getApps',
      allRoles: 'uacModule/getRoles',
      allProjects: 'uacModule/getAllProjects'
    }),
    projects() {
      return this.allProjects.map((project) => {
        return {projectId: project._id, name: project.name}
      })
    }
  }
}
</script>

<style scoped>

</style>