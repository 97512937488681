<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <b-modal v-model="modalActive" width="90%" scroll="clip" style="z-index: 101" @close="clearFields()">
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head" style="padding: 12px">
          <h6 class="is-size-5 ml-auto mr-auto pl-4 has-text-weight-semibold">
            {{ devices.length > 1 ? 'Maintenance Site Plan Devices' : 'Maintenance Site Plan Device' }}</h6>
          <button
              type="button"
              class="delete"
              @click="show(false, '', deselectOnClose)"/>
        </header>
        <section class="modal-card-body p-0" style="overflow: unset">
          <map-input ref="mapBoxInput" v-if="mapType === 'mapbox'"
                     @onMapInputClicked="onMapInputClicked"
                     @onCoordinatesUpdated="onCoordinatesUpdated"
                     @onRotationChanged="onRotationChanged"
                     @onMarkerClicked="onSelectDevice"
                     :devices="editedDevices"
                     :selected-device="selectedDevice"
                     :selected-project="selectedProject"
                     draw-controls-mode="edit"
                     @onMapClicked="onMapClicked">

          </map-input>
          <g-map-input ref="gMapInput" v-if="mapType === 'gmap'" style="max-height: 30vh;"
                       @onMarkerClicked="onSelectDevice"
                       :is-markers-selectable="true"
                       :devices="editedDevices"
                       :selected-device="selectedDevice"
                       :selected-project="selectedProject"
                       :site-location="editedDevices.length === 1 ? editedDevices[0].siteLocation : null"
                       :site-name="editedDevices.length === 1 ? editedDevices[0].siteName : null"
                       draw-controls-mode="none"
                       @onMapClicked="onMapClicked"
          ></g-map-input>
          <div>
            <div class="ml-0 mr-0 mb-0 mt-3">
              <div class="modal-tab-content" style="min-height: 50vh; padding-bottom: 64px">
                <div class="box simple-device-card p-2 pl-5 ml-2 mr-2 mt-2 pr-4" v-for="device in editedDevices"
                     :key="device._id + onChanged" :class="{'selected':selectedDevice === device}">
                  <div class="columns m-0 p-0 is-align-items-center">
                    <div class="column p-0 is-3" @click="onSelectDevice(device)" style="cursor: pointer">
                      <div class="is-size-7 has-text-grey-light">Site Name</div>
                      <div class="is-size-6 has-text-weight-semibold has-text-black">{{ device.siteName }}</div>
                    </div>
                    <div class="column p-0 is-2" @click="onSelectDevice(device)" style="cursor: pointer">
                      <div class="is-size-7 has-text-grey-light">Device type</div>
                      <div class="is-size-6 has-text-weight-semibold has-text-black">{{ device.type }}</div>
                    </div>
                    <div class="column p-0 is-2" @click="onSelectDevice(device)" style="cursor: pointer">
                      <div class="is-size-7 has-text-grey-light">Device Name</div>
                      <div class="is-size-6 has-text-weight-semibold has-text-black">
                        {{ device.name ? device.name : 'Undefined' }}
                      </div>
                    </div>
                    <div class="column m-0 p-0 is-flex is-1">
                      <b-tooltip v-if="selectedDevice === device  && devices.length > 1" label="Copy to all"
                                 class="ml-auto mr-2" position="is-left"
                                 :delay="1000" type="is-dark">
                        <a @click="onCopyToDoClick(device)">
                          <b-icon icon="content-copy">
                          </b-icon>
                        </a>
                      </b-tooltip>
                    </div>
                  </div>
                  <div class="mt-4">
                    <div class="is-flex">
                      <div class="is-size-5">Maintenance tasks</div>
                      <b-tooltip label="Add Maintenance Task" class="ml-auto mr-1" :delay="1000" type="is-dark"
                                 position="is-left">
                        <a v-on:click.stop @click="onAddMaintenance(device)">
                          <b-icon icon="plus-circle-outline">
                          </b-icon>
                        </a>
                      </b-tooltip>
                    </div>
                    <hr class="m-0">
                    <div style="margin-top: 32px">
                      <div v-for="(task, index) in device.maintenanceTasks" :key="index" class="mt-3 pb-5">
                        <div class="columns">
                          <div class="column p-0 is-2">
                            <b-field grouped label="Maintenance date *" label-position="on-border">
                              <b-datepicker :mobile-native="false" expanded v-model="task.date"
                                            :disabled="task.status === 'DONE'"
                                            size="is-small" position="is-bottom-right">
                                <template v-slot:trigger>
                                  <b-input expanded readonly rounded placeholder="Maintenance date *"
                                           :disabled="task.status === 'DONE'"
                                           :value="maintenanceDateString(task.date)" type="text"
                                           required/>
                                </template>
                              </b-datepicker>
                            </b-field>
                          </div>
                          <div class="column p-0 pr-2 is-3 pl-2">
                            <b-field expanded label="Maintenance crew *" label-position="on-border">
                              <b-dropdown
                                  expanded
                                  v-model="task.crew"
                                  multiple
                                  @change="onInstallDropDownChanged" :disabled="task.status === 'DONE'"
                                  aria-role="list">
                                <template #trigger>
                                  <my-button
                                      :key="installDropdownKey"
                                      expanded
                                      style="border-radius: 32px"
                                      icon-right="menu-down">
                                    <div class="is-flex" v-if="task.crew.length > 0">
                                      <user-avatar v-for="user in task.crew" :user="user" :is-small="true"
                                                   :key="user.id"></user-avatar>
                                    </div>
                                    <div v-else class="has-text-grey-light">
                                      Maintenance crew
                                    </div>

                                  </my-button>
                                </template>
                                <b-dropdown-item v-for="user in users" :value="user" :key="user.id"
                                                 aria-role="listitem">
                                  <div class="is-flex m-0 p-0">
                                    <div class="m-0 p-0">
                                      <user-avatar :user="user" :is-small="true"></user-avatar>
                                    </div>
                                    <div class="m-0 ml-2 p-0">
                                        <div class="is-size-7 has-text-weight-semibold" style="line-height: 1">{{ user.fullName }}</div>
                                        <div class="is-size-7" style="line-height: 1"> {{user.partEmail}}</div>
                                    </div>
                                  </div>

                                </b-dropdown-item>
                              </b-dropdown>
                            </b-field>
                          </div>
                          <div class="column mt-0 p-0 pr-2">
                            <b-field label="Maintenance instruction" label-position="on-border">
                              <b-input v-model="task.instruction" placeholder="Maintenance Instruction"
                                       :disabled="task.status === 'DONE'"
                                       rounded></b-input>
                            </b-field>
                          </div>
                        </div>
                        <div class="columns mb-0">
                          <div class="column">
                            <div class="is-flex">
                              <div v-if="task.todos" :key="task.todos.length">
                                <b-tooltip :label="item.doneAt ? getDateAndUser(item) : 'Not Done'" :delay="1000"
                                           type="is-dark"
                                           v-for="item in task.todos" :key="item.name">
                                  <b-taglist attached class="m-0 mr-1 mb-1"
                                             style="display: inline-flex; cursor: pointer">
                                    <b-tag :type="item.doneAt ? 'is-success' : 'is-danger'" class="m-0">
                                      <b-icon :icon="item.doneAt ? 'check' : 'close'" size="is-small"></b-icon>
                                    </b-tag>
                                    <b-tag class="m-0" style="background: #eaeaea">{{ item.name }}</b-tag>
                                  </b-taglist>
                                </b-tooltip>
                              </div>
                              <div v-else class="is-size-6 has-text-weight-normal has-text-warning">Undefined</div>
                            </div>
                          </div>
                          <div class="column is-flex">
                            <div class="ml-auto">
                              <b-field expanded class="ml-3" label="Maintenance template" label-position="on-border">
                                <b-select size="is-small" style="min-width: 200px" expanded
                                          placeholder="Select template" :disabled="task.status === 'DONE'"
                                          rounded
                                          @input="onInstallTemplateChanged({event: $event, device: device, task: task})">
                                  <option v-for="settingsTemplate in settingsTemplates(device.type)"
                                          :value="settingsTemplate"
                                          :key="settingsTemplate._id">{{ settingsTemplate.name }}
                                  </option>
                                </b-select>
                              </b-field>
                            </div>
                            <b-tooltip position="is-left" label="Clear fields" class="ml-2" :delay="1000"
                                       type="is-dark">
                              <b-button @click="onClearInstallFields({device: device, task: task})" size="is-small"
                                        type="is-primary" outlined
                                        icon-right="close"></b-button>
                            </b-tooltip>
                          </div>
                        </div>
                        <hr class="m-0" style="background-color: #e3e3e3">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot" style="padding: 0px">
          <b-button class="is-primary is-fullwidth m-2" @click="onUpdateDevices" rounded>
            {{ devices.length > 1 ? 'UPDATE DEVICES' : 'UPDATE DEVICE' }}
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MapInput from "@/components/map-input/map-input.vue";
import GMapInput from "@/components/g-map-input/g-map-input.vue";
import {mapActions, mapGetters} from "vuex";
import {
  DeviceType,
  getDate,
  getDateObjectFromMelbourneUtcToLocal,
  getDateTime,
  TaskType,
  TemplateType
} from "@/utils/utils";
import {getUpdateSitePlanDevicesMaintenancePayload, getUpdateSitePlanDevicesPayload} from "@/utils/helper";
import UserAvatar from "@/components/avatar/avatar.vue";
import MyButton from "@/components/my-button/my-button.vue";

export default {
  name: "add-edit-maintenance",
  components: {MapInput, GMapInput, UserAvatar, MyButton},
  props: {
    devices: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      isLoading: false,
      modalActive: false,
      deviceType: 'CAMERA',
      editedDevices: [],
      selectedDevice: null,
      selectedTab: 0,
      deselectOnClose: false,
      clickedMarker: false,
      changes: 0,
      installConfiguration: [],
      selectedInstallConfiguration: null,
      confChanged: false,
      installDropdownKey: 0,
      onChanged: false
    }
  },
  mounted() {
    console.log('Devices: ', this.devices)
    this.setDevices()
  },
  methods: {
    ...mapActions({
      getLocation: 'getLocationData',
      deselectAllDevices: 'projectsModule/deselectAllDevices',
      updateSitePlanDevices: 'projectsModule/updateSitePlanDevices'
    }),
    show(show, action, deselectOnClose) {
      this.modalActive = show
      this.deselectOnClose = deselectOnClose
      this.setDevices()
      console.log('Show edit device:', this.deselectOnClose, this.editedDevices)
      if (action === 'location') {
        this.selectedTab = 0
      } else if (action === 'configuration') {
        this.selectedTab = 1
      } else if (action === 'instruction') {
        this.selectedTab = 2
      } else if (action === 'todo') {
        this.selectedTab = 3
      }

      if (!this.modalActive) {
        this.clearFields()
      }
    },
    setDevices() {
      this.editedDevices = JSON.parse(JSON.stringify(this.devices))
      console.log('Edited devices: ', this.editedDevices)
      this.editedDevices.forEach(device => {
        let maintenanceTasks = device.tasks.filter(task => task.taskType === TaskType.MAINTENANCE)

        device.maintenanceTasks = []
        maintenanceTasks.forEach(task => {
          device.maintenanceTasks.push({
            _id: task._id,
            taskType: task.taskType,
            date: getDateObjectFromMelbourneUtcToLocal(new Date(task.date)),
            crew: task.crew.map(userId => this.getUserById(userId)),
            status: task.status,
            todos: task.todos,
            instruction: task.instruction
          })
        })

        console.log('Maintenance task: ', maintenanceTasks)

      })
    },
    onAddMaintenance(device) {
      device.maintenanceTasks.push({
        taskType: TaskType.MAINTENANCE,
        date: null,
        crew: [],
        status: 'UNDONE',
        todos: null,
        instruction: null
      })

      this.onChanged = !this.onChanged
    },
    getDateAndUser(item) {
      const user = this.$store.getters.getUserById(item.doneBy)
      const userName = user && user.fullName ? user.fullName : 'Unknown'
      const dateTime = getDateTime(new Date(item.doneAt))
      return dateTime + ' by ' + userName
    },
    onInstallDropDownChanged() {
      this.installDropdownKey++
    },
    onConfigurationChanged(data) {
      console.log('On configuration changed: ', data)
      let index = this.editedDevices.findIndex(device => device._id === data.device._id)
      if (index !== -1) {
        if (this.editedDevices[index].settingsProfile == null) {
          this.editedDevices[index].settingsProfile = {}
        }
        data.configuration.forEach(conf => {
          this.editedDevices[index].settingsProfile[conf.field] = conf.value
        })
      }
    },
    onInstallTodoListChanged(data) {
      console.log('On install todo list changed: ', data)
      let index = this.editedDevices.findIndex(device => device._id === data.device._id)
      if (index !== -1) {
        this.editedDevices[index].todos = data.configuration
      }
    },
    onDecommissionTodoListChanged(data) {
      console.log('On decommission todo list changed: ', data)
      let index = this.editedDevices.findIndex(device => device._id === data.device._id)
      if (index !== -1) {
        this.editedDevices[index].decommissionTodos = data.configuration
      }
    },
    async onUpdateDevices() {
      console.log('On update devices: ', this.editedDevices)
      let payload = getUpdateSitePlanDevicesMaintenancePayload(this.editedDevices)
      this.isLoading = true
      let response = await this.updateSitePlanDevices(payload)
      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
      } else {
        await this.clearFields()
        this.showToast('Devices successfully updated', 'is-success')
        this.show(false)
        await this.deselectAllDevices()
      }
      this.isLoading = false
      console.log('On update devices payload: ', payload)
    },
    async onMapClicked(position) {
      console.log('On map clicked edit devices:', position)
      if (!this.clickedMarker || this.mapType === 'gmap') {
        this.selectedDevice = null
      }
      this.clickedMarker = false
      this.zoomToObject()
    },
    getAddressString(location) {
      if (location.address) {
        if (location.place.includes(location.address)) {
          return `${location.place}`
        } else {
          return `${location.address}, ${location.place}`
        }

      } else {
        this.$buefy.toast.open({
          message: 'Can\'t get address for selected location',
          duration: 4000,
          type: 'is-danger'
        })
        return 'unknown'
      }
    },
    async clearFields() {
      console.log('Clear fields edit device', this.deselectOnClose)
      if (this.deselectOnClose) {
        console.log('Clear fields edit device', this.devices[0])
        this.selectedDevice = null
        await this.deselectAllDevices()
      }
    },
    onSelectDevice(device) {
      console.log('Device selected:', device)
      if (this.selectedDevice && this.selectedDevice._id === device._id) {
        this.selectedDevice = null
      } else {
        this.selectedDevice = device
        this.clickedMarker = true
      }
      this.zoomToObject()
    },
    onCopyConfigurationClick(device) {
      console.log('On copy configuration click', device)
      this.editedDevices.forEach(dev => {
        if (dev.type === device.type) {
          dev.settingsProfile = device.settingsProfile
        }
      })
      this.changes++
    },
    onCopyToDoClick(device) {
      console.log('On copy To lists click', device)
      this.editedDevices.forEach(dev => {
        if (dev.type === device.type) {
          dev.todos = device.todos
          dev.decommissionTodos = device.decommissionTodos
        }
      })
      this.changes++
    },
    onCopyInstructionClick(device) {
      console.log('On copy instruction click', device)
      this.editedDevices.forEach(dev => {
        if (dev.type === device.type) {
          dev.instruction = device.instruction
        }
      })
      this.changes++
    },
    onCopyDecommissionInstructionClick(device) {
      console.log('On copy decommission instruction click', device)
      this.editedDevices.forEach(dev => {
        if (dev.type === device.type) {
          dev.decommissionInstruction = device.decommissionInstruction
        }
      })
      this.changes++
    },
    click() {
      console.log('Clcked')
    },
    zoomToObject() {
      if (this.$refs.mapBoxInput) {
        this.$refs.mapBoxInput.zoomMapToSelectedSiteOrProject(true)
      } else if (this.$refs.gMapInput) {
        this.$refs.gMapInput.zoomMapToSelectedSiteOrProject(true)
      }
    },
    onInstallTemplateChanged(data) {
      console.log('On template changed:', data)
      data.task.todos = data.event.template
      this.onChanged = !this.onChanged
      console.log('New data: ', this.editedDevices)

    },
    onClearInstallFields(data) {
      console.log('On template clear:', data)
      data.task.todos = []
      this.onChanged = !this.onChanged
    },
    maintenanceDateString(date) {
      return date ? /*date.toLocaleDateString()*/ getDate(date) : ''
    },
    settingsTemplates(deviceType) {
      if (this.settings && this.settings.todoTemplates) {
        return this.settings.todoTemplates.filter(template => template.deviceType === deviceType && template.templateType === TemplateType.TODO)
      }
      return []
    },
    getUserById(id) {
      return this.$store.getters.getUserById(id)
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
  computed: {
    ...mapGetters({
      mapType: 'getMapType',
      selectedProject: 'projectsModule/getSelectedProject',
      settings: 'getSettings'
    }),
    DeviceType() {
      return DeviceType
    },
    users() {
      console.log('Allowed users:', this.$store.getters.getAllowedUsers)
      return this.$store.getters.getAllowedUsers
    }
  }
}
</script>

<style scoped lang="scss">
.simple-device-card {
  border-radius: 32px;
  background-color: #fbfbfb;
  box-shadow: 0 0.125em 0.125em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
}

.selected {
  background-color: #e3e3e3;
}

.modal-tab-content {
  height: 30vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 24px;
  padding-right: 16px;
}
</style>