import Vue from "vue";

export default {
    getMonitoringProject: async ({commit}, selectedProject) => {
        if (selectedProject) {
            let config = {
                method: 'get',
                url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/project-report-v3',
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {id: selectedProject._id},
            };
            return await Vue.prototype.$http(config)
                .then((response) => {
                    console.log('Get project response: ', response)
                    if (response.status === 200) {
                        //commit('setAllProjects', response.data)
                        commit('setSelectedMonitoringProject', response.data)

                    }
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });

        } else {
            commit('setSelectedMonitoringProject', null)
        }
    },
    setQueryData: async ({commit}, queryData) => {
        commit('setQueryData', queryData)
    },
    fetchMonitoringData: async ({commit}, projectId) => {
        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/monitoring-v3',
            headers: {
                'Content-Type': 'application/json'
            },
            params: {projectId: projectId},
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Get assessment response: ', response)
                if (response.status === 200) {
                    commit('setMonitoringData', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

