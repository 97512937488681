<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <b-modal v-model="modalActive" width="70%" scroll="keep">
      <div class="card">
        <header class="card-header ">
          <div class="card-header-title is-flex">
            <h6 class="is-size-6 ml-auto mr-auto pl-4 has-text-weight-semibold">Set Video Configuration Date Range</h6>
          </div>
          <b-button class="card-header-icon mt-2 mr-2" aria-label="more options" @click="show(false, false)">
            <b-icon icon="close"></b-icon>
          </b-button>
        </header>
        <div class="card-content">
          <div class="content" v-if="payload">
            <b-notification
                has-icon
                icon-size="is-small"
                :closable="false"
                type="is-info">
              Please notice: You will set date and time for device time zone. Time zone for this device is <strong>{{selectedDevice.site.timezone}}</strong>
            </b-notification>
            <div class="columns" style="margin-bottom: 350px">
              <div class="column pt-4 pb-4 pl-2">
                <b-field grouped label="Valid From" label-position="on-border">
                  <b-datetimepicker :mobile-native="false"
                                    horizontal-time-picker
                                    :timepicker="{ hourFormat: '24', incrementMinutes: 15}"
                                    placeholder="Select valid from..."
                                    rounded expanded v-model="payload.validFromPicker" position="is-bottom-right"
                                    @input="isValidForm">
                  </b-datetimepicker>

                </b-field>
              </div>
              <div class="column pt-4 pb-4 pl-2">
                <b-field grouped label="Valid To" label-position="on-border">
                  <b-datetimepicker :mobile-native="false"
                                    :timepicker="{ hourFormat: '24',incrementMinutes: 15}"
                                    horizontal-time-picker
                                    placeholder="Select valid to..."
                                    rounded expanded v-model="payload.validToPicker" position="is-bottom-right"
                                    @input="isValidForm">
                  </b-datetimepicker>
                </b-field>
              </div>
            </div>

            <div class="m-0 p-0 pr-2 pl-0" v-if="selectedDevice.processingPeriods">
              <div class="is-size-7 has-text-grey-light mt-1">Processing periods</div>
              <div class="is-flex" v-for="(period, index) in selectedDevice.processingPeriods" :key="index">
                <div v-if="processingPeriodByIndex(index)" class="is-flex">
                  <b-icon icon="calendar" size="is-small" class="mt-1 mr-1"></b-icon>
                  <div>{{ processingPeriodByIndex(index) }}</div>
                  <div class="is-flex is-flex-direction-column ml-4">
                    <div v-for="timeRange in processingTimesByIndex(index)" :key="timeRange">
                      <div class="is-flex">
                        <b-icon icon="clock-outline" size="is-small" class="ml-2 mt-1 mr-1"></b-icon>
                        <div>{{ timeRange }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="is-size-6 has-text-weight-normal has-text-warning">Undefined</div>
              </div>
            </div>

          </div>
        </div>
        <footer class="card-footer">
          <b-button class="is-primary is-fullwidth m-2" @click="onSaveClicked" rounded :disabled="!formValid">Continue
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {
  dateToISOString,
  extractGMapGpsCoordinates,
  getDate,
  getDateObjectFromMelbourneUtcToLocal, getDateRange, getDateTime, getPickerTimeToTimeZone,
  gMapOptions, gMapStyles, parseTimeRanges
} from "@/utils/utils";
import moment from "moment-timezone";

export default {
  name: "save-modal",
  components: {},
  data() {
    return {
      isLoading: false,
      modalActive: false,
      isEditMode: true,
      formValid: false,
      payload: null
    }
  },
  mounted() {

  },
  methods: {
    ...mapActions({
      saveCalibration: 'dataProcessingModule/saveCalibration',
      selectCalibration: 'dataProcessingModule/selectCalibration'
    }),
    show(show, calibration) {

      this.payload = {
        dataDeviceId: this.selectedDevice._id,
        siteId: this.selectedDevice.siteId,
        calibration: null,
        pixelCalibration: null,
        validFromPicker: null,
        validToPicker: null,
        validFrom: null,
        validTo: null,
        version: 1,
        videoMetaDataId: this.selectedDevice.videoMetaDataId,
        localCaptureStart: this.selectedDevice.localCaptureStart,
        localCaptureEnd: this.selectedDevice.localCaptureEnd,
        videoUrl: this.selectedDevice.videoUrl,
        availableFiles: this.selectedDevice.availableFiles,
        isNew: true
      }

      if(calibration){
        this.payload.pixelCalibration = calibration.pixelCalibration
        this.payload.videoMetaDataId = calibration.videoMetaDataId
        this.payload.localCaptureStart = calibration.localCaptureStart
        this.payload.localCaptureEnd = calibration.localCaptureEnd
        this.payload.videoUrl = calibration.videoUrl
      }

      this.modalActive = show
    },
    async onSaveClicked() {
      console.log('On save Payload', this.payload)
      await this.selectCalibration(this.payload)
      this.show(false, false)
    },
    isValidForm() {
      console.log('Is valid form: ', this.payload)
      if (this.payload.validFromPicker && this.payload.validToPicker) {
        this.formValid = true
      } else {
        this.formValid = false
      }

      if(this.payload.validFromPicker){
        let timeFrom = getPickerTimeToTimeZone(this.payload.validFromPicker, this.selectedDevice.site.timezone)
        this.payload.validFrom = timeFrom
      }
      if(this.payload.validToPicker){
        let timeTo = getPickerTimeToTimeZone(this.payload.validToPicker, this.selectedDevice.site.timezone)
        this.payload.validTo = timeTo
      }
    },
    processingPeriodByIndex(index) {
      if (this.selectedDevice && this.selectedDevice.processingPeriods && this.selectedDevice.processingPeriods.length > 0) {
        if (this.selectedDevice.processingPeriods[index].dateRange && this.selectedDevice.processingPeriods[index].dateRange.length > 1) {
          return getDateRange(this.selectedDevice.processingPeriods[index].dateRange)
        }
      }
      return null
    },
    processingTimesByIndex(index) {
      if (this.selectedDevice && this.selectedDevice.processingPeriods && this.selectedDevice.processingPeriods.length > 0) {
        if (this.selectedDevice.processingPeriods[index].dateRange && this.selectedDevice.processingPeriods[index].dateRange.length > 1) {
          return parseTimeRanges(this.selectedDevice.processingPeriods[index].timeRanges)
        }
      }
      return null
    },
  },
  computed: {
    ...mapGetters({
      selectedDevice: 'dataProcessingModule/getSelectedProcessingDevice'
    }),
  }
}
</script>

<style scoped lang="scss">
</style>