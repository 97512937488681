/**
 * Created by Sale on 6/4/2024
 */
import {Role} from "@/utils/utils";
import qaPage from "@/modules/qa-module/views/qa/qa-page.vue";

const Module = () => import("./Module.vue");


//TODO change permission uac:get
const moduleRoute = {
    path: "/qa",
    component: Module,
    children: [
        {
            path: '/',
            name: 'qa',
            meta: {
                product: 'Realite Admin',
                title: 'QA',
                isSubCategory: false,
                roles: [Role.ADMIN, Role.TRAFFIC_ENG],
                permission:'qa-validation:get-report',
                topBarTitle: '',
                menuPriority: 5,
                hasMenu: true,
                hasInternalTransition: false
            },
            component: qaPage
        }
    ]
};

export default router => {
    router.addRoutes([moduleRoute]);
};
