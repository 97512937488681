export default {
    getAllProjects: (state) => {
        return state.allProjects
    },
    getSelectedProject: (state) => {
        return state.selectedProject
    },
    getUserInfo: (state) => {
        return state.user
    },
    getQueryData: (state) => {
        return state.searchQueryData
    },
    getFilteredProjects: (state) => {
        if (state.allProjects.length > 0) {
            let filteredProjects = state.allProjects
            if (state.searchQueryData.query.length > 0) {
                filteredProjects = state.allProjects.filter(project => project.name.toLowerCase().includes(state.searchQueryData.query.toLowerCase()))
            }
            if (state.searchQueryData.active.value !== 'ALL') {
                filteredProjects = filteredProjects.filter(project => project.status === state.searchQueryData.active.value)
            }
            if (state.searchQueryData.projectType.value !== 'ALL') {
                filteredProjects = filteredProjects.filter(project => project.types.includes(state.searchQueryData.projectType.value))
            }

            if (state.searchQueryData.sort) {
                if (state.searchQueryData.sort.value === 'ASCENDING') {
                    filteredProjects = filteredProjects.sort((a, b) => (a.name > b.name) ? 1 : -1);
                } else {
                    filteredProjects = filteredProjects.sort((a, b) => (a.name > b.name) ? -1 : 1);
                }
            }
            return filteredProjects
        } else {
            return state.allProjects
        }
    },
    getSelectedDevices: (state) => {
        return state.selectedDevices
    },
    getSelectedSites: (state) => {
        return state.selectedSites
    },
}
