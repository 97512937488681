<template>
  <div>
    <transition name="fade">
      <floating-view v-if="selectedDevices.length > 0" element-id="12392775487" :width="320">
        <div class="is-flex is-flex-direction-row pt-3">
          <b-tooltip label="Edit Locations" class="ml-auto mr-4" :delay="1000" type="is-dark">
            <a v-on:click.stop @click="onClick('location')">
              <b-icon icon="map-marker">
              </b-icon>
            </a>
          </b-tooltip>
          <b-tooltip label="Edit Configurations" class="mr-4" :delay="1000" type="is-dark">
            <a v-on:click.stop @click="onClick('configuration')">
              <b-icon icon="cog">
              </b-icon>
            </a>
          </b-tooltip>
          <b-tooltip label="Edit Instructions" class="mr-4" :delay="1000" type="is-dark">
            <a v-on:click.stop @click="onClick('instruction')">
              <b-icon icon="information">
              </b-icon>
            </a>
          </b-tooltip>
          <b-tooltip label="Edit To-Do List" class="mr-4" :delay="1000" type="is-dark">
            <a v-on:click.stop @click="onClick('todo')">
              <b-icon icon="clipboard-list">
              </b-icon>
            </a>
          </b-tooltip>
          <b-tooltip label="Edit Collection/Processing Periods" class="mr-4" :delay="1000" type="is-dark">
            <a v-on:click.stop @click="onClick('periods')">
              <b-icon icon="calendar-range">
              </b-icon>
            </a>
          </b-tooltip>
          <b-tooltip label="Select All Devices" class="mr-5" :delay="1000" type="is-dark">
            <a v-on:click.stop @click="onClick('selectAll')">
              <b-icon icon="select-all">
              </b-icon>
            </a>
          </b-tooltip>
          <b-tooltip label="Clear selection" class="mr-auto" :delay="1000" type="is-dark">
            <a v-on:click.stop @click="onClick('clear')">
              <b-icon icon="close">
              </b-icon>
            </a>
          </b-tooltip>
        </div>
        <div class="mt-4 is-flex ml-auto mr-auto selected-devices-background box">
          <div class="ml-auto mr-auto is-size-7">Selected Devices: <span class="has-text-weight-bold">{{selectedDevices.length}}</span></div>
        </div>
      </floating-view>
    </transition>
  </div>
</template>

<script>
import FloatingView from "@/components/floating-view/floating-view.vue";
import {mapGetters} from "vuex";

export default {
  name: "floating-menu",
  components: {FloatingView},
  data() {
    return {
      isVisible: false
    }
  },
  methods: {
    onClick(action) {
      console.log('On click change: ', action)
      this.$emit('onBulkActionClicked', action)
    },
  },

  computed: {
    ...mapGetters({
      selectedDevices: 'projectsModule/getSelectedDevices'
    }),
  }
}
</script>

<style scoped>
.selected-devices-background{
  height: 16px !important;
  border-radius: 16px;
  background: white;
  width: 150px;
  padding: 0 0 20px 0;
}

</style>