<template>
  <div class="ml-0 mr-0 mt-0">
    <div class="is-flex">
      <b-taglist attached class="m-0 p-0 ml-auto">
        <b-tag type="is-dark">Version</b-tag>
        <b-tag type="is-info">
          {{
            selectedDevice.chartsData && selectedDevice.chartsData.querySet ? selectedDevice.chartsData.querySet.veh.version : 'unknown'
          }}
        </b-tag>
      </b-taglist>
    </div>
    <apex-chart type="line" height="400" :options="chartOptions" :series="series"></apex-chart>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "speed-distribution-chart",
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          height: 400,
          type: 'line',
          fontFamily: 'Barlow'
        },
        stroke: {
          width: [0, 4, 4]
        },
        title: {
          text: 'Speed Bin Distribution'
        },
        /*        dataLabels: {
                  enabled: true,
                  enabledOnSeries: [1]
                },*/
        plotOptions: {
          bar: {
            columnWidth: '98%',
          },
        },
        labels: [],
        yaxis: {
          title: {
            text: 'Vehicle count',
          }
        },
      },
    }
  },
  mounted() {
    this.setChartData()

  },
  methods: {
    setChartData() {

      let series = []
      let categories = []
      let yAxis = []
      let widths = []

      if (this.chartsData && this.chartsData.vehSpeedData) {
        let speedData = this.chartsData.vehSpeedData
        /*let speedData = {
          "total": {
            "bins": [
              0,
              5,
              10,
              15,
              20,
              25,
              30,
              35,
              40,
              45,
              50,
              55,
              60,
              65,
              70,
              75
            ],
            "binCounts": [
              53,
              116,
              145,
              151,
              298,
              288,
              715,
              1578,
              1608,
              1332,
              568,
              499,
              56,
              46,
              1
            ]
          },
          "MainRd-Northbound": {
            "bins": [
              0,
              5,
              10,
              15,
              20,
              25,
              30,
              35,
              40,
              45,
              50,
              55,
              60,
              65,
              70,
              75
            ],
            "binCounts": [
              33,
              64,
              73,
              47,
              136,
              139,
              361,
              905,
              826,
              633,
              285,
              236,
              24,
              22,
              1
            ]
          },
          "MainRd-Southbound": {
            "bins": [
              0,
              5,
              10,
              15,
              20,
              25,
              30,
              35,
              40,
              45,
              50,
              55,
              60,
              65,
              70,
              75
            ],
            "binCounts": [
              20,
              52,
              71,
              93,
              137,
              107,
              273,
              622,
              756,
              693,
              278,
              263,
              32,
              24,
              0
            ]
          },
          "ServiceRd-Northbound": {
            "bins": [
              0,
              5,
              10,
              15,
              20,
              25,
              30,
              35,
              40,
              45,
              50,
              55,
              60,
              65,
              70,
              75
            ],
            "binCounts": [
              0,
              0,
              0,
              0,
              1,
              0,
              1,
              0,
              1,
              0,
              0,
              0,
              0,
              0,
              0
            ]
          },
          "ServiceRd-Southbound": {
            "bins": [
              0,
              5,
              10,
              15,
              20,
              25,
              30,
              35,
              40,
              45,
              50,
              55,
              60,
              65,
              70,
              75
            ],
            "binCounts": [
              0,
              0,
              1,
              11,
              24,
              42,
              80,
              51,
              25,
              6,
              5,
              0,
              0,
              0,
              0
            ]
          }
        }*/

        let keys = Object.keys(speedData)

        keys.forEach(key => {

          if (speedData[key] && speedData[key].bins && speedData[key].bins.length > 0) {

            if (key.trim().toLowerCase() === 'total') {
              categories = speedData[key].bins.map(bean => `${bean + '-' + (bean + 5)}`)
              widths.push(0)
              yAxis.push({
                title: {
                  text: key,
                },
              })
            } else {
              widths.push(4)
              yAxis.push({
                opposite: true,
                title: {
                  text: key
                }
              })
            }

            let binCounts = speedData[key].binCounts
            //Fix missing binCount, common tooltip problem
            if (binCounts.length > 0 && binCounts.length < categories.length) {
              binCounts.push(0)
            }

            series.push({
                  name: key,
                  type: key.trim().toLowerCase() === 'total' ? 'column' : 'line',
                  data: binCounts
                }
            )
          }
        })
      }

      this.series = series
      this.chartOptions = {
        chart: {
          height: 400,
          type: 'line',
          stacked: false,
          fontFamily: 'Barlow'
        },
        stroke: {
          width: widths
        },
        title: {
          text: 'Speed Bin Distribution'
        },
        /*                        dataLabels: {
                                  enabled: true,
                                  //enabledOnSeries: [0]
                                },*/
        plotOptions: {
          bar: {
            columnWidth: '95%'
          },
        },
        markers: {
          size: 1
        },
        labels: categories,
        yaxis: {
          title: {
            text: 'Vehicle count',
          }
        },
        tooltip: {
          shared: true,
          intersect: false,
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      selectedDevice: 'qaModule/getSelectedQaDevice'
    }),
    chartsData() {
      if (this.selectedDevice && this.selectedDevice.chartsData) {
        return this.selectedDevice.chartsData
      }

      return null
    }
  },

  watch: {
    chartsData: {
      handler: function (after, before) {
        this.setChartData()
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>