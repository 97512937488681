<template>
  <div>
    <div>
      <top-bar v-if="$router.currentRoute.meta.hasMenu"></top-bar>
      <main v-if="$router.currentRoute.meta.hasInternalTransition"
            :class="[$router.currentRoute.meta.hasMenu ? 'main-style-menu' : 'main-style']">
        <slot/>
      </main>
      <transition v-else name="fade" mode="out-in">
        <main :class="[$router.currentRoute.meta.hasMenu ? 'main-style-menu' : 'main-style']"
              :key="$router.currentRoute.path">
          <slot/>
        </main>
      </transition>
    </div>
    <footer class="footer app-footer">
      <div class="content is-flex">
        <div class="ml-4" style="margin-top: 4px;font-size: 14px;">v3.0</div>
        <div style="margin-top: 3px; font-size: 14px;" class="ml-auto mr-auto">
          Powered by Realite (<a href="https://realtimetraffic.com.au/" style="color: rgba(255,255,255,0.65)">Real Time
          Traffic Pty Ltd</a> @ 2024)
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
/* eslint-disable */
import TopBar from "@/components/top-bar/top-bar.vue";

export default {
  name: 'LayoutDefault',
  components: {
    TopBar
  },
  data() {
    return {
      isEditProfileActive: false
    };
  },
  mounted() {
  },
  methods: {}
};
</script>
<style scoped lang="scss">
.bottom-shadow {
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
  z-index: 10;
}
</style>
