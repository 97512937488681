<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <add-sites ref="addSitesModal"></add-sites>
    <edit-devices ref="editDevices" :devices="selectedDevices"></edit-devices>
    <edit-site-plans ref="editSitePlans" :sites="selectedSites"></edit-site-plans>
    <add-edit-maintenance ref="addEditMaintenance" :devices="selectedDevices"></add-edit-maintenance>
    <floating-menu ref="floatingMenu" @onBulkActionClicked="onBulkActionClicked"></floating-menu>
    <floating-menu-sites ref="floatingMenuSites" @onBulkActionClicked="onBulkActionSitesClicked"></floating-menu-sites>
    <history-component ref="historyComponent" key="sites-view"></history-component>
    <div class="is-flex is-align-items-center mb-3">
      <div class="is-size-5 has-text-grey">PROJECT SITE PLANS</div>
      <view-guard :permission="'site-plan:create'" class="ml-auto">
        <div>
          <b-button v-if="isUnfinished" @click="confirmFinishPlanning" class="mr-2" type="is-info" icon-left="check"
                    rounded>Finish Planning
          </b-button>
          <b-button @click="onAddSite" type="is-primary" icon-left="plus" rounded>Add Site Plans
          </b-button>
        </div>
      </view-guard>
    </div>
    <div v-if="project.projectSitePlans && project.projectSitePlans.length > 0">
      <project-site-view v-for="plan in project.projectSitePlans" :site="plan" :key="plan._id" class="mb-1"
                         @onEditDevice="onEditDevice"
                         @onMaintenanceDevice="onAddEditMaintenance"
                         @onSiteBulkAction="onSiteBulkAction"
                         @onHistoryClicked="onHistoryClicked"
      ></project-site-view>
    </div>
    <div v-else class="is-flex is-align-items-center dashed-border">
      <h4 class="is-size-4 ml-auto mr-auto has-text-grey-light has-text-weight-light">Click on "+" button to add first
        project site plan</h4>
    </div>
  </div>
</template>

<script>
import ProjectSiteView from "@/modules/projects-module/components/project-sites/project-site-view.vue";
import FloatingMenu from "@/modules/projects-module/components/floating-menu/floating-menu.vue";
import {mapActions, mapGetters} from "vuex";
import AddSites from "@/modules/projects-module/components/add-sites/add-sites.vue";
import EditDevices from "@/modules/projects-module/components/edit-devices/edit-devices.vue";
import HistoryComponent from "@/modules/projects-module/components/history-component/history-component.vue";
import {getUpdateProjectSitePlanStatusesPayload} from "@/utils/helper";
import AddEditMaintenance from "@/modules/projects-module/components/add-edit-maintenance/add-edit-maintenance.vue";
import ViewGuard from "@/components/view-guard/view-guard.vue";
import FloatingMenuSites from "@/modules/projects-module/components/floating-menu-sites/floating-menu-sites.vue";
import EditSitePlans from "@/modules/projects-module/components/edit-site-plans/edit-site-plans.vue";

export default {
  name: "project-sites",
  components: {
    EditSitePlans,
    FloatingMenuSites,
    ViewGuard, AddEditMaintenance, EditDevices, AddSites, FloatingMenu, ProjectSiteView, HistoryComponent},
  data() {
    return {
      isLoading: false
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      selectDevice: 'projectsModule/selectDevice',
      deselectDevice: 'projectsModule/deselectDevice',
      selectDevices: 'projectsModule/selectDevices',
      deselectAllDevices: 'projectsModule/deselectAllDevices',
      updateSitePlanStatuses: 'projectsModule/updateSitePlanStatuses',
      selectSites: 'projectsModule/selectSites',
      deselectAllSites: 'projectsModule/deselectAllSites',
    }),
    onAddSite() {
      this.$refs.addSitesModal.show(true)
    },
    async onFinishPlanning() {
      console.log('On finish planning')


      let payload = getUpdateProjectSitePlanStatusesPayload(this.project.projectSitePlans)

      console.log('Payload: ', payload)

      this.isLoading = true
      let response = await this.updateSitePlanStatuses(payload)
      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
      } else {
        this.showToast('Project Site planning successfully finished', 'is-success')
      }
      this.isLoading = false
    },
    confirmFinishPlanning() {
      this.$buefy.dialog.confirm({
        type: 'is-info',
        confirmText: 'PROCEED',
        message: 'After you finish planing you not be able to delete project site plans and planned devices. Are you sure you want to Finish Project Planing?',
        onConfirm: () => this.onFinishPlanning()
      })
    },
    onBulkActionClicked(action) {
      console.log('Project sites, on bulk action clicked: ', action)
      if (action === 'clear') {
        this.deselectAllDevices()
      } else if(action === 'selectAll'){
        this.selectAllDevices()
      }else {
        this.$refs.editDevices.show(true, action, false)
      }
    },
    onBulkActionSitesClicked(action){
      console.log('Project sites, on bulk action clicked: ', action)
      if (action === 'clear') {
        this.deselectAllSites()
      } else if(action === 'selectAll'){
        this.selectAllSites()
      }else {
        this.$refs.editSitePlans.show(true, action, false)
      }
    },
    async selectAllDevices(){
      console.log('Project select all:', this.project)
      let selectedDevices = []
      if(this.project && this.project.projectSitePlans && this.project.projectSitePlans.length > 0){
        this.project.projectSitePlans.forEach(plan => {
          if(plan.listOfPlanDevices && plan.listOfPlanDevices.length > 0){
            selectedDevices.push(...plan.listOfPlanDevices)
          }
        })
      }
      await this.deselectAllDevices()
      await this.selectDevices(selectedDevices)
    },
    async selectAllSites(){
      console.log('Project select all:', this.project)
      let selectedSites = []
      if(this.project && this.project.projectSitePlans && this.project.projectSitePlans.length > 0){
            selectedSites.push(...this.project.projectSitePlans)
      }
      await this.deselectAllSites()
      await this.selectSites(selectedSites)
    },
    async onSiteBulkAction(data) {
      console.log('On site bulk action: ', data)
      data.site.listOfPlanDevices.forEach(device => {
        device.siteName = data.site.siteName
        device.color = this.planStatusColor(data.site.status)
      })
      await this.deselectAllDevices()
      await this.selectDevices(data.site.listOfPlanDevices)
      this.$refs.editDevices.show(true, data.action, true)
    },
    async onEditDevice(device) {
      console.log('On edit device: ', device)
      await this.deselectAllDevices()
      await this.selectDevice(device)
      this.$refs.editDevices.show(true, 'location', true)
    },
    async onAddEditMaintenance(device) {
      await this.deselectAllDevices()
      await this.selectDevice(device)
      this.$refs.addEditMaintenance.show(true, 'location', true)
    },
    onHistoryClicked(object) {
      let name = object ? object.name ? object.name : object.siteName ? object.siteName : 'Undefined' : 'Undefined'
      this.$refs.historyComponent.show(true, name, object._id)
    },
    planStatusColor(name) {
      if (this.$store.getters.getSettings) {
        let status = this.$store.getters.getSettings.planStatuses.find(status => status.name === name)
        if (status)
          return status.color
      }

      return '#ffffff'
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
  computed: {
    ...mapGetters({
      project: 'projectsModule/getSelectedProject',
      selectedDevices: 'projectsModule/getSelectedDevices',
      selectedSites: 'projectsModule/getSelectedSites'
    }),
    isUnfinished() {
      if (this.project && this.project.projectSitePlans) {
        let planningPlans = this.project.projectSitePlans.filter(sitePlan => sitePlan.status === 'PLANNING')
        return planningPlans.length > 0
      }

      return false
    }
  }

}
</script>

<style lang="scss" scoped>
</style>