<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <add-qa-validation ref="addQaValidation"></add-qa-validation>
    <div class="box m-0 mb-1 mt-4">
      <div class="columns">
        <div class="column p-0 pl-3">
          <div class="is-size-7 has-text-grey-light mt-1">Device</div>
          <div class="has-text-weight-semibold">{{ selectedDevice.name }}</div>
        </div>
        <div class="column p-0 pl-2 is-2">
          <div class="is-size-7 has-text-grey-light mt-1">Site</div>
          <div class="has-text-weight-semibold">{{ selectedDevice.siteName }}</div>
        </div>
        <div class="column p-0 is-3">
          <div class="column m-0 p-0 pr-2 pl-2">
            <div class="is-size-7 has-text-grey-light mt-1">Processing period/s</div>
            <div class="is-flex">
              <div v-if="getPeriods().length > 0" class="is-flex">
                <div v-for="data in getPeriods()" :key="data.dates" class="mr-3">
                  <div class="is-flex">
                <b-icon icon="calendar" size="is-small" class="mr-1" style="margin-top: 2px"></b-icon>
                <div class="has-text-weight-normal is-size-7">{{ data.dates }}</div>
                  </div>
                <div class="is-flex is-flex-direction-column ml-0">
                  <div v-for="timeRange in data.times" :key="timeRange">
                    <div class="is-flex">
                      <b-icon icon="clock-outline" size="is-small" class="ml-0 mr-1" style="margin-top: 2px"></b-icon>
                      <div class="has-text-weight-normal is-size-7">{{ timeRange }}</div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
              <div v-else-if="processingPeriod" class="is-flex">
                <b-icon icon="calendar" size="is-small" class="mr-1" style="margin-top: 2px"></b-icon>
                <div class="has-text-weight-normal is-size-7">{{ processingPeriod }}</div>
                <div class="is-flex is-flex-direction-column ml-4">
                  <div v-for="timeRange in processingTimes" :key="timeRange">
                    <div class="is-flex">
                      <b-icon icon="clock-outline" size="is-small" class="ml-2 mr-1" style="margin-top: 2px"></b-icon>
                      <div class="has-text-weight-normal is-size-7">{{ timeRange }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="is-size-6 has-text-weight-normal has-text-warning">Undefined</div>
            </div>
          </div>
        </div>
        <hr class="m-0 has-background-grey-light mt-auto mb-auto mr-5" style="height: 56px;width: 1px">
        <div class="column p-0 pl-2">
          <div class="is-size-7 has-text-grey-light mt-1">QA by</div>
          <div class="has-text-weight-semibold">{{ userFullName }}</div>
          <div class="is-size-7 has-text-grey-light" style="line-height: 1">@ {{ atDate }}</div>
        </div>
        <div class="column p-0 pl-2 is-flex is-2">
          <div class="ml-auto mr-auto">
            <div class="is-size-7 has-text-grey-light mt-1">Comment</div>
            <div class="is-size-7 line-ellipsis">{{comment}}</div>
          </div>
        </div>
        <div class="column p-0 pl-2 is-flex">
          <div class="ml-auto mr-auto">
          <div class="is-size-7 has-text-grey-light mt-1">QA Status</div>
          <div class="has-text-weight-semibold">
            <b-tag class="mt-1" :type="statusColor">{{ status }}</b-tag>
          </div>
          </div>
        </div>

        <div class="column p-0 pl-2 is-flex">
          <div class="ml-auto">
          <div class="is-size-7 has-text-grey-light mt-1">Vehicles</div>
          <b-taglist class="mt-1" attached>
            <b-tag type="is-dark">Version</b-tag>
            <b-tag type="is-info">{{ vehicleDataVersion }}</b-tag>
          </b-taglist>
          </div>
        </div>
        <div class="column p-0 pl-2 is-flex">
          <div class="mr-1">
            <div class="is-size-7 has-text-grey-light mt-1">Pedestrians</div>
            <b-taglist class="mt-1" attached>
              <b-tag type="is-dark">Version</b-tag>
              <b-tag type="is-info">{{ pedestriansDataVersion }}</b-tag>
            </b-taglist>
          </div>
        </div>
        <view-guard :permission="'qa-validation:create'">
        <div class="column is-flex" style="max-width: 96px">
          <b-tooltip label="Change Data Status" :delay="1000" type="is-dark" position="is-left"
                     class="ml-auto mt-auto mb-auto mr-1">
            <b-button type="is-success" icon-left="pencil" @click="onChangeStatus">
            </b-button>
          </b-tooltip>
        </div>
        </view-guard>
        <!--        <hr class="m-0 has-background-grey-light mt-auto mb-auto mr-1" style="height: 56px;width: 1px">
                <div class="column is-flex" style="max-width: 56px">
                  <b-tooltip label="Close Device View" :delay="1000" type="is-dark" position="is-left"
                             class="mt-auto mb-auto">
                    <b-button icon-left="close" size="is-small" @click="onCloseDevice">
                    </b-button>
                  </b-tooltip>
                </div>-->
      </div>
    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {getDateRange, getDateTime, parseTimeRanges} from "@/utils/utils";
import AddQaValidation from "@/modules/qa-module/components/add-qa-validation/add-qa-validation.vue";
import ViewGuard from "@/components/view-guard/view-guard.vue";

export default {
  name: "device-info",
  components: {ViewGuard, AddQaValidation},
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    ...mapActions({
      setSelectedQaDevice: 'qaModule/setSelectedQaDevice'
    }),
    onCloseDevice() {
      this.setSelectedQaDevice(null)
    },
    async onChangeStatus() {
      this.$refs.addQaValidation.show(true)
    },
    getPeriods(){
      let data = []
      if (this.selectedDevice && this.selectedDevice.processingPeriods && this.selectedDevice.processingPeriods.length > 0) {
        this.selectedDevice.processingPeriods.forEach(period => {
          if(period.dateRange && period.dateRange.length > 1){
            data.push({
              dates: getDateRange(period.dateRange),
              times: parseTimeRanges(period.timeRanges)
            })
          }
        })
      }
      return data
    }
  },
  computed: {
    ...mapGetters({
      selectedDevice: 'qaModule/getSelectedQaDevice',
      getUser: 'getUserById'
    }),
    status() {
      if (this.selectedDevice && this.selectedDevice.qaValidation) {
        if (this.selectedDevice.qaValidation.qaStatus === 'PASS') {
          return 'Pass'
        } else if (this.selectedDevice.qaValidation.qaStatus === 'FAIL') {
          return 'Fail'
        } else if (this.selectedDevice.qaValidation.qaStatus === 'PASS_WITH_CHANGES') {
          return 'Pass With Changes'
        }
      }

      return '-'
    },
    statusColor() {
      if (this.selectedDevice && this.selectedDevice.qaValidation) {
        if (this.selectedDevice.qaValidation.qaStatus === 'PASS') {
          return 'is-success'
        } else if (this.selectedDevice.qaValidation.qaStatus === 'FAIL') {
          return 'is-danger'
        } else if (this.selectedDevice.qaValidation.qaStatus === 'PASS_WITH_CHANGES') {
          return 'is-info'
        }
      }
      return ''
    },
    userFullName() {
      if (this.selectedDevice && this.selectedDevice.qaValidation) {
        return this.getUser(this.selectedDevice.qaValidation.createdBy).fullName
      }

      return '-'
    },
    atDate() {
      if (this.selectedDevice && this.selectedDevice.qaValidation && this.selectedDevice.qaValidation.createdAt) {
        return getDateTime(new Date(this.selectedDevice.qaValidation.createdAt))
      }

      return '-'
    },
    vehicleDataVersion() {
      if (this.selectedDevice && this.selectedDevice.qaValidation) {
        return this.selectedDevice.qaValidation.vehicleDataVersion
      }

      return '-'
    },
    pedestriansDataVersion() {
      if (this.selectedDevice && this.selectedDevice.qaValidation) {
        return this.selectedDevice.qaValidation.pedestrianDataVersion
      }

      return '-'
    },
    comment() {
      if (this.selectedDevice && this.selectedDevice.qaValidation && this.selectedDevice.qaValidation.comment) {
        return this.selectedDevice.qaValidation.comment
      }

      return '-'
    },
    processingPeriod() {
      if (this.selectedDevice && this.selectedDevice.processingPeriod && this.selectedDevice.processingPeriod.dateRange && this.selectedDevice.processingPeriod.dateRange.length > 1) {
        return getDateRange(this.selectedDevice.processingPeriod.dateRange)
      }

      return null
    },
    processingTimes() {
      if (this.selectedDevice && this.selectedDevice.processingPeriod && this.selectedDevice.processingPeriod.dateRange && this.selectedDevice.processingPeriod.dateRange.length > 1) {
        return parseTimeRanges(this.selectedDevice.processingPeriod.timeRanges)
      }

      return null
    },
  }
}
</script>

<style scoped lang="scss">

.line-ellipsis {
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  max-height: 3em;
  line-height: 1em;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>