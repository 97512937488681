import Vue from "vue";

export default {
    setQueryData: async ({commit}, queryData) => {
        commit('setQueryData', queryData)
    },
    setSelectedQaProject: async ({commit}, selectedProject) => {
        if (selectedProject) {
            let config = {
                method: 'get',
                url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/qa-report',
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {projectId: selectedProject._id},
            };
            return await Vue.prototype.$http(config)
                .then((response) => {
                    console.log('Get project response: ', response)
                    if (response.status === 200) {
                        commit('setSelectedQaProject', response.data)
                    }
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });

        } else {
            commit('setSelectedQaProject', null)
            commit('setSelectedQaDevice', null)
        }
    },
    getQaTableData: async ({commit}, data) =>  {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/user-counts',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {dataDeviceId: data.deviceId, startDate: data.startDate, endDate: data.endDate, userType: 'Vehicle'},
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Get project response: ', response)
                if (response.status === 200) {
                    commit('setQaData', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    getPedestriansQaTableData: async ({commit}, data) =>  {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/user-counts',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {dataDeviceId: data.deviceId, startDate: data.startDate, endDate: data.endDate, userType: 'Pedestrian'},
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Get pedestrians response: ', response)
                if (response.status === 200) {
                    commit('setPedestriansQaData', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    addQaValidation: async ({commit}, data) =>  {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/qa-validation',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data,
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Add qa validation response: ', response)
                if (response.status === 200) {
                    commit('setQaValidationData', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    changeDataSet: async ({commit}, data) =>  {
        let config = {
            method: 'put',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/road-user-counts',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data,
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Change data set response: ', response)
                if (response.status === 200) {
                    commit('setDataDeviceAvailableDatesAndCharts', {dataDeviceId: data.dataDeviceId, responseData: response.data})
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    setSelectedQaDevice: async ({commit}, selectedQaDevice) => {
        if (selectedQaDevice) {
            commit('setSelectedQaDevice', selectedQaDevice)
        } else {
            commit('setSelectedQaDevice', null)
        }

        commit('setQaData', [])
        commit('resetQaTableHeaderData')
        commit('setPedestriansQaData', [])
        commit('resetPedestriansQaTableHeaderData')
    },
    setQaTableHeaderData: async ({commit}, newData) => {
        commit('setQaTableHeaderData', newData)
    },
    resetQaTableHeaderData: async ({commit}) => {
        commit('resetQaTableHeaderData')
    },
    setPedestriansQaTableHeaderData: async ({commit}, newData) => {
        commit('setPedestriansQaTableHeaderData', newData)
    },
    resetPedestriansQaTableHeaderData: async ({commit}) => {
        commit('resetPedestriansQaTableHeaderData')
    },
    setQaLegendSelection: async ({commit}, selection) => {
        commit('setQaLegendSelection', selection)
    }
}

