<template>
  <div>
    <transition name="fade">
      <floating-view v-if="selectedSites.length > 0" element-id="123927003958" :width="290">
        <div class="is-flex is-flex-direction-row pt-3">
<!--          <b-tooltip label="Edit Locations" class="ml-auto mr-4" :delay="1000" type="is-dark">
            <a v-on:click.stop @click="onClick('location')">
              <b-icon icon="map-marker">
              </b-icon>
            </a>
          </b-tooltip>-->
          <b-tooltip label="Edit Installation Date" class="ml-auto mr-4" :delay="1000" type="is-dark">
            <a v-on:click.stop @click="onClick('installDate')">
              <b-icon icon="calendar-check">
              </b-icon>
            </a>
          </b-tooltip>
          <b-tooltip label="Edit Decommission Date" class="mr-4" :delay="1000" type="is-dark">
            <a v-on:click.stop @click="onClick('decommissionDate')">
              <b-icon icon="calendar-remove">
              </b-icon>
            </a>
          </b-tooltip>
          <b-tooltip label="Edit Installation Crew" class="mr-4" :delay="1000" type="is-dark">
            <a v-on:click.stop @click="onClick('installCrew')">
              <b-icon icon="account-multiple-check">
              </b-icon>
            </a>
          </b-tooltip>
          <b-tooltip label="Edit Decommission Crew" class="mr-4" :delay="1000" type="is-dark">
            <a v-on:click.stop @click="onClick('decommissionCrew')">
              <b-icon icon="account-multiple-minus">
              </b-icon>
            </a>
          </b-tooltip>
          <b-tooltip label="Select All Sites" class="mr-5" :delay="1000" type="is-dark">
            <a v-on:click.stop @click="onClick('selectAll')">
              <b-icon icon="select-all">
              </b-icon>
            </a>
          </b-tooltip>
          <b-tooltip label="Clear selection" class="mr-auto" :delay="1000" type="is-dark">
            <a v-on:click.stop @click="onClick('clear')">
              <b-icon icon="close">
              </b-icon>
            </a>
          </b-tooltip>
        </div>
        <div class="mt-4 is-flex ml-auto mr-auto selected-devices-background box">
          <div class="ml-auto mr-auto is-size-7">Selected Sites: <span class="has-text-weight-bold">{{selectedSites.length}}</span></div>
        </div>
      </floating-view>
    </transition>
  </div>
</template>

<script>
import FloatingView from "@/components/floating-view/floating-view.vue";
import {mapGetters} from "vuex";

export default {
  name: "floating-menu-sites",
  components: {FloatingView},
  data() {
    return {
      isVisible: false
    }
  },
  methods: {
    onClick(action) {
      console.log('On click change: ', action)
      this.$emit('onBulkActionClicked', action)
    },
  },

  computed: {
    ...mapGetters({
      selectedSites: 'projectsModule/getSelectedSites'
    }),
  }
}
</script>

<style scoped>
.selected-devices-background{
  height: 16px !important;
  border-radius: 16px;
  background: white;
  width: 150px;
  padding: 0 0 20px 0;
}

</style>