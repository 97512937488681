/**
 * Created by Sale on 10/28/2024
 */
import {Role} from "@/utils/utils";
import dataProcessingPage from "@/modules/data-processing-module/views/data-processing-page/data-processing-page.vue";
import videoCalibrationPage
    from "@/modules/data-processing-module/views/video-calibration-page/video-calibration-page.vue";

const Module = () => import("./Module.vue");

const moduleRoute = {
    path: "/data-processing",
    component: Module,
    children: [
        {
            path: '/',
            name: 'data-processing',
            meta: {
                product: 'Realite Admin',
                title: 'Data Processing',
                isSubCategory: false,
                roles: [Role.ADMIN, Role.TRAFFIC_ENG],
                permission:'data-processing:get-report',
                topBarTitle: '',
                menuPriority: 6,
                hasMenu: true,
                hasInternalTransition: false
            },
            component: dataProcessingPage
        },
        {
            path: '/video-calibration/:projectId?/:dataDeviceId?',
            name: 'video-calibration',
            meta: {
                product: 'Realite Admin',
                title: 'Video Calibration',
                isSubCategory: true,
                roles: [Role.ADMIN, Role.TRAFFIC_ENG],
                permission:'data-processing:list-video-calibrations',
                topBarTitle: 'Video Calibration',
                menuPriority: 6,
                hasMenu: true,
                hasInternalTransition: false
            },
            props: true,
            component: videoCalibrationPage
        }
    ]
};

export default router => {
    router.addRoutes([moduleRoute]);
};
