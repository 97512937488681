import Vue from "vue";

export default {
    getAllProjects: async ({commit}) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/project-list-v3',
            headers: {
                'Content-Type': 'application/json'
            }
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Get all projects response: ', response)
                if (response.status === 200) {
                    commit('setAllProjects', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    getUsers: async ({commit}, filter) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-uac/user-list',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {},
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Get users response: ', response)
                if (response.status === 200) {
                    //commit('setAllProjects', response.data)
                    commit('setUsers', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    getApps: async ({commit}) => {
        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-uac/app-list',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {},
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Get apps response: ', response)
                if (response.status === 200) {
                    commit('setApps', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    getRoles: async ({commit}) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-uac/role-list',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {},
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Get aroles response: ', response)
                if (response.status === 200) {
                    commit('setRoles', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    getPermissions: async ({commit}) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-uac/permission-list',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {},
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Get permissions response: ', response)
                if (response.status === 200) {
                    commit('setPermissions', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    setUsersTableHeaderData: async ({commit}, newData) => {
        commit('setUsersTableHeaderData', newData)
    },
    resetUsersTableHeaderData: async ({commit}) => {
        commit('resetUsersTableHeaderData')
    },
    setRolesTableHeaderData: async ({commit}, newData) => {
        commit('setRolesTableHeaderData', newData)
    },
    resetRolesTableHeaderData: async ({commit}) => {
        commit('resetRolesTableHeaderData')
    },
    setPermissionsTableHeaderData: async ({commit}, newData) => {
        commit('setPermissionsTableHeaderData', newData)
    },
    resetPermissionsTableHeaderData: async ({commit}) => {
        commit('resetPermissionsTableHeaderData')
    },
    //Roles
    addRole: async ({commit}, payload) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-uac/role',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Add role response: ', response.data)
                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    commit('addNewRole', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    updateRole: async ({commit}, payload) => {
        let config = {
            method: 'put',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-uac/role',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Update role response: ', response.data)
                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    commit('updateRole', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    deleteRole: async ({commit}, id) => {
        let config = {
            method: 'delete',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-uac/role',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {id: id}
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Delete role response: ', response.data)
                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    //role id is returned
                    commit('deleteRole', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    //Apps
    updateApp: async ({commit}, payload) => {
        let config = {
            method: 'put',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-uac/app',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Update app response: ', response.data)
                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    commit('updateApp', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    addApp: async ({commit}, payload) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-uac/app',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Add app response: ', response.data)
                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    commit('addNewApp', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    deleteApp: async ({commit}, id) => {
        let config = {
            method: 'delete',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-uac/app',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {id: id}
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Delete app response: ', response.data)
                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    //app id is returned
                    commit('deleteApp', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    //UAC
    addUac: async ({commit}, payload) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-uac/uac',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Add uac response: ', response.data)
                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    commit('mergeUserUac', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    updateUac: async ({commit}, payload) => {
        let config = {
            method: 'put',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-uac/uac',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Update uac response: ', response.data)
                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    commit('mergeUserUac', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    updateMultipleUac: async ({commit}, payload) => {
        let config = {
            method: 'put',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-uac/uac-multiple',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Update multiple uac response: ', response.data)
                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    commit('mergeMultipleUserUac', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    //Permissions
    addPermission: async ({commit}, payload) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-uac/permission',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Add permission response: ', response.data)
                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    commit('addNewPermission', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    updatePermission: async ({commit}, payload) => {
        let config = {
            method: 'put',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-uac/permission',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Update permission response: ', response.data)
                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    commit('updatePermission', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    deletePermission: async ({commit}, id) => {
        let config = {
            method: 'delete',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-uac/permission',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {id: id}
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Delete permission response: ', response.data)
                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    //role id is returned
                    commit('deletePermission', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    //Users
    deleteUserUac: async ({commit}, id) => {
        let config = {
            method: 'delete',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-uac/uac',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {id: id}
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Delete UAC response: ', response.data)
                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    //app id is returned
                    commit('deleteUserUac', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    disableUser: async ({commit}, id) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-uac/user-disable',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {id: id}
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Disable UAC response: ', response.data)
                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    //app id is returned
                    commit('disableUser', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },

}

