export default {
    setAllProjects(state, items) {
        state.allProjects = items
    },
    setUsers(state, users) {
        state.users = users
    },
    setApps(state, apps) {
        state.apps = apps
    },
    setRoles(state, roles) {
        state.roles = roles
    },
    setPermissions(state, permissions) {
        state.permissions = permissions
    },
    addNewRole(state, role) {
        state.roles.push(role)
    },
    deleteRole(state, roleId) {
        let index = state.roles.findIndex((rl) => rl._id === roleId)
        if (index !== -1) {
            state.roles.splice(index, 1)
        }
    },
    updateRole(state, role) {
        let index = state.roles.findIndex((rl) => rl._id === role._id)
        if (index !== -1) {
            state.roles.splice(index, 1, role)
        }
    },
    addNewApp(state, app) {
        state.apps.push(app)
    },
    updateApp(state, app) {
        let index = state.apps.findIndex((ap) => ap._id === app._id)
        if (index !== -1) {
            state.apps.splice(index, 1, app)
        }
    },
    deleteApp(state, appId) {
        let index = state.apps.findIndex((app) => app._id === appId)
        if (index !== -1) {
            state.apps.splice(index, 1)
        }
    },
    addNewPermission(state, permission) {
        state.permissions.push(permission)
    },
    updatePermission(state, permission) {
        let index = state.permissions.findIndex((perm) => perm._id === permission._id)
        if (index !== -1) {
            state.permissions.splice(index, 1, permission)
        }
    },
    deletePermission(state, permId) {
        let index = state.permissions.findIndex((perm) => perm._id === permId)
        if (index !== -1) {
            state.permissions.splice(index, 1)
        }
    },
    mergeUserUac(state, uac) {
        let index = state.users.findIndex((user) => user.id === uac.userId)
        if (index !== -1) {
            let newUser = JSON.parse(JSON.stringify(state.users[index]))
            newUser.uac = uac
            state.users.splice(index, 1, newUser)
        }
    },
    deleteUserUac(state, uacId) {
        let index = state.users.findIndex((user) => user.uac && user.uac._id === uacId)
        if (index !== -1) {
            let newUser = JSON.parse(JSON.stringify(state.users[index]))
            newUser.uac = {}
            state.users.splice(index, 1, newUser)
        }
    },
    disableUser(state, userId) {
        let index = state.users.findIndex((user) => user.id === userId)
        if (index !== -1) {
            let newUser = JSON.parse(JSON.stringify(state.users[index]))
            newUser.status = 'Disabled'
            state.users.splice(index, 1, newUser)
        }
    },
    mergeMultipleUserUac(state, uacs) {
        if (uacs && uacs.length > 0) {
            uacs.forEach(uac => {
                let index = state.users.findIndex((user) => user.id === uac.userId)
                if (index !== -1) {
                    let newUser = JSON.parse(JSON.stringify(state.users[index]))
                    newUser.uac = uac
                    state.users.splice(index, 1, newUser)
                }
            })
        }
    },
    setUsersTableHeaderData(state, newData) {
        state.usersTableHeaderData = newData
        console.log('Table header data: ', state.usersTableHeaderData)
    },
    resetUsersTableHeaderData(state) {
        state.usersTableHeaderData = {
            activeSorted: {
                field: '',
                order: ''
            },
            activeFilterField: '',
            filters: {roles: [], name: null, email: null}
        }
    },
    setRolesTableHeaderData(state, newData) {
        state.rolesTableHeaderData = newData
        console.log('Table header data: ', state.rolesTableHeaderData)
    },
    resetRolesTableHeaderData(state) {
        state.rolesTableHeaderData = {
            activeSorted: {
                field: '',
                order: ''
            },
            activeFilterField: '',
            filters: {name: null, app: null, permissions: null, description: null}
        }
    },
    setPermissionsTableHeaderData(state, newData) {
        state.permissionsTableHeaderData = newData
    },
    resetPermissionsTableHeaderData(state) {
        state.permissionsTableHeaderData = {
            activeSorted: {
                field: '',
                order: ''
            },
            activeFilterField: '',
            filters: {name: null, description: null, functionName: null}
        }
    },
    clearAllData(state) {
        state.allProjects = []
        state.users = []
        state.apps = []
        state.roles = []
        state.permissions = []
        state.usersTableHeaderData = {
            activeSorted: {
                field: '',
                order: ''
            },
            activeFilterField: '',
            filters: {roles: [], name: null, email: null, resources: null}
        }
        state.rolesTableHeaderData = {
            activeSorted: {
                field: '',
                order: ''
            },
            activeFilterField: '',
            filters: {name: null, appName: [], permissionNames: [], description: null}
        }
        state.permissionsTableHeaderData = {
            activeSorted: {
                field: '',
                order: ''
            },
            activeFilterField: '',
            filters: {name: null, description: null, functionName: null}
        }
    }
};
