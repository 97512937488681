import { render, staticRenderFns } from "./pedestrians-qa-table.vue?vue&type=template&id=6681dc0b&scoped=true"
import script from "./pedestrians-qa-table.vue?vue&type=script&lang=js"
export * from "./pedestrians-qa-table.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6681dc0b",
  null
  
)

export default component.exports