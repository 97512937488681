<template>
  <div>
    <div class="is-flex">
      <div class="is-size-6 has-text-grey-light has-text-weight-normal mb-2">{{ deviceType }} {{ title }}</div>
      <div class="ml-auto">
        <b-field expanded class="ml-3" style="min-width: 200px" label="Device configuration" label-position="on-border">
          <b-select size="is-small" expanded placeholder="Select template" rounded @input="onTemplateChanged"
                    v-model="selectedConfiguration">
            <option v-for="settingsTemplate in settingsTemplates" :value="settingsTemplate" :key="settingsTemplate._id">
              {{ settingsTemplate.name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <b-tooltip position="is-left" label="Clear fields" class="ml-2" :delay="1000" type="is-dark">
        <b-button @click="onClearFields" size="is-small" type="is-primary" outlined icon-right="close"></b-button>
      </b-tooltip>
    </div>
    <hr class="p-0 m-0 mt-1">

    <div class="columns mt-1" v-if="configuration.length > 0" :key="confChanged">
      <div class="column pt-3 pb-3 pr-2 is-3" v-if="configuration.length > 0">
        <b-field>
          <b-input :placeholder="configuration[0].name" rounded v-model="configuration[0].value" @input="onDataChanged"
                   size="is-small"></b-input>
        </b-field>
      </div>
      <div class="column pt-3 pb-3 pr-2 is-3" v-if="configuration.length > 1">
        <b-field>
          <b-input :placeholder="configuration[1].name" rounded v-model="configuration[1].value" @input="onDataChanged"
                   size="is-small"></b-input>
        </b-field>
      </div>
      <div class="column pt-3 pb-3 pr-2 is-3" v-if="configuration.length > 2">
        <b-field>
          <b-input :placeholder="configuration[2].name" rounded v-model="configuration[2].value" @input="onDataChanged"
                   size="is-small"></b-input>
        </b-field>
      </div>
      <div class="column pt-3 pb-3 pr-2 is-3" v-if="configuration.length > 3">
        <b-field>
          <b-input :placeholder="configuration[3].name" rounded v-model="configuration[3].value" @input="onDataChanged"
                   size="is-small"></b-input>
        </b-field>
      </div>
    </div>

    <div class="columns" v-if="configuration.length > 4">
      <div class="column pt-0 pb-4 pr-2 is-3" v-if="configuration.length > 4">
        <b-field>
          <b-input :placeholder="configuration[4].name" rounded v-model="configuration[4].value" @input="onDataChanged"
                   size="is-small"></b-input>
        </b-field>
      </div>
      <div class="column pt-0 pb-4 pr-2 is-3" v-if="configuration.length > 5">
        <b-field>
          <b-input :placeholder="configuration[5].name" rounded v-model="configuration[5].value" @input="onDataChanged"
                   size="is-small"></b-input>
        </b-field>
      </div>
      <div class="column pt-0 pb-4 pr-2 is-3" v-if="configuration.length > 6">
        <b-field>
          <b-input :placeholder="configuration[6].name" rounded v-model="configuration[6].value" @input="onDataChanged"
                   size="is-small"></b-input>
        </b-field>
      </div>
      <div class="column pt-0 pb-4 pr-2 is-3" v-if="configuration.length > 7">
        <b-field>
          <b-input :placeholder="configuration[7].name" rounded v-model="configuration[7].value" @input="onDataChanged"
                   size="is-small"></b-input>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import {DeviceType, TemplateType} from "@/utils/utils";
import {mapGetters} from "vuex";

export default {
  name: "configuration-input",
  computed: {
    DeviceType() {
      return DeviceType
    },
    ...mapGetters({
      settings: 'getSettings'
    }),
    settingsTemplates() {
      if (this.settings && this.settings.todoTemplates) {
        return this.settings.todoTemplates.filter(template => template.deviceType === this.deviceType && template.templateType === TemplateType.SETTINGS_PROFILE)
      }

      return []
    }
  },
  props: {
    deviceType: {
      type: String,
      required: true
    },
    device: {
      type: Object
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      configuration: [],
      confChanged: false,
      selectedConfiguration: null
    }
  },
  mounted() {
    console.log('Loaded configuration 0: ', this.settings)
    if (this.settings.configurations[this.deviceType]) {
      this.configuration = JSON.parse(JSON.stringify(this.settings.configurations[this.deviceType]))
      if (this.device && this.device.settingsProfile) {
        this.configuration.forEach(conf => {
          conf.value = this.device.settingsProfile[conf.field]
        })
      }
      console.log('Loaded configuration: ', this.configuration)
    } else {
      console.error('Configuration not exists for device type:', this.deviceType)
    }
  },
  methods: {
    onDataChanged() {
      this.$emit('onDataChanged', {configuration: this.configuration, device: this.device})
    },
    onTemplateChanged(settingsTemplate) {
      console.log('On template changed:', settingsTemplate)
      this.configuration.forEach(conf => {
        let fromTemplate = settingsTemplate.template.find(templ => templ.name === conf.name)
        console.log('Find template', fromTemplate)
        if (fromTemplate) {
          conf.value = fromTemplate.description
        }
      })

      console.log('New configuration:', this.configuration)
      this.confChanged = !this.confChanged
      this.$emit('onDataChanged', {configuration: this.configuration, device: this.device})
    },
    onClearFields() {
      this.configuration.forEach(conf => {
        conf.value = null
      })
      this.selectedConfiguration = null
      this.confChanged = !this.confChanged
    }
  }
}
</script>

<style scoped>

</style>