import {hasSubArray} from "@/utils/utils";

export default {
    getSelectedQaProject: (state) => {
        return state.selectedQaProject
    },
    getSelectedQaDevice:(state) => {
       return state.selectedQaDevice
    },
    getFilteredQaProjects: (state, getters, _, rootGetters) => {
        let allProjects = rootGetters['projectsModule/getAllProjects']
        if (allProjects.length > 0) {
            let filteredProjects = allProjects
            if (state.searchQueryData.query.length > 0) {
                filteredProjects = allProjects.filter(project => project.name.toLowerCase().includes(state.searchQueryData.query.toLowerCase()))
            }
            if (state.searchQueryData.active.value !== 'ALL') {
                filteredProjects = filteredProjects.filter(project => project.status === state.searchQueryData.active.value)
            }
            if (state.searchQueryData.projectType.value !== 'ALL') {
                filteredProjects = filteredProjects.filter(project => project.types.includes(state.searchQueryData.projectType.value))
            }

            if (state.searchQueryData.sort) {
                if (state.searchQueryData.sort.value === 'ASCENDING') {
                    filteredProjects = filteredProjects.sort((a, b) => (a.name > b.name) ? 1 : -1);
                } else {
                    filteredProjects = filteredProjects.sort((a, b) => (a.name > b.name) ? -1 : 1);
                }
            }
            return filteredProjects
        } else {
            return allProjects
        }
    },
    getQueryData: (state) => {
        return state.searchQueryData
    },
    getQaData: (state) => {
        return state.qaData
    },
    getQaTableHeaderData: (state) => {
        return state.qaTableHeaderData
    },
    getQaTable: (state) => {
        let filters = state.qaTableHeaderData.filters
        console.log('Filters: ', filters)
        let table = []
        table = JSON.parse(JSON.stringify(state.qaData))
        let filteredTable = table.filter(dt => filters && filters.avgSpeed != null && filters.avgSpeed.length > 0 ? dt.avgSpeed && dt.avgSpeed.toString().length > 0 ? dt.avgSpeed.toString().toLowerCase().includes(filters.avgSpeed.toLowerCase()) : false : true)

        filteredTable = filteredTable.filter(dt => filters && filters.userType != null && filters.userType.length > 0 ? dt.userType && dt.userType.length > 0 ? dt.userType.toString().toLowerCase().includes(filters.userType.toLowerCase()) : false : true)
        filteredTable = filteredTable.filter(dt => filters && filters.direction != null && filters.direction.length > 0 ? dt.direction && dt.direction.length > 0 ? dt.direction.toString().toLowerCase().includes(filters.direction.toLowerCase()) : false : true)
        filteredTable = filteredTable.filter(dt => filters && filters.eventAt ? new Date(dt.eventAt).toLocaleDateString() === new Date(filters.eventAt).toLocaleDateString() : true)
        filteredTable = filteredTable.filter(dt => filters && filters.userClass && filters.userClass.length > 0 ? dt.userClass && dt.userClass.length > 0 ? filters.userClass.includes(dt.userClass) : false : true)

        return filteredTable
    },
    getPedestriansQaData: (state) => {
        return state.pedestriansQaData
    },
    getPedestriansQaTableHeaderData: (state) => {
        return state.pedestriansQaTableHeaderData
    },
    getPedestriansQaTable: (state) => {
        let filters = state.pedestriansQaTableHeaderData.filters
        console.log('Filters pedestrians: ', filters)
        let table = []
        table = JSON.parse(JSON.stringify(state.pedestriansQaData))
        console.log('Peds Table: ',table)



        let filteredTablePedestrians = table.filter(dt => filters && filters.avgSpeed != null && filters.avgSpeed.length > 0 ? dt.avgSpeed && dt.avgSpeed.toString().length > 0 ? dt.avgSpeed.toString().toLowerCase().includes(filters.avgSpeed.toLowerCase()) : false : true)

        filteredTablePedestrians = filteredTablePedestrians.filter(dt => filters && filters.userType != null && filters.userType.length > 0 ? dt.userType && dt.userType.length > 0 ? dt.userType.toString().toLowerCase().includes(filters.userType.toLowerCase()) : false : true)
        filteredTablePedestrians = filteredTablePedestrians.filter(dt => filters && filters.direction != null && filters.direction.length > 0 ? dt.direction && dt.direction.length > 0 ? dt.direction.toString().toLowerCase().includes(filters.direction.toLowerCase()) : false : true)
        filteredTablePedestrians = filteredTablePedestrians.filter(dt => filters && filters.eventAt ? new Date(dt.eventAt).toLocaleDateString() === new Date(filters.eventAt).toLocaleDateString() : true)
        filteredTablePedestrians = filteredTablePedestrians.filter(dt => filters && filters.userClass && filters.userClass.length > 0 ? dt.userClass && dt.userClass.length > 0 ? filters.userClass.includes(dt.userClass) : false : true)

        return filteredTablePedestrians
    },
    qaLegendSelection:(state) => {
        return state.qaLegendSelection
    }
}
