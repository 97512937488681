
export default {
    getSelectedProcessingProject: (state) => {
        return state.selectedProcessingProject
    },
    getFilteredProcessingProjects: (state, getters, _, rootGetters) => {
        let allProjects = rootGetters['projectsModule/getAllProjects']
        if (allProjects.length > 0) {
            let filteredProjects = allProjects
            if (state.searchQueryData.query.length > 0) {
                filteredProjects = allProjects.filter(project => project.name.toLowerCase().includes(state.searchQueryData.query.toLowerCase()))
            }
            if (state.searchQueryData.active.value !== 'ALL') {
                filteredProjects = filteredProjects.filter(project => project.status === state.searchQueryData.active.value)
            }
            if (state.searchQueryData.projectType.value !== 'ALL') {
                filteredProjects = filteredProjects.filter(project => project.types.includes(state.searchQueryData.projectType.value))
            }

            if (state.searchQueryData.sort) {
                if (state.searchQueryData.sort.value === 'ASCENDING') {
                    filteredProjects = filteredProjects.sort((a, b) => (a.name > b.name) ? 1 : -1);
                } else {
                    filteredProjects = filteredProjects.sort((a, b) => (a.name > b.name) ? -1 : 1);
                }
            }
            return filteredProjects
        } else {
            return allProjects
        }
    },
    getSelectedVideoCalibrations: (state) => {
        return state.selectedVideoCalibrations
    },
    getSelectedCalibration: (state) => {
        return state.selectedCalibration
    },
    getSelectedProcessingDevice: (state) => {
        return state.selectedProcessingDevice
    },
    dpLegendSelection:(state) => {
        return state.dpLegendSelection
    }
}
