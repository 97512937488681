/**
 * Created by Sale on 8/29/2024
 */
import {Role} from "@/utils/utils";
import liveMonitoringPage from "@/modules/live-monitoring-module/views/live-monitoring-page/live-monitoring-page.vue";

const Module = () => import("./Module.vue");

const moduleRoute = {
    path: "/monitoring",
    component: Module,
    children: [
        {
            path: '/',
            name: 'monitoring',
            meta: {
                product: 'Realite Admin',
                title: 'Monitoring',
                isSubCategory: false,
                roles: [Role.ADMIN, Role.TRAFFIC_ENG],
                permission:'report-provisioning:live-monitoring',
                topBarTitle: '',
                menuPriority: 4,
                hasMenu: true,
                hasInternalTransition: false
            },
            component: liveMonitoringPage
        }
    ]
};

export default router => {
    router.addRoutes([moduleRoute]);
};
