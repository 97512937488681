<template>
  <div>
    <div class="is-flex">
      <div class="is-size-6 has-text-grey-light has-text-weight-normal mb-2">{{ deviceType }} {{ title }}</div>
      <div class="ml-auto">
        <b-field expanded class="ml-3" label="Installation template" label-position="on-border">
          <b-select size="is-small" style="min-width: 200px" expanded placeholder="Select template" rounded
                    @input="onInstallTemplateChanged" v-model="selectedInstallConfiguration">
            <option v-for="settingsTemplate in settingsTemplates" :value="settingsTemplate" :key="settingsTemplate._id">
              {{ settingsTemplate.name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <b-tooltip position="is-left" label="Clear fields" class="ml-2" :delay="1000" type="is-dark">
        <b-button @click="onClearInstallFields" size="is-small" type="is-primary" outlined
                  icon-right="close"></b-button>
      </b-tooltip>
      <div class="ml-1">
        <b-field expanded class="ml-3" label="Decommission template" label-position="on-border">
          <b-select size="is-small" style="min-width: 200px" expanded placeholder="Select template" rounded
                    @input="onDecommissionTemplateChanged" v-model="selectedDecommissionConfiguration">
            <option v-for="settingsTemplate in settingsTemplates" :value="settingsTemplate" :key="settingsTemplate._id">
              {{ settingsTemplate.name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <b-tooltip position="is-left" label="Clear fields" class="ml-2" :delay="1000" type="is-dark">
        <b-button @click="onClearDecommissionFields" size="is-small" type="is-primary" outlined
                  icon-right="close"></b-button>
      </b-tooltip>
    </div>
    <hr class="p-0 m-0 mt-1">

    <div class="has-text-grey-light">Installation Todo List</div>
    <div class="is-flex" v-if="installConfiguration.length > 0" :key="confChanged + 'Inst'">
      <div class="pt-3 pb-3 pr-2 is-3" v-for="(conf, index) in installConfiguration" :key="conf.name">
        <b-tag rounded>{{ index + 1 }}. {{ conf.name }}</b-tag>
      </div>
    </div>
    <div class="has-text-grey-light">Decommission Todo List</div>
    <div class="is-flex" v-if="decommissionConfiguration.length > 0" :key="decommissionConfChanged + 'Dec'">
      <div class="pt-3 pb-3 pr-2 is-3" v-for="(conf, index) in decommissionConfiguration" :key="conf.name">
        <b-tag rounded>{{ index + 1 }}. {{ conf.name }}</b-tag>
      </div>
    </div>
  </div>
</template>

<script>
import {DeviceType, TemplateType} from "@/utils/utils";
import {mapGetters} from "vuex";

export default {
  name: "todo-list-input",
  props: {
    deviceType: {
      type: String,
      required: true
    },
    device: {
      type: Object
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      installConfiguration: [],
      decommissionConfiguration: [],
      selectedInstallConfiguration: null,
      selectedDecommissionConfiguration: null,
      confChanged: false,
      decommissionConfChanged: false
    }
  },
  mounted() {
    console.log('Loaded configuration 0: ', this.settings)
    if (this.device) {
      if (this.device.todos) {
        this.installConfiguration = JSON.parse(JSON.stringify(this.device.todos))
      }
      if (this.device.decommissionTodos) {
        this.decommissionConfiguration = JSON.parse(JSON.stringify(this.device.decommissionTodos))
      }
    }
  },
  computed: {
    DeviceType() {
      return DeviceType
    },
    ...mapGetters({
      settings: 'getSettings'
    }),
    settingsTemplates() {
      if (this.settings && this.settings.todoTemplates) {
        return this.settings.todoTemplates.filter(template => template.deviceType === this.deviceType && template.templateType === TemplateType.TODO)
      }

      return []
    }
  },
  methods: {
    onInstallTemplateChanged(settingsTemplate) {
      console.log('On template changed:', settingsTemplate)
      this.installConfiguration = JSON.parse(JSON.stringify(settingsTemplate.template))

      console.log('New configuration:', this.installConfiguration)
      this.confChanged = !this.confChanged
      this.$emit('onInstallDataChanged', {configuration: this.installConfiguration, device: this.device})
    },
    onClearInstallFields() {
      this.installConfiguration = []
      this.selectedInstallConfiguration = null
      this.confChanged = !this.confChanged
    },
    onDecommissionTemplateChanged(settingsTemplate) {
      console.log('On template changed:', settingsTemplate)
      this.decommissionConfiguration = JSON.parse(JSON.stringify(settingsTemplate.template))

      console.log('New configuration:', this.decommissionConfiguration)
      this.decommissionConfChanged = !this.decommissionConfChanged
      this.$emit('onDecommissionDataChanged', {configuration: this.decommissionConfiguration, device: this.device})
    },
    onClearDecommissionFields() {
      this.decommissionConfiguration = []
      this.selectedDecommissionConfiguration = null
      this.decommissionConfChanged = !this.decommissionConfChanged
    }

  }
}
</script>

<style scoped>

</style>