<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <div class="box m-0" style="background: #fafafa; padding: 0 0 128px 0;">
      <g-map-monitoring :selected-device-id="selectedDeviceId"></g-map-monitoring>
      <health-chart class="mb-6 mt-5" @deviceHover="onDeviceHover"></health-chart>
    </div>
  </div>
</template>

<script>
import GMapMonitoring from "@/modules/live-monitoring-module/components/g-map-monitoring/g-map-monitoring.vue";
import HealthChart from "@/modules/live-monitoring-module/components/health-chart/health-chart.vue";
import {mapGetters} from "vuex";

export default {
  name: "live-monitoring-page",
  components: {HealthChart, GMapMonitoring},
  data() {
    return {
      isLoading: false,
      selectedDeviceId: null
    }
  },
  methods: {
    onDeviceHover(id) {
      this.selectedDeviceId = id
    }
  },
  computed: {
    ...mapGetters({
      liveMonitoring: 'liveMonitoringModule/liveMonitoring',
    }),
  }
}
</script>

<style scoped>

</style>