export default {
    setSelectedSite(state, site) {
        state.selectedSite = site
    },
    setSelectedMonitoringProject(state, project) {
        state.selectedMonitoringProject = project
        if(project === null){
            state.monitoringData = null
        }
    },
    setQueryData(state, queryData) {
        state.searchQueryData = queryData
        console.log('Set query data mutation: ', state.searchQueryData)
    },
    setMonitoringData(state, data) {
        state.monitoringData = data
    }
}
