<template>
  <MglMarker ref="marker" v-if="item.location.coordinates"
             @update:coordinates="updateCoordinates"
             :draggable="drawMode === 'move'"
             :coordinates="item.location.coordinates"

             @click="markerClicked">
    <div slot="marker">
      <div v-if="item.type === DeviceType.CAMERA" :class="getDirectionClass(item)"
           :style="{'rotate': rotationRepresentation+'deg'}"></div>
      <div :class="getMarkerClass(item)" :style="{'background': item.currentStatus.color}" v-on:mousedown="mouseDown"
           v-on:mouseup="mouseUp"
           v-on:mousedown.right="mouseDownRight" v-on:mouseup.right="mouseUpRight">
        <b-icon style="font-size: 18px" :icon="getIcon(item)" size="is-small"></b-icon>
      </div>
    </div>
    <div v-if="drawMode === null && isSelected">
      <MglPopup ref="popup"
                :showed="showPopup"
                :closeOnClick="true"
                @close="onClosePopup"
                :coordinates="item.location.coordinates" anchor="bottom" :offset="16">
        <device-popup-content :device="item"></device-popup-content>
      </MglPopup>
    </div>

  </MglMarker>
</template>

<script>
import {MglMap, MglMarker, MglPopup} from "vue-mapbox";
import {DeviceType, getDirectionClass, getMarkerClass, getMarkerIcon} from "@/utils/utils";
import {mapGetters} from "vuex";
import DevicePopupContent from "@/modules/projects-module/components/device-popup-content/device-popup-content.vue";

export default {
  name: "my-marker",
  components: {MglMarker, MglPopup, DevicePopupContent},
  props: {
    item: {
      type: Object,
      required: true
    },
    drawMode: {
      type: String,
      required: false
    },
    mapRotation: {
      type: Number,
      default: 0
    },
    isSelected: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      //isSelected: false,
      rotation: this.item.rotation,
      rotationRepresentation: this.item.rotation,
      started: false,
      direction: false,
      showPopup: false
    }
  },
  mounted() {
    console.log('My marker device: ', this.item)
    console.log('Marker rotation: ', this.rotation)
    console.log('Marker device rotation: ', this.item.rotation)
    this.rotationRepresentation = this.rotation - this.mapRotation
    console.log('Marker device rotation VIEW: ', this.rotationRepresentation)
  },
  methods: {
    countDownTimer() {
      if (this.started) {
        this.direction ? this.rotation-- : this.rotation++;
        if (this.rotation < -180) {
          this.rotation = 180
        } else if (this.rotation > 180) {
          this.rotation = -180
        }

        this.rotationRepresentation = this.rotation - this.mapRotation

        console.log('Rotation:', this.rotation)
        setTimeout(() => {
          this.countDownTimer()
        }, 20)
      }
    },
    markerClicked(event) {
      console.log('Marker clicked my marker:', event)
      //this.isSelected = !this.isSelected
      if (this.drawMode === null) {
        this.$emit('onMarkerClicked', event)
      }
    },
    onClosePopup() {
      console.log('On close popup')
    },
    mouseDown(event) {
      console.log('Mouse down:', event)
      if (this.drawMode === 'rotate') {
        this.started = true
        this.direction = false
        this.countDownTimer()
      }

    },
    mouseUp(event) {
      console.log('Mouse up:', event)
      if (this.drawMode === 'rotate') {
        this.started = false
        this.$emit('onRotateFinished', {item: this.item, rotation: this.rotation})
      }
    },
    mouseDownRight(event) {
      console.log('Mouse down:', event)
      if (this.drawMode === 'rotate') {
        this.started = true
        this.direction = true
        this.countDownTimer()
      }
    },
    mouseUpRight(event) {
      console.log('Mouse up:', event)
      if (this.drawMode === 'rotate') {
        this.started = false
        this.$emit('onRotateFinished', {item: this.item, rotation: this.rotation})
      }
    },
    getMarkerClass(item) {
      return getMarkerClass(this.isSelected)
    },
    getDirectionClass() {
      return getDirectionClass(this.getMapStyle, this.isSelected)
    },
    getIcon(item) {
      return getMarkerIcon(item.type)
    },
    updateCoordinates(event) {
      console.log('Update coordinates:', event)
      let ev = {
        item: this.item,
        coordinates: {lng: event[0], lat: event[1]}
      }
      this.$emit('onUpdateCoordinates', ev)
    },
  },
  computed: {
    DeviceType() {
      return DeviceType
    },
    ...mapGetters({
      getMapStyle: 'getMapStyle'
    }),
  },
  watch: {
    mapRotation() {
      console.log('Map rotate: ', this.mapRotation)
      this.rotationRepresentation = this.rotation - this.mapRotation
      console.log('Map rotate REPRESENTATION: ', this.rotationRepresentation)
    },
    isSelected() {
      console.log('Is selected: ', this.isSelected)
      /*      setTimeout(()=>{
              this.showPopup = this.isSelected
            }, 100)*/

    }
  },
}
</script>

<style scoped>

</style>