<template>
  <div class="columns m-0 p-0">
    <div class="column pl-0">
      <div class="box unselected" style="height: 100%">
        <div class="is-size-5 has-text-weight-semibold">Missing Files</div>
        <div class="is-flex is-flex-direction-column">
          <div class="columns mt-0 mb-0">
            <div class="is-size-7 has-text-grey-light column">FROM</div>
            <div class="is-size-7 has-text-grey-light column is-flex"><div class="ml-auto">TO</div></div>
          </div>
          <div v-for="(missing, index) in missingCollectionTimes"
               :key="missing.start + missing.end + index">
            <hr class="m-0 mb-2">
            <div class="columns pb-2">
              <div class="column">
                <div class="is-size-7 has-text-weight-semibold">{{
                    formatTimeInTimeZone(missing.start)
                  }}
                </div>
              </div>
              <div class="column is-flex">
                <div class="is-size-7 has-text-weight-semibold ml-auto">
                  {{ formatTimeInTimeZone(missing.end) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column pl-0">
      <div class="box unselected" style="height: 100%">
        <div class="is-size-5 has-text-weight-semibold">Incorrect Files</div>
        <div class="is-flex is-flex-direction-column">
          <div class="columns mt-0 mb-0">
            <div class="is-size-7 has-text-grey-light column">FROM</div>
            <div class="is-size-7 has-text-grey-light column">TO</div>
            <div class="is-size-7 has-text-grey-light column" style="max-width: 72px">DURATION</div>
          </div>
          <div v-for="(missing, index) in incorrectCollectionTimes"
               :key="missing.start + missing.end + index">
            <hr class="m-0 mb-2">
            <div class="columns pb-2">
              <div class="column">
                <div class="is-size-7 has-text-weight-semibold">{{
                    formatTimeInTimeZone(missing.start)
                  }}
                </div>
              </div>
              <div class="column">
              <div class="is-size-7 has-text-weight-semibold">
                {{ formatTimeInTimeZone(missing.end) }}
              </div>
              </div>
                <div class="column" style="max-width: 72px">
              <div class="is-size-7 has-text-weight-semibold">
                {{ missing.duration ? missing.duration.toFixed(0) : '-' }} s
              </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column pl-0">
      <div class="box unselected" style="height: 100%">
        <div class="is-size-5 has-text-weight-semibold">Outside range files</div>
        <div class="is-flex is-flex-direction-column">
          <div class="columns mt-0 mb-0">
            <div class="is-size-7 has-text-grey-light column">FROM</div>
            <div class="is-size-7 has-text-grey-light column">TO</div>
            <div class="is-size-7 has-text-grey-light column" style="max-width: 72px">DURATION</div>
          </div>
          <div v-for="(missing, index) in outsideRangeCollectionTimes"
               :key="missing.start + missing.end + index">
            <hr class="m-0 mb-2">
            <div class="columns pb-2">
              <div class="column">
                <div class="is-size-7 has-text-weight-semibold">{{
                    formatTimeInTimeZone(missing.start)
                  }}
                </div>
              </div>
              <div class="column">
                <div class="is-size-7 has-text-weight-semibold">
                  {{ formatTimeInTimeZone(missing.end) }}
                </div>
              </div>
              <div class="column" style="max-width: 72px">
                <div class="is-size-7 has-text-weight-semibold">
                  {{ missing.duration ? missing.duration.toFixed(0) : '-' }} s
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column pr-0">
      <div class="box unselected" style="height: 100%">
        <div class="is-size-5 has-text-weight-semibold">Amount Of Footage</div>
        <div class="is-flex is-flex-direction-column">
          <hr class="m-0 mb-2 mt-3">
          <div class="is-flex pb-3">
            <div class="is-size-7 has-text-weight-semibold">Raw:</div>
            <div class="is-size-7 has-text-weight-semibold ml-auto">
              {{ camera.amountOfFootage.raw }}
            </div>
          </div>
          <hr class="m-0 mb-2">
          <div class="is-flex pb-3">
            <div class="is-size-7 has-text-weight-semibold">Conditioned:</div>
            <div class="is-size-7 has-text-weight-semibold ml-auto">
              {{ camera.amountOfFootage.conditioned }}
            </div>
          </div>
          <hr class="m-0 mb-2">
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import {formatTimeInTimeZone} from "@/utils/utils";

export default {

  props:{
    camera:{
      type:Object
    },
    dateFilter:{
      type:String
    }
  },
  name: "files-data-view",
  mounted() {
    console.log('Camera Data: ', this.camera)
  },
  methods: {formatTimeInTimeZone},
  computed:{
    missingCollectionTimes(){
      if(this.dateFilter && this.dateFilter !== 'All'){
        return this.camera.missingCollectionTimes.filter(miss => miss.start.includes(this.dateFilter))
      }
      return this.camera.missingCollectionTimes
    },
    correctCollectionTimes(){
      if(this.dateFilter && this.dateFilter !== 'All'){
        return this.camera.missingCollectionTimes.filter(miss => miss.start.includes(this.dateFilter))
      }
      return this.camera.missingCollectionTimes
    },
    incorrectCollectionTimes(){
      if(this.dateFilter && this.dateFilter !== 'All'){
        return this.camera.irregularConditionedFiles.filter(miss => miss.start.includes(this.dateFilter))
      }
      return this.camera.irregularConditionedFiles
    },
    outsideRangeCollectionTimes(){
      if(this.dateFilter && this.dateFilter !== 'All'){
        return this.camera.unfittedConditionalFiles.filter(miss => miss.start.includes(this.dateFilter))
      }
      return this.camera.unfittedConditionalFiles
    },
  }
}
</script>

<style scoped lang="scss">
.unselected {
  background-color: hsl(0, 0%, 98%);
  box-shadow: none;
  border-radius: 8px;
  border-style: solid;
  border-color: #ececec;
  border-width: 1px;
}

</style>