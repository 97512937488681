import { render, staticRenderFns } from "./save-modal.vue?vue&type=template&id=e4aed760&scoped=true"
import script from "./save-modal.vue?vue&type=script&lang=js"
export * from "./save-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4aed760",
  null
  
)

export default component.exports