<template src="./template.html"></template>

<script>
import {getMarkerClass, getRiskRatingClass} from "@/utils/utils";
import {mapActions, mapGetters, mapState} from "vuex";
import SearchBox from "@/modules/projects-module/components/search-box/search-box.vue";
import AddProject from "@/modules/projects-module/components/add-project/add-project.vue";
import ProjectOverview from "@/modules/projects-module/components/project-overview/project-overview.vue";
import ProjectSiteView from "@/modules/projects-module/components/project-sites/project-site-view.vue";
import ProjectSites from "@/modules/projects-module/components/project-sites/project-sites.vue";
import MapBoxMap from "@/components/map-box-map/map-box-map.vue";
import GMap from "@/components/g-map/g-map.vue";
import ViewGuard from "@/components/view-guard/view-guard.vue";

export default {
  name: "home-screen",
  components: {
    ViewGuard,
    GMap,
    MapBoxMap,
    ProjectSites,
    ProjectSiteView,
    ProjectOverview,
    AddProject,
    SearchBox,
  },
  data() {
    return {
      isLoading: false
    }
  },
  async mounted() {

  },
  created() {
  },
  methods: {
    onMapClicked() {
      console.log('On map clicked')
      if (this.$refs.searchBox) {
        this.$refs.searchBox.onClickedOutside()
      }

    },
    openAddEditProject(isEditMode) {
      this.$refs.addProjectModal.show(true, isEditMode)
    },
    ...mapActions({
      setSelectedProject: 'projectsModule/setSelectedProject'
    }),
    /**
     * On map load
     * @param map
     */
    onMapLoaded() {
      setTimeout(() => {
        this.scrollToContent()
      }, 1000)

    },
    onQueryChanged(query) {
      console.log('Changed: ', query)
      this.queryData = {
        query: query
      }
    },
    getMarkerClass(item) {
      let selectedSite = this.state.selectedSite
      return getMarkerClass(item, selectedSite)
    },
    getRatingClass(property) {
      return getRiskRatingClass(property)
    },
    async markerClicked(site) {
      console.log('OnMarkerClicked', site)
    },
    async onViewClicked(site) {
      console.log('onViewClicked')
      //await this.setSelectedSite(site)
    },
    async setSelectedSite(site) {

    },
    scrollToContent() {
      console.log('Scroll to content:', this.$refs.content)
      if (this.$refs.content) {
        this.$refs.content.scrollIntoView({behavior: "smooth"})
      }
    },
    closeProjectOverview() {
      this.setSelectedProject(null)
    }
  },
  computed: {
    ...mapGetters({
      getMapStyle: 'getMapStyle',
      selectedProject: 'projectsModule/getSelectedProject',
      mapType: 'getMapType'
    }),
    ...mapState({
      state: state => state.projectsModule,
    }),
  }
}
</script>

<style scoped lang="scss" src="./style.scss"></style>