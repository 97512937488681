<template>
    <div class="flex-row">
      <div class="source">
        <draw-canvas
            ref="VueCanvasDrawing"
            @onDrawDone="onDrawDone"
            @onDeleteImage="onDeleteImage"
            :selected-category="selectedCategory"
            :image.sync="image"
            :width="width"
            :height="height"
            :stroke-type="strokeType"
            :line-cap="lineCap"
            :line-join="lineJoin"
            :fill-shape="fillShape"
            :eraser="eraser"
            :lineWidth="line"
            :color="color"
            :background-color="backgroundColor"
            :background-image="backgroundImage"
            :watermark="watermark"
            :initial-image="initialImage"
            saveAs="png"
            :styles="{border: 'solid 1px #fff'}"
            :lock="!selectedCalibration || !selectedCalibration.isNew || tabIndex > 5"
            @mousemove.native="getCoordinate($event)"
            :additional-images="additionalImages"/>
        <div class="has-background-white pl-2 pr-2 pt-1 pb-0" style="height: 78px;">
          <div class="is-flex pt-0 pb-0">



            <div class="is-flex pt-3">
            <div class="is-size-7 ml-0" style="font-variant-numeric: tabular-nums">Rendered: <strong>{{ this.width }}x{{ this.height }}</strong></div>
            <div class="is-size-7 ml-2" style="font-variant-numeric: tabular-nums">X:<strong>{{ x }}</strong>, Y:<strong>{{ y }}</strong></div>

            <div class="is-size-7 ml-4" style="font-variant-numeric: tabular-nums;">Original: <strong>{{ this.videoSrcWidth }}x{{ this.videoSrcHeight }}</strong></div>
            <div class="ml-2 is-size-7" style="font-variant-numeric: tabular-nums; width: 86px">X:<strong>{{ vx }}</strong>, Y:<strong>{{ vy }}</strong></div>
            </div>
            <div v-if="!selectedCalibration || !selectedCalibration.isNew" class="is-size-7 ml-auto mr-1 mt-3" style="font-variant-numeric: tabular-nums;">Video Time: <strong>{{ videoStart }} - {{ videoEnd }}</strong></div>


            <b-field class="m-0 p-0 ml-auto" v-else-if="selectedCalibration && selectedCalibration.isNew && selectedCalibration.availableFiles">
              <b-select v-model="selectedVideo" size="is-small"  @input="onSelectionChanged">
                <option v-for="file in selectedCalibration.availableFiles" :value="file" :key="file._id">{{getDateString(file)}}</option>
              </b-select>
            </b-field>

          </div>

          <div class="button-container m-0" :style="{'padding-top': selectedCalibration && selectedCalibration.isNew ? '6px' : '24px'}">
            <b-button  class="mt-0 mb-0" :disabled="!selectedCalibration || !selectedCalibration.isNew" :icon-left="eraser === false ? 'draw-pen' : 'eraser'" size="is-small" @click="eraser = !eraser">{{eraser ? 'Eraser' : 'Draw'}}</b-button>
            <b-field class="mt-0 mb-0 pb-1">
              <b-select v-model="strokeType" size="is-small" disabled>
                <option value="dash">Dash</option>
                <option value="line">Straight Line</option>
                <option value="polygon">Polygon</option>
                <option value="circle">Circle</option>
                <option value="square">Square</option>
                <option value="triangle">Triangle</option>
                <option value="half_triangle">Half Triangle</option>
                <option value="dot">Dot</option>
              </b-select>
            </b-field>
            <input class="mt-0" :disabled="!selectedCalibration || !selectedCalibration.isNew" type="color" v-model="color" style="width: 30px; height: 30px" @input="onColorChanged"/>
            <b-icon v-if="isVideoLoaded" size="is-medium"  class="ml-auto command-icon mt-1 mr-0"  @click.native="onPlayPause" :icon="isVideoPlaying ? 'pause-circle' : 'play-circle'"></b-icon>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import DrawCanvas from "@/modules/data-processing-module/components/draw-canvas/draw-canvas.vue";
import {mapGetters} from "vuex";
import {getDateTime, VideoCalibrationCategory} from "@/utils/utils";

export default {
  name: "draw-on-video",
  components: {DrawCanvas},
  props:{
    tabIndex:{
      type:Number
    },
    videoStart:{
      type: String
    },
    videoEnd:{
      type: String
    },
    isVideoPlaying:{
      type: Boolean
    },
    isVideoLoaded:{
      type:Boolean
    }
  },
  data() {
    return {
      width: 600,
      height: 400,
      selectedVideo: null,
      initialImage: [
        {
          type: "dash",
          from: {
            x: 262,
            y: 154,
          },
          coordinates: [],
          color: "#000000",
          width: 5,
          fill: false,
        },
      ],
      x: 0,
      y: 0,
      vx: 0,
      vy: 0,
      image: "",
      eraser: false,
      disabled: false,
      fillShape: false,
      line: 3,
      color: "#ffffff",
      strokeType: "polygon",
      lineCap: "square",
      lineJoin: "miter",
      backgroundColor: "rgba(255,255,255,0)",
      backgroundImage: null,
      watermark: null,
      additionalImages: [],
      videoSrcWidth: 0,
      videoSrcHeight: 0,
      selectedCategory: VideoCalibrationCategory.STAGE_POLYGON
    };
  },
  mounted() {
    document.addEventListener( "keydown", this.onKeydown)
    this.setSelectedVideo()
  },
  beforeDestroy() {
    document.removeEventListener( "keydown", this.onKeydown)
  },
  methods: {
    onSelectionChanged(videoMetaData){
      console.log('On selection changed: ', videoMetaData)
      this.$emit('onVideoChanged', videoMetaData)
    },
    getDateString(metaData) {
      if (metaData) {
        if (metaData.localCaptureStart && metaData.localCaptureEnd && this.selectedDevice.site) {
          return getDateTime(metaData.localCaptureStart, this.selectedDevice.site.timezone) + ' - ' + getDateTime(metaData.localCaptureEnd, this.selectedDevice.site.timezone)
        }
        return '--:--'
      }
      return '--:--'
    },
    onPlayPause(){
      this.$emit('onPlayVideo')
    },
    onColorChanged(color){
      this.$emit('onColorChanged')
    },
    onDeleteHover(imageId){
      this.$refs.VueCanvasDrawing.onHoverOutside(imageId)
    },
    onDeleteLeave(imageId){
      this.$refs.VueCanvasDrawing.onLeaveOutside(imageId)
    },
    onDelete(imageId){
      this.$refs.VueCanvasDrawing.onDeleteOutside(imageId)
    },
    onDeleteImage(imageId){
      this.$emit('onDeleteImage', imageId)
    },
    setInputData(images){
      this.disabled = images === null
      this.$refs.VueCanvasDrawing.setInputData(images)
    },
    getSettings(){
      return {
        color: this.color,
        width: this.line,
        fill: false,
        lineCap: this.lineCap,
        lineJoin: this.lineJoin
      }
    },
    onDrawDone(images){
      this.$emit('onDrawFinished', images)
    },
    setSelectedCategory(strokeType, category){
      this.selectedCategory = category
      this.strokeType = strokeType
    },
    setOriginalVideoDimensions(width, height){
      this.videoSrcWidth = width
      this.videoSrcHeight = height
    },
    onKeydown( event ) {
      if(event.key === "Enter"){
        this.$refs.VueCanvasDrawing.stopDraw(true, 'enter')
      }
    },
    setSize(width, height){
      this.width = width
      this.height = height
      this.$refs.VueCanvasDrawing.setNewDimension(this.width, this.height)
    },
    getSize(){
      return {
        width:this.width,
        height: this.height
      }
    },
    async setImage(event) {
      let URL = window.URL;
      this.backgroundImage = URL.createObjectURL(event.target.files[0]);
      await this.$refs.VueCanvasDrawing.redraw();
    },
    getCoordinate(event) {
      let coordinates = this.$refs.VueCanvasDrawing.getCoordinates(event);
      this.x = coordinates.x;
      this.y = coordinates.y;

      let diffX = this.videoSrcWidth/this.width
      let diffY = this.videoSrcHeight/this.height

      this.vx = Math.round(this.x * diffX)
      this.vy = Math.round(this.y * diffY)
    },
    setSelectedVideo(){
      if(this.selectedCalibration && this.selectedCalibration.availableFiles){
        let index = this.selectedCalibration.availableFiles.findIndex(video => video._id === this.selectedCalibration.videoMetaDataId)
        if(index !== -1){
          this.selectedVideo = this.selectedCalibration.availableFiles[index]
        }else{
          this.selectedVideo = null
        }
      }else{
        this.selectedVideo = null
      }
    }
  },
  computed:{
    ...mapGetters({
      selectedCalibration: 'dataProcessingModule/getSelectedCalibration',
      selectedDevice: 'dataProcessingModule/getSelectedProcessingDevice',
    }),
  },
  watch: {
    selectedCalibration() {
      this.setSelectedVideo()
    }
  }
}
</script>

<style scoped>
.flex-row {
  display: flex;
  flex-direction: row;
}

.button-container {
  display: flex;
  flex-direction: row;
}

.command-icon{
  color: #0D322A;
}

.command-icon:hover{
  cursor: pointer;
}

.button-container > * {
  margin-top: 15px;
  margin-right: 10px;
}
</style>