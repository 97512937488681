import {Role} from "@/utils/utils";
import uac from "@/modules/uac-module/views/uac/uac.vue";

const Module = () => import("./Module.vue");

const moduleRoute = {
    path: "/uac",
    component: Module,
    children: [
        {
            path: '/',
            name: 'uac',
            meta: {
                product: 'Realite Admin',
                title: 'UAC',
                isSubCategory: false,
                roles: [Role.ADMIN, Role.TRAFFIC_ENG],
                permission: 'uac:get',
                topBarTitle: '',
                menuPriority: 2,
                hasMenu: true,
                hasInternalTransition: false
            },
            component: uac
        }
    ]
};

export default router => {
    router.addRoutes([moduleRoute]);
};
