<template>
  <div>
    <gmap-marker
        ref="marker"
        :position="location"
        :clickable="true"
        :draggable="drawMode === 'move'"
        :icon="markerIcon"
        @click="onMarkerClicked"
        @dragend="updateCoordinates"
        @mousedown="mouseDown"
        @mouseup="mouseUp">
    </gmap-marker>
  </div>
</template>

<script>

import {DeviceType, getDeviceSvg, getRealDeviceSvg, getSitePlanSvg, getSiteSvg} from "@/utils/utils";

export default {
  name: "my-google-marker",
  props: {
    item: {
      type: Object,
      required: true
    },
    drawMode: {
      type: String,
      required: false
    },
    mapRotation: {
      type: Number,
      default: 0
    },
    isSelected: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      rotation: this.item.rotation,
      rotationRepresentation: this.item.rotation,
      markerIcon: null,
      started: false,
      direction: false
    }
  },
  mounted() {
    this.setMarker()
  },
  methods: {
    countDownTimer() {
      if (this.started) {
        this.direction ? this.rotation-- : this.rotation++;
        if (this.rotation < -180) {
          this.rotation = 180
        } else if (this.rotation > 180) {
          this.rotation = -180
        }

        this.setMarker()

        setTimeout(() => {
          this.countDownTimer()
        }, 10)
      }
    },
    onMarkerClicked(location) {
      console.log('On marker clicked:', location)
      this.$emit('onMarkerClicked')
    },
    mouseDown(event) {
      console.log('Mouse down:', event)
      if (this.drawMode === 'rotate') {
        this.started = true
        if (event.domEvent.button === 0) {
          this.direction = false
        } else {
          this.direction = true
        }

        this.countDownTimer()
      }
    },
    mouseUp(event) {
      console.log('Mouse up:', event)
      if (this.drawMode === 'rotate') {
        this.started = false
        this.$emit('onRotateFinished', {item: this.item, rotation: this.rotation})
      }
    },
    updateCoordinates(event) {
      let position = event.latLng.toJSON()
      let ev = {
        item: this.item,
        coordinates: position
      }

      this.$emit('onUpdateCoordinates', ev)
    },
    setMarker() {
      this.rotationRepresentation = this.rotation - this.mapRotation

      if (this.item.objectType) {
        if (this.item.objectType === 'site-plan') {
          this.markerIcon = {
            url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(getSitePlanSvg(null, this.item.siteName, this.isSelected)),
            anchor: {x: 42, y: 42},
          }
        } else if (this.item.objectType === 'site') {
          this.markerIcon = {
            url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(getSiteSvg('red', this.item.shortName)),
            anchor: {x: 42, y: 42},
          }
        } else if (this.item.objectType === 'device') {
          this.markerIcon = {
            url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(getRealDeviceSvg(this.item)),
            anchor: {x: 32, y: 32},
          }
        }
      } else {
        this.markerIcon = {
          url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(getDeviceSvg(this.rotationRepresentation, this.item, this.isSelected)),
          anchor: this.item.type === DeviceType.CAMERA ? {x: 50, y: 50} : {x: 28, y: 28},
        }
      }


      console.log('My google marker: ', this.location)
    }
  },
  watch: {
    mapRotation() {
      this.setMarker()
    },
    isSelected() {
      this.setMarker()
    }
  },
  computed: {
    location() {
      return {lat: this.item.location.coordinates[1], lng: this.item.location.coordinates[0]}
    }
  }
}
</script>

<style scoped>

</style>