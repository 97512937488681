/**
 * Created by Sale on 6/4/2024
 */
import {Role} from "@/utils/utils";
import assessmentPage from "@/modules/assessment-module/views/assessment/assessment-page.vue";

const Module = () => import("./Module.vue");


//TODO change permission uac:get
const moduleRoute = {
    path: "/assessment",
    component: Module,
    children: [
        {
            path: '/',
            name: 'assessment',
            meta: {
                product: 'Realite Admin',
                title: 'Data Availability',
                isSubCategory: false,
                roles: [Role.ADMIN, Role.TRAFFIC_ENG],
                permission:'report-provisioning:monitoring',
                topBarTitle: '',
                menuPriority: 3,
                hasMenu: true,
                hasInternalTransition: false
            },
            component: assessmentPage
        }
    ]
};

export default router => {
    router.addRoutes([moduleRoute]);
};
