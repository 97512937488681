import {groupPermissions, hasSubArray} from "@/utils/utils";

export default {
    getAllProjects: (state) => {
        return state.allProjects
    },
    getUsers: (state) => {
        return state.users
    },
    getApps: (state) => {
        return state.apps
    },
    getRoles: (state) => {
        return state.roles
    },
    getPermissions: (state) => {
        return state.permissions
    },
    getUsersTableHeaderData: (state) => {
        return state.usersTableHeaderData
    },
    getRolesTableHeaderData: (state) => {
        return state.rolesTableHeaderData
    },
    getPermissionsTableHeaderData: (state) => {
        return state.permissionsTableHeaderData
    },
    getUsersTable: (state) => {
        let filters = state.usersTableHeaderData.filters
        console.log('Filters: ', filters)
        console.log('Roles', state.roles)
        let table = []
        table = state.users.map((user) => {
            let roles = []
            if (user.uac && user.uac.roles && user.uac.roles.length > 0) {
                user.uac.roles.forEach(roleId => {
                    let findRole = state.roles.find((rl) => rl._id === roleId);
                    if (findRole) {
                        roles.push(findRole)
                    }
                })
            }
            let projectNames = []
            if (user.uac && user.uac.resources && user.uac.resources.length > 0) {
                if (user.uac.resources !== '*') {
                    user.uac.resources.forEach(res => {
                        let project = state.allProjects.find(pr => pr._id === res.projectId)
                        if (project) {
                            projectNames.push(project.name)
                        }
                    })
                }
            }

            return {
                id: user.id,
                name: user.name,
                email: user.email,
                internalUser: user.uac !== undefined && user.uac !== null && user.uac.isInternal !== null ? user.uac.isInternal : null,
                roles: roles.map(role => role.name),
                resources: user.uac && user.uac.resources === '*' ? user.uac.resources : projectNames,
                uac: user.uac,
                status: user.status
            }
        })
        let filteredTable = table.filter(dt => filters && filters.name != null && filters.name.length > 0 ? dt.name && dt.name.length > 0 ? dt.name.toString().toLowerCase().includes(filters.name.toLowerCase()) : false : true)
        filteredTable = filteredTable.filter(dt => filters && filters.email != null && filters.email.length > 0 ? dt.email && dt.email.length > 0 ? dt.email.toString().toLowerCase().includes(filters.email.toLowerCase()) : false : true)
        filteredTable = filteredTable.filter(dt => filters && filters.roles.length > 0 ? dt.roles && dt.roles && dt.roles.length > 0 ? hasSubArray(dt.roles, filters.roles) : false : true)
        filteredTable = filteredTable.filter(dt => filters && filters.resources != null && filters.resources.length > 0 ? dt.resources && dt.resources.length > 0 ? dt.resources.toString().toLowerCase().includes(filters.resources.toLowerCase()) : false : true)

        console.log('Users table: ', filteredTable)

        return filteredTable
    },
    getRolesTable: (state) => {
        let filters = state.rolesTableHeaderData.filters
        console.log('Filters: ', filters)
        let table = []
        table = state.roles.map((role) => {
            let app = state.apps.find((app) => app._id === role.appId)
            let permissions = []
            role.permissions.forEach(permId => {
                let permission = state.permissions.find(perm => perm._id === permId)
                if (permission) {
                    permissions.push(permission)
                }

            })
            return {
                id: role._id,
                name: role.name,
                appName: app ? app.name : null,
                description: role.description,
                permissionNames: permissions.map(perm => perm.name),
                groupedPermissions: groupPermissions(permissions)
            }
        })

        let filteredTable = table.filter(dt => filters && filters.name != null && filters.name.length > 0 ? dt.name && dt.name.length > 0 ? dt.name.toString().toLowerCase().includes(filters.name.toLowerCase()) : false : true)
        filteredTable = filteredTable.filter(dt => filters && filters.description != null && filters.description.length > 0 ? dt.description && dt.description.length > 0 ? dt.description.toString().toLowerCase().includes(filters.description.toLowerCase()) : false : true)
        filteredTable = filteredTable.filter(dt => filters && filters.appName.length > 0 ? dt.appName && dt.appName.length > 0 ? filters.appName.includes(dt.appName) : false : true)
        filteredTable = filteredTable.filter(dt => filters && filters.permissionNames.length > 0 ? dt.permissionNames && dt.permissionNames.length > 0 ? hasSubArray(dt.permissionNames, filters.permissionNames) : false : true)

        return filteredTable
    },
    getPermissionsTable: (state) => {
        let filters = state.permissionsTableHeaderData.filters
        console.log('Filters: ', filters)
        let table = []
        table = state.permissions.map((permission) => {
            return {
                id: permission._id,
                name: permission.name,
                functionName: permission.functionName,
                description: permission.description,
            }
        })

        let filteredTable = table.filter(dt => filters && filters.name != null && filters.name.length > 0 ? dt.name && dt.name.length > 0 ? dt.name.toString().toLowerCase().includes(filters.name.toLowerCase()) : false : true)
        filteredTable = filteredTable.filter(dt => filters && filters.description != null && filters.description.length > 0 ? dt.description && dt.description.length > 0 ? dt.description.toString().toLowerCase().includes(filters.description.toLowerCase()) : false : true)
        filteredTable = filteredTable.filter(dt => filters && filters.functionName != null && filters.functionName.length > 0 ? dt.functionName && dt.functionName.length > 0 ? dt.functionName.toString().toLowerCase().includes(filters.functionName.toLowerCase()) : false : true)

        return filteredTable
    },
}
