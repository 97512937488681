<template>
  <div>
    <history-component ref="projectHistory" key="projects-view"></history-component>
    <div class="is-flex is-align-items-center">
      <div class="is-size-5 has-text-grey">PROJECT OVERVIEW</div>
      <view-guard :permission="'project:update'" class="ml-auto">
        <b-button @click="onEditProject" type="is-primary" icon-left="pencil" rounded>Edit overview
        </b-button>
      </view-guard>
    </div>
    <div class="box my-box mt-5 mb-6 p-0">
      <div class="columns pl-6 pr-4 pt-5">
        <div class="column">
          <div class="is-size-7 has-text-grey-light">Project Name</div>
          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ project.name }}</div>
        </div>
        <div class="column">
          <div class="is-size-7 has-text-grey-light">Project Types</div>
          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ project.types.toString() }}</div>
        </div>
        <div class="column">
          <div class="is-size-7 has-text-grey-light">Project Code</div>
          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ project.code }}</div>
        </div>
        <div class="column">
          <div class="is-size-7 has-text-grey-light">Project Description</div>
          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ project.description }}</div>
        </div>
      </div>
      <div class="columns pl-6 pr-4 pt-3">
        <div class="column">
          <div class="is-size-7 has-text-grey-light">Client</div>
          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ project.clientName }}</div>
        </div>
        <div class="column">
          <div class="is-size-7 has-text-grey-light">Client Address</div>
          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ project.clientAddress }}</div>
        </div>
        <div class="column">
          <div class="is-size-7 has-text-grey-light">Client Contact Phone</div>
          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ project.clientPhone }}</div>
        </div>
        <div class="column">
          <div class="is-size-7 has-text-grey-light">Client Contact E-mail</div>
          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ project.clientEmail }}</div>
        </div>
      </div>
      <div class="columns pl-6 pr-4 pt-3">
        <div class="column">
          <div class="is-size-7 has-text-grey-light">Start Date</div>
          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ getDate(project.startDate) }}</div>
        </div>
        <div class="column">
          <div class="is-size-7 has-text-grey-light">End Date</div>
          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ getDate(project.endDate) }}</div>
        </div>
        <div class="column">
          <div class="is-size-7 has-text-grey-light">Created By</div>
          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ getUserName(project.createdBy) }}</div>
          <div class="is-size-7 has-text-weight-normal">{{ getDate(project.createdAt) }}</div>
        </div>
        <div class="column">
          <div class="is-size-7 has-text-grey-light">Last Update By</div>
          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ getUserName(project.updatedBy) }}</div>
          <div class="is-size-7 has-text-weight-normal">{{ getDate(project.updatedAt) }}</div>
        </div>
      </div>
      <hr class="solid">
      <div class="columns pl-6 pr-4">
        <div class="column">
          <div class="is-flex mt-3">
            <b-icon :icon="activityIcon"></b-icon>
            <div class="ml-1 has-text-weight-semibold">{{status}}</div>
          </div>
        </div>
        <div class="column">
        </div>
        <div class="column">
        </div>
        <div class="column">
          <div class="columns">
            <div class="column">
            </div>
            <div class="column is-flex">
              <view-guard :permission="'report-provisioning:history'" class="mr-1 mt-2 ml-auto">
                <b-tooltip label="Show Project History" :delay="1000" type="is-dark">
                  <b-button class="card-header-icon" aria-label="more options" @click="onShowHistoryClicked">
                    <b-icon type="is-success" icon="clipboard-text-clock"></b-icon>
                  </b-button>
                </b-tooltip>
              </view-guard>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import HistoryComponent from "@/modules/projects-module/components/history-component/history-component.vue";
import {getDate} from "@/utils/utils";
import ViewGuard from "@/components/view-guard/view-guard.vue";

export default {
  name: "project-overview",
  components: {ViewGuard, HistoryComponent},
  data() {
    return {}
  },
  methods: {
    onEditProject() {
      this.$emit('onEditProject')
    },
    onShowHistoryClicked() {
      console.log('On show history clicked')
      this.$refs.projectHistory.show(true, 'Project', this.project._id)
    },
    getDate(date) {
      if(date === null){
        return ''
      }
      return getDate(new Date(date))
    },
    getUserName(userId) {
      return this.$store.getters.getUserById(userId).fullName
    }
  },
  computed: {
    ...mapGetters({
      project: 'projectsModule/getSelectedProject'
    }),
    activityIcon(){
      if(this.project.status === 'Active'){
        return 'calendar-check'
      }else if(this.project.status === 'Inactive'){
        return 'calendar-lock'
      }else if(this.project.status === 'Archived'){
        return 'archive-lock-outline'
      }

      return 'alert-box'
    },
    status(){
      if(this.project.status === 'Active' || this.project.status === 'Inactive' || this.project.status === 'Archived'){
        return this.project.status
      }

      return 'Unknown'
    }
  }
}
</script>

<style lang="scss" scoped>
.my-box {
  border-radius: 16px;
  box-shadow: 0 0.125em 0.125em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
  color: hsl(0deg, 0%, 29%);
  display: block;
  padding: 1.25rem;
}
</style>