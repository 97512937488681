<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <b-modal v-model="modalActive" width="70%" scroll="keep">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title is-flex">
            <b-field class="mt-3" label="Template name *" label-position="on-border">
              <b-input style="min-width: 300px" expanded placeholder="Type template name..." rounded
                       v-model="editTemplate.name"
                       type="text" required></b-input>
            </b-field>
            <b-field expanded class="ml-3" style="min-width: 300px" label="Select device type *"
                     label-position="on-border">
              <b-select expanded style="min-width: 300px" placeholder="Select device type" rounded
                        v-model="editTemplate.deviceType">
                <option :value="DeviceType.CAMERA">Camera</option>
                <option :value="DeviceType.RADAR">Radar</option>
                <option :value="DeviceType.ATC">ATC</option>
              </b-select>
            </b-field>

          </p>
          <button class="card-header-icon" aria-label="more options">
          </button>
        </header>
        <div class="card-content">
          <div class="content">
            <div class="columns" v-for="(todo, index) in editTemplate.template" v-bind:key="index">
              <div class="column" style="padding: 0.5rem 0.8rem; max-width: 64px">
                <h1>{{ index + 1 }}.</h1>
              </div>
              <div class="column" style="padding: 0.5rem 0.8rem">
                <div style="margin-bottom: 16px">
                  <small style="margin-bottom: 0">Name: <span><b-input v-model="todo.name"
                                                                       size="is-small"></b-input></span></small>
                  <small>Description:
                    <b-input v-model="todo.description" size="is-small"></b-input>
                  </small>
                </div>
              </div>
              <div class="column is-flex mt-4" style="max-width: 72px">
                <b-button type="is-danger" class="ml-auto" icon-right="delete" size="is-small"
                          @click="deleteTodo(index)"/>
              </div>
            </div>
            <b-button class="is-primary is-fullwidth" @click="addNewItem">Add new todo</b-button>
          </div>
        </div>
        <footer class="card-footer">
          <a href="#" class="card-footer-item" @click="saveItem">Save</a>
          <a href="#" class="card-footer-item" @click="closeModal">Cancel</a>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {DeviceType, TemplateType} from "@/utils/utils";

export default {
  name: "add-edit-template",
  computed: {
    DeviceType() {
      return DeviceType
    },
    todoTemplates() {
      if (this.$store.getters.getSettings) {
        return this.$store.getters.getSettings.todoTemplates.filter(template => template.templateType === TemplateType.TODO)
      }
      return []
    }
  },
  data() {
    return {
      modalActive: false,
      isEditMode: true,
      editTemplate: {
        name: '',
        templateType: TemplateType.TODO,
        deviceType: '',
        template: [
          {
            name: '',
            description: ''
          }
        ]
      },
      isLoading: false
    }
  },
  methods: {
    show(show, templateId) {
      this.isEditMode = templateId !== null
      this.modalActive = show

      if (!this.isEditMode) {
        this.editTemplate = {
          name: '',
          templateType: TemplateType.TODO,
          deviceType: '',
          template: [
            {
              name: '',
              description: ''
            }
          ]
        }
      } else {
        let template = this.todoTemplates.find(template => template._id === templateId)
        console.log('Selected template: ', template)
        this.editTemplate = JSON.parse(JSON.stringify(template))
        console.log('Selected template: ', this.editTemplate)
      }
    },
    addNewItem() {
      this.editTemplate.template.push({
        name: null,
        description: null
      });
    },
    async saveItem() {
      if (this.editTemplate.name.length === 0) {
        this.$buefy.toast.open('Please enter template name')
        return
      }

      if (this.editTemplate.deviceType.length === 0) {
        this.$buefy.toast.open('Please select device type')
        return
      }

      if (this.editTemplate.template.length === 0 || this.editTemplate.template[0].name.length === 0) {
        this.$buefy.toast.open('Please add at least one todo')
        return
      }

      if (!this.isEditMode) {
        this.isLoading = true
        let response = await this.$store.dispatch('addNewTemplate', this.editTemplate)
        if (response.errorMessage) {
          this.showToast(response.errorMessage, 'is-danger')
        } else {
          this.showToast('New ToDo template successfully created', 'is-success')
          this.modalActive = false
        }
        this.isLoading = false
      } else {
        this.isLoading = true
        this.editTemplate.id = this.editTemplate._id
        let response = await this.$store.dispatch('updateTemplate', this.editTemplate)
        if (response.errorMessage) {
          this.showToast(response.errorMessage, 'is-danger')
        } else {
          this.showToast('ToDo template successfully updated', 'is-success')
          this.isEditMode = false
          this.modalActive = false
        }
        this.isLoading = false
      }
    },
    closeModal() {
      this.editTemplate = {
        name: '',
        templateType: TemplateType.TODO,
        deviceType: '',
        template: [
          {
            name: '',
            description: ''
          }
        ]
      }
      this.modalActive = false
    },
    deleteTodo(index) {
      this.editTemplate.template.splice(index, 1)
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
}
</script>

<style scoped>

</style>