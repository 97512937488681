<template>
  <nav class="listing-group-top-left">
    <section>
      <div class="buttons has-addons">
        <button v-if="mode === 'full'" class="button" :class="{'is-primary is-selected' : selectedItem === 'add'}"
                @click="setSelected('add')">
          <b-tooltip label="Placement" type="is-dark">
            <b-icon icon="map-marker" size="is-small"></b-icon>
          </b-tooltip>
        </button>
        <button class="button" :class="{'is-primary is-selected' : selectedItem === 'move'}" is-light
                @click="setSelected('move')">
          <b-tooltip label="Move" type="is-dark">
            <b-icon icon="cursor-move" size="is-small"></b-icon>
          </b-tooltip>
        </button>
        <button v-if="mode !== 'move'" class="button" :class="{'is-primary is-selected' : selectedItem === 'rotate'}"
                is-light
                @click="setSelected('rotate')">
          <b-tooltip label="Rotate" type="is-dark">
            <b-icon icon="rotate-right" size="is-small"></b-icon>
          </b-tooltip>
        </button>
      </div>
    </section>
  </nav>
</template>

<script>
export default {
  name: "draw-controls",
  props: {
    mode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedItem: null
    }
  },
  methods: {
    setSelected(item) {
      if (this.selectedItem === item) {
        this.selectedItem = null
      } else {
        this.selectedItem = item
      }
      this.$emit('onDrawModeSelected', this.selectedItem)
    }
  }
}
</script>

<style scoped>

</style>