<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <b-modal v-model="modalActive" width="70%" scroll="keep" style="z-index: 101" @close="clearFields()">
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head">
          <h6 class="is-size-5 ml-auto mr-auto pl-4 has-text-weight-semibold">
            {{ isUpdateMode ? 'Update Role' : 'Create New Role' }}</h6>
          <button
              type="button"
              class="delete"
              @click="show(false, null)"/>
        </header>
        <section class="modal-card-body p-0 pt-5 pl-3 pr-3 pb-6">
          <b-field label="Name *" label-position="on-border" expanded class="mt-5">
            <b-input v-model="role.name" rounded required ref="name"
                     validation-message="Required filed"></b-input>
          </b-field>
          <b-field label="Application *" expanded label-position="on-border" class="mt-5">
            <b-select placeholder="Select Application" rounded v-model="role.app" expanded required ref="app"
                      @input="onAppChanged"
                      validation-message="Required filed">
              <option v-for="app in apps" :value="app" :key="app._id">{{ app.name }}</option>
            </b-select>
          </b-field>
          <b-field label="Description *" label-position="on-border" expanded class="mt-5">
            <b-input v-model="role.description" rounded required ref="description"
                     validation-message="Required filed"></b-input>
          </b-field>
          <div class="has-text-weight-semibold ml-2 mt-4 is-size-6">Permissions</div>
          <hr class="m-0 mr-3 mb-4">
          <div class="is is-flex is-flex-wrap-wrap ml-2 mr-2">
            <div v-for="groupName in Object.keys(groutedPermissions)" :key="groupName" style="width: 33%" class="mb-2">
              <div class="has-background-light m-0 mr-3 mb-2 pl-2" style="border-radius: 4px">{{ groupName }}</div>
              <div v-for="item in Object.values(groutedPermissions[groupName])" :key="item._id" class="pl-2">
                <b-checkbox v-model="role.permissions" :disabled="item.name && item.name === 'uac:getme'"
                            :native-value="item">
                  {{ item.name }}
                </b-checkbox>
              </div>

            </div>
          </div>

        </section>
        <footer class="modal-card-foot">
          <b-button class="is-primary is-fullwidth ml-2 mr-2" @click="onActionClicked" rounded
                    :disabled="!isFormValid()" :key="refresh">{{ isUpdateMode ? 'UPDATE ROLE' : 'CREATE ROLE' }}
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {groupPermissions} from "@/utils/utils";

export default {
  name: "add-change-role",
  data() {
    return {
      isLoading: false,
      modalActive: false,
      refresh: false,
      isUpdateMode: false,
      role: {
        id: null,
        name: null,
        app: null,
        description: null,
        permissions: []
      }
    }
  },
  methods: {
    ...mapActions({
      addRole: 'uacModule/addRole',
      updateRole: 'uacModule/updateRole'
    }),
    onAppChanged(app) {
      if (this.role.name && this.role.name.length > 0) {
        let startIndex = this.role.name.indexOf(' -')
        if (startIndex !== -1) {
          this.role.name = this.role.name.substr(0, startIndex)
        }
      }
      let roleName = this.role.name !== null ? this.role.name : ''
      this.role.name = roleName + ' - ' + app.name

    },
    async onActionClicked() {
      this.isLoading = true
      let payload = {
        name: this.role.name,
        appId: this.role.app._id,
        description: this.role.description,
        permissions: this.role.permissions.map((permission) => permission._id)
      }
      console.log('Add role payload: ', payload)
      if (this.isUpdateMode) {
        payload.id = this.role.id
        let response = await this.updateRole(payload)
        if (response instanceof Error) {
          this.showToast(response.message, 'is-danger')
        } else {
          this.clearFields()
          this.showToast('Role successfully updated', 'is-success')
          this.show(false, null)
        }
      } else {
        let response = await this.addRole(payload)
        if (response instanceof Error) {
          this.showToast(response.message, 'is-danger')
        } else {
          this.clearFields()
          this.showToast('Role successfully created', 'is-success')
          this.show(false, null)
        }
      }

      this.isLoading = false
    },
    show(show, roleId) {
      console.log('Permissions: ', this.permissionsList)
      let getMePem = this.permissionsList.find(perm=> perm.name === 'uac:getme')
      console.log('Get me: ', getMePem)
      if (roleId) {
        let updateRole = this.roles.find((rl) => rl._id === roleId)
        if (updateRole) {
          let app = this.apps.find((app) => app._id === updateRole.appId)
          let permissions = []
          updateRole.permissions.forEach(permId => {
            let permission = this.permissionsList.find(perm => perm._id === permId)
            if (permission) {
              permissions.push(permission)
            }
          })
          this.role.id = roleId
          this.role.app = app
          this.role.name = updateRole.name
          this.role.description = updateRole.description
          this.role.permissions = permissions

          this.isUpdateMode = true

          setTimeout(() => {
            this.refresh = true
          }, 50)
        }
      }

      if(this.role.permissions.findIndex(perm => perm.name === 'uac:getme') === -1){
        if(getMePem){
          this.role.permissions.push(getMePem)
        }
      }

      this.modalActive = show
      if (!this.modalActive) {
        this.clearFields()
      }
    },
    clearFields() {
      this.role.id = null
      this.role.name = null
      this.role.app = null
      this.role.description = null
      this.lastApp = null
      this.role.permissions = []
      this.refresh = false
      this.isUpdateMode = false
    },
    isFormValid() {
      if (this.$refs.name && this.$refs.name.value && this.$refs.app && this.$refs.app.value && this.$refs.description && this.$refs.description.value) {
        return this.$refs.name.isValid && this.$refs.app.isValid && this.$refs.description.isValid
      }
      return false
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
  computed: {
    ...mapGetters({
      apps: 'uacModule/getApps',
      permissionsList: 'uacModule/getPermissions',
      roles: 'uacModule/getRoles'
    }),
    groutedPermissions() {
      return groupPermissions(this.permissionsList)
    }
  }
}
</script>

<style scoped>

</style>