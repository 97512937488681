import Vue from "vue";

export default {
    setSelectedProject: async ({commit}, selectedProject) => {
        if (selectedProject) {
            let config = {
                method: 'get',
                url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/project-report-v3',
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {id: selectedProject._id},
            };
            return await Vue.prototype.$http(config)
                .then((response) => {
                    console.log('Get project response: ', response)
                    if (response.status === 200) {
                        //commit('setAllProjects', response.data)
                        commit('setSelectedProject', response.data)
                    }
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });

        } else {
            commit('setSelectedProject', null)
            commit('deselectAllSites')
            commit('deselectAllDevices')
        }
    },
    getAllProjects: async ({commit}) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/project-list-v3',
            headers: {
                'Content-Type': 'application/json'
            },
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Get projects response: ', response)
                if (response.status === 200) {
                    commit('setAllProjects', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    setQueryData: async ({commit}, queryData) => {
        commit('setQueryData', queryData)
    },
    selectDevice: async ({commit}, device) => {
        commit('selectDevice', device)
    },
    deselectDevice: async ({commit}, device) => {
        commit('deselectDevice', device)
    },
    selectDevices: async ({commit}, devices) => {
        commit('selectDevices', devices)
    },
    deselectAllDevices: async ({commit}) => {
        commit('deselectAllDevices')
    },
    selectSite: async ({commit}, site) => {
        commit('selectSite', site)
    },
    deselectSite: async ({commit}, site) => {
        commit('deselectSite', site)
    },
    selectSites: async ({commit}, sites) => {
        commit('selectSites', sites)
    },
    deselectAllSites: async ({commit}) => {
        commit('deselectAllSites')
    },
    //Create
    createNewProject: async ({commit}, payload) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/project-v3',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Create new project response: ', response.data)

                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    commit('addProjectToAllProjects', response.data)
                    commit('setMqttEventToSend', response.data._id, { root: true })
                }

                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    updateProject: async ({commit, dispatch}, payload) => {
        let config = {
            method: 'put',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/project-v3',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };
        return await Vue.prototype.$http(config)
            .then(async (response) => {
                console.log('Update project response: ', response.data)
                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    await dispatch('setSelectedProject', response.data)
                    commit('updateProjectInList', response.data)
                    commit('setMqttEventToSend', response.data._id, { root: true })
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    createProjectSitePlans: async ({commit}, payload) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/project-multiple-site-plans-v3',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Create project site plan response: ', response.data)

                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    commit('addProjectSitePlans', response.data)
                    if(response.data && response.data.length > 0){
                        commit('setMqttEventToSend', response.data[0].projectId, { root: true })
                    }
                }

                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    updateSitePlan: async ({commit}, payload) => {
        let config = {
            method: 'put',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/project-site-plan-v3',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Update project site plan response: ', response.data)

                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    commit('updateProjectSitePlan', response.data)
                    commit('setMqttEventToSend', response.data.projectId, { root: true })
                }

                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    addDevicesToSitePlan: async ({commit}, payload) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/add-plan-devices-v3',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Add devices to site plan response: ', response.data)

                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    commit('updateProjectSitePlan', response.data)
                    commit('setMqttEventToSend', response.data.projectId, { root: true })
                }

                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    updateSitePlanDevices: async ({commit}, payload) => {
        let config = {
            method: 'put',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/update-plan-devices',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Update site plan devices response: ', response.data)

                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    if (response.data && response.data.length > 0) {
                        response.data.forEach(plan => {
                            commit('updateProjectSitePlan', plan)
                        })

                        commit('setMqttEventToSend', response.data[0].projectId, { root: true })
                    }
                }

                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    deleteSitePlan: async ({commit, getters}, id) => {

        let payload = {
            ids: [id]
        }

        let config = {
            method: 'delete',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/project-site-plan-v3',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Delete site plan response: ', response.data)

                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    commit('deleteSitePlans', response.data)
                    let selectedProject = getters.getSelectedProject
                    if(selectedProject !== null){
                        commit('setMqttEventToSend', selectedProject._id, { root: true })
                    }
                }

                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    deleteSitePlanDevice: async ({commit}, id) => {

        let payload = {
            deviceIds: [id]
        }

        let config = {
            method: 'delete',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/delete-plan-devices-v3',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Delete site plan device response: ', response.data)

                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    if (response.data && response.data.length > 0) {
                        response.data.forEach(plan => {
                            commit('updateProjectSitePlan', plan)
                        })
                        commit('setMqttEventToSend', response.data[0].projectId, { root: true })
                    }
                }

                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    updateSitePlanStatuses: async ({commit}, payload) => {
        let config = {
            method: 'put',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/project-site-plan-status-v3',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Update project site plans statuses response: ', response.data)

                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    if (response.data && response.data.length > 0) {
                        response.data.forEach(plan => {
                            commit('updateProjectSitePlan', plan)
                        })
                        commit('setMqttEventToSend', response.data[0].projectId, { root: true })
                    }
                }

                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    changeDataDeviceStatus: async ({commit,getters}, payload) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/change-data-device-status-v3',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };
        return await Vue.prototype.$http(config)
            .then(async (response) => {
                console.log('Change data device status response: ', response.data)
                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    commit('updateDataDevice', response.data)
                    let selectedProject = getters.getSelectedProject
                    if(selectedProject !== null){
                        commit('setMqttEventToSend', selectedProject._id, { root: true })
                    }
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    updateDataDeviceLocation: async ({commit,getters}, payload) => {
        let config = {
            method: 'put',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/data-device-v3',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };
        return await Vue.prototype.$http(config)
            .then(async (response) => {
                console.log('CUpdate data device location: ', response.data)
                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    commit('updateDataDevice', response.data)
                    let selectedProject = getters.getSelectedProject
                    if(selectedProject !== null){
                        commit('setMqttEventToSend', selectedProject._id, { root: true })
                    }
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    getHistory: async ({commit}, id) => {
        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v3-provisioning/history-v3',
            headers: {
                'Content-Type': 'application/json'
            },
            params: {objectId: id}
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Get history response: ', response)
                if (response.data) {
                    if (response.data.errorMessage) {
                        return Error(response.data.errorMessage)
                    }

                    return response.data;
                }

                return Error('No Data')
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    getImages: async ({commit}, deviceId) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-fsm/file/list',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {objectIds: [deviceId]}
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Get images response: ', response)
                if (response.data) {
                    if (response.data.errorMessage) {
                        return Error(response.data.errorMessage)
                    }

                    return response.data;
                }

                return Error('No Data')
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

