<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <b-modal v-model="modalActive" width="85%" scroll="keep" style="z-index: 101" v-if="objectName" @close="onClose">
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head">
          <h6 class="is-size-5 ml-auto mr-auto pl-4 has-text-weight-semibold">{{ objectName }} - Changes History</h6>
          <button
              type="button"
              class="delete"
              @click="show(false)"/>
        </header>
        <section class="modal-card-body p-0 pt-4 pl-2 pr-2">
          <div class="ml-4 mr-4 mb-6 mt-6">
            <div class="is-flex m-0 p-0" v-for="(entry, index) in history" :key="index">
              <div class="m-0 p-0 ml-6" style="width: 128px; min-width: 128px">
                <div class="is-size-7 date-background">{{ formatDateTime(entry) }}</div>
              </div>
              <div class="m-0 p-0 is-1 ml-2 mr-2" style="max-width: 16px">
                <div class="timeline-line"></div>
              </div>
              <div class="ml-2" style="margin-top: 8px">
                <div class="timeline-item is-flex mt-auto mb-auto pl-3 pr-5">
                  <div class="media mr-4" v-if="entry.changedBy != null">
                    <user-avatar :user="entry.changedBy"></user-avatar>
                    <div class="media-content" style="margin-left: 8px; padding-top: 0">
                      <div class="has-text-weight-semibold"
                           style="margin-bottom: 0; margin-top: 12px; line-height: 0.5;">{{ entry.changedBy.fullName }}
                      </div>
                      <small class="is-size-7 has-text-grey-light" style="white-space: nowrap">{{
                          entry.changedBy.role
                        }}</small>
                    </div>
                  </div>
                  <hr>
                  <div>
                    <div class="media" v-if="entry.changedBy != null">
                      <div class="media-content" style="padding-top: 0">
                        <small class="is-size-7 has-text-grey-light" style="white-space: nowrap">CHANGED</small>
                        <div class="has-text-weight-semibold"
                             style="margin-bottom: 0; margin-top: 4px; line-height: 0.5;">{{ entry.changed }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div v-if="getFrom(entry) && isCrew(entry.changed) === false"
                       class="status-horizontal mt-auto mb-auto has-text-weight-normal"
                       :class="[isStatus(entry) ? '' : 'no-color has-text-grey']"
                       :style="{'background': getColor(entry, true)}" style="white-space: nowrap">{{ getFrom(entry) }}
                  </div>
                  <div v-else-if="getFrom(entry) && isCrew(entry.changed) === true" class="is-flex mt-3"
                       style="white-space: nowrap">
                    <user-avatar v-for="user in getUsers(entry, true)" :user="user" :key="user.id"
                                 :is-small="true"></user-avatar>
                  </div>
                  <div class="arrow mr-3 ml-3" style="margin-top: 20px" v-if="getFrom(entry)"></div>

                  <div v-if="isCrew(entry.changed) === true" style="white-space: nowrap" class="is-flex mt-3">
                    <user-avatar v-for="user in getUsers(entry, false)" :user="user" :key="user.id"
                                 :is-small="true"></user-avatar>
                  </div>
                  <div v-else class="status-horizontal mt-auto mb-auto has-text-weight-normal"
                       :class="[isStatus(entry) ? '' : 'no-color has-text-grey']"
                       :style="{'background': getColor(entry, false)}" style="white-space: nowrap">{{ getTo(entry) }}
                  </div>
                </div>
              </div>
              <div v-for="change in entry.moreChanges" :key="change.to" class="ml-3" style="margin-top: 8px">
                <div class="timeline-item is-flex mt-auto mb-auto pl-5 pr-5">
                  <div>
                    <div class="media" v-if="change.changed != null">
                      <div class="media-content" style="padding-top: 0">
                        <small class="is-size-7 has-text-grey-light" style="white-space: nowrap">CHANGED</small>
                        <div class="has-text-weight-semibold"
                             style="margin-bottom: 0; margin-top: 4px; line-height: 0.5; white-space: nowrap">
                          {{ change.changed }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div v-if="getFrom(change) && isCrew(change.changed) === false"
                       class="status-horizontal mt-auto mb-auto has-text-weight-normal"
                       :class="[isStatus(change) ? '' : 'no-color has-text-grey']"
                       :style="{'background': getColor(change, true)}" style="white-space: nowrap">{{ getFrom(change) }}
                  </div>
                  <div v-else-if="getFrom(change) && isCrew(change.changed) === true" class="is-flex mt-3"
                       style="white-space: nowrap">
                    <user-avatar v-for="user in getUsers(change, true)" :user="user" :key="user.id"
                                 :is-small="true"></user-avatar>
                  </div>
                  <div class="arrow mr-3 ml-3" style="margin-top: 20px" v-if="getFrom(change)"></div>
                  <div v-if="isCrew(change.changed) === true" style="white-space: nowrap" class="is-flex mt-3">
                    <user-avatar v-for="user in getUsers(change, false)" :user="user" :key="user.id"
                                 :is-small="true"></user-avatar>
                  </div>
                  <div v-else class="status-horizontal mt-auto mb-auto has-text-weight-normal"
                       :class="[isStatus(change) ? '' : 'no-color has-text-grey']"
                       :style="{'background': getColor(change, false)}" style="white-space: nowrap">{{ getTo(change) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {getDate, getDateTime} from "@/utils/utils";
import UserAvatar from "@/components/avatar/avatar.vue";
import {mapActions} from "vuex";

export default {
  name: "history-component",
  components: {UserAvatar},
  data() {
    return {
      isLoading: false,
      modalActive: false,
      objectName: null,
      history: []
    }
  },
  methods: {
    ...mapActions({
      getHistory: 'projectsModule/getHistory',
    }),
    async show(show, objectName, objectId) {
      this.objectName = objectName
      console.log('Object name:', objectName)

      if (show) {
        this.history = []
        this.isLoading = true
        let response = await this.getHistory(objectId)
        if (response instanceof Error) {
          this.showToast(response.message, 'is-danger')
        } else {

          if (response.history && response.history.length > 0) {
            response.history.forEach(entry => {

              let historyItem = {
                date: entry.date,
                changedBy: this.$store.getters.getUserById(entry.changedBy),
                changed: null,
                from: null,
                to: null,
                moreChanges: []
              }

              if (entry.changes && entry.changes.length > 0) {

                entry.changes.forEach(change => {

                  let from
                  let to

                  if (change.to.field.toLowerCase().includes('date')) {
                    from = change.from && change.from.value ? getDate(new Date(change.from.value)) : 'Undefined'
                    to = change.to.value ? getDate(new Date(change.to.value)) : 'Undefined'
                  } else if (change.to.value instanceof Array) {
                    from = change.from && change.from.value ? change.from.value.toString() : 'Undefined'
                    to = change.to.value ? change.to.value.toString() : 'Undefined'
                  } else {
                    from = change.from && change.from.value ? change.from.value : 'Undefined'
                    to = change.to.value ? change.to.value : 'Undefined'
                  }

                  if (historyItem.changed) {
                    console.log('History item to add: ', historyItem)
                    historyItem.moreChanges.push({
                      changed: change.to.field,
                      from: from,
                      to: to
                    })
                  } else {
                    historyItem.changed = change.to.field
                    historyItem.from = from
                    historyItem.to = to

                  }
                })
              }

              this.history.push(historyItem)

            })
          }
          console.log('Received history: ', response.history)
          this.modalActive = show
        }
        this.isLoading = false
      } else {
        this.modalActive = false
      }

      console.log('Parsed history: ', this.history)

      if (!this.modalActive) {
        this.history = []
        this.objectName = null
      }
    },
    onClose() {
      this.history = []
      this.objectName = null
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    },
    getFrom(entry) {
      if (entry.from && entry.from !== 'Undefined') {
        return entry.from
      }
      return null
    },
    getTo(entry) {
      return entry.to ? entry.to : null
    },
    getColor(entry, isFrom) {
      if (this.isStatus(entry)) {
        let value = isFrom ? entry.from : entry.to
        return this.statusColor(value)
      }
      return 'transparent'
    },
    isStatus(entry) {
      return entry.changed.toLowerCase() === 'status'
    },
    isCrew(changed) {
      return changed ? changed.toLowerCase().includes('crew') : false
    },
    getUsers(entry, isFrom) {
      let users = []
      let ids = []
      if (isFrom) {
        if (entry.from) {
          let from = entry.from.split(',');
          console.log('From users', from, typeof from)
          ids.push(...from)
        }
      } else {
        if (entry.to) {
          let to = entry.to.split(',');
          console.log('From users', to, typeof to)
          ids.push(...to)
        }
      }

      if (ids.length > 0) {
        ids.forEach(id => {
          let index = this.users.findIndex(user => user.id === id)
          if (index !== -1) {
            users.push(this.users[index])
          }
        })

        return users
      }

      return null
    },
    formatDateTime(entry) {
      return getDateTime(entry.date)
    },
    statusColor(name) {
      if (this.$store.getters.getSettings) {
        let status = this.$store.getters.getSettings.planStatuses.find(status => status.name === name)
        if (status) {
          return status.color
        }
        status = this.$store.getters.getSettings.deviceStatuses.find(status => status.name === name)
        if (status) {
          return status.color
        }
      }

      return 'transparent'
    },
  },
  computed: {
    users() {
      return this.$store.getters.getAllowedUsers
    }
  }
}
</script>

<style scoped>

.date-background {
  color: white;
  width: fit-content;
  border-radius: 8px;
  background: #454545;
  padding: 4px 8px;
  margin-top: 26px;
}

.timeline-line {
  background-image: url('~@/assets/timeline_line.png');
  width: 12px;
  height: 112px;
}

.timeline-line-small {
  background-image: url('~@/assets/timeline_line.png');
  width: 12px;
  height: 64px;
}

.arrow {
  background-image: url('~@/assets/arrow_right.png');
  width: 16px;
  height: 8px;
}

.timeline-item {
  position: relative;
  height: 64px;
  background: #F4F4F4;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  padding: 8px;
}

.timeline-item:before {
  content: "";
  position: absolute;
  top: 22px;
  left: -20px;
  z-index: 1;
  border: solid 10px transparent;
  border-right-color: #F4F4F4;
}

hr {
  border: none;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  height: 80%;
  width: 1px;
  margin: 8px 24px 0 24px;
}

.status-horizontal {
  color: white;
  height: 24px;
  width: fit-content;
  border-radius: 4px;
  background: #B1B1B1;
  padding: 0 8px;
}

.no-color {
  border-color: rgba(0, 0, 0, 0.5);
  border-width: 1px;
  border-style: solid;
  background: transparent;
}

</style>