import { render, staticRenderFns } from "./to-do-templates.vue?vue&type=template&id=192d2d71&scoped=true"
import script from "./to-do-templates.vue?vue&type=script&lang=js"
export * from "./to-do-templates.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "192d2d71",
  null
  
)

export default component.exports