<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <b-modal v-model="modalActive" width="90%" scroll="keep" style="z-index: 101" @close="clearFields()">
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head">
          <h6 class="is-size-5 ml-auto mr-auto pl-4 has-text-weight-semibold">Create New Site Plans</h6>
          <button
              type="button"
              class="delete"
              @click="show(false)"/>
        </header>
        <section class="modal-card-body p-0 pt-4 pl-2 pr-2">
          <div class="columns m-0 mb-2">
            <div class="column">
              <b-field>
                <b-select placeholder="Select device type" rounded v-model="deviceType">
                  <option :value="DeviceType.CAMERA">Camera - TMC/Live/Parking</option>
                  <option :value="DeviceType.RADAR">Radar</option>
                  <option :value="DeviceType.ATC">ATC</option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-flex">
              <b-field class="file is-primary ml-auto" :class="{'has-name': !!file}" rounded>
                <b-upload v-model="file" class="file-label" accept=".csv" required rounded
                          validationMessage="Please select a file" @input="onImportData">
                  <span class="file-cta">
                      <b-icon class="file-icon" icon="upload"></b-icon>
                      <span class="file-label">Import data (.csv)</span>
                  </span>
                  <span class="file-name" v-if="file">
                      {{ file.name }}
                  </span>
                </b-upload>
              </b-field>
            </div>
          </div>
          <map-input v-if="mapType === 'mapbox'"
                     @onMapInputClicked="onMapClicked"
                     @onCoordinatesUpdated="onCoordinatesUpdated"
                     @onRotationChanged="onRotationChanged"
                     :selected-project="selectedProject"
                     draw-controls-mode="full"
                     :devices="devices">

          </map-input>
          <g-map-input v-if="mapType === 'gmap'"
                       @onMapInputClicked="onMapClicked"
                       @onCoordinatesUpdated="onCoordinatesUpdated"
                       @onRotationChanged="onRotationChanged"
                       :is-markers-selectable="false"
                       :selected-project="selectedProject"
                       draw-controls-mode="full"
                       :devices="devices"></g-map-input>
          <div class="ml-4 mr-4 mb-6">
            <div class="is-size-6 has-text-grey-light has-text-weight-normal mt-6 mb-2">PROJECT SITES</div>
            <simple-project-site-card v-for="(site, index) in sitePlans" :site="site" :key="site.name" class="mb-2"
                                      :selection-mode="true"
                                      @onPlanSelectionChanged="onPlanSelectionChanged"
                                      @onSiteDeleteClicked="removeSite(index)"
                                      @onSitePlanChanged="onSitePlanChanged"></simple-project-site-card>
            <collection-processing-input class="mt-6" v-if="sitePlans && sitePlans.length > 0"
                                         @onCopyToSelection="onCopyToSelectionPeriods"
                                         @onCopyToAll="onCopyToAllPeriods"></collection-processing-input>
            <installation-inputs class="mt-6" v-if="sitePlans && sitePlans.length > 0"
                                 @onCopyToSelection="onCopyToSelection"
                                 @onCopyToAll="onCopyToAll"></installation-inputs>
            <configuration-input class="mt-6 mb-2" title="COMMON CONFIGURATION" ref="cameraConfig"
                                 v-if="hasCameraDevice" :device-type="DeviceType.CAMERA"
                                 :key="DeviceType.CAMERA"></configuration-input>
            <todo-list-input class="mt-6 mb-2" title="COMMON TODO LIST" ref="cameraTodo"
                             v-if="hasCameraDevice" :device-type="DeviceType.CAMERA"
                             :key="DeviceType.CAMERA + 'todo'"></todo-list-input>
            <configuration-input class="mt-6 mb-2" title="COMMON CONFIGURATION" ref="atcConfig" v-if="hasAtcDevice"
                                 :device-type="DeviceType.ATC" :key="DeviceType.ATC"></configuration-input>
            <todo-list-input class="mt-6 mb-2" title="COMMON TODO LIST" ref="atcTodo" v-if="hasAtcDevice"
                             :device-type="DeviceType.ATC" :key="DeviceType.ATC + 'todo'"></todo-list-input>
            <configuration-input class="mt-6 mb-2" title="COMMON CONFIGURATION" ref="radarConfig" v-if="hasRadarDevice"
                                 :device-type="DeviceType.RADAR" :key="DeviceType.RADAR"></configuration-input>
            <todo-list-input class="mt-6 mb-2" title="COMMON TODO LIST" ref="radarTodo" v-if="hasRadarDevice"
                             :device-type="DeviceType.RADAR" :key="DeviceType.RADAR + 'todo'"></todo-list-input>
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button class="is-primary is-fullwidth m-2" @click="onCreateSites" rounded>CREATE SITES</b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MapInput from "@/components/map-input/map-input.vue";
import SimpleProjectSiteCard
  from "@/modules/projects-module/components/simple-project-site-card/simple-project-site-card.vue";
import ConfigurationInput from "@/modules/projects-module/components/configuraton-input/configuration-input.vue";
import {mapActions, mapGetters} from "vuex";
import {capitalizeFirstLetter, DeviceStatus, DeviceType} from "@/utils/utils";
import GMapInput from "@/components/g-map-input/g-map-input.vue";
import {getNextDeviceNumber, getProjectSitePlanPayload} from "@/utils/helper";
import InstallationInputs from "@/modules/projects-module/components/installation-inputs/installation-inputs.vue";
import TodoListInput from "@/modules/projects-module/components/todo-list-input/todo-list-input.vue";
import CollectionProcessingInput
  from "@/modules/projects-module/components/collection-processing-input/collection-processing-input.vue";

export default {
  name: "add-sites",
  components: {
    CollectionProcessingInput,
    TodoListInput, InstallationInputs, MapInput, GMapInput, ConfigurationInput, SimpleProjectSiteCard
  },
  data() {
    return {
      isLoading: false,
      modalActive: false,
      sitePlans: [],
      deviceType: 'CAMERA',
      selectedSitePlans: [],
      file: null
    }
  },
  methods: {
    ...mapActions({
      getLocation: 'getLocationData',
      createProjectSitePlans: 'projectsModule/createProjectSitePlans'
    }),
    show(show) {
      this.modalActive = show
      if (!this.modalActive) {
        this.clearFields()
      }
    },
    onPlanSelectionChanged(plan) {
      let siteIndex = this.sitePlans.findIndex(site => site.name === plan.siteName)
      if (siteIndex !== -1) {

        let site = this.sitePlans[siteIndex]
        site.isSelected = plan.isSelected
        console.log('Find and change site: ', this.sitePlans[siteIndex])
      }
    },
    onCopyToSelection(installationData) {
      console.log('On Copy to selection', installationData)
      this.copy(installationData, true)
    },
    onCopyToAll(installationData) {
      console.log('On Copy to all', installationData)
      this.copy(installationData, false)
    },
    onCopyToSelectionPeriods(data) {
      console.log('On Copy to selection', data)
      this.copyPeriods(data, true)
    },
    onCopyToAllPeriods(data) {
      console.log('On Copy to all', data)
      this.copyPeriods(data, false)
    },
    copy(installationData, toSelected) {
      this.sitePlans.forEach(sitePlan => {
        if (!toSelected || sitePlan.isSelected) {
          sitePlan.installationCrew = installationData.installationCrew
          sitePlan.decommissionCrew = installationData.decommissionCrew
          sitePlan.installationDate = installationData.installationDate
          sitePlan.decommissionDate = installationData.decommissionDate
        }
      })
    },
    copyPeriods(data, toSelected) {
      console.log('On copy new periods: ', data)
      this.sitePlans.forEach(sitePlan => {
        if (!toSelected || sitePlan.isSelected) {
          let newData = JSON.parse(JSON.stringify(data))

          if (newData && newData.collectionPeriod.dateRange && newData.collectionPeriod.dateRange.length > 1) {
            newData.collectionPeriod.dateRange = [new Date(newData.collectionPeriod.dateRange[0]), new Date(newData.collectionPeriod.dateRange[1])]
          }

          if (newData && newData.collectionPeriod.timeRanges && newData.collectionPeriod.timeRanges.length > 0) {
            newData.collectionPeriod.timeRanges.forEach(timeRange => {
              if (timeRange.startTime) {
                timeRange.startTime = new Date(timeRange.startTime)
              }
              if (timeRange.endTime) {
                timeRange.endTime = new Date(timeRange.endTime)
              }
            })
          }

          if (newData && newData.processingPeriod.dateRange && newData.processingPeriod.dateRange.length > 1) {
            newData.processingPeriod.dateRange = [new Date(newData.processingPeriod.dateRange[0]), new Date(newData.processingPeriod.dateRange[1])]
          }

          if (newData && newData.processingPeriod.timeRanges && newData.processingPeriod.timeRanges.length > 0) {
            newData.processingPeriod.timeRanges.forEach(timeRange => {
              if (timeRange.startTime) {
                timeRange.startTime = new Date(timeRange.startTime)
              }
              if (timeRange.endTime) {
                timeRange.endTime = new Date(timeRange.endTime)
              }
            })
          }


          if(newData && newData.processingPeriods && newData.processingPeriods.length > 0){
            newData.processingPeriods.forEach(processingPeriod => {
              if (processingPeriod.dateRange && processingPeriod.dateRange.length > 1) {
                processingPeriod.dateRange = [new Date(processingPeriod.dateRange[0]), new Date(processingPeriod.dateRange[1])]
              }

              if (processingPeriod.timeRanges && processingPeriod.timeRanges.length > 0) {
                processingPeriod.timeRanges.forEach(timeRange => {
                  if (timeRange.startTime) {
                    timeRange.startTime = new Date(timeRange.startTime)
                  }
                  if (timeRange.endTime) {
                    timeRange.endTime = new Date(timeRange.endTime)
                  }
                })
              }
            })
          }

          sitePlan.collectionPeriod = newData.collectionPeriod
          sitePlan.processingPeriod = newData.processingPeriod
          sitePlan.processingPeriods = newData.processingPeriods
        }
      })
    },
    async onCreateSites() {
      let cameraConf = null
      let atcConf = null
      let radarConf = null

      let cameraTodo = []
      let atcTodo = []
      let radarTodo = []

      let cameraDecommissionTodo = []
      let atcDecommissionTodo = []
      let radarDecommissionTodo = []

      if (this.$refs.cameraConfig && this.$refs.cameraConfig.configuration) {
        cameraConf = {}
        this.$refs.cameraConfig.configuration.forEach((config) => {
          cameraConf[config.field] = config.value
        })
      }

      if (this.$refs.atcConfig && this.$refs.atcConfig.configuration) {
        atcConf = {}
        this.$refs.atcConfig.configuration.forEach((config) => {
          atcConf[config.field] = config.value
        })
      }

      if (this.$refs.radarConfig && this.$refs.radarConfig.configuration) {
        radarConf = {}
        this.$refs.radarConfig.configuration.forEach((config) => {
          radarConf[config.field] = config.value
        })
      }

      if (this.$refs.cameraTodo && this.$refs.cameraTodo.installConfiguration) {
        this.$refs.cameraTodo.installConfiguration.forEach((config) => {
          cameraTodo.push({
            name: config.name,
            description: config.description
          })
        })
      }

      if (this.$refs.atcTodo && this.$refs.atcTodo.installConfiguration) {
        this.$refs.atcTodo.installConfiguration.forEach((config) => {
          atcTodo.push({
            name: config.name,
            description: config.description
          })
        })
      }

      if (this.$refs.radarTodo && this.$refs.radarTodo.installConfiguration) {
        this.$refs.radarTodo.installConfiguration.forEach((config) => {
          radarTodo.push({
            name: config.name,
            description: config.description
          })
        })
      }

      if (this.$refs.cameraTodo && this.$refs.cameraTodo.decommissionConfiguration) {
        this.$refs.cameraTodo.decommissionConfiguration.forEach((config) => {
          cameraDecommissionTodo.push({
            name: config.name,
            description: config.description
          })
        })
      }

      if (this.$refs.atcTodo && this.$refs.atcTodo.decommissionConfiguration) {
        this.$refs.atcTodo.decommissionConfiguration.forEach((config) => {
          atcDecommissionTodo.push({
            name: config.name,
            description: config.description
          })
        })
      }

      if (this.$refs.radarTodo && this.$refs.radarTodo.decommissionConfiguration) {
        this.$refs.radarTodo.decommissionConfiguration.forEach((config) => {
          radarDecommissionTodo.push({
            name: config.name,
            description: config.description
          })
        })
      }


      this.sitePlans.forEach(site => {
        if (site.device.type === DeviceType.CAMERA) {
          if (cameraConf) {
            site.device.settingsProfile = cameraConf
          }
          site.device.todos = cameraTodo
          site.device.decommissionTodos = cameraDecommissionTodo

        } else if (site.device.type === DeviceType.ATC) {
          if (atcConf) {
            site.device.settingsProfile = atcConf
          }

          site.device.todos = atcTodo
          site.device.decommissionTodos = atcDecommissionTodo

        } else if (site.device.type === DeviceType.RADAR) {
          if (radarConf) {
            site.device.settingsProfile = radarConf
          }

          site.device.todos = radarTodo
          site.device.decommissionTodos = radarDecommissionTodo
        }
      })

      console.log('Configurations: ', cameraConf, atcConf, radarConf)


      if (this.sitePlans.length > 0) {
        let payload = []
        this.sitePlans.forEach(sitePlan => {
          let sitePayload = getProjectSitePlanPayload(sitePlan, this.selectedProject._id)
          payload.push(sitePayload)
        })

        console.log('Sites to add', payload)

        this.isLoading = true
        let response = await this.createProjectSitePlans(payload)
        if (response instanceof Error) {
          this.showToast(response.message, 'is-danger')
        } else {
          this.clearFields()
          this.showToast('Project Site Plans successfully created', 'is-success')
          this.show(false, false)
        }
        this.isLoading = false
      }
    },
    clearFields() {
      this.sitePlans = []
      this.file = null
    },
    async onImportData(file) {
      console.log('On import data: ', file)

      const reader = new FileReader();
      reader.onload = async (e) => {
        let result = e.target.result
        console.log(e.target.result);

        let description = {
          SEQ:{type: 'number', group: 1},
          deviceType: {type: 'string', group: 1},
          longitude: {type: 'number', group: 1},
          latitude: {type: 'number', group: 1}
        }
        const csvToObj = require('csv-to-js-parser').csvToObj;
        let obj = csvToObj(result, description, ',');
        console.log('Object: ', obj)
        if(obj && obj.length > 0){
          let correctRows = obj.filter(dt => dt.deviceType !== null && dt.longitude !== null && dt.latitude !== null)
          console.log('Object Correct rows: ',correctRows)
          let data = correctRows.map(item => {delete item.SEQ; return item})
          console.log('Object Prepared data: ' ,data)
          if(data && data.length > 0){
            this.isLoading = true
            for (let i = 0; i < data.length; i++) {
              await this.onAutoImport(data[i])
            }
            this.isLoading = false
          }
        }
      }
      reader.readAsText(file);
    },
    removeSite(index) {
      this.sitePlans.splice(index, 1)
    },
    onSitePlanChanged(plan) {
      console.log('On site plan changed', plan)
      let siteIndex = this.sitePlans.findIndex(site => site.name === plan.siteName)
      if (siteIndex !== -1) {

        let site = this.sitePlans[siteIndex]
        site.name = plan.siteName
        site.editedSiteName = plan.editedSiteName
        site.installationCrew = plan.installationCrew
        site.decommissionCrew = plan.decommissionCrew
        site.installationDate = plan.installationDate
        site.decommissionDate = plan.decommissionDate

        site.processingPeriod = plan.processingPeriod
        site.collectionPeriod = plan.collectionPeriod
        site.processingPeriods = plan.processingPeriods

        console.log('Find and change site: ', this.sitePlans[siteIndex])
      }
    },
    async onMapClicked(position) {
      console.log('On map clicked:', position)
      this.isLoading = true
      let nextNumber = getNextDeviceNumber(this.selectedProject.projectSitePlans, this.devices, this.deviceType)

      let newSite = {
        name: ``,
        siteLocationName: null,
        siteLocation: {
          coordinates: [position.lng, position.lat]
        },
        devicesCount: `1 ${this.deviceType}`,
        installationCrew: [],
        decommissionCrew: [],
        installationDate: null,
        decommissionDate: null,
        collectionPeriod: {},
        processingPeriod: {},
        device: {
          type: this.deviceType,
          name: `${capitalizeFirstLetter(this.deviceType)} ${nextNumber}`,
          rotation: 0,
          location: {
            coordinates: [position.lng, position.lat]
          },
          currentStatus: {name: DeviceStatus.CREATED.name, color: DeviceStatus.CREATED.color},
        }
      }


      this.sitePlans.push(newSite)
      let location = await this.getLocation(position)
      this.isLoading = false
      console.log('Get location response', location)

      let parsed = this.parseAddress(location)
      newSite.name = parsed.address
      newSite.siteLocationName = parsed.place && parsed.place !== 'unknown' ? parsed.place : parsed.address
      newSite.device.siteName = parsed.address
      newSite.device.address = `${parsed.address}, ${parsed.place}`

      console.log('Add new site o map:', newSite)
    },
    async onAutoImport(inputData) {
      console.log('On auto import:', inputData)
      //this.isLoading = true
      let nextNumber = getNextDeviceNumber(this.selectedProject.projectSitePlans, this.devices, inputData.deviceType)

      let newSite = {
        name: ``,
        siteLocationName: null,
        siteLocation: {
          coordinates: [inputData.longitude, inputData.latitude]
        },
        devicesCount: `1 ${inputData.deviceType}`,
        installationCrew: [],
        decommissionCrew: [],
        installationDate: null,
        decommissionDate: null,
        collectionPeriod: {},
        processingPeriod: {},
        device: {
          type: inputData.deviceType,
          name: `${capitalizeFirstLetter(inputData.deviceType)} ${nextNumber}`,
          rotation: 0,
          location: {
            coordinates: [inputData.longitude, inputData.latitude]
          },
          currentStatus: {name: DeviceStatus.CREATED.name, color: DeviceStatus.CREATED.color},
        }
      }


      this.sitePlans.push(newSite)
      let location = await this.getLocation({lat:inputData.latitude, lng:inputData.longitude})
      //this.isLoading = false
      console.log('Get location response', location)

      let parsed = this.parseAddress(location)
      newSite.name = parsed.address
      newSite.siteLocationName = parsed.place && parsed.place !== 'unknown' ? parsed.place : parsed.address
      newSite.device.siteName = parsed.address
      newSite.device.address = `${parsed.address}, ${parsed.place}`

      console.log('Add new site o map:', newSite)
    },
    parseAddress(location) {
      if (location.address) {
        //check if we have site with same name
        let sites = this.sitePlans.filter(site => site.name === location.address)
        let name = location.address
        if (sites.length > 0) {
          name = `${name} ${sites.length}`
        }
        return {address: name, place: location.place}
      } else {
        let sites = this.sitePlans.filter(site => site.name === 'unknown')

        let name = 'unknown'
        if (sites.length > 0) {
          name = `${name} ${sites.length}`
        }
        this.$buefy.toast.open({
          message: 'Can\'t get address for selected location',
          duration: 4000,
          type: 'is-danger'
        })

        return {address: name, place: location.place}
      }
    },
    async onCoordinatesUpdated(data) {
      console.log('On update coordinates: ', data)
      let index = this.sitePlans.findIndex(site => site.name === data.item.siteName)
      if (index !== -1) {
        console.log('Find item: ', this.sitePlans[index])
        this.isLoading = true
        let location = await this.getLocation(data.coordinates)
        this.isLoading = false
        let parsed = this.parseAddress(location)
        this.sitePlans[index].name = parsed.address
        this.sitePlans[index].editedSiteName = parsed.address
        this.sitePlans[index].device.siteName = parsed.address
        this.sitePlans[index].device.address = `${parsed.address}, ${parsed.place}`
        this.sitePlans[index].device.location.coordinates = [data.coordinates.lng, data.coordinates.lat]

        console.log('changed item item: ', this.sitePlans[index])
      }
    },
    onRotationChanged(data) {
      console.log('On rotation changed', data)
      let index = this.sitePlans.findIndex(site => site.name === data.item.siteName)
      if (index !== -1) {
        console.log('Find item rotation: ', this.sitePlans[index])
        this.sitePlans[index].device.rotation = data.rotation
      }
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
  computed: {
    ...mapGetters({
      mapType: 'getMapType',
      selectedProject: 'projectsModule/getSelectedProject'
    }),
    DeviceType() {
      return DeviceType
    },
    hasCameraDevice() {
      let index = this.sitePlans.findIndex(site => site.device.type === DeviceType.CAMERA)
      return index !== -1
    },
    hasAtcDevice() {
      let index = this.sitePlans.findIndex(site => site.device.type === DeviceType.ATC)
      return index !== -1
    },
    hasRadarDevice() {
      let index = this.sitePlans.findIndex(site => site.device.type === DeviceType.RADAR)
      return index !== -1
    },
    devices() {
      if (this.sitePlans && this.sitePlans.length > 0) {
        return this.sitePlans.map(site => site.device)
      }
      return []
    }
  }
}
</script>

<style scoped>

</style>