<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="false"
        :is-full-page="true"
    ></b-loading>
    <info-modal ref="infoModal"></info-modal>
    <check-materials-modal ref="checkMaterialsModal" @onProceed="onProceed"></check-materials-modal>
    <div style="background: #eeeeee;">
      <transition name="fade" mode="out-in" v-if="selectedProcessingProject === null">
        <search-box-processing ref="searchBoxProcessing" style="margin-top: 150px"></search-box-processing>
      </transition>
      <g-map-data-processing @onMapClicked="onMapClicked" @onMapLoaded="onMapLoaded"></g-map-data-processing>

      <div v-if="selectedProcessingProject" class="pb-6">
        <div>
          <div class="is-flex has-background-white title-box p-2">
            <div class="is-size-4 ml-5 mr-5 pt-1 pb-1 has-text-weight-bold">{{ selectedProcessingProject.project.name }}
            </div>
            <b-button class="card-header-icon ml-auto mt-auto mb-auto mr-2" aria-label="more options"
                      @click="closeProjectOverview">
              <b-icon icon="close"></b-icon>
            </b-button>
          </div>
          <div class="is-flex is-flex-direction-column p-5" style="padding-bottom: 200px !important;">
            <div class="is-size-6 mb-3 mt-2">PROJECT TASKS</div>
            <data-processing-card v-for="dt in selectedProcessingProject.devices" :key="dt._id" :device-data="dt"
                                  @onProcessingClick="onProcessingClick" @onAiClick="onAiClick" @onRunTasksClick="onRunTasksClick"
                                  @onAddTasksClick="onAddTasksClick"></data-processing-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBoxProcessing
  from "@/modules/data-processing-module/components/search-box-processing/search-box-processing.vue";
import {mapActions, mapGetters} from "vuex";
import GMapDataProcessing
  from "@/modules/data-processing-module/components/g-map-data-processing/g-map-data-processing.vue";
import DataProcessingCard
  from "@/modules/data-processing-module/components/data-processing-card/data-processing-card.vue";
import InfoModal from "@/modules/data-processing-module/components/info-modal/info-modal.vue";
import CheckMaterialsModal
  from "@/modules/data-processing-module/components/check-materials-modal/check-materials-modal.vue";
import {getVideoTimeFromLocal, getVideoTimeFromLocalToIsoString} from "@/utils/utils";

export default {
  name: "data-processing-page",
  components: {CheckMaterialsModal, InfoModal, GMapDataProcessing, SearchBoxProcessing, DataProcessingCard},
  data() {
    return {
      isLoading: false
    }
  },
  mounted() {
    console.log('O Mounted')
    this.selectCalibration(null)
    this.setSelectedProcessingDevice(null)
  },
  methods: {
    ...mapActions({
      setSelectedProcessingProject: 'dataProcessingModule/setSelectedProcessingProject',
      //setSelectedVideoCalibrations: 'dataProcessingModule/setSelectedVideoCalibrations',
      selectCalibration: 'dataProcessingModule/selectCalibration',
      setSelectedProcessingDevice: 'dataProcessingModule/setSelectedProcessingDevice',
      createTasks: 'dataProcessingModule/createTasks',
      pushTasksToSqs: 'dataProcessingModule/pushTasksToSqs',
    }),
    async onAddTasksClick(deviceData) {

      this.isLoading = true

      let payload = {
        dataDeviceId: deviceData._id,
        saveTasks: false
      }

      let response = await this.createTasks(payload)

      if (response.errorMessage) {
        this.showToast(response.errorMessage, 'is-danger')
      } else {
        //this.show(false, false)
        console.log('Create tasks response: ', response)
        if (response.summaryCharts) {
          let chartsData = JSON.parse(JSON.stringify(response.summaryCharts))

/*          let chartsData = [
            {
              chartName: "PR-24038 - SPK - U-Turn Analysis - CCTV 1 | Camera 1 | 2024-09-20",
              x: ["2024-09-20T00:00:00+10:00",
                "2024-09-20T17:15:00+10:00",
                "2024-09-20T17:00:00+10:00",
                "2024-09-20T16:45:00+10:00",
                "2024-09-20T16:30:00+10:00",
                "2024-09-20T16:15:00+10:00"],
              y: [15, 15, 14, 15, 12, 15]
            },
            {
              chartName: "PR-24038 - SPK - U-Turn Analysis - CCTV 1 | Camera 1 | 2024-09-21",
              x: ["2024-09-21T00:00:00+10:00",
                "2024-09-21T17:15:00+10:00",
                "2024-09-21T17:00:00+10:00",
                "2024-09-21T16:45:00+10:00",
                "2024-09-21T16:30:00+10:00",
                "2024-09-21T16:15:00+10:00"],
              y: [15, 13, 15, 11, 15, 15]
            },
            {
              chartName: "PR-24038 - SPK - U-Turn Analysis - CCTV 1 | Camera 1 | 2024-09-22",
              x: ["2024-09-22T00:00:00+10:00",
                "2024-09-22T17:15:00+10:00",
                "2024-09-22T17:00:00+10:00",
                "2024-09-22T16:45:00+10:00",
                "2024-09-22T16:30:00+10:00",
                "2024-09-22T16:15:00+10:00"],
              y: [15, 13, 15, 11, 15, 15]
            },
            {
              chartName: "PR-24038 - SPK - U-Turn Analysis - CCTV 1 | Camera 1 | 2024-09-23",
              x: ["2024-09-23T00:00:00+10:00",
                "2024-09-23T17:15:00+10:00",
                "2024-09-23T17:00:00+10:00",
                "2024-09-23T16:45:00+10:00",
                "2024-09-23T16:30:00+10:00",
                "2024-09-23T16:15:00+10:00"],
              y: [9, 13, 11, 15, 14, 15]
            }
          ]*/

          chartsData.forEach(data => {
            data.x = data.x.map(x => getVideoTimeFromLocalToIsoString(x))
          })

          let data = {
            _id:deviceData._id,
            siteName: deviceData.site.name,
            name: deviceData.name,
            chartsData: chartsData
          }
          this.$refs.checkMaterialsModal.show(true, data)
        }
      }

      this.isLoading = false
    },
    async onProceed(dt){
      let data = JSON.parse(JSON.stringify(dt))
      this.$refs.checkMaterialsModal.show(false, null)
      console.log('On proceed data: ', data)


      this.isLoading = true

      let payload = {
        dataDeviceId: dt._id,
        saveTasks: true
      }

      let response = await this.createTasks(payload)

      if (response.errorMessage) {
        this.showToast(response.errorMessage, 'is-danger')
      } else {
        this.showToast('Tasks successfully created', 'is-success')
        console.log('Create tasks response 1: ', response)
      }

      this.isLoading = false

    },
    async onRunTasksClick(dataDevice){
      this.isLoading = true

      let payload = {
        dataDeviceId: dataDevice._id,
        taskType: 'video_path_tracking',
        version: 1
      }

      let response = await this.pushTasksToSqs(payload)

      if (response.errorMessage) {
        this.showToast(response.errorMessage, 'is-danger')
      } else {
        let message = 'Tasks successfully pushed to processing queue. Info: '
        if(response.pushToSqsMessage && response.pushToSqsMessage.message){
          message += response.pushToSqsMessage.message
        }else {
          message += 'No Info'
        }
        this.showToast(message, 'is-success')
      }

      this.isLoading = false
    },
    onMapClicked() {
      console.log('On map clicked')
      if (this.$refs.searchBoxQa) {
        this.$refs.searchBoxProcessing.onClickedOutside()
      }
      console.log('Selected Processing Project: ', this.selectedProcessingProject)
    },
    onMapLoaded() {
      console.log('On map loaded')
    },
    closeProjectOverview() {
      this.setSelectedProcessingProject(null)
    },
    openCalibrationPage() {
      this.$router.push('/video-calibration')
    },
    onProcessingClick(data) {
      this.$refs.infoModal.show(true, data, 'Processing Period/s', 'periods')
    },
    onAiClick(data) {
      this.$refs.infoModal.show(true, data, 'AI Configuration', 'ai')
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
  computed: {
    ...mapGetters({
      selectedProcessingProject: 'dataProcessingModule/getSelectedProcessingProject',
      selectedDevice: 'dataProcessingModule/getSelectedProcessingDevice'
    }),
  }
}
</script>

<style scoped>
.title-box {
  background-color: hsl(0deg, 0%, 100%);
  border-radius: 0;
  box-shadow: 0 0.125em 0.5em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
  color: hsl(0deg, 0%, 29%);
  display: block;
  padding: 1.25rem;
}
</style>