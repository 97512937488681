<template>
  <div>
    <b-loading v-model="isLoading" :can-cancel="true" :is-full-page="true"></b-loading>
    <div class="ml-0 mr-0 mt-0">
      <apex-chart height="130" type="rangeBar" :options="chartOptions" :series="cSeries" ref="apexChart"></apex-chart>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {getDate, getDateTimeInTimeZone} from "@/utils/utils";

export default {
  name: "apex-recording-chart",
  props: {
    projectId: {
      type: String
    },
    plannedTimes: {
      type: Array
    },
    existingTimes: {
      type: Array
    },
    missingTimes: {
      type: Array
    },
    incorrectTimes:{
      type:Array
    },
    unfittedTimes:{
      type:Array
    },
    selectedDate:null
  },
  data() {
    return {
      isLoading: false,
      refresh: false
    }
  },

  created() {
    this.refresh = !this.refresh
  },

  async mounted() {
    console.log('Apex chart', this.$refs.apexChart)
    console.log('Series:', this.$refs.apexChart1)
  },
  methods: {
  },
  computed: {
    ...mapGetters({
      selectedProject: 'assessmentModule/getSelectedMonitoringProject'
    }),
    chartOptions(){
      let minDate = null
      let maxDate = null

      let series = this.cSeries


      if (series && series.length > 0 && series[0].data && series[0].data.length > 0) {
        series[0].data.forEach(data => {
          if (data.y && data.y.length > 1) {
            let startDate = new Date(data.y[0])
            let endDate = new Date(data.y[1])

            if (minDate === null) {
              minDate = new Date(startDate)
            } else if (startDate.getTime() < minDate.getTime()) {
              minDate = new Date(startDate)
            }

            if (maxDate === null) {
              maxDate = new Date(endDate)
            } else if (endDate.getTime() > maxDate.getTime()) {
              maxDate = new Date(endDate)
            }
          }
        })
      }

      //Check unfitted
      if (series && series.length > 4 && series[4].data && series[4].data.length > 0) {
        series[4].data.forEach(data => {
          if (data.y && data.y.length > 1) {
            let startDate = new Date(data.y[0])
            let endDate = new Date(data.y[1])

            if (minDate === null) {
              minDate = new Date(startDate)
            } else if (startDate.getTime() < minDate.getTime()) {
              minDate = new Date(startDate)
            }

            if (maxDate === null) {
              maxDate = new Date(endDate)
            } else if (endDate.getTime() > maxDate.getTime()) {
              maxDate = new Date(endDate)
            }
          }
        })
      }

      console.log('Min date: ', minDate)
      console.log('Max date: ', maxDate)

      let startDate = minDate ? new Date(minDate) : new Date()
      let endDate = maxDate ? new Date(maxDate) : new Date()

      startDate.setHours(0)
      startDate.setMinutes(0)
      startDate.setSeconds(0)
      startDate.setMilliseconds(0)

      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)
      endDate.setMilliseconds(999)

      let chartOptions = {
        chart: {
          id: 'chart2',
          height: 130,
          type: 'rangeBar',
          animations: {
            enabled: false,
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '100%',
            rangeBarGroupRows: true
          }
        },
        colors: [
          "#cccccc",
          "#48c78e",
          "#ff0026",
          "#f38825",
          "#ad1df5"
        ],
        fill: {
          type: 'solid'
        },
        tooltip: {
          x: {
            format: "dd-MM-yyyy HH:mm:ss"
          }
        },
        xaxis: {
          type: 'datetime',
          min: startDate.getTime(),
          max: endDate.getTime(),
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left'
        },
        grid: {
          padding: {
            left: -10,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 0.1,
          colors: ['#ffffff'],
          curve: 'straight'
        },
      }

      return chartOptions
    },
    cSeries(){
      let series = [
        {
          name: 'Planned',
          data: []
        },
        {
          name: 'Correct',
          data: []
        },
        {
          name: 'Missing',
          data: []
        },
        {
          name: 'Incorrect',
          data: []
        },
        {
          name: 'Outside range',
          data: []
        },
      ]
      if (this.plannedTimes) {
        this.plannedTimes.forEach((timing, index) => {
          if(this.selectedDate === null || this.selectedDate === 'All' || (timing.start && timing.end && timing.start.includes(this.selectedDate) && timing.end.includes(this.selectedDate))) {
            series[0].data.push(
                {
                  x: 'Video Materials',
                  y: [
                    getDateTimeInTimeZone(timing.start, 'Australia/Melbourne').getTime(),
                    getDateTimeInTimeZone(timing.end, 'Australia/Melbourne').getTime()
                  ]
                },
            )
          }
        })
      }

      if (this.existingTimes) {
        this.existingTimes.forEach((timing, index)  => {
          if(this.selectedDate === null || this.selectedDate === 'All' || (timing.start && timing.end && timing.start.includes(this.selectedDate) && timing.end.includes(this.selectedDate))){
            series[1].data.push(
                {
                  x: 'Video Materials',
                  y: [
                    getDateTimeInTimeZone(timing.start).getTime(),
                    getDateTimeInTimeZone(timing.end).getTime()
                  ]
                },
            )
          }
        })
      }

      if (this.missingTimes) {
        this.missingTimes.forEach((timing, index)  => {
          if(this.selectedDate === null || this.selectedDate === 'All' || (timing.start && timing.end && timing.start.includes(this.selectedDate) && timing.end.includes(this.selectedDate))){
            series[2].data.push(
                {
                  x: 'Video Materials',
                  y: [
                    getDateTimeInTimeZone(timing.start).getTime(),
                    getDateTimeInTimeZone(timing.end).getTime()
                  ]
                },
            )
          }

        })
      }

      if (this.incorrectTimes) {
        this.incorrectTimes.forEach((timing, index)  => {
          if(this.selectedDate === null || this.selectedDate === 'All' || (timing.start && timing.end && timing.start.includes(this.selectedDate) && timing.end.includes(this.selectedDate))){
            series[3].data.push(
                {
                  x: 'Video Materials',
                  y: [
                    getDateTimeInTimeZone(timing.start).getTime(),
                    getDateTimeInTimeZone(timing.end).getTime()
                  ]
                },
            )
          }
        })
      }

      if (this.unfittedTimes) {
        this.unfittedTimes.forEach((timing, index)  => {
          if(this.selectedDate === null || this.selectedDate === 'All' || (timing.start && timing.end && timing.start.includes(this.selectedDate) && timing.end.includes(this.selectedDate))){
            series[4].data.push(
                {
                  x: 'Video Materials',
                  y: [
                    getDateTimeInTimeZone(timing.start).getTime(),
                    getDateTimeInTimeZone(timing.end).getTime()
                  ]
                },
            )
          }
        })
      }

      return series
    }
  },
}
</script>

<style scoped>
.chart {
  height: calc(100vh - 250px) !important;
}

.chart-inside {
  height: calc(100vh - 300px) !important;
}
</style>