export default () => ({
    allProjects: [],
    users: [],
    apps: [],
    roles: [],
    permissions: [],
    usersTableHeaderData: {
        activeSorted: {
            field: '',
            order: ''
        },
        activeFilterField: '',
        filters: {roles: [], name: null, email: null, resources: null}
    },
    rolesTableHeaderData: {
        activeSorted: {
            field: '',
            order: ''
        },
        activeFilterField: '',
        filters: {name: null, appName: [], permissionNames: [], description: null}
    },
    permissionsTableHeaderData: {
        activeSorted: {
            field: '',
            order: ''
        },
        activeFilterField: '',
        filters: {name: null, description: null, functionName: null}
    },
});
