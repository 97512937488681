export default {
    /**
     * Save user data
     * @param state (this state)
     * @param item
     */
    saveUserData(state, item) {
        state.user = item;
    },
    setMe(state, user) {
        state.me = user
    },
    /**
     * Remove user data
     * @param state (this state)
     * @param item
     */
    removeUserData(state, item) {
        state.user = null
        state.me = null
        state.allProjects = []
    },
    setMapStyle(state, newStyle) {
        state.mapStyle = newStyle
    },
    setUsers(state, users) {
        let newUsers = []
        if(users && users.length > 0){
            users.forEach(user => {
                newUsers.push({
                    fullName: user.name ? user.name : 'Unknown',
                    id: user.id ? user.id : 'Undefined',
                    role: user.role ? user.role : 'Undefined',
                    email: user.email ? user.email : 'Undefined',
                    partEmail: user.email ? user.email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1...@$2") : 'Undefined'
                })
            })
        }
        state.users = newUsers
    },
    setSettings(state, settings) {
        let systemSettings = {}
        let configurations = settings.configurationTemplates
        if (configurations && configurations.length > 0) {
            systemSettings.configurations = {}
            configurations.forEach(conf => {
                let deviceConfigurations = []
                conf.configurationFields.forEach(field => {
                    //split and capitalize camel case
                    let result = field.replace(/([A-Z])/g, ' $1')
                        .replace(/^./, function (str) {
                            return str.toUpperCase();
                        })
                    deviceConfigurations.push({field: field, name: result, id: conf._id})
                })
                systemSettings.configurations[conf.deviceType] = deviceConfigurations
            })
        }

        //Set device statuses
        systemSettings.deviceStatuses = settings.deviceStatuses && settings.deviceStatuses.length > 0 ? settings.deviceStatuses : []
        systemSettings.planStatuses = settings.planStatuses && settings.planStatuses.length > 0 ? settings.planStatuses : []
        systemSettings.taskStatuses = settings.taskStatuses && settings.taskStatuses.length > 0 ? settings.taskStatuses : []
        systemSettings.configurationTemplatesTypes = settings.configurationTemplatesTypes && settings.configurationTemplatesTypes.length > 0 ? settings.configurationTemplatesTypes : []
        systemSettings.statusesList = settings.statusesList
        systemSettings.projectTypes = settings.projectTypes
        systemSettings.todoTemplates = settings.todoTemplates
        state.settings = systemSettings
    },
    setMqttStatus: (state, status) => {
        state.mqttStatus = status
    },
    setMqttEventToSend: (state, message) => {
        state.mqttEventToSend = message
    },
};
