<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <add-change-app ref="addChangeApp"></add-change-app>
    <div class="mb-5">
      <div class="box is-flex is-flex-direction-column">
        <div class="is-flex">
          <div class="is-size-4 has-text-weight-semibold">RTT Applications</div>
          <view-guard :permission="'app:create'" class="ml-auto">
            <b-button type="is-success" @click="onAddApp" icon-left="plus">Add application</b-button>
          </view-guard>
        </div>
        <hr class="m-0 mt-2 p-0">
        <div class="columns ml-auto mr-auto mt-2 m-0 p-0" v-if="apps && apps.length > 0">
          <div class="column is-flex is-flex-direction-column" v-for="app in apps" :key="app._id">
            <div class="ml-auto mr-auto mt-0 mb-1 is-size-6">{{ app.name }}</div>
            <div class="box mb-2 p-0" style="width: 118px; height: 118px;" v-html="getIcon(app)"></div>
            <div class="is-flex ml-auto mr-auto mb-1">
              <view-guard :permission="'app:update'">
                <b-tooltip position="is-top" label="Update App" :delay="1000" type="is-dark">
                  <b-button icon-left="pencil" size="is-small" class="mr-1" @click="changeApp(app)"></b-button>
                </b-tooltip>
              </view-guard>
              <view-guard :permission="'app:delete'">
                <b-tooltip position="is-top" label="Delete App" :delay="1000" type="is-dark">
                  <b-button icon-left="delete" type="is-danger" outlined size="is-small"
                            @click="confirmDelete(app)"></b-button>
                </b-tooltip>
              </view-guard>
            </div>
          </div>
        </div>
        <div v-else class="is-flex is-align-items-center dashed-border m-2">
          <h4 class="is-size-4 ml-auto mr-auto has-text-grey-light has-text-weight-light">Click on "+" button to add
            first
            application</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AddChangeApp from "@/modules/uac-module/components/add-change-app/add-change-app.vue";
import ViewGuard from "@/components/view-guard/view-guard.vue";

export default {
  name: "apps-list",
  components: {ViewGuard, AddChangeApp},
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    ...mapActions({
      deleteApp: 'uacModule/deleteApp',
      getUsers: 'uacModule/getUsers',
      getRoles: 'uacModule/getRoles',
    }),
    onAddApp() {
      this.$refs.addChangeApp.show(true, null)
    },
    changeApp(app) {
      this.$refs.addChangeApp.show(true, app)
    },
    confirmDelete(app) {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: 'This action will delete all Roles associated with this application. Are you sure you want to delete ' + app.name + ' application ?',
        onConfirm: () => this.delete(app)
      })
    },
    async delete(app) {
      console.log('Delete role: ', app)
      this.isLoading = true
      let response = await this.deleteApp(app._id)
      await this.getRoles()
      await this.getUsers()
      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
      } else {
        this.showToast('Application successfully deleted', 'is-success')
      }
      this.isLoading = false
    },
    getIcon(app) {
      return app.icon
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
  computed: {
    ...mapGetters({
      apps: 'uacModule/getApps'
    }),
  }
}
</script>

<style scoped>

</style>