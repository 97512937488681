<template>
  <div id="map-container" class="columns is-gapless" style="margin-bottom: 0" :class="mapHeight">
    <MglMap
        ref="mapboxMapHome"
        container="map-home"
        :accessToken="$env.VUE_APP_ACCESS_TOKEN"
        :center="mapCenter"
        :zoom="mapZoom"
        :mapStyle="mapStyle"
        @click="onMapClicked"
        @load="onMapLoaded">
      <!--                  <geocoder-control
                                :accessToken="$env.VUE_APP_ACCESS_TOKEN"
                                :mapbox="mapboxGl()"
                                :marker="false"
                        />-->
      <div v-for="(item, index) in devices" :key="index">
        <my-marker :item="item" :map-rotation="mapRotation" @onMarkerClicked="onMarkerClicked(item)"
                   :is-selected="selectedDevice === item"
                   :draw-mode="null">
        </my-marker>
      </div>

      <map-style-menu @onStyleChanged="changeStyle"></map-style-menu>
      <map-device-legend v-if="selectedProject != null"
                         :items="[{icon: 'cctv', name: 'Camera'},{icon: 'radar', name: 'Wavetronix'},{icon: 'arrow-split-vertical', name: 'ATC'}]"></map-device-legend>
    </MglMap>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import {mapGetters} from "vuex";
import {MglMap, MglMarker, MglPopup} from "vue-mapbox";
import geocoderControl from "@/components/geocoder-control/geocoder-control";
import MapStyleMenu from "@/components/map-style-menu/map-style-menu.vue";
import MapDeviceLegend from "@/modules/projects-module/components/map-device-legend/map-device-legend.vue";
import MyMarker from "@/modules/projects-module/components/my-marker/my-marker.vue";
import {bbox, lineString} from "@turf/turf";

export default {
  name: "map-box-map",
  components: {
    MyMarker,
    MapDeviceLegend,
    MglMap,
    MapStyleMenu
  },
  props: {},
  data() {
    return {
      mapStyle: '',
      mapCenter: [144.94444, -37.80193],
      mapData: [],
      mapZoom: 10,
      mapRotation: 0,
      selectedDevice: null,
      clickedMarker: false,
    }
  },
  mounted() {
    this.mapStyle = this.getMapStyle
  },
  methods: {
    onMapLoaded(map) {
      console.log('On map loaded')
      let obj = this
      this.map = map.map;
      this.map.on('zoom', () => {
        this.zoom = this.map.getZoom();
      });
      this.map.on('click', (e) => {
        console.log('Clicked on map: ', e.lngLat)
        if (!this.clickedMarker) {
          this.selectedDevice = null
        }
        this.clickedMarker = false
        this.zoomMapToSelectedSiteOrProject(true)
      })

      this.map.on('rotate', () => {
        console.log('Map rotation', this.map.getBearing())
        obj.mapRotation = this.map.getBearing()
      })

      this.$emit('onMapLoaded')
      this.zoomMapToSelectedSiteOrProject(true)

    },
    zoomMapToSelectedSiteOrProject(animate) {

      if (this.selectedProject != null) {
        //Zoom to selected site
        let coordinates = []
        let zoomPoint = {
          center: this.mapCenter,
          zoom: 18,
          pitch: 0,
          bearing: 0
        }
        if (this.selectedDevice !== null) {
          zoomPoint = {
            center: this.selectedDevice.location.coordinates,
            zoom: 18,
            pitch: 0,
            bearing: 0
          }
        } else {
          this.selectedProject.sites.forEach(site => {
            coordinates.push(...site.devices.map(device => {
              return device.location.coordinates
            }))
          })
        }

        if (coordinates.length > 0) {
          let line = lineString(coordinates);
          let bb = bbox(line);
          this.map.fitBounds(bb, {padding: 200})
        } else if (animate) {
          this.map.flyTo(zoomPoint)
        }
      } else {
        const startPoint = {
          center: this.mapCenter,
          zoom: 13,
          pitch: 0,
          bearing: 0
        };
        this.map.flyTo(startPoint)
      }
    },
    changeStyle(style) {
      this.mapStyle = style
    },
    onMapClicked() {
      console.log('On map clicked')
      this.$emit('onMapClicked')
      //this.selectedDevice = null
    },
    mapboxGl() {
      return mapboxgl
    },
    onMarkerClicked(device) {
      console.log('On marker clicked', device)
      if (this.selectedDevice === device) {
        this.selectedDevice = null
      } else {
        this.selectedDevice = device
        this.clickedMarker = true
      }

      this.zoomMapToSelectedSiteOrProject(true)
    }
  },
  computed: {
    ...mapGetters({
      getMapStyle: 'getMapStyle',
      selectedProject: 'projectsModule/getSelectedProject'
    }),
    mapHeight() {
      if (this.selectedProject == null) {
        return 'full-height-map'
      } else {
        return 'smaller-height-map'
      }
    },
    sites() {
      if (this.selectedProject) {
        return this.selectedProject.sites
      }
      return []
    },
    devices() {
      let devices = []
      if (this.sites && this.sites.length > 0) {
        console.log('Sites', this.sites)
        this.sites.forEach(site => {
          let devs = site.devices
          devs.forEach(dev => {
            dev.siteName = site.name
          })
          devices.push(...devs)
        })

        console.log('Devices: ', devices)
      }
      return devices
    }
  },
  watch: {
    selectedProject() {
      this.zoomMapToSelectedSiteOrProject(true)
    }
  }
}
</script>

<style scoped>

</style>