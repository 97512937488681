export default {
    setSelectedSite(state, site) {
        state.selectedSite = site
    },
    setQueryData(state, queryData) {
        state.searchQueryData = queryData
        console.log('Set query data mutation: ', state.searchQueryData)
    },
    setSelectedProject(state, project) {
        state.selectedProject = project
    },
    updateDataDevice(state, dataDevice) {
        if (state.selectedProject) {
            console.log('Selected project', state.selectedProject)
            let deviceIndex = -1
            let sitesAndDevicesIndex = -1
            let newSelectedProject = JSON.parse(JSON.stringify(state.selectedProject))
            if (newSelectedProject && newSelectedProject.sitesAndDevices && newSelectedProject.sitesAndDevices.length > 0) {
                for (let i = 0; i < newSelectedProject.sitesAndDevices.length; i++) {
                    let devIndex = newSelectedProject.sitesAndDevices[i].devices.findIndex(device => device._id === dataDevice._id)
                    if (devIndex !== -1) {
                        deviceIndex = devIndex
                        sitesAndDevicesIndex = i
                    }
                }
            }

            if (deviceIndex !== -1 && sitesAndDevicesIndex !== -1) {
                newSelectedProject.sitesAndDevices[sitesAndDevicesIndex].devices[deviceIndex] = dataDevice
            }

            state.selectedProject = newSelectedProject
        }
    },
    selectDevice(state, device) {
        if (state.selectedDevices.indexOf(device) === -1) {
            state.selectedDevices.push(device)
        }
    },
    selectDevices(state, devices) {
        state.selectedDevices.push(...devices)
    },
    deselectDevice(state, device) {
        let index = state.selectedDevices.indexOf(device)
        if (index !== -1) {
            state.selectedDevices.splice(index, 1)
        }
    },
    deselectAllDevices(state) {
        state.selectedDevices = []
    },
    selectSite(state, site) {
        if (state.selectedSites.indexOf(site) === -1) {
            state.selectedSites.push(site)
        }
    },
    selectSites(state, sites) {
        state.selectedSites.push(...sites)
    },
    deselectSite(state, site) {
        let index = state.selectedSites.indexOf(site)
        if (index !== -1) {
            state.selectedSites.splice(index, 1)
        }
    },
    deselectAllSites(state) {
        state.selectedSites = []
    },
    setAllProjects(state, items) {
        console.log('Set all projects: ', items)
        state.allProjects = items
    },
    addProjectToAllProjects(state, project) {
        let newProject = {
            _id: project._id,
            name: project.name,
            types: project.types,
            status: project.status
        }
        state.allProjects.push(newProject)
    },
    addProjectSitePlans(state, sitePlans) {
        if (sitePlans && sitePlans.length > 0 && state.selectedProject && state.selectedProject.projectSitePlans) {
            state.selectedProject.projectSitePlans.push(...sitePlans)
        }
    },
    deleteSitePlans(state, planIds) {
        if (planIds && planIds.length > 0 && state.selectedProject && state.selectedProject.projectSitePlans) {
            planIds.forEach(planId => {
                let index = state.selectedProject.projectSitePlans.findIndex(plan => plan._id === planId)
                if (index !== -1) {
                    state.selectedProject.projectSitePlans.splice(index, 1)
                }
            })
        }
    },
    updateProjectSitePlan(state, newSitePlan) {
        if (newSitePlan && state.selectedProject && state.selectedProject.projectSitePlans) {
            let index = state.selectedProject.projectSitePlans.findIndex(plan => plan._id === newSitePlan._id)
            if (index !== -1) {
                state.selectedProject.projectSitePlans.splice(index, 1, newSitePlan)
            }
        }
    },
    updateProjectInList(state, project) {
        let index = state.allProjects.findIndex(prj => prj._id === project._id)
        if (index !== -1) {
            let newProject = {
                _id: project._id,
                name: project.name,
                types: project.types,
                status: project.status
            }
            state.allProjects.splice(index, 1, newProject)
        }
    },
    setTableHeaderData(state, newData) {
        state.tableHeaderData = newData
    },
    resetTableHeaderData(state) {
        state.tableHeaderData = {
            activeSorted: {
                field: '',
                order: ''
            },
            activeFilterField: '',
            filters: {
                risk: [],
                pet: null,
                dca: null,
                date: null,
                time: null,
                ttc: null,
                kineticEnergy: null,
                userTypes: null
            }
        }
    },
    clearAllData(state) {
        state.allProjects = []
        state.selectedProject = null
        state.tableData = []
        state.selectedDevices = []
        state.selectedSites = []
        state.tableHeaderData = {
            activeSorted: {
                field: '',
                order: ''
            },
            activeFilterField: '',
            filters: {
                risk: [],
                pet: null,
                dca: null,
                date: null,
                time: null,
                ttc: null,
                kineticEnergy: null,
                userTypes: null
            }
        }
    }
};
