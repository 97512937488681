<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <b-modal v-model="modalActive" width="70%" scroll="keep">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title is-flex">
            <b-field class="mt-3" label="Template name *" label-position="on-border">
              <b-input style="min-width: 300px" expanded placeholder="Type template name..." rounded
                       v-model="editTemplate.name"
                       type="text" required></b-input>
            </b-field>
            <b-field expanded class="ml-3" style="min-width: 300px" label="Select device type *"
                     label-position="on-border">
              <b-select expanded style="min-width: 300px" placeholder="Select device type" rounded
                        v-model="editTemplate.deviceType" @input="onTypeChanged">
                <option :value="DeviceType.CAMERA">Camera</option>
                <option :value="DeviceType.RADAR">Radar</option>
                <option :value="DeviceType.ATC">ATC</option>
              </b-select>
            </b-field>

          </p>
          <button class="card-header-icon" aria-label="more options">
          </button>
        </header>
        <div class="card-content">
          <div class="content">
            <div class="columns" v-for="(todo, index) in editTemplate.template" v-bind:key="index">
              <div class="column" style="padding: 0.5rem 0.8rem">
                <div style="margin-bottom: 16px">
                  <b-field class="mt-3" :label="todo.name" label-position="on-border">
                    <b-input v-model="todo.description" size="is-small"></b-input>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer class="card-footer">
          <a href="#" class="card-footer-item" @click="saveItem">Save</a>
          <a href="#" class="card-footer-item" @click="closeModal">Cancel</a>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {DeviceType, TemplateType} from "@/utils/utils";

export default {
  name: "add-edit-settings-template",
  computed: {
    DeviceType() {
      return DeviceType
    },
    todoTemplates() {
      if (this.$store.getters.getSettings) {
        return this.$store.getters.getSettings.todoTemplates.filter(template => template.templateType === TemplateType.SETTINGS_PROFILE)
      }
      return []
    },
    configurationFields() {
      if (this.$store.getters.getSettings && this.$store.getters.getSettings.configurations) {
        return this.$store.getters.getSettings.configurations[this.editTemplate.deviceType]
      }
      return []
    }
  },
  data() {
    return {
      modalActive: false,
      isEditMode: true,
      editTemplate: {
        name: '',
        templateType: TemplateType.SETTINGS_PROFILE,
        deviceType: DeviceType.CAMERA,
        template: [
          {
            name: '',
            description: ''
          }
        ]
      },
      isLoading: false
    }
  },
  methods: {
    show(show, templateId) {
      this.isEditMode = templateId !== null
      this.modalActive = show

      if (!this.isEditMode) {
        this.editTemplate = {
          name: '',
          templateType: TemplateType.SETTINGS_PROFILE,
          deviceType: DeviceType.CAMERA,
          template: [
            {
              name: '',
              description: ''
            }
          ]
        }

        this.editTemplate.template = this.configurationFields.map(filed => {
          return {name: filed.name, description: ''}
        })

      } else {
        let template = this.todoTemplates.find(template => template._id === templateId)
        console.log('Selected template: ', template)
        this.editTemplate = JSON.parse(JSON.stringify(template))
        console.log('Selected template: ', this.editTemplate)
      }
    },
    onTypeChanged(deviceType) {
      this.editTemplate.template = this.configurationFields.map(filed => {
        return {name: filed.name, description: ''}
      })
    },
    async saveItem() {
      if (this.editTemplate.name.length === 0) {
        this.$buefy.toast.open('Please enter template name')
        return
      }

      if (this.editTemplate.deviceType.length === 0) {
        this.$buefy.toast.open('Please select device type')
        return
      }

      if (!this.isEditMode) {
        this.isLoading = true
        let response = await this.$store.dispatch('addNewTemplate', this.editTemplate)
        if (response.errorMessage) {
          this.showToast(response.errorMessage, 'is-danger')
        } else {
          this.showToast('New Device Settings template successfully created', 'is-success')
          this.modalActive = false
        }
        this.isLoading = false

      } else {
        this.isLoading = true
        this.editTemplate.id = this.editTemplate._id
        let response = await this.$store.dispatch('updateTemplate', this.editTemplate)
        if (response.errorMessage) {
          this.showToast(response.errorMessage, 'is-danger')
        } else {
          this.showToast('Device Settings template successfully updated', 'is-success')
          this.modalActive = false
        }
        this.isLoading = false
        this.isEditMode = false
        this.modalActive = false
      }
    },
    closeModal() {
      this.editTemplate = {
        name: '',
        templateType: TemplateType.SETTINGS_PROFILE,
        deviceType: '',
        template: [
          {
            name: '',
            description: ''
          }
        ]
      }
      this.modalActive = false
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
}
</script>

<style scoped>

</style>