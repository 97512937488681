<template>
  <nav class="listing-group-top-left"
       style="background: rgba(255,255,255,0.8); border-radius: 8px; padding: 8px; margin-top: 64px; width: 170px">
    <section>
      <div class="columns pl-4 pr-2 pb-0 pt-3">
        <div class="column is-flex is-align-items-center p-0">
          <div class="mt-2 ml-1">
            <b-icon style="font-size: 24px; color: rgba(255,0,0,0.58)" icon="record-circle-outline"
                    size="is-small"></b-icon>
          </div>
          <div class="ml-2" style="min-width: 64px">Traffic site</div>
        </div>
        <div class="column m-0 p-0 pt-2 is-flex">
          <b-field class="ml-auto">
            <b-checkbox v-model="site" @input="onSiteChanged"
                        native-value="default">
            </b-checkbox>
          </b-field>
        </div>
      </div>
      <div class="columns pl-4 pr-2 pb-0 pt-0">
        <div class="column is-flex is-align-items-center p-0">
          <div class="mt-2 ml-1">
            <b-icon style="font-size: 24px; color: #656565" icon="hexagon-outline" size="is-small"></b-icon>
          </div>
          <div class="ml-2" style="min-width: 64px">Device</div>
        </div>
        <div class="column m-0 p-0 pt-2 is-flex">
          <b-field class="ml-auto">
            <b-checkbox v-model="device" @input="onDeviceChanged"
                        native-value="default">
            </b-checkbox>
          </b-field>
        </div>
      </div>
      <div class="columns pl-4 pr-2 pb-0 pt-0">
        <div class="column is-flex is-align-items-center p-0">
          <div class="mt-2 ml-1">
            <b-icon style="font-size: 24px; color: rgba(0,0,0,0.56)" icon="record-circle-outline"
                    size="is-small"></b-icon>
          </div>
          <div class="ml-2" style="min-width: 64px">Site plan</div>
        </div>
        <div class="column m-0 p-0 pt-2 is-flex">
          <b-field class="ml-auto">
            <b-checkbox v-model="sitePlan" @input="onSitePlanChanged"
                        native-value="default">
            </b-checkbox>
          </b-field>
        </div>
      </div>
      <div class="columns pl-4 pr-2 pb-3 pt-0">
        <div class="column is-flex is-align-items-center p-0">
          <div class="mt-2 ml-1">
            <b-icon style="font-size: 24px; color: rgba(0,0,0,0.56)" icon="circle-outline" size="is-small"></b-icon>
          </div>
          <div class="ml-2" style="min-width: 64px">Device plan</div>
        </div>
        <div class="column m-0 p-0 pt-2 is-flex">
          <b-field class="ml-auto">
            <b-checkbox v-model="planDevice" @input="onPlanDeviceChanged"
                        native-value="default">
            </b-checkbox>
          </b-field>
        </div>
      </div>
    </section>
  </nav>
</template>

<script>
export default {
  name: "map-markers-legend",
  props: {
    legend: {
      type: Array
    }
  },
  data() {
    return {
      site: true,
      device: true,
      sitePlan: true,
      planDevice: true
    }
  },
  methods: {
    onSiteChanged(value) {
      this.$emit('onFilterChanged', {
        site: value,
        device: this.device,
        sitePlan: this.sitePlan,
        planDevice: this.planDevice
      })
    },
    onDeviceChanged(value) {
      this.$emit('onFilterChanged', {
        site: this.site,
        device: value,
        sitePlan: this.sitePlan,
        planDevice: this.planDevice
      })
    },
    onSitePlanChanged(value) {
      this.$emit('onFilterChanged', {
        site: this.site,
        device: this.device,
        sitePlan: value,
        planDevice: this.planDevice
      })
    },
    onPlanDeviceChanged(value) {
      this.$emit('onFilterChanged', {site: this.site, device: this.device, sitePlan: this.sitePlan, planDevice: value})
    }
  }
}
</script>

<style scoped>

</style>