import { render, staticRenderFns } from "./add-qa-validation.vue?vue&type=template&id=dfee9b2a&scoped=true"
import script from "./add-qa-validation.vue?vue&type=script&lang=js"
export * from "./add-qa-validation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfee9b2a",
  null
  
)

export default component.exports