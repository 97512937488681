<template>
  <div class="ml-0 mr-0 mt-0">
    <div class="is-flex">
      <b-taglist attached class="m-0 p-0 ml-auto">
        <b-tag type="is-dark">Version</b-tag>
        <b-tag type="is-info">{{selectedDevice.chartsData && selectedDevice.chartsData.querySet ? selectedDevice.chartsData.querySet.ped.version : 'unknown'}}</b-tag>
      </b-taglist>
    </div>
    <apex-chart height="400" type="bar" :options="chartOptions" :series="series" ref="apexChart"></apex-chart>
  </div>
</template>

<script>
import {dateToISOString, getDateTime, mergeDeduplicate} from "@/utils/utils";
import {mapGetters} from "vuex";

export default {
  name: "pedestrian-chart",
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 400,
          fontFamily: 'Barlow',
          animations: {
            enabled: false,
          }
        },
        markers:{
          size:0
        },
        plotOptions: {
          bar: {
            columnWidth: '40%',
          },
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: 'Pedestrians'
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          type: 'datetime',
          categories: [],
          labels: {
            datetimeUTC: true,
          }
        },
        yaxis: {
          title: {
            text: ''
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          x: {
            format: "dd-MM-yyyy HH:mm:ss"
          },
          y: {
            formatter: function (val) {
              return  val
            }
          }
        }
      },
    }
  },
  mounted() {
    console.log('Today: ', new Date().toISOString())
    console.log('Selected device: ',this.selectedDevice)
    this.setChartData()
  },
  methods:{
    setChartData(){

      let series = []
      let categories = []

      if(this.chartsData && this.chartsData.pedVolumeData){
        let pedVolumeData = this.chartsData.pedVolumeData
        if(pedVolumeData.length > 0){
          let arrays = pedVolumeData.map(volData => volData.x)
          console.log('Arrays: ', arrays)
          categories = mergeDeduplicate(arrays)
          console.log('Unique: ', categories)

          pedVolumeData.forEach(volData => {
            let ser = {
              name: volData.name,
              data:[]
            }

            categories.forEach(date => {
              let index = volData.x.indexOf(date)
              if(index !== -1){
                ser.data.push(volData.y[index])
              }else{
                ser.data.push(0)
              }
            })

            series.push(ser)

          })
        }
      }

      this.series = series

      this.chartOptions = {
        chart: {
          type: 'bar',
          height: 400,
          fontFamily: 'Barlow',
          animations: {
            enabled: false,
          }
        },
        markers:{
          size:0
        },
        plotOptions: {
          bar: {
            columnWidth: '60%',
          },
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: 'Pedestrians'
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          type: 'datetime',
          categories: categories,
          labels: {
            datetimeUTC: true,
          }
        },
        yaxis: {
          title: {
            text: ''
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          x: {
            format: "dd-MM-yyyy HH:mm:ss"
          },
          y: {
            formatter: function (val) {
              return  val
            }
          }
        }
      }

    }
  },
  computed:{
    ...mapGetters({
      selectedDevice: 'qaModule/getSelectedQaDevice'
    }),
    chartsData(){
      if(this.selectedDevice && this.selectedDevice.chartsData){
        return this.selectedDevice.chartsData
      }

      return null
    }
  },

  watch:{
    chartsData: {
      handler: function (after, before) {
        this.setChartData()
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>