<template>
  <nav class="listing-group-top-center" style="background: rgba(255,255,255,0.8); border-radius: 8px; padding: 8px">
    <section>
      <div class="columns pl-1 pr-1" v-if="items">
        <div v-for="item in items" class="column is-flex is-align-items-center" :key="item.name">
          <div class="legend-oval">
            <b-icon style="font-size: 18px" :icon="item.icon" size="is-small"></b-icon>
          </div>
          <div class="ml-1">{{ item.name }}</div>
        </div>
      </div>
    </section>
  </nav>
</template>

<script>
export default {
  name: "map-device-legend",
  props: {
    items: {
      type: Array
    }
  }
}
</script>

<style lang="scss" scoped>
.legend-oval {
  display: flex;
  width: 24px;
  height: 24px;
  background: #3E3E3E;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  border-radius: 50%;
}
</style>