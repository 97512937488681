export default {
    setSelectedProcessingProject(state, project) {
        state.selectedProcessingProject = project
    },
    setQueryData(state, queryData) {
        state.searchQueryData = queryData
    },
    setSelectedVideoCalibration(state, calibrations) {
        state.selectedVideoCalibrations = calibrations
    },
    selectCalibration(state, calibration) {
        state.selectedCalibration = calibration
    },
    setSelectedProcessingDevice(state, processingDevice) {
        state.selectedProcessingDevice = processingDevice
    },
    replaceVideoCalibrations(state, videoCalibrations) {
        if (state.selectedProcessingProject) {
            let deviceIndex = state.selectedProcessingProject.devices.findIndex(device => state.selectedProcessingDevice._id === device._id)
            if (deviceIndex !== -1) {
                state.selectedProcessingProject.devices[deviceIndex].videoCalibrations = videoCalibrations
                state.selectedProcessingDevice = state.selectedProcessingProject.devices[deviceIndex]
                state.selectedVideoCalibrations = state.selectedProcessingProject.devices[deviceIndex].videoCalibrations
            }
        }
    },
    updateDeviceTasks(state, deviceData) {
        if (state.selectedProcessingProject) {
            let deviceIndex = state.selectedProcessingProject.devices.findIndex(device => deviceData.dataDeviceId === device._id)
            if (deviceIndex !== -1) {
                state.selectedProcessingProject.devices[deviceIndex].tasks = deviceData.tasks
            }
        }
    },
    setDpLegendSelection(state, selection) {
        state.dpLegendSelection = selection
    }
}
