<template>
  <div>
    <save-modal ref="saveModal"></save-modal>
    <div class="shadow-calibration">
      <div class="columns ml-3 mr-0 mt-0" style="height: 64px">

        <div v-if="!selectedCalibration && selectedDevice" class="column">
          <div class="is-size-5">Video Calibrations</div>
        </div>
        <div class="column" v-if="selectedCalibration">
          <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px;">CALIBRATION ID</div>
          <div class="has-text-black has-text-weight-semibold" style="line-height: 1">
            {{ selectedCalibration.isNew ? 'New Calibration' : selectedCalibration._id }}
          </div>
        </div>
        <div class="column" v-if="selectedCalibration">
          <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px;">VALID FROM</div>
          <div class="has-text-black has-text-weight-semibold" style="line-height: 1">{{
              getDate(selectedCalibration.validFrom)
            }}
          </div>
        </div>
        <div class="column" v-if="selectedCalibration">
          <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px;">VALID TO</div>
          <div class="has-text-black has-text-weight-semibold" style="line-height: 1">{{
              getDate(selectedCalibration.validTo)
            }}
          </div>
        </div>
        <div class="column" v-if="selectedCalibration">
          <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px;">STATUS</div>
          <div class="has-text-black has-text-weight-semibold" style="line-height: 1">{{
              selectedCalibration.status ? selectedCalibration.status : '-'
            }}
          </div>
        </div>

        <view-guard :permission="'data-processing:create-video-calibration'" class="ml-auto mt-auto mb-auto mr-0">
          <b-tooltip label="Add Calibration" :delay="1000" type="is-dark" position="is-left">
            <b-button v-if="!selectedCalibration" type="is-primary" icon-left="plus" size="is-small"
                      @click="onAddCalibration"></b-button>
          </b-tooltip>
        </view-guard>

        <view-guard :permission="'data-processing:create-video-calibration'" class="ml-auto mr-2 mt-auto mb-auto">
          <b-tooltip label="Save Calibration" :delay="1000" type="is-dark"
                     position="is-left">
            <b-button v-if="selectedCalibration" type="is-primary" @click="onSaveData" size="is-small"
                      icon-left="content-save"
                      :disabled="!selectedCalibration.isNew"></b-button>
          </b-tooltip>
        </view-guard>

        <b-tooltip label="Close Summary" :delay="1000" type="is-dark" class="mt-auto mb-auto mr-3" position="is-left">
          <b-button v-if="selectedCalibration" @click="closeVideoCalibration" icon-left="close"
                    size="is-small"></b-button>
        </b-tooltip>

      </div>
    </div>
  </div>
</template>

<script>
import SaveModal from "@/modules/data-processing-module/components/save-modal/save-modal.vue";
import {mapActions, mapGetters} from "vuex";
import {getDateTime} from "@/utils/utils";
import ViewGuard from "@/components/view-guard/view-guard.vue";

export default {
  name: "actions-bar",
  components: {ViewGuard, SaveModal},
  methods: {
    ...mapActions({
      selectCalibration: 'dataProcessingModule/selectCalibration'
    }),
    onAddCalibration() {
      this.$refs.saveModal.show(true)
    },
    cloneCalibration(calibration){
      console.log('Clone calibration: ', calibration)
      this.$refs.saveModal.show(true, calibration)
    },
    onSaveData() {
      console.log('On save data 1')
      this.$emit('onSaveData')
    },
    closeVideoCalibration() {
      this.selectCalibration(null)
      this.$emit('onCloseCalibration')
    },
    getDate(dateString) {
      return getDateTime(dateString, this.selectedDevice.site.timezone)
    }
  },
  computed: {
    ...mapGetters({
      selectedCalibration: 'dataProcessingModule/getSelectedCalibration',
      selectedDevice: 'dataProcessingModule/getSelectedProcessingDevice'
    }),
  },
}
</script>

<style scoped lang="scss">
.shadow-calibration {
  position: relative;
  border-top: 2px solid #18AA7E;
  z-index: 1;
  height: 64px;
  border-radius: 0;
  box-shadow: 0 0.125em 0.5em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
  display: block;
  background: white;
}
</style>