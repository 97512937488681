<template>
  <div class="card card-shadow p-2 mb-2">
    <div class="columns pl-2 pr-2 pt-1 pb-0">
      <div class="column is-3 ml-2">
        <div class="is-size-7 has-text-grey-light has-text-weight-normal">Site name</div>
        <div class="has-text-black has-text-weight-bold">{{ deviceData.siteName }}</div>
      </div>
      <div class="column is-1 m-0">
        <div class="is-size-7 has-text-grey-light has-text-weight-normal">Device</div>
        <div class="has-text-black has-text-weight-bold">{{ deviceData.name }}</div>
      </div>
      <hr class="mt-3 mb-4 has-background-grey-lighter mr-0" style="width: 1px; height: 48px">
      <div class="column m-0 ml-2 pl-1 pr-1" style="width: 110px; max-width: 110px" @click="onProcessingClick">
        <div class="has-text-centered clickable-info p-1">
          <div class="is-size-7 has-text-grey has-text-weight-normal">Processing time</div>
          <b-icon
              :type="deviceData.processingPeriods && deviceData.processingPeriods.length > 0 ? 'is-primary' : 'is-danger'"
              :icon="deviceData.processingPeriods && deviceData.processingPeriods.length > 0 ? 'check-circle' : 'close-circle'"
              size="is-small"></b-icon>
        </div>
      </div>
      <div class="column m-0 pl-1 pr-1" style="width: 110px; max-width: 110px" @click="onAiClick">
        <div class="has-text-centered clickable-info p-1">
          <div class="is-size-7 has-text-grey has-text-weight-normal">AI Config</div>
          <b-icon
              :type="deviceData.trackingConfig ? 'is-primary' : 'is-danger'"
              :icon="deviceData.trackingConfig ? 'check-circle' : 'close-circle'"
              size="is-small"></b-icon>
        </div>
      </div>
      <div class="column m-0 mr-2 pl-1 pr-1" style="width: 110px; max-width: 110px" @click="onVideoCalibrationClick">
        <div class="has-text-centered clickable-info p-1">
          <div class="is-size-7 has-text-grey has-text-weight-normal">Video Config</div>
          <b-icon
              :type="isVideoCalibrationExists() ? 'is-primary' : 'is-danger'"
              :icon="isVideoCalibrationExists() ? 'check-circle' : 'close-circle'"
              size="is-small"></b-icon>
        </div>
      </div>
      <hr class="mt-3 mb-4 has-background-grey-lighter mr-0" style="width: 1px; height: 48px">
      <div class="column">
        <div v-if="deviceData.tasks === null" style="height: 48px"
             class="is-flex is-align-items-center dashed-border-small ml-0 mr-0 mt-auto mb-auto">
          <h4 class="is-size-6 ml-auto mr-auto has-text-grey-light has-text-weight-light">{{ getMessage() }}</h4>
        </div>
        <div v-else style="height: 48px" class="is-flex mt-auto mb-auto">
          <div class="clickable-info has-text-centered is-flex" style="height: 48px; width: 80px"
               :style="statusStyle()">
            <div class="is-size-7 mt-auto mb-auto ml-auto mr-auto has-text-white">{{ deviceData.tasks.status }}</div>
          </div>
          <div class="clickable-info ml-2 is-flex pt-2 pl-4" style="flex-grow: 1">
            <div class="m-0" style="flex: 1">
              <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px;">
                {{ deviceData.tasks.status === 'CREATED' ? 'CREATED TASKS' : 'QUEUED' }}
              </div>
              <div class="has-text-black has-text-weight-bold" style="line-height: 1">
                {{ deviceData.tasks.status === 'CREATED' ? deviceData.tasks.created : deviceData.tasks.queued }}
              </div>
            </div>
            <div class="m-0" style="flex: 1" v-if="deviceData.tasks.status !== 'CREATED'">
              <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px">IN PROGRESS</div>
              <div class="has-text-black has-text-weight-bold" style="line-height: 1">{{
                  deviceData.tasks.inProgress
                }}
              </div>
            </div>
            <div class="m-0" style="flex: 1" v-if="deviceData.tasks.status !== 'CREATED'">
              <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px">REPROCESSING</div>
              <div class="has-text-black has-text-weight-bold" style="line-height: 1">{{
                  deviceData.tasks.reprocessing
                }}
              </div>
            </div>
            <div style="flex: 0.5" class="is-flex" v-if="deviceData.tasks.status !== 'CREATED'">
              <hr class="mt-0 mb-1 ml-auto mr-auto has-background-grey-lighter" style="width: 1px; height: 30px">
            </div>
            <div class="m-0" style="flex: 1" v-if="deviceData.tasks.status !== 'CREATED'">
              <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px">REPROCESSED</div>
              <div class="has-text-black has-text-weight-bold" style="line-height: 1">{{
                  deviceData.tasks.reprocessed
                }}
              </div>
            </div>
            <div class="m-0" style="flex: 1" v-if="deviceData.tasks.status !== 'CREATED'">
              <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px">FAILED</div>
              <div class="has-text-black has-text-weight-bold" style="line-height: 1">{{
                  deviceData.tasks.failed
                }}
              </div>
            </div>
            <div class="m-0" style="flex: 1" v-if="deviceData.tasks.status !== 'CREATED'">
              <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px">COMPLETED</div>
              <div class="has-text-black has-text-weight-bold" style="line-height: 1">{{
                  deviceData.tasks.completed
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-flex" style="max-width: 54px">
        <view-guard :permission="'data-processing:create-tasks'" class="ml-auto mr-auto mt-auto mb-auto">
          <b-tooltip v-if="deviceData.tasks === null && allConditions()" label="Create Tasks" :delay="1000"
                     type="is-dark">
            <b-icon icon="plus-circle" class="clickable"
                    size="is-medium" @click.native="onAddClick"></b-icon>
          </b-tooltip>
          <b-tooltip v-else-if="deviceData.tasks && deviceData.tasks.status === 'CREATED' && allConditions()"
                     class="ml-auto mr-auto mt-auto mb-auto" label="Run Tasks" :delay="1000" type="is-dark">
            <b-icon
                icon="play-circle"
                class="clickable" size="is-medium"
                @click.native="onRunClick"></b-icon>
          </b-tooltip>
          <b-tooltip v-else-if="deviceData.tasks && deviceData.tasks.status === 'RUNNING' && allConditions()"
                     class="ml-auto mr-auto mt-auto mb-auto" label="Please Wait..." :delay="1000" type="is-dark">
            <b-icon
                icon="progress-clock"
                class="clickable" size="is-medium"></b-icon>
          </b-tooltip>
          <b-tooltip v-else-if="deviceData.tasks && deviceData.tasks.status === 'COMPLETED' && allConditions()"
                     class="ml-auto mr-auto mt-auto mb-auto" label="Call next action" :delay="1000" type="is-dark">
            <b-icon
                icon="play-circle"
                class="clickable" size="is-medium"
                @click.native="onContinueClick"></b-icon>
          </b-tooltip>
        </view-guard>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ViewGuard from "@/components/view-guard/view-guard.vue";

export default {
  name: "data-processing-card",
  components: {ViewGuard},
  props: {
    deviceData: {
      type: Object
    }
  },
  methods: {
    ...mapActions({
      setSelectedVideoCalibrations: 'dataProcessingModule/setSelectedVideoCalibrations',
      setSelectedProcessingDevice: 'dataProcessingModule/setSelectedProcessingDevice'
    }),
    getMessage() {
      if (this.allConditions()) {
        return 'This device has no tasks'
      } else {
        return 'Conditions missing'
      }
    },
    onProcessingClick() {
      console.log('On processing click')
      this.$emit('onProcessingClick', this.deviceData)
    },
    onAiClick() {
      console.log('On AI click')
      this.$emit('onAiClick', this.deviceData)
    },
    onVideoCalibrationClick() {
      console.log('On Video calibration click: ', this.deviceData)
      this.setSelectedVideoCalibrations(this.deviceData.videoCalibrations)
      this.setSelectedProcessingDevice(this.deviceData)
      //this.$router.push('/video-calibration')

      const params = {
        projectId: this.selectedProcessingProject.project._id,
        dataDeviceId: this.deviceData._id
      }

      console.log('Params: ', params)

      this.$router.push({name: 'video-calibration', params: params})
    },
    onAddClick() {
      console.log('On Add task clicked')
      this.$emit('onAddTasksClick', this.deviceData)
    },
    onRunClick() {
      console.log('On Run task clicked')
      this.$emit('onRunTasksClick', this.deviceData)
    },
    onContinueClick() {
      console.log('On Continue clicked')
      this.showToast('Call next action from here', 'is-success')
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    },
    statusStyle() {
      if (this.deviceData.tasks && this.deviceData.tasks.status) {
        if (this.deviceData.tasks.status === 'CREATED') {
          return {background: '#8C84FF'}
        } else if (this.deviceData.tasks.status === 'RUNNING') {
          return {background: '#D8BA13'}
        } else if (this.deviceData.tasks.status === 'COMPLETED') {
          return {background: '#1CAB80'}
        }
      }
      return {background: '#000000'}
    },
    isVideoCalibrationExists() {
      if (this.deviceData.videoCalibrations && this.deviceData.videoCalibrations.length > 0) {
        if (this.deviceData.videoCalibrations.filter(calibration => calibration.status === 'active').length > 0) {
          return true
        }
      }
      return false
    },
    allConditions() {
      return this.isVideoCalibrationExists() && this.deviceData.processingPeriods && this.deviceData.processingPeriods.length > 0 && this.deviceData.trackingConfig
    }
  },
  computed:{
    ...mapGetters({
      selectedProcessingProject: 'dataProcessingModule/getSelectedProcessingProject',
      selectedDevice: 'dataProcessingModule/getSelectedProcessingDevice'
    }),
  }
}
</script>

<style scoped lang="scss">
.card-shadow {
  box-shadow: 0 0.125em 0.125em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
  background-color: white;
  border-radius: 0.35rem;
}

.clickable-info {
  background: #f4f4f4;
  border-radius: 0.3rem;
}

.clickable-info:hover {
  cursor: pointer;
  box-shadow: 0 0.125em 0.125em -0.125em rgb(10 10 10 / 20%), 0 0px 0 1px rgb(10 10 10 / 5%);
  background: #fcfcfc;
}

.clickable:hover {
  cursor: pointer;
}

.dashed-border-small {
  padding: 8px 8px;
  border-style: dashed;
  border-radius: 8px;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
}
</style>