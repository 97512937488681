<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <b-modal v-model="modalActive" width="90%" scroll="keep" style="z-index: 101" @close="clearFields()">
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head" style="padding: 12px">
          <h6 class="is-size-5 ml-auto mr-auto pl-4 has-text-weight-semibold">Edit Site Plans</h6>
          <button
              type="button"
              class="delete"
              @click="show(false, '', deselectOnClose)"/>
        </header>
        <section class="modal-card-body p-0" style="overflow: unset">
          <g-map-input ref="gMapInput" v-if="mapType === 'gmap'" style="max-height: 30vh;"
                       @onMapInputClicked="onMapInputClicked"
                       @onCoordinatesUpdated="onCoordinatesUpdated"
                       @onRotationChanged="onRotationChanged"
                       @onMarkerClicked="onSelectSite"
                       :is-markers-selectable="true"
                       :devices="editedSites"
                       :selected-device="selectedSite"
                       :selected-project="selectedProject"
                       :show-edit-location="false"
                       draw-controls-mode="none"
                       @onMapClicked="onMapClicked"
                       @onMapEditAddressChanged="onMapEditAddressChanged"
          ></g-map-input>
          <div style="min-height: 50vh">
            <div class="ml-0 mr-0 mb-0 mt-3">
              <b-tabs type="is-toggle" v-model="selectedTab" class="ml-4 mt-5">
<!--                <b-tab-item label="Locations" icon="map-marker">
                  <div class="modal-tab-content">
                    <hr class="m-0">
                    <div class="box simple-site-card p-2 pl-5 m-0 mt-2" style="cursor: pointer"
                         v-for="editedSite in editedSites"
                         :key="editedSite._id" :class="{'selected':selectedSite === editedSite}">
                      <div class="columns m-0 p-0 is-align-items-center">
                        <div class="column p-0 is-2" @click="onSelectSite(editedSite)">
                          <div class="is-size-7 has-text-grey-light">Site name</div>
                          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ editedSite.siteName }}</div>
                        </div>
                        <div class="column p-0 is-4" @click="onSelectSite(editedSite)">
                          <div class="is-size-7 has-text-grey-light">Location</div>
                          <div class="is-size-6 has-text-weight-semibold has-text-black">
                            {{ editedSite.location.coordinates }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab-item>-->
                <b-tab-item label="Installation date" icon="calendar-check">
                  <div class="modal-tab-content" style="height: 45vh">
                    <hr class="m-0">
                    <div class="box simple-site-card p-2 pl-4 m-0 mt-2" style="cursor: pointer"
                         v-for="editedSite in editedSites"
                         :key="editedSite._id" :class="{'selected':selectedSite === editedSite}">
                      <div class="columns m-0 p-0 is-align-items-center">
                        <div class="column is-1 m-0 p-0 ml-0 mr-3 is-flex"
                             style="width: 12px; max-width: 12px; align-self: center;">
                          <b-tooltip :label="editedSite.status" position="is-right" style="cursor: default; height: 100%">
                            <div class="status-indicator" :style="{'background': planStatusColor(editedSite.status)}"></div>
                          </b-tooltip>
                        </div>
                        <div class="column p-0 is-2" @click="onSelectSite(editedSite)">
                          <div class="is-size-7 has-text-grey-light">Site name</div>
                          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ editedSite.siteName }}</div>
                        </div>
                        <div class="column p-0 mt-1 is-4">
                          <b-field grouped label="Installation date *" label-position="on-border">
                            <b-datepicker :mobile-native="false" expanded v-model="editedSite.installationDate"
                                          size="is-small" position="is-bottom-right">
                              <template v-slot:trigger>
                                <b-input expanded readonly rounded placeholder="Installation date *"
                                         :value="installationDateString(editedSite)" type="text"
                                         required/>
                              </template>
                            </b-datepicker>
                          </b-field>
                        </div>
                        <div class="column" style="width: 100%" @click="onSelectSite(editedSite)"></div>
                        <div class="column m-0 p-0 is-flex is-1">
                          <b-tooltip v-if="selectedSite === editedSite  && editedSites.length > 1" label="Copy to all"
                                     class="ml-auto mr-2" position="is-left"
                                     :delay="1000" type="is-dark">
                            <a @click="onCopyToInstallDateClick(editedSite)">
                              <b-icon icon="content-copy">
                              </b-icon>
                            </a>
                          </b-tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab-item>
                <b-tab-item label="Decommission Date" icon="calendar-remove">
                  <div class="modal-tab-content" style="height: 45vh">
                    <hr class="m-0">
                    <div class="box simple-site-card p-2 pl-4 m-0 mt-2" style="cursor: pointer"
                         v-for="editedSite in editedSites"
                         :key="editedSite._id" :class="{'selected':selectedSite === editedSite}">
                      <div class="columns m-0 p-0 is-align-items-center">
                        <div class="column is-1 m-0 p-0 ml-0 mr-3 is-flex"
                             style="width: 12px; max-width: 12px; align-self: center;">
                          <b-tooltip :label="editedSite.status" position="is-right" style="cursor: default; height: 100%">
                            <div class="status-indicator" :style="{'background': planStatusColor(editedSite.status)}"></div>
                          </b-tooltip>
                        </div>
                        <div class="column p-0 is-2" @click="onSelectSite(editedSite)">
                          <div class="is-size-7 has-text-grey-light">Site name</div>
                          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ editedSite.siteName }}</div>
                        </div>
                        <div class="column p-0 mt-1">
                          <b-field grouped label="Decommission date *" label-position="on-border">
                            <b-datepicker :mobile-native="false" expanded v-model="editedSite.decommissionDate"
                                          size="is-small" position="is-bottom-right">
                              <template v-slot:trigger>
                                <b-input expanded readonly rounded placeholder="Decommission date *"
                                         :value="decommissionDateString(editedSite)" type="text"
                                         required/>
                              </template>
                            </b-datepicker>
                          </b-field>
                        </div>
                        <div class="column" style="width: 100%" @click="onSelectSite(editedSite)"></div>
                        <div class="column m-0 p-0 is-flex is-1">
                          <b-tooltip v-if="selectedSite === editedSite  && editedSites.length > 1" label="Copy to all"
                                     class="ml-auto mr-2" position="is-left"
                                     :delay="1000" type="is-dark">
                            <a @click="onCopyToDecommissionDateClick(editedSite)">
                              <b-icon icon="content-copy">
                              </b-icon>
                            </a>
                          </b-tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab-item>
                <b-tab-item label="Installation Crew" icon="account-multiple-check">
                  <div class="modal-tab-content" style="height: 45vh">
                    <hr class="m-0">
                    <div class="box simple-site-card p-2 pl-4 m-0 mt-2" style="cursor: pointer"
                         v-for="editedSite in editedSites"
                         :key="editedSite._id" :class="{'selected':selectedSite === editedSite}">
                      <div class="columns m-0 p-0 is-align-items-center">
                        <div class="column is-1 m-0 p-0 ml-0 mr-3 is-flex"
                             style="width: 12px; max-width: 12px; align-self: center;">
                          <b-tooltip :label="editedSite.status" position="is-right" style="cursor: default; height: 100%">
                            <div class="status-indicator" :style="{'background': planStatusColor(editedSite.status)}"></div>
                          </b-tooltip>
                        </div>
                        <div class="column p-0 is-2" @click="onSelectSite(editedSite)">
                          <div class="is-size-7 has-text-grey-light">Site name</div>
                          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ editedSite.siteName }}</div>
                        </div>
                        <div class="column p-0 mt-1">
                          <b-field expanded label="Installation Crew" label-position="on-border">
                            <b-dropdown
                                expanded
                                v-model="editedSite.installationCrew"
                                multiple
                                @change="onInstallDropDownChanged"
                                aria-role="list">
                              <template #trigger>
                                <my-button
                                    :key="installDropdownKey"
                                    expanded
                                    style="border-radius: 32px"
                                    icon-right="menu-down">
                                  <div class="is-flex" v-if="editedSite.installationCrew.length > 0">
                                    <user-avatar v-for="user in editedSite.installationCrew" :user="user"
                                                 :is-small="true"
                                                 :key="user.id"></user-avatar>
                                  </div>
                                  <div v-else class="has-text-grey-light">
                                    Installation crew
                                  </div>

                                </my-button>
                              </template>
                              <b-dropdown-item v-for="user in users" :value="user" :key="user.id"
                                               aria-role="listitem">
                                <div class="is-flex m-0 p-0">
                                  <div class="m-0 p-0">
                                    <user-avatar :user="user" :is-small="true"></user-avatar>
                                  </div>
                                  <div class="m-0 ml-2 p-0">
                                    <div class="is-size-7 has-text-weight-semibold" style="line-height: 1">
                                      {{ user.fullName }}
                                    </div>
                                    <div class="is-size-7" style="line-height: 1"> {{ user.partEmail }}</div>
                                  </div>
                                </div>

                              </b-dropdown-item>
                            </b-dropdown>
                          </b-field>
                        </div>
                        <div class="column" style="width: 100%" @click="onSelectSite(editedSite)"></div>
                        <div class="column m-0 p-0 is-flex is-1">
                          <b-tooltip v-if="selectedSite === editedSite  && editedSites.length > 1" label="Copy to all"
                                     class="ml-auto mr-2" position="is-left"
                                     :delay="1000" type="is-dark">
                            <a @click="onCopyInstallationCrewClick(editedSite)">
                              <b-icon icon="content-copy">
                              </b-icon>
                            </a>
                          </b-tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab-item>
                <b-tab-item label="Decommission Crew" icon="account-multiple-minus">
                  <div class="modal-tab-content" style="height: 45vh">
                    <hr class="m-0">
                    <div class="box simple-site-card p-2 pl-4 m-0 mt-2" style="cursor: pointer"
                         v-for="editedSite in editedSites"
                         :key="editedSite._id" :class="{'selected':selectedSite === editedSite}">
                      <div class="columns m-0 p-0 is-align-items-center">
                        <div class="column is-1 m-0 p-0 ml-0 mr-3 is-flex"
                             style="width: 12px; max-width: 12px; align-self: center;">
                          <b-tooltip :label="editedSite.status" position="is-right" style="cursor: default; height: 100%">
                            <div class="status-indicator" :style="{'background': planStatusColor(editedSite.status)}"></div>
                          </b-tooltip>
                        </div>
                        <div class="column p-0 is-2" @click="onSelectSite(editedSite)">
                          <div class="is-size-7 has-text-grey-light">Site name</div>
                          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ editedSite.siteName }}</div>
                        </div>
                        <div class="column p-0 mt-1">
                          <b-field expanded label="Decommission Crew" label-position="on-border">
                            <b-dropdown
                                expanded
                                v-model="editedSite.decommissionCrew"
                                @change="onDecommissionDropDownChanged"
                                multiple
                                aria-role="list">
                              <template #trigger>
                                <my-button
                                    :key="decommissionDropdownKey"
                                    expanded
                                    style="border-radius: 32px"
                                    icon-right="menu-down">
                                  <div class="is-flex"
                                       v-if="editedSite.decommissionCrew && editedSite.decommissionCrew.length > 0">
                                    <user-avatar v-for="user in editedSite.decommissionCrew" :user="user"
                                                 :is-small="true"
                                                 :key="user.id"></user-avatar>
                                  </div>
                                  <div v-else class="has-text-grey-light">
                                    Decommission crew
                                  </div>

                                </my-button>
                              </template>
                              <b-dropdown-item v-for="user in users" :value="user" :key="user.id"
                                               aria-role="listitem">
                                <div class="is-flex m-0 p-0">
                                  <div class="m-0 p-0">
                                    <user-avatar :user="user" :is-small="true"></user-avatar>
                                  </div>
                                  <div class="m-0 ml-2 p-0">
                                    <div class="is-size-7 has-text-weight-semibold" style="line-height: 1">
                                      {{ user.fullName }}
                                    </div>
                                    <div class="is-size-7" style="line-height: 1"> {{ user.partEmail }}</div>
                                  </div>
                                </div>

                              </b-dropdown-item>
                            </b-dropdown>
                          </b-field>
                        </div>
                        <div class="column" style="width: 100%" @click="onSelectSite(editedSite)"></div>
                        <div class="column m-0 p-0 is-flex is-1">
                          <b-tooltip v-if="selectedSite === editedSite  && editedSites.length > 1" label="Copy to all"
                                     class="ml-auto mr-2" position="is-left"
                                     :delay="1000" type="is-dark">
                            <a @click="onCopyDecommissionCrewClick(editedSite)">
                              <b-icon icon="content-copy">
                              </b-icon>
                            </a>
                          </b-tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab-item>
              </b-tabs>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot" style="padding: 0">
          <b-button class="is-primary is-fullwidth m-2" @click="onUpdateSite" rounded>
            UPDATE SITES
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import GMapInput from "@/components/g-map-input/g-map-input.vue";
import {
  getDate,
  getDateObjectFromMelbourneUtcToLocal,
  TaskType
} from "@/utils/utils";
import MyButton from "@/components/my-button/my-button.vue";
import UserAvatar from "@/components/avatar/avatar.vue";
import {
  getBulkDevicesPayloadForMultipleSites,
  getUpdateSitePlanPayload
} from "@/utils/helper";

export default {
  name: "edit-site-plans",
  components: {UserAvatar, MyButton, GMapInput},
  props: {
    sites: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      isLoading: false,
      modalActive: false,
      editedSites: null,
      selectedSite: null,
      deselectOnClose: false,
      clickedMarker: false,
      decommissionDropdownKey: 0,
      installDropdownKey: 0,
      selectedTab: 0,
    }
  },
  mounted() {
    this.editedSites = JSON.parse(JSON.stringify(this.sites))
    this.populateFields()
  },
  methods: {
    ...mapActions({
      getLocation: 'getLocationData',
      deselectAllSites: 'projectsModule/deselectAllSites',
      updateSitePlanDevices: 'projectsModule/updateSitePlanDevices'
    }),
    populateFields() {
      console.log('Populate fields: ', this.sites)
      this.editedSites = JSON.parse(JSON.stringify(this.sites))

      this.editedSites.forEach(editedSite => {
        editedSite.objectType = 'site-plan'
        editedSite.location = editedSite.siteLocation

        let installationCrew = [];
        let decommissionCrew = [];
        let installationDate;
        let decommissionDate;

        editedSite.listOfPlanDevices.forEach(device => {
          let installTask = device.tasks.find(task => task.taskType === TaskType.INSTALLATION)
          let decommissionTask = device.tasks.find(task => task.taskType === TaskType.DECOMMISSION)

          if (installTask) {
            installationCrew = installTask.crew
            installationDate = installTask.date
          }

          if (decommissionTask) {
            decommissionCrew = decommissionTask.crew
            decommissionDate = decommissionTask.date
          }

        })

        editedSite.installationCrew = installationCrew.map(userId => this.getUserById(userId))
        editedSite.decommissionCrew = decommissionCrew.map(userId => this.getUserById(userId))
        editedSite.installationDate = installationDate ? getDateObjectFromMelbourneUtcToLocal(installationDate) : null
        editedSite.decommissionDate = decommissionDate ? getDateObjectFromMelbourneUtcToLocal(decommissionDate) : null
      })
      console.log('Edited sites changes: ', this.editedSites)
    },
    show(show, action, deselectOnClose) {
      this.modalActive = show
      this.deselectOnClose = deselectOnClose

      if (!this.modalActive) {
        this.clearFields()
      } else {
        this.isLoading = true
        if (action === 'installDate') {
          this.selectedTab = 0
        } else if (action === 'decommissionDate') {
          this.selectedTab = 1
        } else if (action === 'installCrew') {
          this.selectedTab = 2
        } else if (action === 'decommissionCrew') {
          this.selectedTab = 3
        }/* else if (action === 'periods') {
          this.selectedTab = 4
        }*/
        setTimeout(() => {
          this.populateFields()
          console.log('Show edit sites:', this.deselectOnClose, this.editedDevices)
          this.isLoading = false
        }, 500)

      }
    },
    async onUpdateSite() {
      let payloads = []
      this.editedSites.forEach(site => {
        let payload = getUpdateSitePlanPayload(site)
        payloads.push(...payload.listOfPlanDevices)
      })

      console.log('All sites for update: ', payloads)

      let payload = getBulkDevicesPayloadForMultipleSites(payloads)
      console.log('Payload Devices: ', payload)
      this.isLoading = true
      let response = await this.updateSitePlanDevices(payload)
      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
      } else {
        await this.clearFields()
        this.showToast('Sites successfully updated', 'is-success')
        this.show(false)
        await this.deselectAllSites()
      }
      this.isLoading = false

    },
    async onMapClicked(position) {
      console.log('On map clicked edit devices:', position)
      if (!this.clickedMarker || this.mapType === 'gmap') {
        this.selectedDevice = null
      }
      this.clickedMarker = false
      this.zoomToObject()
    },
    onRotationChanged(data) {
      console.log('On rotation changed', data)
    },
    onMapInputClicked(position) {
      console.log('On map input clicked:', position)
    },
    getAddressString(location) {
      if (location.address) {
        if (location.place.includes(location.address)) {
          return `${location.place}`
        } else {
          return `${location.address}, ${location.place}`
        }

      } else {
        this.$buefy.toast.open({
          message: 'Can\'t get address for selected location',
          duration: 4000,
          type: 'is-danger'
        })
        return 'unknown'
      }
    },
    parseAddress(location) {
      if (location.address) {
        let name = location.address
        return {address: name, place: location.place}
      } else {

        let name = 'Unknown'
        this.$buefy.toast.open({
          message: 'Can\'t get address for selected location',
          duration: 4000,
          type: 'is-danger'
        })

        return {address: name, place: location.place}
      }
    },
    async onCoordinatesUpdated(data) {
      console.log('On update coordinates: ', data)
      this.isLoading = true
      let location = await this.getLocation(data.coordinates)
      this.isLoading = false


      let editedIndex = this.editedSites.findIndex(st => st._id === data.item._id)
      if (editedIndex !== -1) {
        this.editedSites[editedIndex].location.coordinates = [data.coordinates.lng, data.coordinates.lat]
        let parsed = this.parseAddress(location)
        this.editedSites[editedIndex].siteName = parsed.address
        this.editedSites[editedIndex].siteLocationName = parsed.place && parsed.place !== 'unknown' ? parsed.place : parsed.address

        if (this.selectedSite && this.selectedSite._id === this.editedSites[editedIndex]._id) {
          console.log('Device is selected')
          this.selectedSite = this.editedSites[editedIndex]
        }
      }


    },
    onMapEditAddressChanged(data) {
      console.log('On address changed:', data)

      let editedIndex = this.editedSites.findIndex(st => st._id === data._id)
      if (editedIndex !== -1) {
        this.editedSites[editedIndex].location.coordinates = data.location.coordinates

        let siteName = data.address ? data.address.split(',')[0] : 'Undefined'

        this.editedSites[editedIndex].address = data.address
        this.editedSites[editedIndex].siteName = siteName
        if (this.selectedSite && this.selectedSite._id === this.editedSites[editedIndex]._id) {
          console.log('Device is selected')
          this.selectedSite = this.editedSites[editedIndex]
        }
      }
    },
    async clearFields() {
      console.log('Clear fields edit device', this.deselectOnClose)
      if (this.deselectOnClose) {
        await this.deselectAllSites()
        this.selectedSite = null
        this.editedSites = null
      }
    },
    onSelectSite(site) {
      console.log('Site selected:', site)
      if (this.selectedSite && this.selectedSite._id === site._id) {
        this.selectedSite = null
      } else {
        this.selectedSite = site
        this.clickedMarker = true
      }
      this.zoomToObject()
    },
    click() {
      console.log('Clcked')
    },
    zoomToObject() {
      if (this.$refs.mapBoxInput) {
        this.$refs.mapBoxInput.zoomMapToSelectedSiteOrProject(true)
      } else if (this.$refs.gMapInput) {
        this.$refs.gMapInput.zoomMapToSelectedSiteOrProject(true)
      }
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    },
    getUserById(id) {
      return this.$store.getters.getUserById(id)
    },
    installationDateString(site) {
      return site.installationDate ? getDate(site.installationDate) : ''
    },
    decommissionDateString(site) {
      return site.decommissionDate ? getDate(site.decommissionDate) : ''
    },
    onInstallDropDownChanged() {
      this.installDropdownKey++
    },
    onDecommissionDropDownChanged() {
      this.decommissionDropdownKey++
    },
    planStatusColor(name) {
      if (this.$store.getters.getSettings) {
        let status = this.$store.getters.getSettings.planStatuses.find(status => status.name === name)
        if (status)
          return status.color
      }

      return '#ffffff'
    },
    onCopyToInstallDateClick(editedSite) {
      console.log('On copy Installation click', editedSite)
      this.isLoading = true
      setTimeout(() => {
        this.editedSites.forEach(site => {
          site.installationDate = editedSite.installationDate
        })
        this.isLoading = false
      }, 200)
    },
    onCopyToDecommissionDateClick(editedSite) {
      console.log('On copy Decommission click', editedSite)
      this.isLoading = true
      setTimeout(() => {
        this.editedSites.forEach(site => {
          site.decommissionDate = editedSite.decommissionDate
        })
        this.isLoading = false
      }, 200)
    },
    onCopyInstallationCrewClick(editedSite) {
      console.log('On copy Installation Crew Click', editedSite)
      this.isLoading = true
      setTimeout(() => {
        this.editedSites.forEach(site => {
          site.installationCrew = editedSite.installationCrew
        })
        this.isLoading = false
      }, 200)
    },
    onCopyDecommissionCrewClick(editedSite) {
      console.log('On copy Decommission Crew Click', editedSite)
      this.isLoading = true
      setTimeout(() => {
        this.editedSites.forEach(site => {
          site.decommissionCrew = editedSite.decommissionCrew
        })
        this.isLoading = false
      }, 200)
    },
  },
  computed: {
    ...mapGetters({
      mapType: 'getMapType',
      selectedProject: 'projectsModule/getSelectedProject'
    }),
    users() {
      console.log('Allowed users:', this.$store.getters.getAllowedUsers)
      return this.$store.getters.getAllowedUsers
    }
  }
}
</script>

<style lang="scss" scoped>
.simple-site-card {
  border-radius: 16px;
  background-color: #f7f7f7;
  box-shadow: 0 0.125em 0.125em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
}

.selected {
  background-color: #e3e3e3;
}

.modal-tab-content {
  height: 30vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 24px;
  padding-right: 16px;
}

.status-indicator {
  border-radius: 4px;
  background: black;
  width: 4px;
  height: 48px;
}

</style>