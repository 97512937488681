<template>
  <nav class="listing-group-bottom-left"
       style="background: rgba(255,255,255,0.8); border-radius: 8px; padding: 8px; margin-top: 64px; width: 170px">
    <section>
      <div class="columns pl-4 pr-2 pb-0 pt-3">
        <div class="column is-flex is-align-items-center p-0">
          <div class="mt-2 ml-1">
            <b-icon style="font-size: 24px; color: green" icon="hexagon"
                    size="is-small"></b-icon>
          </div>
          <div class="ml-2" style="min-width: 64px">Green</div>
        </div>
        <div class="column m-0 p-0 pt-2 is-flex">
          <b-field class="ml-auto">
            <b-checkbox v-model="green" @input="onGreenChanged"
                        native-value="default">
            </b-checkbox>
          </b-field>
        </div>
      </div>
      <div class="columns pl-4 pr-2 pb-0 pt-0">
        <div class="column is-flex is-align-items-center p-0">
          <div class="mt-2 ml-1">
            <b-icon style="font-size: 24px; color: #ffea00" icon="hexagon" size="is-small"></b-icon>
          </div>
          <div class="ml-2" style="min-width: 64px">Yellow</div>
        </div>
        <div class="column m-0 p-0 pt-2 is-flex">
          <b-field class="ml-auto">
            <b-checkbox v-model="yellow" @input="onYellowChanged"
                        native-value="default">
            </b-checkbox>
          </b-field>
        </div>
      </div>
      <div class="columns pl-4 pr-2 pb-0 pt-0">
      <div class="column is-flex is-align-items-center p-0">
        <div class="mt-2 ml-1">
          <b-icon style="font-size: 24px; color: red" icon="hexagon"
                  size="is-small"></b-icon>
        </div>
        <div class="ml-2" style="min-width: 64px">Red</div>
      </div>
      <div class="column m-0 p-0 pt-2 is-flex">
        <b-field class="ml-auto">
          <b-checkbox v-model="red" @input="onRedChanged"
                      native-value="default">
          </b-checkbox>
        </b-field>
      </div>
    </div>
      <div class="columns pl-4 pr-2 pb-3 pt-0">
        <div class="column is-flex is-align-items-center p-0">
          <div class="mt-2 ml-1">
            <b-icon style="font-size: 24px; color: black" icon="hexagon"
                    size="is-small"></b-icon>
          </div>
          <div class="ml-2" style="min-width: 64px">No Health</div>
        </div>
        <div class="column m-0 p-0 pt-2 is-flex">
          <b-field class="ml-auto">
            <b-checkbox v-model="noHealth" @input="onNoHealthChanged"
                        native-value="default">
            </b-checkbox>
          </b-field>
        </div>
      </div>
    </section>
  </nav>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "health-legend",
  data() {
    return {
      green: true,
      yellow: true,
      red: true,
      noHealth: true
    }
  },
  mounted() {
    this.green = this.legendSelection.green
    this.yellow = this.legendSelection.yellow
    this.red = this.legendSelection.red
    this.noHealth = this.legendSelection.noHealth
  },
  methods: {
    onGreenChanged(value) {
      this.$emit('onHealthFilterChanged', {
        green: value,
        yellow: this.yellow,
        red: this.red,
        noHealth: this.noHealth
      })
    },
    onYellowChanged(value) {
      this.$emit('onHealthFilterChanged', {
        green: this.green,
        yellow: value,
        red: this.red,
        noHealth: this.noHealth
      })
    },
    onRedChanged(value) {
      this.$emit('onHealthFilterChanged', {
        green: this.green,
        yellow: this.yellow,
        red: value,
        noHealth: this.noHealth
      })
    },
    onNoHealthChanged(value) {
      this.$emit('onHealthFilterChanged', {
        green: this.green,
        yellow: this.yellow,
        red: this.red,
        noHealth: value
      })
    }
  },
  computed: {
    ...mapGetters({
      legendSelection: 'liveMonitoringModule/legendSelection',
    }),
  }
}
</script>

<style scoped>

</style>