<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <add-change-permission ref="addModal"></add-change-permission>
    <div class="box p-4 mb-6 is-flex is-flex-direction-column">
      <div class="is-flex">
        <div class="is-size-4 has-text-weight-semibold ml-2">Permissions</div>
        <view-guard :permission="'permission:create'" class="ml-auto">
          <b-button type="is-success" @click="onAddPermission" icon-left="plus">Create new Permission</b-button>
        </view-guard>
      </div>
      <hr class="m-0 mt-2 p-0 mb-5">
      <b-table v-if="allPermissions && allPermissions.length > 0"
               key="incident_table"
               ref="table"
               :data="permissions"
               hoverable
               paginated
               per-page="10"
               current-page.sync="1"
               :pagination-simple="false"
               pagination-position="bottom"
               default-sort-direction="asc"
               :pagination-rounded="false"
               sort-icon="arrow-up"
               sort-icon-size="is-small"
               @sort="sortClicked"
               default-sort="name"
               aria-next-label="Next page"
               aria-previous-label="Previous page"
               aria-page-label="Page"
               aria-current-label="Current page"
               :page-input="false">
        <b-table-column field="name" label="Permission name" sortable>
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="search" tooltip="Filter by name" element-id="132232765233"
                          :table-header-data="tableHeaderData" @onSort="onSort">
            </table-header>
          </template>
          <template v-slot="props">
            {{ props.row.name }}
          </template>
        </b-table-column>
        <b-table-column field="description" label="Description" sortable>
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="search" tooltip="Filter by description"
                          element-id="4672187624434"
                          :table-header-data="tableHeaderData" @onSort="onSort">
            </table-header>
          </template>
          <template v-slot="props">
            {{ props.row.description }}
          </template>
        </b-table-column>
        <b-table-column field="functionName" label="Function name" sortable>
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="search" tooltip="Filter by function name"
                          element-id="4672387224434"
                          :table-header-data="tableHeaderData" @onSort="onSort">
            </table-header>
          </template>
          <template v-slot="props">
            {{ props.row.functionName }}
          </template>
        </b-table-column>
        <b-table-column field="actions" label="Actions" sortable width="100">
          <template v-slot:header="{ column }">
            <table-header :column="column" header-type="none" tooltip="" element-id="467545sd224434"
                          :table-header-data="tableHeaderData" @onSort="onSort">
            </table-header>
          </template>
          <template v-slot="props">
            <div class="is-flex">
              <view-guard :permission="'permission:update'" class="mr-1 ml-auto">
                <b-tooltip position="is-top" label="Update Permission" :delay="1000" type="is-dark">
                  <b-button size="is-small" icon-left="pencil" @click="onEditPermission(props.row)"></b-button>
                </b-tooltip>
              </view-guard>
              <view-guard :permission="'permission:delete'">
                <b-tooltip position="is-top" label="Delete Permission" :delay="1000" type="is-dark">
                  <b-button size="is-small" icon-left="delete" type="is-danger" outlined
                            @click="confirmDelete(props.row)"></b-button>
                </b-tooltip>
              </view-guard>
            </div>
          </template>
        </b-table-column>
      </b-table>
      <div v-else class="is-flex is-align-items-center dashed-border m-2">
        <h4 class="is-size-4 ml-auto mr-auto has-text-grey-light has-text-weight-light">Click on "+" button to add first
          permission</h4>
      </div>
    </div>
  </div>

</template>

<script>
import TableHeader from "@/components/table-header/table-header.vue";
import {mapActions, mapGetters} from "vuex";
import AddChangePermission from "@/modules/uac-module/components/add-change-permission/add-change-permission.vue";
import ViewGuard from "@/components/view-guard/view-guard.vue";

export default {
  name: "permissions-list",
  components: {ViewGuard, AddChangePermission, TableHeader},
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    ...mapActions({
      setTableHeaderData: 'uacModule/setPermissionsTableHeaderData',
      deletePermission: 'uacModule/deletePermission',
      getRoles: 'uacModule/getRoles'
    }),
    sortClicked(field, order) {
      console.log('Sorted: ', field, order)
      let tableHeaderData = this.tableHeaderData
      tableHeaderData.activeSorted = {field: field, order: order}
      this.setTableHeaderData(tableHeaderData)
    },
    onSort(tableHeaderData) {
      this.setTableHeaderData(tableHeaderData)
    },
    onEditPermission(permission) {
      console.log('Clicked: ', permission)
      this.$refs.addModal.show(true, permission.id)
    },
    confirmDelete(permission) {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: 'This action will remove permission from all roles also. Are you sure you want to delete ' + permission.name + ' ?',
        onConfirm: () => this.delete(permission)
      })
    },
    async delete(permission) {
      console.log('Delete permission: ', permission)
      this.isLoading = true
      let response = await this.deletePermission(permission.id)
      await this.getRoles()
      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
      } else {
        this.showToast('Permission successfully deleted', 'is-success')
      }
      this.isLoading = false
    },
    onAddPermission() {
      this.$refs.addModal.show(true)
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
  computed: {
    ...mapGetters({
      tableHeaderData: 'uacModule/getPermissionsTableHeaderData',
      permissions: 'uacModule/getPermissionsTable',
      allPermissions: 'uacModule/getPermissions'
    }),
  }
}
</script>

<style scoped>

</style>