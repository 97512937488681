<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <div class="is-flex">
      <div class="box ml-5 mr-5 mt-6 p-0" style="width: 100%">
        <div class="is-flex">
          <div class="is-size-5 has-text-grey-light p-4">Device configuration templates</div>
          <view-guard :permission="'conf-template:create'" class="ml-auto mr-3 mt-auto mb-auto">
            <b-tooltip label="Add Template" :delay="1000" type="is-dark">
              <a v-on:click.stop @click="onAddNewConfiguration">
                <b-icon icon="plus-circle-outline">
                </b-icon>
              </a>
            </b-tooltip>
          </view-guard>
        </div>
        <hr class="p-0 m-0">
        <div class="p-5">
          <div v-if="!editConfMode">
            <div v-for="conf in configurations" :key="conf">
              <div class="columns mb-4" style="background: #f4f4f4; border-radius: 8px">
                <div class="column is-3">
                  <div class="is-size-7 has-text-grey-light">Name</div>
                  <div class="is-size-6 has-text-weight-semibold">{{ conf }}</div>
                </div>
                <div class="column is-7">
                  <div class="is-size-7 has-text-grey-light">Fields</div>
                  <div class="is-size-6 has-text-weight-semibold">{{ getFields(conf) }}</div>
                </div>
                <div class="column is-2 is-flex">
                  <div class="ml-auto mt-auto mb-auto">
                    <view-guard :permission="'conf-template:update'" class="mr-1">
                      <b-tooltip label="Edit template" :delay="1000" type="is-dark">
                        <a v-on:click.stop @click="onClickEdit(conf)">
                          <b-icon icon="pencil-circle-outline">
                          </b-icon>
                        </a>
                      </b-tooltip>
                    </view-guard>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="addConfMode || editConfMode" class="mt-4">
            <div class="columns" style="background: #f4f4f4; border-radius: 8px">
              <div class="column is-3">
                <b-field>
                  <b-select placeholder="Configuration name *" v-model="newConf.name" rounded required>
                    <option v-for="option in configurationOptions" :value="option" :key="option">{{ option }}</option>
                  </b-select>
                </b-field>
              </div>
              <div class="column is-7">
                <b-field>
                  <b-input ref="name" placeholder="Fields - comma separated *" rounded v-model="newConf.fields"
                           type="text" required></b-input>
                </b-field>
              </div>
              <div class="column is-2 is-flex is-align-items-center">
                <b-button @click="onCancelAddConfiguration" size="is-small" class="ml-auto mr-1" type="is-danger"
                          outlined>CANCEL
                </b-button>
                <b-button @click="onSaveConfiguration" size="is-small" type="is-primary">SAVE</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewGuard from "@/components/view-guard/view-guard.vue";

export default {
  name: "device-configuration-templates",
  components: {ViewGuard},
  data() {
    return {
      isLoading: false,
      addConfMode: false,
      editConfMode: false,
      newConf: {
        name: null,
        fields: null
      }
    }
  },
  methods: {
    getFields(device) {
      let fieldsStr = ''
      let fields = Object.values(this.$store.getters.getSettings.configurations[device])
      fields.forEach(field => {
        fieldsStr += `${field.field}, `
      })
      fieldsStr = fieldsStr.trim()
      fieldsStr = fieldsStr.slice(0, -1);
      return fieldsStr
    },
    onAddNewConfiguration() {
      this.clearNewConf()
      this.editConfMode = false
      this.addConfMode = true
    },
    onCancelAddConfiguration() {
      this.clearNewConf()
      this.addConfMode = false
      this.editConfMode = false
    },
    clearNewConf() {
      this.newConf = {
        name: null,
        fields: null
      }
    },
    onClickEdit(template) {
      this.newConf = {
        name: template,
        fields: this.getFields(template)
      }
      this.addConfMode = false
      this.editConfMode = true
    },
    async onSaveConfiguration() {
      console.log('On save conf clicked')
      if (this.newConf.name && this.newConf.fields) {
        let fields = this.newConf.fields.replace(/\s/g, "");
        fields = fields.split(',');
        console.log('Call save conf:', fields)

        let payload = {
          deviceType: this.newConf.name,
          configurationFields: fields
        }
        this.isLoading = true
        if (this.addConfMode) {
          let response = await this.$store.dispatch('addNewConfiguration', payload)
          if (response.errorMessage) {
            this.showToast(response.errorMessage, 'is-danger')
          } else {
            this.newConf = {
              name: null,
              fields: null
            }
            this.addConfMode = false
            this.showToast('New configuration template successfully created', 'is-success')
          }
        } else if (this.editConfMode) {
          let confFields = Object.values(this.$store.getters.getSettings.configurations[this.newConf.name])
          let id = confFields[0].id
          payload.id = id
          let response = await this.$store.dispatch('editConfigurationTemplate', payload)
          if (response.errorMessage) {
            this.showToast(response.errorMessage, 'is-danger')
          } else {
            this.newConf = {
              name: null,
              fields: null
            }
            this.editConfMode = false
            this.showToast('Configuration template successfully updated', 'is-success')
          }
        }
        this.isLoading = false

      } else {
        console.log('Empty fields')
        this.showToast('Please fill all required fields', 'is-danger')
      }
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
  computed: {
    configurations() {
      if (this.$store.getters.getSettings) {
        let confs = this.$store.getters.getSettings.configurations
        if (confs) {
          return Object.keys(confs)
        }
      }
      return []
    },
    configurationOptions() {
      if (this.$store.getters.getSettings) {
        return this.$store.getters.getSettings.configurationTemplatesTypes
      }
      return []
    }
  }
}
</script>

<style scoped>

</style>