<template>
  <div>
    <div id="map-container-calibration" :style="{'height': mapHeight + 80 + 'px'}" style="line-height: 1">
      <MglMap
          ref="mapboxMapHome"
          container="map-container-calibration"
          :accessToken="$env.VUE_APP_ACCESS_TOKEN"
          :center="mapCenter"
          :zoom="mapZoom"
          :style="{'height': mapHeight + 80 + 'px'}"
          :mapStyle="mapStyle"
          @click="onMapClicked"
          @load="onMapLoaded">
        <!--        <draw-controls @onDrawModeSelected="onDrawModeSelected" @onDrawModeDelete="onDeleteItem"
                               mode="full"></draw-controls>-->
        <!--        <div class="listing-group-top-left" style="top: 64px;">
                  <b-button v-if="selectedItem">{{ selectedItem.wayLabel }} -> {{ selectedItem.wayKitLabel }}</b-button>
                </div>-->
      </MglMap>
    </div>
  </div>
</template>

<script>

import Mapbox from "mapbox-gl";
import {mapGetters, mapState} from "vuex";
import {MglMap} from "vue-mapbox";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import {point, distance} from "@turf/turf";
import {VideoCalibrationCategory} from "@/utils/utils";

export default {
  name: "draw-map",
  props: {
    selectedTab: null
  },
  components: {MglMap},
  data() {
    return {
      mapStyle: '',
      mapCenter: [144.94444, -37.80193],
      mapZoom: 18,
      drawMode: null,
      mapRotation: 0,
      allDrawings: null,
      highResolutionStyle: null,
      mapHeight: 200,
      features: null,
      crossingDistanceFeatures: null
    }
  },
  created() {
    this.map = null
    if (this.selectedDevice && this.selectedDevice.location) {
      this.mapCenter = this.selectedDevice.location.coordinates
    }

    const rasterTilesSource = {
      type: 'raster',
      tiles: [
        'https://map.webtunel.com/download?z={z}&y={y}&x={x}'
      ],
      tileSize: 256,
      attribution: 'Map tiles by RTT'
    };
    const rasterTilesLayer = {
      id: 'simple-tiles',
      type: 'raster',
      source: 'raster-tiles',
      minzoom: 18,
      maxzoom: 22
    };
    this.highResolutionStyle = {
      version: 8,
      sources: {
        'raster-tiles': rasterTilesSource
      },
      layers: [rasterTilesLayer]
    }

    this.mapStyle = this.highResolutionStyle
    //this.mapStyle = 'mapbox://styles/mapbox/streets-v11?optimize=true'
  },
  mounted() {
    //this.mapStyle = 'mapbox://styles/mapbox/streets-v11?optimize=true'
    if (this.selectedDevice && this.selectedDevice.location) {
      this.mapCenter = this.selectedDevice.location.coordinates
    }
    this.draw = null;
  },
  methods: {
    setMapHeight(mapHeight) {
      this.mapHeight = mapHeight
      if (this.map) {
        this.map.resize()
        setTimeout(() => {
          this.map.resize()
        }, 1000)
      }
    },
    onDrawModeSelected(mode) {
      this.drawMode = mode
      if (this.drawMode) {
        if (this.drawMode.includes('add')) {
          this.map._canvas.style.cursor = "crosshair";
        } else if (this.drawMode === 'move') {
          this.map._canvas.style.cursor = "move";
        } else if (this.drawMode === 'rotate') {
          this.map._canvas.style.cursor = "default";
        } else {
          this.map._canvas.style.cursor = "grab";
        }
      }

      console.log('Draw mode', this.drawMode)
    },
    onMapClicked(event) {
      console.log('On map clicked', event)
      if (this.drawMode && this.drawMode.includes('add')) {
        this.$emit('onMapInputClicked', {drawMode: this.drawMode, coordinates: event.mapboxEvent.lngLat})
      } else {
        this.$emit('onMapClicked', event.mapboxEvent.lngLat)
      }
    },
    onMapLoaded(map) {
      console.log('On map loaded')
      let obj = this
      this.map = map.map;
      this.addDraw()
      setTimeout(() => {
        this.map.resize()
      }, 300)
    },
    addDraw() {
      if (this.draw) {
        this.map.removeControl(this.draw)
      }
      if (this.selectedTab === 0 || this.selectedTab === 3) {
        this.draw = new MapboxDraw({
          controls: {
            line_string: true,
            trash: true
          },
          displayControlsDefault: false,
          styles: [
            {
              'id': 'gl-draw-line-inactive',
              'type': 'line',
              'filter': ['all', ['==', 'active', 'false'],
                ['==', '$type', 'LineString'],
                ['!=', 'mode', 'static']
              ],
              'layout': {
                'line-cap': 'round',
                'line-join': 'round'
              },
              'paint': {
                'line-color': '#ffffff',
                'line-width': 4
              }
            },
            {
              'id': 'gl-draw-line-active',
              'type': 'line',
              'filter': ['all', ['==', '$type', 'LineString'],
                ['==', 'active', 'true']
              ],
              'layout': {
                'line-cap': 'round',
                'line-join': 'round'
              },
              'paint': {
                'line-color': '#fc2d07',
                'line-dasharray': [0.2, 2],
                'line-width': 4
              }
            },]
        });
        this.map.addControl(this.draw, 'top-right');

        this.map.on('draw.create', this.updateDrawn);
        this.map.on('draw.delete', this.onDeleteDrawn);
        this.map.on('draw.update', this.updateDrawn);

        setTimeout(() => {
          this.draw.deleteAll()
          if (this.selectedTab === 3) {
            if (this.features) {
              this.draw.add(this.features)
            }
          } else if (this.selectedTab === 0) {
            if (this.crossingDistanceFeatures) {
              this.draw.add(this.crossingDistanceFeatures)
            }
          }
          this.map.resize()

        }, 500)
      }

    },
    removeDraw() {
      if (this.map && this.draw) {
        this.map.removeControl(this.draw)
        this.draw = null
      }
    },
    clearDrawings() {
      this.draw.deleteAll()
    },
    updateDrawn(event) {
      console.log('On draw changed: ', event)
      console.log('All drawings', this.draw.getAll())

      if (this.selectedTab === 3) {
        this.features = JSON.parse(JSON.stringify(this.draw.getAll()))
        this.draw.deleteAll()

        if (this.features && this.features.features && this.features.features.length > 0) {
          this.features.features.forEach((feature, index) => {
            feature['id'] = `${VideoCalibrationCategory.SPEED_LINE}_${index}`
          })
        }
        this.draw.add(this.features)
      } else if (this.selectedTab === 0) {
        this.crossingDistanceFeatures = JSON.parse(JSON.stringify(this.draw.getAll()))
        this.draw.deleteAll()

        if (this.crossingDistanceFeatures && this.crossingDistanceFeatures.features && this.crossingDistanceFeatures.features.length > 0) {
          this.crossingDistanceFeatures.features.forEach((feature, index) => {
            feature['id'] = `${VideoCalibrationCategory.STAGE_POLYGON}_${index}`
          })
        }
        this.draw.add(this.crossingDistanceFeatures)
      }

      this.$emit('onLinesDrawn', this.draw.getAll())

      console.log('All drawings with id', this.draw.getAll())

    },
    onDeleteDrawn(event) {
      console.log('On draw changed: ', event)
      console.log('All drawings', this.draw.getAll())
      if (this.selectedTab === 3) {
        this.features = JSON.parse(JSON.stringify(this.draw.getAll()))
      } else if (this.selectedTab === 0) {
        this.crossingDistanceFeatures = JSON.parse(JSON.stringify(this.draw.getAll()))
      }

      this.$emit('onLinesDrawn', this.draw.getAll())
    },
    deleteFeature(index) {
      console.log('On delete line with index: ', index)
      if (this.selectedTab === 3) {
        if (this.features && this.features.features && this.features.features.length > 0) {
          if (index < this.features.features.length) {
            this.features.features.splice(index, 1)
            this.draw.deleteAll()
            this.draw.add(this.features)
            this.$emit('onLinesDrawn', this.draw.getAll())
          }
        }
      } else if (this.selectedTab === 0) {
        if (this.crossingDistanceFeatures && this.crossingDistanceFeatures.features && this.crossingDistanceFeatures.features.length > 0) {
          if (index < this.crossingDistanceFeatures.features.length) {
            this.crossingDistanceFeatures.features.splice(index, 1)
            this.draw.deleteAll()
            this.draw.add(this.crossingDistanceFeatures)
            this.$emit('onLinesDrawn', this.draw.getAll())
          }
        }
      }


      console.log('After delete features: ', this.features)
      console.log('After delete crossing: ', this.crossingDistanceFeatures)
      if(this.draw){
        console.log('After delete draw: ', this.draw.getAll())
      }


    },
    setFeature(features) {
      this.features = features
    },
    setCrossingDistanceFeature(features) {
      this.crossingDistanceFeatures = features
    },
    changeStyle(style) {
      this.mapStyle = style
    },
    mapboxGl() {
      return Mapbox
    },
    markerClicked(item) {
      console.log('Marker clicked map input:', item)
    },
    zoomMapToSelectedSiteOrProject() {
      const startPoint = {
        center: this.mapCenter,
        zoom: 13,
        pitch: 0,
        bearing: 0
      };
      this.map.flyTo(startPoint)
    },
    onClose() {
      this.features = null
      this.crossingDistanceFeatures = null
      this.removeDraw()
    },
    addOrRemoveDraw() {
      if(this.map){
        if (this.selectedTab === 3 || this.selectedTab === 0) {
          this.addDraw()
        } else {
          this.removeDraw()
        }
      }

    }
  },
  computed: {
    ...mapState({}),
    ...mapGetters({
      getMapStyle: 'getMapStyle',
      selectedDevice: 'dataProcessingModule/getSelectedProcessingDevice'
    }),
  },
  watch: {
    selectedTab() {
      this.addOrRemoveDraw()
    }
  }

}
</script>

<style scoped>
</style>