<template>
  <div>
    <device-configuration-templates></device-configuration-templates>
    <system-statuses></system-statuses>
    <to-do-templates></to-do-templates>
    <device-settings-templates></device-settings-templates>
  </div>
</template>

<script>
import DeviceConfigurationTemplates
  from "@/components/device-configuration-templates/device-configuration-templates.vue";
import SystemStatuses from "@/components/system-statuses/system-statuses.vue";
import ToDoTemplates from "@/components/to-do-templates/to-do-templates.vue";
import DeviceSettingsTemplates from "@/components/device-settings-templates/device-settings-templates.vue";

export default {
  name: "settings-page",
  components: {DeviceSettingsTemplates, ToDoTemplates, SystemStatuses, DeviceConfigurationTemplates},
  data() {
    return {}
  },
  mounted() {
    console.log('Settings', this.$store.getters.getSettings)
  },
  methods: {},
  computed: {}
}
</script>

<style scoped>

</style>