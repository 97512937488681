<template>
  <div class="box card-box m-0 p-0" style="border-radius: 16px">
    <div class="columns m-0 p-3">
      <div class="column m-0 p-0">
        <div class="is-size-5 has-text-weight-semibold">{{ device.name }}</div>
      </div>
      <div class="column m-0 p-0">
        <div class="status ml-auto" :style="{'background': device.currentStatus.color}">
          {{ device.currentStatus.name }}
        </div>
      </div>
    </div>
    <div class="columns m-0 p-0 pl-3 pr-3">
      <div class="column p-0 m-0">
        <div class="is-size-7">Device:</div>
      </div>
      <div class="column is-flex p-0 m-0">
        <div class="ml-auto is-size-7 has-text-weight-semibold">{{ device.warehouseId }}</div>
      </div>
    </div>
    <div class="columns m-0 p-0 pl-3 pr-3">
      <div class="column p-0 m-0">
        <div class="is-size-7">Site:</div>
      </div>
      <div class="column is-flex p-0 m-0">
        <div class="ml-auto is-size-7 has-text-weight-semibold">{{ device.siteName }}</div>
      </div>
    </div>
    <hr class="solid-thin mt-3 mb-1">
    <div class="columns m-0 p-0 pl-3 pr-3 pb-2 is-align-items-center">
      <div class="column p-0 m-0 is-flex is-flex-direction-column is-align-items-start">
        <div class="is-flex is-align-items-center">
          <b-icon icon="battery-high" class="has-text-grey" style="font-size: 16px" size="is-small"></b-icon>
          <div class="is-size-6">12.4V</div>
        </div>

      </div>
      <div class="column p-0 m-0 is-flex is-flex-direction-column is-align-items-center">
        <div class="is-flex is-align-items-center">
          <b-icon icon="thermometer" class="has-text-grey" style="font-size: 16px" size="is-small"></b-icon>
          <div class="is-size-6">37°C</div>
        </div>
      </div>
      <div class="column p-0 m-0 is-flex is-flex-direction-column is-align-items-end">
        <div class="is-flex is-align-items-center">
          <b-icon icon="water-percent" class="has-text-grey" style="font-size: 18px" size="is-small"></b-icon>
          <div class="is-size-6">86%</div>
        </div>
      </div>
    </div>
    <!--    <b-button style="margin-top: 32px" type="is-primary" expanded>View details</b-button>-->
  </div>
</template>

<script>
export default {
  name: "device-popup-content",
  props: {
    device: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.status {
  color: white;
  height: 20px;
  width: fit-content;
  border-radius: 4px;
  background: #B1B1B1;
  padding: 0px 8px;
}

hr.solid-thin {
  background: none;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding: 0;
  margin: 0;
}
</style>