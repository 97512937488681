export default {
    liveMonitoring: (state) => {
        return state.liveMonitoring
    },
    selectedProjects:(state) => {
        return state.selectedProjects
    },
    legendSelection:(state) => {
        return state.legendSelection
    }
}
