<template>
  <nav class="listing-group-bottom-left"
       style="background: rgba(255,255,255,0.8); border-radius: 8px; padding: 8px; margin-top: 64px; width: 190px;">
    <section>
      <div class="p-4 pr-5">
        <div class="columns" v-for="key in Object.keys(legend)" :key="key">
          <div class="column is-flex is-align-items-center p-0">
            <div class="mt-2 ml-1">
              <b-icon style="font-size: 24px;" :style="{ 'color': legend[key].color }" icon="hexagon"
                      size="is-small"></b-icon>
            </div>
            <div class="ml-2" style="min-width: 120px">{{legend[key].name}}</div>
          </div>
          <div class="column m-0 p-0 pt-2 is-flex">
            <b-field class="ml-auto">
              <b-checkbox v-model="legend[key].value" @input="onChanged"
                          native-value="default">
              </b-checkbox>
            </b-field>
          </div>
        </div>
      </div>
    </section>
  </nav>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "dp-legend",
  data() {
    return {
      legend:{}
    }
  },
  mounted() {
    this.legend = JSON.parse(JSON.stringify(this.dpLegendSelection))
  },
  methods: {
    ...mapActions({
      setDpLegendSelection: 'dataProcessingModule/setDpLegendSelection',
    }),
    onChanged(value) {
      console.log('On changed: ', this.legend)
      this.setDpLegendSelection(this.legend)
    }
  },
  computed: {
    ...mapGetters({
      dpLegendSelection: 'dataProcessingModule/dpLegendSelection',
    }),
  },
  watch: {
    dpLegendSelection: {
      handler: function (newVal, oldVal) {
        this.legend = JSON.parse(JSON.stringify(this.dpLegendSelection))
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>