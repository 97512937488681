import { render, staticRenderFns } from "./notes-modal.vue?vue&type=template&id=458e433d&scoped=true"
import script from "./notes-modal.vue?vue&type=script&lang=js"
export * from "./notes-modal.vue?vue&type=script&lang=js"
import style0 from "./notes-modal.vue?vue&type=style&index=0&id=458e433d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "458e433d",
  null
  
)

export default component.exports