import Vue from "vue";

export default {
    setQueryData: async ({commit}, queryData) => {
        commit('setQueryData', queryData)
    },
    setSelectedProcessingProject: async ({commit}, selectedProject) => {
        if (selectedProject) {
            let config = {
                method: 'get',
                url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-data-processing/data-processing-report',
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {projectId: selectedProject._id},
            };
            return await Vue.prototype.$http(config)
                .then((response) => {
                    console.log('Get processing project response: ', response)
                    if (response.status === 200) {
                        commit('setSelectedProcessingProject', response.data)
                    }
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });

        } else {
            commit('setSelectedProcessingProject', null)
            commit('setSelectedVideoCalibration', null)
            commit('setSelectedProcessingDevice', null)
        }
    },
    setSelectedProcessingDevice: async ({commit}, processingDevice) => {
        if (processingDevice) {
            commit('setSelectedProcessingDevice', processingDevice)
        } else {
            commit('setSelectedProcessingDevice', null)
        }
    },
    setSelectedVideoCalibrations:  async ({commit}, selectedCalibration) => {
        if (selectedCalibration) {
            commit('setSelectedVideoCalibration', selectedCalibration)
        } else {
            commit('setSelectedVideoCalibration', null)
        }
    },
    selectCalibration:  async ({commit}, calibration) => {
        if (calibration) {
            commit('selectCalibration', calibration)
        } else {
            commit('selectCalibration', null)
        }
    },
    saveCalibration: async ({commit}, calibrationPayload) => {
            let config = {
                method: 'post',
                url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-data-processing/video-calibration',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: calibrationPayload,
            };
            return await Vue.prototype.$http(config)
                .then((response) => {
                    console.log('Get save video calibration response: ', response)
                    if (response.status === 200) {
                        commit('replaceVideoCalibrations', response.data)
                    }
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
    },
    createTasks: async ({commit}, taskPayload) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-data-processing/tasks',
            headers: {
                'Content-Type': 'application/json'
            },
            data: taskPayload,
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Create Tasks response: ', response)
                if (response.status === 200) {
                    if(response.data && response.data.dataDeviceId){
                        commit('updateDeviceTasks', response.data)
                    }
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    pushTasksToSqs: async ({commit}, payload) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-data-processing/tasks/push',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload,
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Push Tasks response: ', response)
                if (response.status === 200) {
                    if(response.data && response.data.dataDeviceId){
                        commit('updateDeviceTasks', response.data)
                    }
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    setDpLegendSelection: async ({commit}, selection) => {
        commit('setDpLegendSelection', selection)
    },
    getVideoUrl: async ({commit}, videoMetaDataId) => {
        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-data-processing/video-presigned-url',
            headers: {
                'Content-Type': 'application/json'
            },
            params: {videoMetaDataId: videoMetaDataId},
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Change video response: ', response)
/*                if (response.status === 200) {
                    commit('changeSelectedCalibrationVideo', response.data)
                }*/
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
}

