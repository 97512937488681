<template src="./template.html"></template>
<script>
export default {
  name: 'Register-Page',
  data() {
    return {
      username: null,
      password: null,
      name: null,
      organisation: null,
      location: null,
      phone: null,
      code: null,
      codeForm: false
    }
  },
  mounted() {
  },
  methods: {
    register: async function () {
      this.$emit('showLoading', true)
      this.$auth.signUp({
        username: this.username,
        password: this.password,
        attributes: {
          name: this.name,
          phone_number: this.phone,
          email: this.username,
          'custom:role': 'Traffic Engineer',
          'custom:organisation': this.organisation,
          'custom:location': this.location
        }
      }).then((user) => {
        this.$buefy.notification.open({
          message: 'Verification code is sent to email: ' + this.username,
          type: 'is-success'
        })
        this.codeForm = true;
        this.$emit('showLoading', false)
      }).catch((error) => {
        this.$buefy.notification.open({
          duration: 5000,
          message: error.message,
          position: 'is-bottom-right',
          type: 'is-danger',
          hasIcon: false
        })
        this.$emit('showLoading', false)
      });
    },
    sendCodeAgain: async function () {
      try {
        await this.$auth.resendSignUp(this.username);
        console.log('code resent successfully');
      } catch (err) {
        console.log('error resending code: ', err);
      }
    },
    confirmSignUp() {
      this.$emit('showLoading', true)
      this.$auth.confirmSignUp(this.username, this.code).then((data) => {
        this.$buefy.notification.open({
          message: 'Verification code is correct, you can Login now',
          type: 'is-success'
        })
        this.$emit('showLoading', false)
        this.$router.push('/')
      }).catch((error) => {
        this.$emit('showLoading', false)
        this.$buefy.notification.open({
          duration: 5000,
          message: error.message,
          position: 'is-bottom-right',
          type: 'is-danger',
          hasIcon: false
        })
      });
    },
    toLogin() {
      this.$router.push('/')
    }
  }
}
</script>
<style scoped lang="scss" src="./style.scss"></style>
