<template>
  <div class="search-container" style="max-width: 430px">
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <div class="box m-0 p-0" style="border-radius: 8px">
      <b-field>
        <b-input placeholder="Search for project..."
                 ref="search"
                 v-model="searchText"
                 @input="onSearchTextChanged"
                 type="search"
                 style="width: 430px"
                 @focus="onFocus"
                 @blur="onBlur"
                 icon="magnify"
                 custom-class="search-field">
        </b-input>
      </b-field>
    </div>
    <transition name="fade">
      <div v-if="showResults" class="box mt-2 is-flex is-flex-direction-column" style="height: 100%; padding: 12px">
        <div class="columns pt-4 pl-4 pr-4">
          <div class="column m-0 p-0 pr-1">
            <b-field>
              <b-dropdown
                  expanded
                  :scrollable="false"
                  :max-height="200"
                  @change="onSortProjectTypeChanged"
                  v-model="projectTypeOption"
                  aria-role="list">
                <template #trigger>
                  <my-button
                      expanded
                      :label="projectTypeOption.text"
                      :icon-left="projectTypeOption.icon"
                      type="is-light"
                      icon-right="menu-down"/>
                </template>
                <b-dropdown-item
                    v-for="(option, index) in projectTypeOptions"
                    :key="index"
                    :value="option" aria-role="listitem">
                  <div class="media">
                    <b-icon v-if="option.icon && option.icon.length > 0" class="media-left"
                            :icon="option.icon"></b-icon>
                    <div class="media-content">
                      <h3>{{ option.text }}</h3>
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </b-field>
          </div>
          <div class="column m-0 p-0 pl-1">
            <b-field>
              <b-dropdown
                  expanded
                  :scrollable="false"
                  :max-height="200"
                  @change="onSortActivityChanged"
                  v-model="activeOption"
                  aria-role="list">
                <template #trigger>
                  <my-button
                      expanded
                      :label="activeOption.text"
                      :icon-left="activeOption.icon"
                      type="is-light"
                      icon-right="menu-down"/>
                </template>
                <b-dropdown-item
                    v-for="(option, index) in activeOptions"
                    :key="index"
                    :value="option" aria-role="listitem">
                  <div class="media">
                    <b-icon class="media-left" :icon="option.icon"></b-icon>
                    <div class="media-content">
                      <h3>{{ option.text }}</h3>
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </b-field>
          </div>
        </div>
        <div class="columns pl-4 pr-4">
          <div class="column m-0 p-0">
            <b-field class="m-0 p-0 pt-2" expanded>
              <b-dropdown
                  expanded
                  :scrollable="false"
                  :max-height="200"
                  @change="onSortChanged"
                  v-model="sortOption"
                  aria-role="list">
                <template #trigger>
                  <my-button
                      expanded
                      :label="sortOption.text"
                      :icon-left="sortOption.icon"
                      type="is-light"
                      icon-right="menu-down"/>
                </template>
                <b-dropdown-item
                    v-for="(option, index) in sortOptions"
                    :key="index"
                    :value="option" aria-role="listitem">
                  <div class="media">
                    <b-icon class="media-left" :icon="option.icon"></b-icon>
                    <div class="media-content">
                      <h3>{{ option.text }}</h3>
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </b-field>
          </div>
        </div>
        <h6 class="mt-4 ml-1 is-size-7 has-text-grey-light">RESULTS</h6>
        <hr class="m-0 ml-1 p-0">
        <div style="height: calc(100vh - 120px - 300px); overflow-y: auto; padding: 12px 4px 8px 4px;">
          <div>
            <div class="box search-box" v-for="project in filteredProjects"
                 :class="{'selected' : selectedProject && selectedProject._id === project._id}"
                 :key="project._id" @click="toggleSelectedProject(project)">
              <div class="is-flex">
                <b-tooltip :label="project.status" class=" mr-1" :delay="1000" type="is-dark" position="is-right">
                  <b-icon :icon="activityIcon(project)" size="is-small"
                          class="has-text-grey"></b-icon>
                </b-tooltip>
                <div class="ml-1">{{ project.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import MyButton from "@/components/my-button/my-button.vue";

export default {
  name: "search-box-assessment",
  components: {MyButton},
  data() {
    return {
      searchText: '',
      isLoading: false,
      showResults: false,
      projectTypeOption: {icon: '', text: 'All', value: 'ALL'},
      activeOption: {icon: 'filter-off', text: 'All', value: 'All'},
      sortOption: {icon: 'sort-alphabetical-ascending', text: 'Alphabetically ascending', value: 'ASCENDING'},
      sortOptions: [
        {icon: 'sort-alphabetical-ascending', text: 'Alphabetically ascending', value: 'ASCENDING'},
        {icon: 'sort-alphabetical-descending', text: 'Alphabetically descending', value: 'DESCENDING'}
      ],
      activeOptions: [
        {icon: 'filter-off', text: 'All', value: 'ALL'},
        {icon: 'calendar-check', text: 'Active', value: 'Active'},
        {icon: 'calendar-lock', text: 'Inactive', value: 'Inactive'},
        {icon: 'archive-lock-outline', text: 'Archived', value: 'Archived'},
      ]
    }
  },
  methods: {
    ...mapActions({
      fetchAllProjects: 'projectsModule/getAllProjects',
      setSelectedMonitoringProject: 'assessmentModule/getMonitoringProject',
      setQueryData: 'assessmentModule/setQueryData',
      getMonitoringData: 'assessmentModule/fetchMonitoringData'
    }),
    onClickedOutside() {
      console.log('On clicked outside')
      this.showResults = false
    },
    onSortProjectTypeChanged(event) {
      console.log('On sort changed', event)
      let queryData = this.queryData
      queryData.projectType = event
      this.setQueryData(queryData)
    },
    onSortActivityChanged(event) {
      console.log('On sort changed', event)
      let queryData = this.queryData
      queryData.active = event
      this.setQueryData(queryData)
    },
    onSortChanged(event) {
      console.log('On sort changed', event)
      let queryData = this.queryData
      queryData.sort = event
      this.setQueryData(queryData)
    },
    async toggleSelectedProject(project) {
      if (this.selectedProject && this.selectedProject._id === project._id) {
        await this.setSelectedMonitoringProject(null)
      } else {
        this.isLoading = true
        console.log('Selected project: ', project)
        await this.getMonitoringData(project._id)
        await this.setSelectedMonitoringProject(project)
        this.isLoading = false

      }
      this.showResults = true
    },
    onFocus() {
      console.log('On search focus')
      this.showResults = true
    },
    onBlur() {
      console.log('On search blur')
    },
    onSearchTextChanged(value) {
      console.log('On search text changed: ', value)
      let queryData = this.queryData
      queryData.query = value
      this.setQueryData(queryData)
    },
    activityIcon(project) {
      if (project.status === 'Active') {
        return 'calendar-check'
      } else if (project.status === 'Inactive') {
        return 'calendar-lock'
      } else if (project.status === 'Archived') {
        return 'archive-lock-outline'
      }

      return 'alert-box'
    },
  },
  computed: {
    ...mapGetters({
      allProjects: 'projectsModule/getAllProjects',
      selectedProject: 'assessmentModule/getSelectedMonitoringProject',
      queryData: 'assessmentModule/getQueryData',
      filteredProjects: 'assessmentModule/getFilteredMonitoringProjects'
    }),
    projectTypeOptions() {
      let options = []
      options.push({icon: '', text: 'All', value: 'ALL'})
      let settings = this.$store.getters.getSettings
      if (settings && settings.projectTypes) {
        settings.projectTypes.forEach(pt => {
          options.push({icon: '', text: pt.name, value: pt.value})
        })
      }
      return options
    }
  },
  async mounted() {
    if (this.allProjects.length === 0) {
      this.isLoading = true
      await this.fetchAllProjects()
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.search-container {
  top: 0;
  left: 0;
  bottom: 0;
  height: calc(100vh - 300px);
  display: flex;
  position: absolute;
  margin: 110px auto auto 16px;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 16px !important;
  z-index: 30;
  transition: all 0.3s linear;
}

.search-box {
  cursor: pointer;
  background-color: #00000005;
  border-radius: 6px;
  box-shadow: none;
  color: hsl(0deg, 0%, 29%);
  display: block;
  padding: 8px;
  margin-bottom: 8px;
}

.selected {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>