<template>
  <div id="map-container" class="columns is-gapless" style="margin-bottom: 0" :class="mapHeight">
    <gmap-map
        ref="googleMap"
        :center="mapCenter"
        @click="onMapClicked"
        :zoom="9"
        :heading="heading"
        :options="options"
        map-type-id="terrain"
        style="width: 100%"
        class="mapbox-container">

      <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
                        @closeclick="onCloseInfoWindow">
      </gmap-info-window>

      <my-google-marker v-for="(item, index) in markers" :key="JSON.stringify(item)"
                        :item="item"
                        @onMarkerClicked="onMarkerClicked(item, index)"
                        :is-selected="selectedDevice === item"
                        :draw-mode="null">
      </my-google-marker>

      <div slot="visible">
        <transition name="fade">
          <!--        <map-legend v-if="selectedProject" @onFilterChanged="onFilterChanged"></map-legend>-->
          <map-markers-legend v-if="selectedProject" @onFilterChanged="onFilterChanged"></map-markers-legend>
        </transition>
      </div>

      <div slot="visible">
        <transition name="fade">
          <map-device-legend v-if="selectedProject"
                             :items="[{icon: 'cctv', name: 'Camera'},{icon: 'radar', name: 'Radar'},{icon: 'arrow-split-vertical', name: 'ATC'}]">

          </map-device-legend>
        </transition>
      </div>
    </gmap-map>
  </div>
</template>

<script>
import MyGoogleMarker from "@/modules/projects-module/components/my-google-marker/my-google-marker.vue";
import {mapGetters} from "vuex";
import {bbox, lineString} from "@turf/turf";
import {
  getGmapPopupContent,
  getGmapPopupDeviceContent,
  getGMapPopupSitePlanContent,
  getGMapPopupTrafficSiteContent, gMapOptions, gMapStyles, truncate
} from "@/utils/utils";
import MapDeviceLegend from "@/modules/projects-module/components/map-device-legend/map-device-legend.vue";
import MapMarkersLegend from "@/modules/projects-module/components/map-markers-legend/map-markers-legend.vue";

export default {
  name: "g-map",
  components: {MapMarkersLegend, MapDeviceLegend, MyGoogleMarker},
  data() {
    return {
      mapCenter: {lat: -37.80193, lng: 144.94444},
      mapData: [],
      mapZoom: 8,
      drawMode: null,
      heading: 0,
      options: {styles: gMapStyles},
      selectedDevice: null,
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        content: '',
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -16
        }
      },
      filter: {site: true, device: true, sitePlan: true, planDevice: true}
    }
  },
  async mounted() {
    console.log('Gmap input sites: ', this.sitePlans)
    await this.$gmapApiPromiseLazy();

    console.log('Map: ', this.$refs.googleMap)


    this.$refs.googleMap.$mapObject.addListener('projection_changed', () => {
      console.log('Map heading', this.$refs.googleMap.$mapObject.heading)
      this.heading = this.$refs.googleMap.$mapObject.heading
    });

    this.zoomMapToSelectedSiteOrProject(true)

  },
  methods: {
    onCloseInfoWindow() {
      this.infoWinOpen = false
      this.selectedDevice = null
      //this.zoomMapToSelectedSiteOrProject(true)
    },
    onMapClicked() {
      console.log('On map clicked')
      this.$emit('onMapClicked')
      this.selectedDevice = null
      if (!this.infoWinOpen) {
        this.zoomMapToSelectedSiteOrProject(true)
      }
      this.infoWinOpen = false
    },
    onMarkerClicked(device, index) {
      console.log('On marker clicked', device)
      if (this.selectedDevice === device) {
        this.selectedDevice = null
      } else {
        this.selectedDevice = device
      }

      this.zoomMapToSelectedSiteOrProject(true)
      this.toggleInfoWindow(device, index)
    },
    toggleInfoWindow(device, idx) {
      this.infoWindowPos = {lat: device.location.coordinates[1], lng: device.location.coordinates[0]};
      if (device.objectType === 'site-plan') {
        this.infoOptions.content = getGMapPopupSitePlanContent(device)
      } else if (device.objectType === 'site') {
        this.infoOptions.content = getGMapPopupTrafficSiteContent(device)
      } else if (device.objectType === 'device') {
        this.infoOptions.content = getGmapPopupDeviceContent(device)
      } else {
        this.infoOptions.content = getGmapPopupContent(device)
      }


      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }

      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    zoomMapToSelectedSiteOrProject(animate) {
      console.log('GMap, zoom to projects or sites')
      if (this.selectedProject != null) {
        //Zoom to selected site
        let coordinates = []
        if (this.selectedDevice !== null) {
          let center = {
            lat: this.selectedDevice.location.coordinates[1],
            lng: this.selectedDevice.location.coordinates[0]
          }
          this.$refs.googleMap.$mapObject.panTo(center);
          return
        } else {
          if (this.selectedProject && this.selectedProject.projectSitePlans) {
            this.selectedProject.projectSitePlans.forEach(site => {
              coordinates.push(...site.listOfPlanDevices.map(device => {
                return device.location.coordinates
              }))
            })

            if (this.selectedProject.sitesAndDevices && this.selectedProject.sitesAndDevices.length > 0) {
              this.selectedProject.sitesAndDevices.forEach(siteDev => {
                coordinates.push(siteDev.site.location.coordinates)
                if (siteDev.devices && siteDev.devices.length > 0) {
                  siteDev.devices.forEach(device => {
                    if (device.location) {
                      coordinates.push(device.location.coordinates)
                    }
                  })
                }
              })
            }
          }

        }

        if (coordinates.length > 0) {
          console.log('COORDINATES', coordinates)

          if (coordinates.length > 1) {
            let line = lineString(coordinates);
            let bb = bbox(line);
            console.log('Bounding box:', bb)
            let boundsGMap = new window.google.maps.LatLngBounds()
            boundsGMap.extend({lat: bb[1], lng: bb[0]})
            boundsGMap.extend({lat: bb[3], lng: bb[2]})
            console.log('LatLngBounds: ', boundsGMap)
            this.$refs.googleMap.$mapObject.panTo(boundsGMap.getCenter());
            this.$refs.googleMap.$mapObject.fitBounds(boundsGMap, 60)
            //this.$refs.googleMap.$mapObject.setZoom(19);
            //this.$refs.googleMap.$mapObject.panToBounds(boundsGMap, 300)
          } else {
            this.$refs.googleMap.$mapObject.panTo({lat: coordinates[0][1], lng: coordinates[0][0]});
          }


        } else if (animate) {
          this.$refs.googleMap.$mapObject.panTo(this.mapCenter);
        }
      } else {
        this.$refs.googleMap.$mapObject.panTo(this.mapCenter);
      }
    },
    planStatusColor(name) {
      if (this.$store.getters.getSettings) {
        let status = this.$store.getters.getSettings.planStatuses.find(status => status.name === name)
        if (status)
          return status.color
      }

      return '#ffffff'
    },
    deviceStatusColor(name) {
      if (this.$store.getters.getSettings) {
        let status = this.$store.getters.getSettings.deviceStatuses.find(status => status.name === name)
        if (status)
          return status.color
      }

      return '#ffffff'
    },
    onFilterChanged(data) {
      this.filter = data
      console.log('On filter changed: ', this.filter)
    }
  },
  computed: {
    gMapOptions() {
      return gMapOptions
    },
    ...mapGetters({
      selectedProject: 'projectsModule/getSelectedProject'
    }),
    mapHeight() {
      if (this.selectedProject == null) {
        return 'full-height-map'
      } else {
        return 'smaller-height-map'
      }
    },
    sitePlans() {
      if (this.selectedProject) {
        return this.selectedProject.projectSitePlans
      }
      return []
    },
    sitesAndDevices() {
      if (this.selectedProject) {
        return this.selectedProject.sitesAndDevices
      }
      return []
    },
    markers() {
      let planDevices = []
      let planSites = []
      let sites = []
      let devices = []

      let allMarkers = []
      if (this.sitePlans && this.sitePlans.length > 0) {
        console.log('Sites', this.sitePlans)
        this.sitePlans.forEach(site => {

          let siteMarker = Object.assign({}, site)
          siteMarker.objectType = 'site-plan'
          siteMarker.location = site.siteLocation
          siteMarker.color = this.planStatusColor(site.status)

          let devs = site.listOfPlanDevices
          devs.forEach(dev => {
            dev.siteName = site.siteName
            dev.status = site.status
            dev.color = this.planStatusColor(site.status)
          })

          siteMarker.deviceNames = devs.map(dev => dev.name)

          if (this.filter.sitePlan) {
            planSites.push(siteMarker)
          }

          if (this.filter.planDevice) {
            planDevices.push(...devs)
          }

        })

        console.log('Devices: ', planDevices)
      }

      if (this.sitesAndDevices && this.sitesAndDevices.length > 0) {
        this.sitesAndDevices.forEach(siteAndDevices => {
          let siteMarker = Object.assign({}, siteAndDevices.site)
          siteMarker.objectType = 'site'
          siteMarker.shortName = truncate(siteMarker.name, 15)
          siteMarker.location = siteAndDevices.site.location
          siteMarker.deviceNames = siteAndDevices.devices.map(device => device.name)

          if (this.filter.site) {
            sites.push(siteMarker)
          }


          let devs = siteAndDevices.devices
          devs.forEach(dev => {
            dev.objectType = 'device'
            dev.siteName = siteAndDevices.site.name
            dev.color = this.deviceStatusColor(dev.currentStatus)

            if (dev.location && this.filter.device) {
              devices.push(dev)
            }
          })
        })
      }

      allMarkers.push(...planSites)
      allMarkers.push(...sites)
      allMarkers.push(...planDevices)
      allMarkers.push(...devices)

      console.log('All markers: ', allMarkers)

      return allMarkers
    }
  },
  watch: {
    selectedProject: {
      handler: function (newVal, oldVal) {
        console.log('Selected project changed:', this.selectedProject)
        this.zoomMapToSelectedSiteOrProject(true)
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>